import axios from 'axios'

import { RULEBOOK_URL } from './urls'
import { logAxiosError } from './functions';
import { AXIOS_TIMEOUT } from './constants'

let cacheRulebook = null

export function requestRulebook(refresh) {
    if(refresh){
        cacheRulebook = null
    }
    if(!cacheRulebook){
        //fetch the configuration variables
        
        const getRulebook = async () => {
            let res 

            try{
                res = await axios({
                    method: 'GET',
                    timeout: AXIOS_TIMEOUT,
                    url: RULEBOOK_URL,
                })
                //console.log('config-res', res)
            } catch(error) {
                console.log('Error fetching rulebook')
                logAxiosError(error)           
            }
        
            let rulebookData = res.data
            return rulebookData
        }  
        cacheRulebook = getRulebook()
        return cacheRulebook
    }
    return cacheRulebook
}
