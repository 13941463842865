import axios from 'axios'

import { CONFIG_URL } from './urls'
import { logAxiosError } from './functions';
import { AXIOS_TIMEOUT } from './constants'

let cacheConfig = null

export function requestConfig(refresh) {
    if(refresh){
        cacheConfig = null
    }
    if(!cacheConfig){
        //fetch the configuration variables
        
        const getConfig = async () => {
            let res 

            try{
                res = await axios({
                    method: 'GET',
                    timeout: AXIOS_TIMEOUT,
                    url: CONFIG_URL,
                })
                //console.log('config-res', res)
            } catch(error) {
                console.log('Error fetching config')
                logAxiosError(error)           
            }
        
            let configValues = res.data
            return configValues
        }  
        cacheConfig = getConfig()
        return cacheConfig
    }
    return cacheConfig
}
