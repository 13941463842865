import React, {useState, useContext, useEffect} from 'react'
import axios from 'axios'
import Alert from 'react-bootstrap/Alert'

import {UserContext} from '../../context/UserContext'

import { capitalize, setUserRole } from '../../utils/functions'

import * as Constants from '../../Constants'
import { AUTH_LOCAL_REGISTER_URL } from '../../utils/urls'
import { logAxiosError } from '../../utils/functions';
import { AXIOS_TIMEOUT } from '../../utils/constants'

import { MAX_LENGTH_EMAIL, MAX_LENGTH_TEXT } from '../../utils/constants'

import './Auth.css'

const Signup = ({history, updateChildMsg, configValues}) => {
    const { signupAllowed, currentSeason } = configValues

    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [consent, setConsent] = useState(true)
    const [confirmPassword, setConfirmPassword] = useState('')
    const [error, setError] = useState('')

    const [user, setUser] = useContext(UserContext)

    //let nextPage = '/register/info'
    let nextPage = '/'

    //console.log('signin user: ', user)
    useEffect(()=>{
        if(!signupAllowed){
            //signup is not allowed. Navigate user to the Home Page
            history.push('/')
        }
    },[])

    useEffect(() => {
        if(user){
            history.push(nextPage)
        }
    }, [history, user])

    const handleFirstName = (event) => {
        setFirstName(event.target.value)
        
        if(event.target.value.length > MAX_LENGTH_TEXT ) {
            setError('First Name is too long')
        } else {
            setError('')
        }
    }

    const handleLastName = (event) => {
        setLastName(event.target.value)
        
        if(event.target.value.length > MAX_LENGTH_TEXT ) {
            setError('Last Name is too long')
        } else {
            setError('')
        }
    }

    const handleEmail = (event) => {
        setEmail(event.target.value)
        
        if(event.target.value.length > MAX_LENGTH_EMAIL ) {
            setError('Email is too long')
        } else {
            setError('')
        }
    }

    const handlePassword = (event) => {
        setPassword(event.target.value)
        
        if(event.target.value.length > MAX_LENGTH_TEXT ) {
            setError('password is too long')
        } else {
            setError('')
        }
    }

    const handleConfirmPassword = (event) => {
        setConfirmPassword(event.target.value)
        
        if(event.target.value.length > MAX_LENGTH_TEXT ) {
            setError('password is too long')
        } else {
            setError('')
        }
    }

    const handleConsent = (event) => {
        setConsent(event.target.checked)
        setError('')
    }

    const validateInput= () => {
        var validInput = true;

        if (firstName === '') {
            setError('Please enter your First Name')
            validInput = false
        }
        
        if (validInput && firstName.length > MAX_LENGTH_TEXT) {
            setError('First Name is too long')
            validInput = false
        }

        if (validInput && lastName === '') {
            setError('Please enter your Last Name')
            validInput = false
        }

        if (validInput && lastName.length > MAX_LENGTH_TEXT) {
            setError('Last Name is too long')
            validInput = false
        }

        if (validInput && email === '') {
            setError('Please enter an email id')
            validInput = false
        } 

        if (validInput && email.length > MAX_LENGTH_EMAIL) {
            setError('Email is too long')
            validInput = false
        }

        if (validInput && password.length < 9) {
            setError('Please enter a password greater than 8 characters')
            validInput = false
        } 

        if (validInput && password.length > MAX_LENGTH_TEXT) {
            setError('Password is too long')
            validInput = false
        }
        
        if (validInput &&  (password !== confirmPassword)) {
            setError('Password mismatch. Please re-enter passwords')
            setPassword('')
            setConfirmPassword('')
            validInput = false
        } 
        
        return validInput
    }

    const handleSubmit = async (event) => {
        event.preventDefault()

        const validInput = validateInput()
        
        if (validInput){    
            const postSignup = async () => {
                let res 

                try{
                    res = await axios({
                        method: 'POST',
                        timeout: AXIOS_TIMEOUT,
                        url: AUTH_LOCAL_REGISTER_URL,
                        data: {
                            firstName: firstName,
                            lastName: lastName,
                            username: email,
                            email,
                            password,
                            consent
                        }
                    })
                } catch (error) {
                    setError('Error when connecting to server ')
                    logAxiosError(error)
                }
                
                const {data} = res

                //console.log('status: ', data.status)
                if (data.status === Constants.RETURN_CODE_OK) {
                    let userRole = setUserRole(data.user)
                    setUser({...data.user, ...userRole})
                    
                    let userMessage = capitalize(firstName) + `, thank you for signing up. Please check the registraton updates for more information`

                    updateChildMsg('', userMessage , Constants.MSG_TYPE_SUCCESS, 6000)
                } else if (data.status === Constants.RETURN_CODE_NOT_AUTH) {
                    setError('Error: Email id already in use')
                    //Clear the form
                    setEmail('')
                    setPassword('')
                    setConfirmPassword('')
                } else {
                    setError('Request failed. Please try again later')
                    //Clear the form
                    setEmail('')
                    setPassword('')
                    setConfirmPassword('')
                }
            }  
            postSignup()
        }    
    }

    return (
        <div className="Auth">
            <h2> Signup </h2>

            {error &&
                <Alert variant="danger" onClose={() => setError('')} dismissible>
                    <Alert.Heading>Error!</Alert.Heading>
                    <p>
                        {error}
                    </p>
                </Alert>                
            }

            <form onSubmit={handleSubmit}>
                First Name
                <input 
                    type="text"
                    name="firstname"
                    value={firstName}
                    placeholder="First Name"
                    maxLength={MAX_LENGTH_TEXT}
                    onChange={handleFirstName}
                />
                
                Last Name
                <input 
                    type="text"
                    name="lastname"
                    value={lastName}
                    placeholder="Last Name"
                    maxLength={MAX_LENGTH_TEXT}
                    onChange={handleLastName}
                />

                Email
                <input 
                    type="email"
                    name="email"
                    value={email}
                    placeholder="Email Address"
                    maxLength={MAX_LENGTH_EMAIL}
                    onChange={handleEmail}
                />
                
                Password
                <input 
                    type="password"
                    name="password"
                    value={password}
                    placeholder="password"
                    maxLength={MAX_LENGTH_TEXT}
                    onChange={handlePassword}
                />  
                
                Re-enter password
                <input 
                    type="password"
                    name="confirmpassword"
                    value={confirmPassword}
                    placeholder="re-enter password"
                    maxLength={MAX_LENGTH_TEXT}
                    onChange={handleConfirmPassword}
                />

                <label className="Consent">
                    <input
                        type="checkbox"
                        checked={consent}
                        onChange={handleConsent}
                    />
                    Keep me posted on club news
                </label>

                <button type="submit"> Signup </button>
            </form>
            <div className="Spacer"></div>
        </div>
    )    
}

export default Signup
