import React from 'react'

import './Phonebook.css'

const PhonebookEntry = ({ phonebookList }) =>  {   
    return(
        phonebookList.map((entry, idx)=> { 
            let key = entry.key

            if(!key){
                return null
            }

            let fnIdx = `fn-${key}`
            let lnIdx = `ln-${key}`
            let pnIdx = `pn-${key}`

            return (
                <>
                    {key &&
                        <tr key={key.toString()}>
                            <td key={fnIdx}>{entry.firstName}</td>    
                            <td key={lnIdx}>{entry.lastName}</td>
                            <td key={pnIdx}>{entry.phoneNumber}</td>
                        </tr>    
                    }
                </>
            )
        })
    )
}  

export default PhonebookEntry
