import React from 'react'

import { MIN_CREDITS } from './../../utils/constants'

const CreditsAndBilling = (props) => {
    const { 
        billingFields,
        confirmEntries,
        serviceFee,
        availableCredits,
        maxCreditsAllowed
    } = props

    let {
        firstName,
        lastName,
        address,
        city,
        postal,
        phone,
        email,
        credits,
        creditsSubTotal,
        totalAmount,
        hst
    } = billingFields
    
    const onChangeHandler = (e) =>{
        return null
    }

    let creditsPurchaseLabel1 = ''
    let creditsPurchaseLabel2 = ''

    if(MIN_CREDITS === maxCreditsAllowed){
        creditsPurchaseLabel2 = `You can purchase ${MIN_CREDITS} credits only`
    } else {
        creditsPurchaseLabel1 = `You can purchase between ${MIN_CREDITS} and ${maxCreditsAllowed} credits`
        creditsPurchaseLabel2 = 'Number of Credits to purchase (minimum: 5)'
    }

    return(
        <>
            <div className="section"><span>1</span>Available Credits</div>
            
            <div className="inner-wrap">
                <label>Your current balance is </label>
                <label 
                    style={{
                        fontSize: "larger"
                    }}
                >
                    {`${availableCredits} Credits`} 
                </label>

                {maxCreditsAllowed === 0 &&
                    <label>Sorry, you cannot purchase any more credits at this time </label>
                }
            </div>

            {maxCreditsAllowed > 0 && 
            <>
                <div className="section"><span>2</span>Purchase Credits</div>
                <div className="inner-wrap">
                    <label>{creditsPurchaseLabel1}</label>
                    <label htmlFor="credits">{creditsPurchaseLabel2}<span className="required">*</span> </label>
                    <input
                        type="number"
                        step="1"
                        name="credits"
                        value={credits}
                        onChange={onChangeHandler}
                    />
                </div>        

                <div className="section"><span>3</span>Billing Name & Address</div>
                <div className="inner-wrap">
                <label htmlFor="firstName">Billing First Name <span className="required">*</span> </label>
                    <input 
                        type="text" 
                        name="firstName" 
                        value={firstName}
                        onChange={onChangeHandler}
                    />

                    <label htmlFor="lastName">Billing Last Name<span className="required">*</span> </label>
                    <input 
                        type="text" 
                        name="lastName" 
                        value={lastName}
                        onChange={onChangeHandler}
                    />

                    <label htmlFor="address">Billing Address<span className="required">*</span> </label>
                    <textarea 
                        name="address" 
                        value={address}
                        onChange={onChangeHandler}
                    />

                    <label htmlFor="city">City<span className="required">*</span> </label>
                    <input 
                        type="text" 
                        name="city" 
                        value={city}
                        onChange={onChangeHandler}
                    />

                    <label htmlFor="postal">Postal Code<span className="required">*</span> </label>
                    <input 
                        type="text" 
                        name="postal"
                        maxLength="7" 
                        value={postal} 
                        onChange={onChangeHandler}
                    />
                </div>

                <div className="section"><span>4</span>Email & Phone</div>
                <div className="inner-wrap">
                    <label htmlFor="email">Email<span className="required">*</span> </label>
                    <input 
                        type="email" 
                        name="email" 
                        value={email}
                        onChange={onChangeHandler}
                    />

                    <label htmlFor="phone">Phone<span className="required">*</span> </label>
                    <input 
                        type="text" 
                        name="phone" 
                        maxLength="10"
                        value={phone}
                        onChange={onChangeHandler}
                    />
                </div>

                {creditsSubTotal && totalAmount ?
                <>
                    {hst && hst > 0 ?
                    <>
                        <div className="section"><span>5</span>Summary</div>
                        <div className="inner-summarywrap">
                            <table>
                                <tbody>
                                    <tr>
                                        <td>Number of Credits: </td>
                                        <td>{credits}</td>
                                    </tr>
                                
                                    <tr>
                                        <td>Credits Sub Total: </td>
                                        <td>${creditsSubTotal.toFixed(2)}</td>
                                    </tr>
                                
                                    
                                    <tr>
                                        <td>HST:</td>
                                        <td>${hst.toFixed(2)}</td>
                                    </tr>
                                    
                                    <tr>
                                        <td>Service Fee:</td>
                                        <td>${serviceFee.toFixed(2)}</td>
                                    </tr>     
                                    
                                    <tr>
                                        <td>Total Amount:</td>
                                        <td>${totalAmount.toFixed(2)}</td>
                                    </tr>
                                </tbody>                                                                                                        
                            </table>
                        </div>
                    </>:    
                    <>
                        <div className="section"><span>5</span>Summary</div>
                        <div className="inner-summarywrap">
                            <table>
                                <tbody>
                                    <tr>
                                        <td>Number of Credits: </td>
                                        <td>{credits}</td>
                                    </tr>
                                
                                    <tr>
                                        <td>Credits Sub Total: </td>
                                        <td>${creditsSubTotal.toFixed(2)}</td>
                                    </tr>
                                
                                    <tr>
                                        <td>Service Fee:</td>
                                        <td>${serviceFee.toFixed(2)}</td>
                                    </tr>     
                                    
                                    <tr>
                                        <td>Total Amount:</td>
                                        <td>${totalAmount.toFixed(2)}</td>
                                    </tr>                                                                                                        
                                </tbody>    
                            </table>
                        </div>
                    </>
                    }
                </> : <></>
                }

                {credits > 0 ?
                <div className="button-section">
                    <span className="privacy-policy">
                        <label className="checkbox" htmlFor="confirmEntries">
                            I have verified credits purchased and billing information
                            <input 
                                type="checkbox" 
                                name="confirmEntries"
                                checked={confirmEntries}
                                onChange={onChangeHandler}
                            /> 
                        </label>
                    </span>
                    <button type="submit"> Enter Credit Card Details</button>
                </div>:
                <div className="inner-wrap">
                    <label style={{
                        color: "red",
                        fontSize: "large"
                    }}>
                        Select Number of Credits to continue ...
                    </label>    
                </div>
                }
            </>
            }        
        </>  
    )
}

export default CreditsAndBilling