import React from 'react'
import * as Constants from '../../Constants'

import { NTRP_RATING_OPTIONS } from '../../utils/constants'

const adultTextTooltip = "Year of Birth is optional for adult members"
const juniorTextTooltip = "Year of Birth is mandatory for junior members"

const Membersinfo = (props) => {
    const { members, deleteMember, waitlist } = props
    
    if(!members){
        return null
    } 

    const handleDeleteMember = (e) => {
        e.preventDefault()
        let memberId = e.target.id
        deleteMember(memberId)
    }

    const onChangeHandler = (e) =>{
        return null
    }

    return(
        members.map((member, idx)=> {
            let adult = false
            let label = "Adult"

            if(member.memberType === "adult"){
                adult = true
                label = "Adult"
            } else {
                label = "Junior"
            }
            
            let firstNameId = `firstName-${idx}`
      
            let lastNameId = `lastName-${idx}`
            let yearOfBirthId = `yearOfBirth-${idx}`
            let ntrpSelfId = `ntrpSelf-${idx}`
            let emailId = `email-${idx}`
            let consentEmailContactId = `consentEmailContact-${idx}`
            let consentNewsLetterId = `consentNewsLetter-${idx}`
      
            let phoneId = `phone-${idx}`
            let consentPhoneBookId = `consentPhoneBook-${idx}`
            let deleteId = `delete-${idx}`
      
            var memberHeading = 'Member Details'
      
            if(idx === 0){
              memberHeading = `1st Member - ${label}`
            } else if (idx === 1) {
              memberHeading = `2nd Member - ${label}` 
            } else if (idx === 2) {
              memberHeading = `3rd Member - ${label}`
            } else if (idx > 2) {
              memberHeading = `${idx+1}th Member - ${label}`        
            }
      
            return (
                <div key={idx}>
                    <div className="inner-wrap">
                        <span className="delete-member">
                            <i className="fa fa-trash-alt"
                                onClick={handleDeleteMember} 
                                id={idx}
                                name={deleteId}
                            >
                                <span className="tooltiptext">Click to delete member</span>
                            </i>
                            
                        </span>  
                        
                        <span>
                            {memberHeading}  
                        </span>
                        
                        <p></p>  
                        <label htmlFor={firstNameId}>First Name <span className="required">*</span> </label>
                        <input 
                            type="text"
                            name={firstNameId}
                            data-id={idx}
                            id={firstNameId}
                            value={members[idx].firstName} 
                            className="firstName"
                            onChange={onChangeHandler}
                        />
                        
                        <p></p>  
                        <label htmlFor={lastNameId}>Last Name <span className="required">*</span> </label>
                        <input
                            type="text"
                            required
                            name={lastNameId}
                            data-id={idx}
                            id={lastNameId}
                            value={members[idx].lastName} 
                            className="lastName"
                            onChange={onChangeHandler}
                        />
        
                        <p></p>
                        <label 
                            htmlFor={ntrpSelfId}>NTRP rating - Self Assessed <span className="required">*</span> 
                            <p></p>
                            <a href={Constants.NTRP_INFO_URL} target="_blank">Click here for NTRP information</a>
                        </label>

                        <select 
                            name={ntrpSelfId}
                            className="ntrpSelf"  
                            size="1"
                            data-id={idx}
                            id={ntrpSelfId}
                            value={members[idx].ntrpSelf}  
                            onChange={onChangeHandler}
                        >    
                            {NTRP_RATING_OPTIONS}
                        </select> 
                        
                        <p></p>  
                        {adult ?
                            <>
                                <label 
                                    className='yobLabel'
                                    htmlFor={yearOfBirthId}>Year of Birth 
                                    
                                    <i className="fas fa-info-circle">
                                        <span className="tooltiptext">{adultTextTooltip}</span>
                                    </i>
                                </label>

                                <input
                                    type="number"
                                    name={yearOfBirthId}
                                    data-id={idx}
                                    id={yearOfBirthId}
                                    value={members[idx].yearOfBirth} 
                                    className="yearOfBirth"
                                    onChange={onChangeHandler}
                                />
                            </>
                            :                      
                            <>
                                <label className='yobLabel' htmlFor={yearOfBirthId}>
                                    <span>
                                        Year of Birth <span className="required">*</span>  
                                    </span>
                                        
                                    
                                    <i className="fas fa-info-circle">
                                        <span className="tooltiptext">{juniorTextTooltip}</span>
                                    </i>
                                </label>

                                <input
                                    type="number"
                                    required
                                    name={yearOfBirthId}
                                    data-id={idx}
                                    id={yearOfBirthId}
                                    value={members[idx].yearOfBirth} 
                                    className="yearOfBirth"
                                    onChange={onChangeHandler}
                                />
                            </>  
                        }

                        {adult && !waitlist &&
                        <> 
                            <p></p>  
                            <label htmlFor={emailId}>Email</label>
                            <input
                                type="email"
                                name={emailId}
                                data-id={idx}
                                id={emailId}
                                value={members[idx].email} 
                                className="email"
                                onChange={onChangeHandler}
                            />

                            {members[idx].email && members[idx].showNewsLetterConsent && 
                                <label htmlFor={consentNewsLetterId} className="checkbox">
                                    <p></p>  
                                    Member wishes to receive club news
                                    <input 
                                        type="checkbox"
                                        name={consentNewsLetterId}
                                        data-id={idx}
                                        id={consentNewsLetterId}
                                        checked={members[idx].consentNewsLetter} 
                                        className="consentNewsLetter"
                                        onChange={onChangeHandler}
                                    />
                                </label>     
                            }

                            <p></p>  
                            <label htmlFor={phoneId}>Phone Number (numbers only - no spaces)</label>
                            <input
                                type="number"
                                name={phoneId}
                                data-id={idx}
                                id={phoneId}
                                value={members[idx].phoneNumber} 
                                className="phoneNumber"
                                maxLength="10"
                                onChange={onChangeHandler}
                            />
                        
                            {members[idx].phoneNumber &&
                            <>
                                <label htmlFor={consentPhoneBookId}  className="checkbox">
                                    <p></p>  
                                    Add phone number to club directory
                                    <input
                                        type="checkbox"
                                        name={consentPhoneBookId}
                                        data-id={idx}
                                        id={consentPhoneBookId}
                                        checked={members[idx].consentPhoneBook} 
                                        className="consentPhoneBook"
                                        onChange={onChangeHandler}
                                    />
                                </label>
                            </>
                            }
                        </>
                        }
                    </div>
                </div>
            )
        })
    )
}

export default Membersinfo