export const capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
}

export function compareValues(key, order = 'asc') {
    return function innerSort(a, b) {
      if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
        // property doesn't exist on either object
        return 0;
      }
  
      const varA = (typeof a[key] === 'string')
        ? a[key].toUpperCase() : a[key];
      const varB = (typeof b[key] === 'string')
        ? b[key].toUpperCase() : b[key];
  
      let comparison = 0;
      if (varA > varB) {
        comparison = 1;
      } else if (varA < varB) {
        comparison = -1;
      }
      return (
        (order === 'desc') ? (comparison * -1) : comparison
      );
    };
}

export function setUserRole(user){
  let role = {
    isMember: false,
    isAuth: false,
    isStaff: false,
    isExec: false,
    isInstructor: false,
    isAdmin: false
  }
  if(user.role === "Authenticated"){
    role.isAuth = true
  }

  if(user.role === "Member"){
    role.isMember = true
  }

  if(user.role === "Executive"){
    role.isExec = true
  }

  if(user.role === "Staff"){
    role.isStaff = true
  }

  if(user.role === "Instructor"){
    role.isInstructor = true
  }

  if(user.role === "Admin"){
    role.isAdmin = true
  }

  return role
}

export function generateIdempotencyKey () {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
  var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
  return v.toString(16);
  });
}

export function logAxiosError (error) {
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    console.log('error data', error.response.data);
    console.log('error status', error.response.status);
    console.log('error headers', error.response.headers);
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    console.log('error request', error.request);
  } else {
    // Something happened in setting up the request that triggered an Error
    console.log('Error', error.message);
  }
  console.log(error.config);
}

export function getNtrpLabel (rating) {
  let ntrpLabel = ''

  switch (rating) {
    case 2.5:
      ntrpLabel = 'less than 3.0 - Beginner';
      break;
    case 3.5:
      ntrpLabel = '3.0 to 4.0 - Intermeditate'
      break;
    case 4.5:
      ntrpLabel = '4.0 to 5.0 - Advanced'
      break;
    case 5.5:
      ntrpLabel = '5.0+ - Open Level'
      break;
    default:
      ntrpLabel = 'Rating not provided'    
      break; 
  }

  return ntrpLabel
}
