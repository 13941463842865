import React, { useEffect, useState } from 'react'
import {requestFees} from '../../utils/fetchFees'

import './Fees.css'

const Fees = () => {
    const [fees, setFees] = useState([{}])
    const [discounts, setDiscounts] = useState([{}])
    
    let refreshFees = false

    useEffect(()=>{
        requestFees(refreshFees).then(data => {
            setFees(data.outputFees)
            setDiscounts(data.outputDiscounts)
        })
    },[])

    return(
        <div className="Fees">
            <h3> Membership Fees </h3>

            {fees && 
                <>
                    <h4> Current Fees</h4>
                    
                    <div className="Membership">
                        <div className="table-scroll">
                            <table>
                                <thead>
                                    <tr>
                                        <th>
                                            <span className="text" >Membership Type</span>
                                        </th>
                                        <th>
                                            <span className="text" >Season Fee</span>
                                        </th>
                                        <th>
                                            <span className="text" >Discounts</span>
                                        </th>
                                        <th>
                                            <span className="text" >Total Fee</span>
                                        </th>
                                    </tr>
                                </thead>        
                                <tbody>
                                    {fees.map((fee, idx) =>{
                                        return(
                                            <tr>
                                                <td>
                                                    <span className="text">{fee.membershipLabel}</span>   
                                                </td>
                                                <td>
                                                    <span className="text">${fee.amount}</span>   
                                                </td>
                                                <td>
                                                    <span className="text">${fee.discountAmount}</span>   
                                                </td>
                                                <td>
                                                    <span className="text">${fee.totalAmount}</span>   
                                                </td>
                                            </tr>    
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </>
            }

            {discounts && 
                <>
                    <h4> Discounts </h4>

                    <div className="Membership">
                        <div className="table-scroll">
                            <table>
                                <thead>
                                    <tr>
                                        <th>
                                            <span className="text" >Membership Type</span>
                                        </th>
                                        <th>
                                            <span className="text" >Discount</span>
                                        </th>
                                        <th>
                                            <span className="text" >Start Date</span>
                                        </th>
                                        <th>
                                            <span className="text" >End Date</span>
                                        </th>
                                    </tr>
                                </thead>        
                                <tbody>
                                    {discounts.map((discount, idx) =>{
                                        return(
                                            <tr>
                                                <td key={`${idx}-${discount.membershipLabel}`}>
                                                    <span className="text">{discount.membershipLabel}</span>   
                                                </td>
                                                <td>
                                                    <span className="text">${discount.amount}</span>   
                                                </td>
                                                <td>
                                                    <span className="text">{discount.startDate}</span>   
                                                </td>
                                                <td>
                                                    <span className="text">{discount.endDate}</span>   
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </>
            }            
         </div>
    )
}

export default Fees