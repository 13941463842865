import axios from 'axios'

import { CREDITS_URL } from './urls'
import { logAxiosError } from './functions';
import { AXIOS_TIMEOUT } from './constants'

//import testList from './phonebook.json'

let cacheCredits = null

export function requestCreditsList(refresh, requestedSeason) {
    if(refresh){
        cacheCredits = null
    }
    if(!cacheCredits){
        //fetch the list of members
        
        const getCreditsList = async () => {
            let res

            try{
                res = await axios({
                    method: 'GET',
                    timeout: AXIOS_TIMEOUT,
                    url: CREDITS_URL,
                    params: {
                        requestedSeason: requestedSeason
                    }
                })
            } catch(error){
                console.log('Error fetching credits list')
                logAxiosError(error)     
            }
        
            console.log('credits response: ', res)
            const {data} = res
            //console.log('data: ', data.data)

            //console.log('phone-data: ', data)
            let creditsList = data.data.data
            return creditsList
        }  
        
        cacheCredits = getCreditsList()
        return cacheCredits
    }
    return cacheCredits
}
