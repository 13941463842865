import React, { useEffect, useState } from 'react'
import axios from 'axios'

import { HOMEPAGE_URL } from '../../utils/urls'
import { logAxiosError } from '../../utils/functions';
import { AXIOS_TIMEOUT } from '../../utils/constants'


import './Notification.css'
import * as Constants from '../../Constants'

const Notification = (props) => {

    let {
        history,
        user,
        updateChildMessage,
        handleRefreshHomePage,
        infobar,
        infobarType
    } = props


    const [message, setMessage] = useState()
    const [messageType, setMessageType] = useState()
    const [transactionCompleted, setTransactionCompleted] = useState(false)
    const [authorizedUser, setAuthorizedUser] = useState(false)
    
    useEffect(()=> {
        setMessage(infobar)
        setMessageType(infobarType)
    },[infobar, infobarType])

    useEffect(()=> {
        if(!user){
            updateChildMessage("", "Please Login to continue...", "primary", 3000)
            history.push('/login')
        } else if(user.isStaff || user.isExec || user.isAdmin) {
            setAuthorizedUser(true)
        } else {
            setAuthorizedUser(false)
            history.push('/')
        }

        setTransactionCompleted(false)
    },[])


    useEffect(()=> {
        if(transactionCompleted){
            history.push('/')
        }

    },[transactionCompleted])

    const onInfobarChangeHandler = (e) => {
        setMessage(e.target.value)
    }

    const onMsgTypeChangeHandler = (e) => {
        setMessageType(e.target.value)
    }

    const handleRegFormSubmit = (e) => {
        e.preventDefault()

        const putMessageUpdate = async () => {
            let res 
            let processRequest = true

            try {
                res = await axios({
                    method: 'PUT',
                    timeout: AXIOS_TIMEOUT,
                    url: HOMEPAGE_URL,
                    data: {
                        messageBoard: message,
                        messageType: messageType
                    }  
                })    
            } catch (error) {
                logAxiosError(error)
                updateChildMessage("Failed", "Error updating notifications", "danger", 5000)
                processRequest = false
            }
            
            //console.log('res', res)
            if(processRequest){
                if (res.status === Constants.RETURN_CODE_OK) {  
                    //refresh home page on successful update
                    handleRefreshHomePage(true)
                    updateChildMessage("Success", "Notifiation updated successfully", "success", 5000)
                    setTransactionCompleted(true)
                }
            }
        }

        putMessageUpdate()        
    }

    return(
        <div className="Notification">
            <h3> View & Update Notifications </h3>

            {authorizedUser &&
                <div className="form-style-10">
                <form onSubmit={handleRegFormSubmit}>
                    <div className="section"><span>N</span>Notification Message</div>
                    <div className="inner-wrap">
                        <label htmlFor="message">Message </label>
                        <textarea 
                            name="message" 
                            value={message}
                            onChange={onInfobarChangeHandler}
                        />
                    </div>

                    <label htmlFor="messageType">Message Type </label>
                    <select 
                        name="messageType"  
                        size="1"
                        value={messageType} 
                        onChange={onMsgTypeChangeHandler}
                    >
                        <option>Select a Message Type </option>
                        <option value="alert">Alert </option>
                        <option value="warning">Warning </option>
                        <option value="information">Information </option>
                    </select> 
                    <button type="submit"> Update Message </button>
                </form>
                </div>
            }
            {!authorizedUser && 
                <h4> Access Denied </h4> 
            }
            
        </div>
    )
}

export default Notification
