import React, {useState, useEffect, useContext} from 'react';
import {BrowserRouter, Switch, Route, Link } from 'react-router-dom'
import axios from 'axios';
import { useIdleTimer } from 'react-idle-timer'
//import { socket } from '../config/web-sockets';

import Alert from 'react-bootstrap/Alert'

import NavBar from '../components/NavBar';
import Footer from '../components/Footer';
import Home from '../pages/public/Home';

import Contact from '../pages/public/Contact';
import Rulebook from '../components/Rulebook';
import Lessons from '../pages/public/Lessons';
import Fees from '../pages/public/Fees';
import MembershipInfo from '../pages/public/MembershipInfo';
import MembershipFees from '../pages/public/MembershipFees';

import EventInfo from '../pages/events/EventInfo';
import CurrentEvents from '../pages/events/CurrentEvents';
import ManageEvents from '../pages/events/ManageEvents';
import CreateEvent from '../pages/events/CreateEvent';

import EventList from '../pages/events/EventList'
import FamilyList from '../pages/events/FamilyList'
import EditEvent from '../pages/events/EditEvent'

import CourtBooking from '../pages/courtBookings/CourtBooking';

import Login from '../pages/auth/Login';
import ChangePassword from '../pages/auth/ChangePassword';
import Signup from '../pages/auth/Signup';
import Signout from '../components/Signout';
import Forgot from '../pages/auth/Forgot';
import Reset from '../pages/auth/Reset';

import Profile from '../pages/members/Profile'
import CreditsForm from '../pages/credits/CreditsForm'
import CreditsFormStaff from '../pages/credits/CreditsFormStaff'
import CreditsFormNew from '../pages/credits/CreditsFormNew'

import Calendar from '../pages/public/Calendar'

import RegisterInfo from '../pages/registeration/RegisterInfo';
import WaitlistInfo from '../pages/registeration/WaitlistInfo';
import ViewRegistration from '../pages/registeration/ViewRegistration';
import UpdateRegistration from '../pages/registeration/UpdateRegistration'
import UpdateMember from '../pages/registeration/UpdateMember'
import RegistrationForm from '../pages/registeration/RegistrationForm';
import RegistrationFormStaff from '../pages/registeration/RegistrationFormStaff';
import RegistrationFormPhone from '../pages/registeration/RegistrationFormPhone';
import WaitlistForm from '../pages/registeration/WaitlistForm';
import UpdateWaitlistMembership from '../pages/registeration/UpdateWaitlistMembership'

import Phonebook from '../pages/members/Phonebook'
import PhonebookTest from '../pages/members/PhonebookTest'

import Members from '../pages/members/Members'
import SingleMember from '../pages/members/SingleMember'
import Notification from '../pages/restricted/Notification'
import Transactions from '../pages/members/Transactions'

import Waitlist from '../pages/members/Waitlist';

import PmtSummary from '../pages/financials/PmtSummary'
import CreditsRep from '../pages/financials/CreditsRep'

import Camera from '../pages/restricted/Camera'

import {UserContext} from '../context/UserContext';

import Blank from '../pages/public/Blank';

import './App.css'
import 'bootstrap/dist/css/bootstrap.min.css';

import Logo from './wptc-logo.jpg';

import * as Constants from '../Constants';

import { IDLE_TIMEOUT, WARN_TIMEOUT, AXIOS_TIMEOUT } from '../utils/constants'
import { HOMEPAGE_URL, SIGNOUT_URL, RESOURCES_URL } from '../utils/urls'
import { logAxiosError, compareValues } from '../utils/functions';
import { requestConfig } from '../utils/fetchConfig';


function App() {
  
  const [homePage, setHomePage] = useState([])
  const [message, setMessage] = useState('')
  const [messageType, setMessageType] = useState('')
  const [childMsgTitle, setChildMsgTitle] = useState('')
  const [childMsg, setChildMsg] = useState('')
  const [childMsgType, setChildMsgType] = useState('')
  const [remainingTime, setRemainingTime] = useState(WARN_TIMEOUT)
  const [startTimer, setStartTimer] = useState(false)
  const [userActive, setUserActive] = useState(true)
  const [timeoutId, setTimeoutId] = useState()
  const [childTimeoutId, setChildTimeoutId] = useState()
  const [refreshPhonebook, setRefreshPhoneBook] = useState(false)
  const [refreshMembers, setRefreshMembers] = useState(false)
  const [refreshTransactions, setRefreshTransactions] = useState(false)
  const [refreshWaitlist, setRefreshWaitlist] = useState(false)
  const [refreshCredits, setRefreshCredits] = useState(false)
  const [refreshHomePage, setRefreshHomePage] = useState(false)
  const [infobar, setInfobar] = useState()
  const [infobarType, setInfobarType] = useState()
  const [configValues, setConfigValues] = useState({})
  const [resourceLinks, setResourceLinks] =useState([{}])
  
  //const [refreshEvents, setRefreshEvents] = useState(false)

  const [signout,setSignout] = useState(false)

  const [user, setUser] = useContext(UserContext)
  
  const updateUser = (user) => {
    setUser(user)
  }

  // The messages use React Bootstrap Alerts that have 8 variants:
  // "primary", "success", "danger" & "warning" are commonly used

  const updateChildMsg = (title, message, messageType, timeOut) => {
    //console.log('showing child message', message)
    //clear any previous timeout

    clearTimeout(childTimeoutId)
    setChildMsgTitle(title)   
    setChildMsg(message)
    setChildMsgType(messageType)
    
    if (timeOut > 0) {
      const childTimeoutId = setTimeout(() => {
        setChildMsg('')
      }, timeOut)
      setChildTimeoutId(childTimeoutId)
    }

    if(message > ' '){
      //scroll to the top to show the message if it is greater than spaces
      window.scrollTo(0, 0)
    }
  }

  const updateMessageBoard = (message, type) => {
    setMessage(message)
    let msgType = "primary"

    if (type === "warning") {
      msgType = type
    } else if (type === "alert") {
      msgType = "danger"  
    } 

    setMessageType(msgType)
  }

  const handleRefreshPhonebook = (refresh) => {
    if(refresh){
      setRefreshPhoneBook(true)  
    } else {
      setRefreshPhoneBook(false)
    }
  }

  const handleRefreshMembers = (refresh) => {
    if(refresh){
      setRefreshMembers(true)  
    } else {
      setRefreshMembers(false)
    }
  }

  const handleRefreshTransactions = (refresh) => {
    if(refresh){
      setRefreshTransactions(true)  
    } else {
      setRefreshTransactions(false)
    }
  }
  
  const handleRefreshWaitlist = (refresh) => {
    if(refresh){
      setRefreshWaitlist(true)  
    } else {
      setRefreshWaitlist(false)
    }
  }
  
  const handleRefreshCredits = (refresh) => {
    if(refresh){
      setRefreshCredits(true)  
    } else {
      setRefreshCredits(false)
    }
  }

  useEffect(() => {
    const getHomePage = async () => {
      let res
      
      try{
        res = await axios({
          method: 'GET',
          timeout: AXIOS_TIMEOUT,
          url: HOMEPAGE_URL
        })
      } catch(error){
        logAxiosError(error)
      }
      
      //console.log('homepage res status:', res.status)
      if(res){
        const {data} = res
        setHomePage(data)
        
        if (data.messageBoard) {
          updateMessageBoard( data.messageBoard, data.messageType)
        }  

        setInfobar(data.messageBoard)
        setInfobarType(data.messageType)
        setRefreshHomePage(false)
      }
    }  
    getHomePage()
    
    if(user){
      setSignout(false)
    } 

  }, [user, refreshHomePage])  

  useEffect(() => {
    const getResources = async () => {
      let res
      
      try{
        res = await axios({
          method: 'GET',
          timeout: AXIOS_TIMEOUT,
          url: RESOURCES_URL
        })
      } catch(error){
        logAxiosError(error)
      }
      
      //console.log('homepage res status:', res.status)
      if(res){
        const {data} = res
        //setup the URL array to be passed to the Navigation Component
        //console.log('resources', data.resourcelist) 

        // resourceList is an array of resources
        let resourceList = [{}]
        let firstLink = true;
        
        if(data.resourcelist){
          //if resource list exists
          for(let i=0; i < data.resourcelist.length; i++) {
            if(data.resourcelist[i].showLink){
              let linkObj = {}
              linkObj.linkOrder = data.resourcelist[i].linkOrder
              linkObj.linkName = data.resourcelist[i].linkName
              linkObj.linkUrl = data.resourcelist[i].linkUrl

              if(firstLink){
                resourceList[0] = {...linkObj}
                firstLink = false
              } else {
                resourceList.push({...linkObj})
              }
            }
          }

          //sort the resourceList by linkOrder
          resourceList.sort(compareValues('linkOrder'))
        }
        
        setResourceLinks(resourceList)
      }
    }  
    getResources()
    
  }, [])  

  useEffect(() => {
    //refreshConfig not used currently
    //let refreshConfig = false

    requestConfig()
    .then(configValues => {
        //store the config values in a state object
        let config={...configValues}

        setConfigValues(config)
        //console.log('config values', configValues)
    })
    .catch(err => {
      console.log('error fetching config', err)
    })
  }, [])   


  useEffect(() => {
    if(startTimer && user){
      if(!userActive){
        const initTimeoutId = setTimeout(() => {
          //update message every 1 second
          updateChildMsg("User Idle", "You will be logged out in " + (remainingTime/1000) + " seconds", "primary", 0)
          setRemainingTime(remainingTime - 1000)
        }, 1000);
        setTimeoutId(initTimeoutId)
        
        if(remainingTime < 0){
          //stop the timer and logout the user
          setStartTimer(false)
          setRemainingTime(WARN_TIMEOUT)
          clearTimeout(timeoutId)
          setSignout(true)

          setTimeout(() =>{
            updateChildMsg("Idle Time Exceeded", "You have been logged out ...", "danger", 0)  
          }, 1000)
        }
      } 
    } 

    if(user && userActive){
      clearTimeout(timeoutId)
      updateChildMsg('')
    }
  },[remainingTime, startTimer, userActive]);

  const handleOnIdle = event => {
    setRemainingTime(WARN_TIMEOUT)
    setStartTimer(true)
    setUserActive(false)
  }
  
  const handleOnActive = event => {
    setUserActive(true)

    if(signout){
      //user was previously signed out, but now is active again !!!
      
      //reload the current page and refresh all variables
      window.location.reload()
    }
  }

  useIdleTimer({
    timeout: IDLE_TIMEOUT,
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    debounce: 500
  })

  useEffect(() => {
    if(signout){
      setUser(null)
      //console.log('signing out user')
      
      const signoutUser = async () => {
        let res

        try{
          res = await axios({
            method: 'GET',
            url: SIGNOUT_URL
          })
        } catch(error){
          console.log('error signing out user')
          logAxiosError(error)
        }
      }  
      signoutUser() 
    }  
  },[signout])

  const handleRefreshHomePage = (refresh) => {
    if(refresh && !refreshHomePage){
      setRefreshHomePage(true)
    }
  }

  /*
  socket.on("expressupdate", (data) => {
    setMessage(data.message)
    setMessageType(data.messageType)
  });
  */

  return (
    <div className="App">
      <div className="NotificationArea">
        {message &&
          <Alert variant={messageType} onClose={() => setMessage('')} dismissible>
            <div>
                <h5>{message}</h5>
            </div>
          </Alert>
        } 
      </div>
                      
      <BrowserRouter>
        <div className="Title" >
            <Link to="/">
              <img className="Logo" src={Logo} alt="logo"/>
            </Link>
        </div>
        
        <div className="Title">
          <h1> {Constants.WEBSITE_TITLE} </h1>  
        </div>

        <div className="Header">
          <NavBar
            configValues={configValues}
            resourceLinks={resourceLinks}
          />
        </div>  
        
        <div className="ChildNotification">
          {childMsg &&
            <Alert variant={childMsgType} onClose={() => setChildMsg('')} dismissible>
                <h3>
                  {childMsgTitle}
                </h3>
                <div>
                    {childMsg}
                </div>
            </Alert>
          } 
       </div>   
   
        <div className="Pages">
          <Switch>
            <Route exact path="/" render={(props) => 
              <Home 
                {...props} 
                data={homePage} 
                user={user} 
                configValues={configValues}
              />} 
            />

            <Route exact path="/test/phonebook" component={PhonebookTest} />
            <Route exact path="/fees" component={Fees} />
            <Route exact path="/membership" component={MembershipInfo} />
            <Route exact path="/membership/fees" render={(props) => <MembershipFees {...props} configValues={configValues}/>} />
            <Route exact path="/calendar" component={Calendar} />
            <Route exact path="/contact" component={Contact} />
            <Route exact path="/lessons" component={Lessons} />
            <Route exact path="/rulebook" component={Rulebook} />
            <Route exact path="/login" render={(props) => <Login {...props} updateChildMsg={updateChildMsg} configValues={configValues}/>} />
            <Route exact path="/signup" render={(props) => <Signup {...props} updateChildMsg={updateChildMsg} configValues={configValues}/>} />
            <Route exact path="/forgot" render={(props) => <Forgot {...props} updateChildMsg={updateChildMsg} />} />
            <Route exact path="/reset" render={(props) => <Reset {...props} updateChildMsg={updateChildMsg} />} />
            <Route exact path="/signout" render={(props) => <Signout {...props} updateChildMsg={updateChildMsg} />} />
            <Route exact path="/password" render={(props) => <ChangePassword {...props} updateChildMsg={updateChildMsg} configValues={configValues}/>} />

            <Route exact path="/notifications" render={(props) => 
                <Notification 
                  {...props} 
                  user={user}
                  updateChildMessage={updateChildMsg} 
                  handleRefreshHomePage={handleRefreshHomePage}
                  infobar={infobar}
                  infobarType={infobarType}
                />} 
            />

            <Route exact path="/credits/purchase" render={(props) => 
                <CreditsForm
                  {...props} 
                  user={user}
                  configValues={configValues}
                  updateChildMessage={updateChildMsg} 
                  updateUser={updateUser}
                  updateTransactions={handleRefreshTransactions}
                />} 
            />

            <Route exact path="/register/self" render={(props) => 
                <RegistrationForm 
                  {...props} 
                  user={user}
                  configValues={configValues}
                  updateChildMessage={updateChildMsg} 
                  updateUser={updateUser}
                />} 
            />
            
            <Route exact path="/register/staff" render={(props) => 
                <RegistrationFormStaff 
                  {...props} 
                  user={user}  
                  configValues={configValues}
                  updatePhonebook={handleRefreshPhonebook}  
                  updateChildMessage={updateChildMsg} 
                />} 
            />
          
            <Route exact path="/register/phone" render={(props) => 
                <RegistrationFormPhone 
                  {...props} 
                  user={user}  
                  configValues={configValues}
                  updatePhonebook={handleRefreshPhonebook}  
                  updateChildMessage={updateChildMsg} 
                />} 
            />

            <Route exact path="/register/waitlistform" render={(props) => 
                <WaitlistForm 
                  {...props} 
                  user={user}
                  configValues={configValues}
                  updateChildMessage={updateChildMsg} 
                />} 
            />

            <Route exact path="/phonebook" render={(props) => 
              <Phonebook 
                {...props} 
                user={user} 
                updatePhonebook={handleRefreshPhonebook}  
                refreshPhonebook= {refreshPhonebook}
              />}
            />

            <Route exact path="/waitlist" render={(props) => 
              <Waitlist 
                {...props} 
                user={user} 
                updateWaitlist={handleRefreshWaitlist}  
                refreshWaitlist={refreshWaitlist}
              />} 
            />

            <Route exact path="/memberlist" render={(props) => 
              <Members 
                {...props} 
                user={user} 
                updateMembers={handleRefreshMembers}  
                refreshMembers={refreshMembers}
              />} 
            />

            <Route exact path="/staff/memberlist/:id" render={(props) => 
              <Members 
                {...props} 
                user={user} 
                updateMembers={handleRefreshMembers}  
                refreshMembers={refreshMembers}
              />} 
            />

            <Route exact path="/transactions" render={(props) => 
              <Transactions 
                {...props} 
                user={user} 
                updateTransactions={handleRefreshTransactions}  
                refreshTransactions={refreshTransactions}
              />} 
            />

            <Route exact path="/staff/transactions/:membershipId" render={(props) => 
              <Transactions
                {...props} 
                user={user} 
                updateTransactions={handleRefreshTransactions}  
                refreshTransactions={refreshTransactions}
              />} 
            />

            <Route exact path="/staff/singlemember/:id" render={(props) => 
              <SingleMember
                {...props} 
                user={user} 
                updateChildMessage={updateChildMsg} 
              />} 
            />

            <Route exact path="/events/currentevents" render={(props) => 
              <CurrentEvents 
                {...props} 
                user={user} 
                updateChildMessage={updateChildMsg} 
                updateUser={updateUser}
              />} 
            />

            <Route exact path="/events/manage" render={(props) => 
              <ManageEvents 
                {...props} 
                user={user} 
                updateChildMessage={updateChildMsg} 
              />} 
            />

            <Route exact path="/events/create" render={(props) => 
              <CreateEvent 
                {...props} 
                user={user} 
                updateChildMessage={updateChildMsg} 
              />} 
            />

            <Route exact path="/events/currentevents/:id/:firstname/:lastname/:eventId" render={(props) => 
              <CurrentEvents 
                {...props} 
                user={user} 
                updateChildMessage={updateChildMsg} 
                updateUser={updateUser}
              />} 
            />

            <Route exact path="/events/event/:id/:title" render={(props) => 
              <EventList 
                {...props} 
                user={user} 
                updateChildMessage={updateChildMsg} 
              />} 
            />

            <Route exact path="/events/family/:id/:title" render={(props) => 
              <FamilyList 
                {...props} 
                user={user} 
                updateChildMessage={updateChildMsg} 
                updateUser={updateUser}
              />} 
            />

            <Route exact path="/events/update/:id" render={(props) => 
              <EditEvent 
                {...props} 
                user={user} 
                updateChildMessage={updateChildMsg} 
              />} 
            />

            <Route exact path="/camera" render={(props) => <Camera {...props} user={user} updateChildMessage={updateChildMsg}/>} />
            <Route exact path="/profile" render={(props) => <Profile {...props} user={user} updateChildMessage={updateChildMsg}/>} />
            <Route exact path="/viewregistration" render={(props) => <ViewRegistration {...props} user={user} updateChildMessage={updateChildMsg}/>} />
            
            <Route exact path="/registration/update/:membershipId" render={(props) => 
              <UpdateRegistration 
                {...props} 
                user={user} 
                updateChildMessage={updateChildMsg}
                updateMembers={handleRefreshMembers}  
              />} 
            />

            <Route exact path="/waitlist/update/:membershipId/:applicantId/:renewalId" render={(props) => 
              <UpdateWaitlistMembership 
                {...props} 
                user={user} 
                updateChildMessage={updateChildMsg}
                updateWaitlist={handleRefreshWaitlist}  
              />} 
            />

            <Route exact path="/member/update/:memberId/" render={(props) => 
              <UpdateMember 
                {...props} 
                user={user} 
                updateChildMessage={updateChildMsg}
                updateMembers={handleRefreshMembers}
              />} 
            />

            <Route exact path="/register/info" render={(props) => 
              <RegisterInfo 
                {...props} 
                user={user} 
                updateChildMessage={updateChildMsg}
                configValues={configValues}
              />} 
            />

            <Route exact path="/register/waitlist" render={(props) => 
              <WaitlistInfo 
                {...props} 
                user={user} 
                updateChildMessage={updateChildMsg}
                configValues={configValues}
              />} 
            />

            <Route exact path="/events/home" render={(props) => <EventInfo {...props} user={user} />} />
            
            <Route exact path="/financials/pmtsummary" render={(props) => 
              <PmtSummary 
                {...props} 
                user={user} 
                configValues={configValues}
                updateChildMessage={updateChildMsg}
              />} 
            />

            <Route exact path="/financials/creditsreport" render={(props) => 
              <CreditsRep 
                {...props} 
                user={user} 
                configValues={configValues}
                updateCredits={handleRefreshCredits}  
                refreshCredits={refreshCredits}
              />} 
            />

            <Route exact path="/courtbookings/self" render={(props) => 
              <CourtBooking 
                {...props} 
                user={user} 
                configValues={configValues}
              />} 
            />

            <Route exact path="/credits/purchasenew/:membershipid/:memberid/:firstname/:lastname" render={(props) => 
              <CreditsFormNew
                {...props} 
                user={user}
                configValues={configValues}
                updateChildMessage={updateChildMsg} 
              />} 
            />

            <Route exact path="/credits/purchase/:membershipid/:memberid/:firstname/:lastname" render={(props) => 
              <CreditsFormStaff
                {...props} 
                user={user}
                configValues={configValues}
                updateChildMessage={updateChildMsg} 
              />} 
            />

            <Route component={Blank}/>
          </Switch>

          <Footer />
        </div>
      </BrowserRouter> 
    </div>
  );
}

export default App;
