import axios from 'axios'

import { WAITLIST_LIST_URL } from './urls'
import { logAxiosError } from './functions';
import { AXIOS_TIMEOUT } from './constants'

let cacheWaitlist = null

export function requestWaitlist(refresh) {
    if(refresh){
        cacheWaitlist = null
    }
    if(!cacheWaitlist){
        //fetch the list of waitlist requests
        
        const getWaitlist = async () => {
            let res

            try{
                res = await axios({
                    method: 'GET',
                    timeout: AXIOS_TIMEOUT,
                    url: WAITLIST_LIST_URL,
                })
            } catch(error){
                console.log('Error fetching waitlist list')
                logAxiosError(error)     
            }
        
            const {data} = res
            
            let waitlistList = data.data
            return waitlistList
        }  
        
        cacheWaitlist = getWaitlist()
        return cacheWaitlist
    }
    return cacheWaitlist
}
