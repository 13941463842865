import React, { useState, useEffect } from 'react';
import axios from 'axios'
import ReactMarkdown from 'react-markdown';

import { Link } from 'react-router-dom';

import './RegisterInfo.css'

import { WAITLIST_INFO_URL } from '../../utils/urls';
import { logAxiosError } from '../../utils/functions';
import { AXIOS_TIMEOUT } from '../../utils/constants';

const NOTIFICATION_MESSAGE_1 = "Sorry, the waitlist form is not available at this time"
const NOTIFICATION_MESSAGE_2 = "Please login/signup to access the waitlist form"

const WaitlistInfo = ({history, user, updateChildMessage, configValues}) => {
    const[instructions, setInstructions] = useState({})
    const [authorizedUser, setAuthorizedUser] = useState(false)
    const [isWaitlistFormAllowed, setIsWaitlistFormAllowed] = useState(true)
    
    let {
        prioritizeRegistration,
        allowRegister,
        juniorOnly,
        showWaitlistForm
    } = configValues

    useEffect(()=> {
        if(!user) {
            setAuthorizedUser(true)
        } else if (user.isAuth) {
            let waitlist = false

            if(allowRegister){
                if(prioritizeRegistration){
                    //do not show waitlist form for users who can register
                    if(user.registrationAllowed){
                        waitlist = false
                    } else {
                        //allow waitlist if user is not prioritized
                        waitlist = true
                    }
                } 
                 else {
                    if(juniorOnly){
                        waitlist = true
                    }
                }
            } else {
                if(showWaitlistForm){
                    waitlist = true
                } else {
                    //club is closed for any application
                    waitlist = false    
                }
            }

            if(waitlist){
                //user is allowed to access the waitlist info
                setAuthorizedUser(true)
            } else {
                setAuthorizedUser(false)
                history.push('/')    
            }
        } else {
            setAuthorizedUser(false)
            history.push('/')
        }
    },[])

    useEffect(() => {
        if(authorizedUser){
            const getWaitlistInfo = async () => {
                let res

                try{
                    res = await axios({
                        method: 'GET',
                        timeout: AXIOS_TIMEOUT,
                        url: WAITLIST_INFO_URL,
                    })
                } catch(error){
                    logAxiosError(error)
                }
                
                const {data} = res
                
                let showWaitlistForm = false

                if(configValues.showWaitlistForm){
                    showWaitlistForm = true
                } else {
                    showWaitlistForm = false
                }

                setIsWaitlistFormAllowed(showWaitlistForm)
                
                setInstructions(data)
            }

            getWaitlistInfo() 
        }
    }, [authorizedUser])  
    
    //setup the display flags
    
    const imageUrl = '/images/wptc-pic1.JPG'
    const { welcomeTitle, welcomeText, leftBox, rightBox, formLink, message, messageType } = instructions

    useEffect(()=>{
        if(isWaitlistFormAllowed){
            if(instructions.message > ''){
                updateChildMessage("Notice", instructions.message, instructions.messageType, 0)
            } else {
                updateChildMessage('')
            }
        } else {
            updateChildMessage("Notice", NOTIFICATION_MESSAGE_1, "danger", 0)
        }
    },[instructions])
    
    return (
        <div className="Register">
            <img className="Image" src={imageUrl} alt="Register Page"/>
                
            <div className="Welcome"> {welcomeTitle} </div>  
            <div className="Welcomemsg"> {welcomeText} </div>

            <div className="Textcontainer"> 
                <div className="Para">
                    <ReactMarkdown>{leftBox}</ReactMarkdown>
                </div>
                <div className="Para"> 
                    <ReactMarkdown>{rightBox}</ReactMarkdown>
                </div>
            </div>
            
            <div className="Link-box">
                {formLink && user && authorizedUser && isWaitlistFormAllowed &&  
                    <Link to="/register/waitlistform">{formLink} </Link>
                }

                {!isWaitlistFormAllowed && 
                    <div className="Link-box">
                        <p> {NOTIFICATION_MESSAGE_1} </p>
                    </div>
                }   

                {!user && isWaitlistFormAllowed && 
                    <div className="Link-box">
                        <p> {NOTIFICATION_MESSAGE_2} </p>
                    </div>
                }
            </div>    
        </div>
    )    
}

export default WaitlistInfo