//Form created from https://www.sanwebe.com/2014/08/css-html-forms-designs

import React, { useEffect, useState } from 'react'
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw } from "draft-js";
import draftToMarkdown from 'draftjs-to-markdown';


import DatePicker from "react-datepicker";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "react-datepicker/dist/react-datepicker.css";

import axios from 'axios'

import { EVENTS_UPDATE_URL, EVENTS_UPDATE_MULTIPART_URL, EVENTS_FINDONE_URL } from '../../utils/urls'

import { logAxiosError } from '../../utils/functions';
import { AXIOS_TIMEOUT } from '../../utils/constants'

import './CreateEvent.css'

const EditEvent = ({user, history, updateChildMessage, match}) => {
    const [eventDate, setEventDate] = useState(new Date());
    const [bookingStartDate, setBookingStartDate] = useState(new Date());
    const [bookingEndDate, setBookingEndDate] = useState(new Date());
    const [eventFields, setEventFields] = useState({})
    const [authorizedUser, setAuthorizedUser] = useState(false)
    const [eventUpdated, setEventUpdated] = useState(false)
    const [photo, setPhoto] = useState(null)

    const [editorState, setEditorState] = useState(() =>
        EditorState.createEmpty()
    );

    let eventId = match.params.id
    //console.log('event-id', eventId)

    const _setDate = (inDate) => {
        // inDate is in "YYYY-MM-DD" format
        let inYear = parseInt(inDate.substr(0,4))
        let inMonth = parseInt(inDate.substr(5,2))
        let inDay = parseInt(inDate.substr(8,2))

        let tempDate = new Date()
        tempDate.setFullYear(inYear)
        tempDate.setMonth(inMonth - 1)
        tempDate.setDate(inDay)

        //console.log('set-data-params', inYear, inMonth, inDay)
        //console.log('tempDate', tempDate)

        return tempDate
    }

    useEffect(()=> {
        if(!user){
           updateChildMessage("", "Please Login to continue...", "primary", 3000)
            history.push('/login')
        } else if(user.isStaff || user.isAdmin) {
            setAuthorizedUser(true)
        } else {
            setAuthorizedUser(false)
            history.push('/')
        }
    },[user])

    useEffect(()=>{
        if(eventUpdated){
            //return to manage events page
            history.goBack()
        }
    }, [eventUpdated])

    //temp code
    /*
    useEffect(()=>{
        console.log('bookingStartDate', bookingStartDate)
    }, [bookingStartDate])
    */

    useEffect(()=>{
        //Fetch the event to be updated
        let processRequest = true
        let res
        let initialEventFields

        if(authorizedUser){
            const getEvent = async () => { 
                try {
                    res = await axios({
                        method: 'GET',
                        timeout: AXIOS_TIMEOUT,
                        url: EVENTS_FINDONE_URL,
                        params: {
                            id: eventId
                        }
                    })  
                    //console.log('find-one-res', res)

                } catch (error) {
                    processRequest = false
                    console.log('Error Fetching Event')            
                    logAxiosError(error)
                }    

                if(processRequest){
                    let { data } =  res.data
                    //console.log('find-one-data', data)

                    let currentEventFields = {
                        eventType: data.eventType,
                        maxParticipants: data.maxParticipants,
                        maxOnlineParticipants: data.maxOnlineParticipants,
                        title: data.title,
                        requiresToken: data.requiresToken,
                        tokensPerSpot: data.tokensPerSpot,
                        enableWaitlist: data.enableWaitlist,
                        waitlistLimit: data.waitlistLimit,
                        status: data.status,
                        needsConfirmation: data.needsConfirmation,
                        autoManageWaitList: data.autoManageWaitList,
                        restricted: data.restricted
                    } 

                    //console.log('currentEventFields', currentEventFields)
                    //console.log('data.eventDate', data.eventDate)

                    /*
                    let fullDate = data.eventDate.concat("T12:00:00Z")
                    let convertedDate = new Date(fullDate)
                    console.log('convertedDate', convertedDate)

                    let testDate = new Date()
                    console.log('testDate', testDate)
                    
                    _setDate(data.eventDate)
                    */

                    setEventFields(currentEventFields)

                    //set date fields
                    setEventDate(_setDate(data.eventDate))
                    setBookingStartDate(_setDate(data.bookingStartDate))
                    setBookingEndDate(_setDate(data.bookingEndDate))
                    
                    setEventUpdated(false)
                } 
            }

            getEvent()
        }
    },[authorizedUser])

    

    const _convertDate = (inDate) => {
        let dd = String(inDate.getDate()).padStart(2, '0');
        let mm = String(inDate.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = inDate.getFullYear();
      
        let outDate = parseInt(yyyy + mm + dd);
        return outDate
    }

    const validateInputs = () => {
        let result = {}
        let validInputs = true
        let message = ''

        //get current date
        let today = new Date();
        let currentDate = _convertDate(today)
        //console.log('current date', currentDate)

        //convert date to number
        let numEventDate = _convertDate(eventDate)
        let numBookingStartDate = _convertDate(bookingStartDate)
        let numBookingEndDate = _convertDate(bookingEndDate)
        
        //console.log('Event Date: ', numEventDate, ' Booking End: ', numBookingEndDate, ' Booking Start ', numBookingStartDate)
        //console.log('event fields', eventFields)

        //1. Event Date must be greater than todays date
        if(currentDate > numEventDate){
            message = "Event Date must be greater than Today's Date"
            validInputs = false
        }

        //2. Booking End Date must be less than Event Date
        if(validInputs){
            if(numBookingEndDate >= numEventDate){
                message = "Event Date must be greater than Booking End Date"
                validInputs = false
            }   
        }

        //3. Booking Start Date must be less or equal to Booking End Date
        if(validInputs){
            if(numBookingStartDate > numBookingEndDate){
                message = "Booking Start Date cannot be greater than Booking End Date"
                validInputs = false
            }   
        }

        //4. Event Title cannot be blank
        if(validInputs){
            if(eventFields.title){
                if(eventFields.title.length < 5){
                    //minimum 5 charactera
                    message = "Event Title too short"
                    validInputs = false
                }   
            }  else {
                message = "Event Title is required"
                validInputs = false
            }
        }

        //5. Event Description cannot be blank
        if(validInputs){
            if(eventFields.description && eventFields.description.length < 5){
                //minimum 5 charactera
                message = "Description is required"
                validInputs = false
            }  
        }

        //6. Maximum Participants
        if(validInputs){
            if(eventFields.maxParticipants > 0){
                // continue
            }  else {
                message = "Max participants must be greater than 0"
                validInputs = false
            }
        }

        //7. Maximum Online Participants
        if(validInputs){
            if(parseInt(eventFields.maxParticipants) < parseInt(eventFields.maxOnlineParticipants)){
                message = "Max online participants cannot be greater than max participants"
                validInputs = false
            }  
        }

        //8. check for credits
        if(validInputs){
            if(eventFields.requiresToken){
                if(eventFields.tokensPerSpot <= 0){
                    message = "Credits must be greater than 0 if Event requires Credits"
                    validInputs = false
                }
            }  else if(eventFields.tokensPerSpot > 0){
                message = "Credits must be 0 if the Event does not require Credits"
                validInputs = false
            }
        }

        //9. check for wait list
        if(validInputs){
            if(eventFields.enableWaitlist){
                if(eventFields.waitlistLimit <= 0){
                    message = "Wait List must be greater than 0 if Wait List is enabled"
                    validInputs = false
                }
            }  else {
                //waitlist is disabled
                if(eventFields.waitlistLimit > 0){
                    message = "Wait List must be 0 if Wait List is not enabled"
                    validInputs = false
                }

                //waitlist is disabled
                if(validInputs){
                    if(eventFields.autoManageWaitList){
                        message = "Auto Manage Wait List must be turned off if Wait List is not enabled"
                        validInputs = false
                    }
                }
            }
        }

        result.success = validInputs
        result.message = message

        return result
    }

    

    const handleOnSubmit = (e) => {
        e.preventDefault()
    }    

    const submitEntry = () => {
        let processFlag = true

        let message = ''
        let messageType = ''
        let messageTitle = ''

        if(!authorizedUser){
            //user cannot submit request
            processFlag = false
            message = "User not authorized to create events"
            messageTitle = "Not Authorized"
            messageType = "danger"
        }

        if(processFlag){
            const rawContentState = convertToRaw(editorState.getCurrentContent());
            const description = draftToMarkdown(rawContentState);
        
            //move rest of the fields to eventFields object
            eventFields.eventDate = eventDate
            eventFields.bookingStartDate = bookingStartDate
            eventFields.bookingEndDate = bookingEndDate
            eventFields.description = description

            //add the photo to the event fields
            //eventFields.photo = photo
        }

        if(processFlag){
            let result = validateInputs()

            if(result.success){
                //all validations were successful
            } else {
                message = result.message
                messageTitle = "Validation Errors"
                messageType = "danger"
                window.scroll(0,0)
                processFlag = false
            }
        }
        
        if(processFlag){
            const formData = new FormData()
            let data 
            let multiPartData = false

            if(photo){
                //mulit-part data 
                multiPartData = true

                formData.append('data', JSON.stringify({...eventFields}))
                formData.append('photo', photo)

                //console.log('form-data')
                //console.log(...formData)
            } else {
                //form with data only - no photo
                data = {...eventFields}
            }

            const postEvent = async () => {
                let res 
                //console.log('idempotency key', idempotencyKey)
    
                try{
                    //console.log('multiPartData', multiPartData)

                    if(multiPartData){
                        res = await axios({
                            method: 'POST',
                            timeout: AXIOS_TIMEOUT,
                            url: EVENTS_UPDATE_MULTIPART_URL,
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            },
                            data: formData
                        })
                    } else {
                        //console.log('event-data', data)
                        
                        res = await axios({
                            method: 'POST',
                            timeout: AXIOS_TIMEOUT,
                            url: EVENTS_UPDATE_URL,
                            data: data
                        })
                    }
                    
                    //console.log('create-event-res', res)
                } catch (error){
                    console.log('post event error')
                    messageTitle = 'Error' 
                    message = 'Error adding Event'
                    messageType = "danger"
                    logAxiosError(error)
                    processFlag = false
                }
                
                if(processFlag){
                    //console.log('good response ')
                    if(res.data.processLog.success){
                        //console.log('Event added successfully')
                        messageTitle = ''
                        message = "Event added successfully"
                        messageType = "success"
                    } else {
                        //console.log('Event add error')
                        messageTitle = 'Error' 
                        message = 'Error adding Event'
                        messageType = "danger"
                        processFlag = false
                    }   
                }
    
                if(processFlag){
                    setEventUpdated(true)
                }  
            }
            postEvent()
        }
        
        updateChildMessage(messageTitle, message, messageType, 10000)
    }

    const handleChange = (e) => {
        // clear errors, if any
        updateChildMessage('', '', '', 0)

        //console.log('name', e.target.name, ' value ', e.target.value)
        //console.log('e.target.value', e.target.value)
        let updatedEventFields = {...eventFields}

        if(["requiresToken", "enableWaitlist", "autoManageWaitList"].includes(e.target.name)){
            if(e.target.value === "yes"){
                updatedEventFields[e.target.name] = true        
            } else {
                updatedEventFields[e.target.name] = false
            }
        } else if(e.target.name === "photo"){
            setPhoto(e.target.files[0])
        } else {
            updatedEventFields[e.target.name] = e.target.value
        }
        
        setEventFields(updatedEventFields)
    }

    return(
        <div className="CreateEvent">
            <div className="header">
                <h3> Create Event </h3>
            </div>
            
            <form onSubmit={handleOnSubmit} onChange={handleChange}> 
                <ul className="form-style-1">
                    <div className="inline">
                        <li>
                            <label>Event Type</label>
                            <select name="eventType" className="field-size200" value={eventFields.eventType}>
                                <option value="roundrobin">Round Robin</option>
                                <option value="bbq">BBQ</option>
                                <option value="tournament">Tournament</option>
                                <option value="other">Other</option>
                            </select>
                        </li>
                        
                        <li>
                            <label>Event Date</label>
                            <DatePicker selected={eventDate} onChange={date => setEventDate(date)} />
                        </li>
                    </div>

                    <li>
                        <label>Event Title 
                            <span class="required">*</span>
                        </label>
                        <input type="text" name="title" placeholder="Title" className="field-long" value={eventFields.title}/> 
                    </li>
                    
                    <li>
                        <label>Event Description <span class="required">*</span></label>
                        <div style={{ border: "1px solid black", padding: '2px', minHeight: '400px' }}>
                            <Editor
                                editorState={editorState}
                                onEditorStateChange={setEditorState}
                            />
                        </div>
                    </li>

                    <div className="inline">
                        <li>
                            <label>Booking Start Date</label>
                            <DatePicker selected={bookingStartDate} onChange={date => setBookingStartDate(date)} />
                        </li>

                        <li>
                            <label>Booking End Date</label>
                            <DatePicker selected={bookingEndDate} onChange={date => setBookingEndDate(date)} />
                        </li>
                    </div>

                    <div className="inline">
                        <li>
                            <label>Maximum Participants
                                <span class="required">*</span>
                            </label>
                            <input type="number" name="maxParticipants" value={eventFields.maxParticipants}/> 
                        </li>

                        <li>
                            <label>Maximum Online Participants</label>
                            <input type="number" name="maxOnlineParticipants" value={eventFields.maxOnlineParticipants}/> 
                        </li>
                    </div>

                    <div className="inline">
                        <li>
                            <label>Requires Credits</label>
                            <select name="requiresToken" className="field-size200" value={eventFields.requiresToken? "yes": "no"}>
                                <option value="no">No</option>
                                <option value="yes">Yes</option>
                            </select>
                        </li>
                        
                        <li>
                            <label>Credits per Spot</label>
                            <input type="number" name="tokensPerSpot" value={eventFields.tokensPerSpot}/> 
                        </li>
                    </div>

                    <div className="inline">
                        <li>
                            <label>Enable Waitlist</label>
                            <select name="enableWaitlist" className="field-size100" value={eventFields.enableWaitlist? "yes": "no"}>
                                <option value="no">No</option>
                                <option value="yes">Yes</option>
                            </select>
                        </li>

                        <li>
                            <label>Auto Manage Waitlist</label>
                            <select name="autoManageWaitList" className="field-size100" value={eventFields.autoManageWaitList? "yes": "no"}>
                                <option value="no">No</option>
                                <option value="yes">Yes</option>
                            </select>
                        </li>
                        
                        <li>
                            <label>Waitlist Limit</label>
                            <input type="number" name="waitlistLimit" value={eventFields.waitlistLimit}/> 
                        </li>
                    </div>

                    <li>
                        <label>Upload Event Picture</label>
                        <input type="file" name="photo" className="field-long"></input>
                    </li>

                    <li>
                        <label>Status</label>
                        <select name="status" className="field-size200" value={eventFields.status}>
                            <option value="draft">Draft</option>
                            <option value="active">Active</option>
                        </select>
                    </li>

                    <div className="inline">
                        <li>
                            <input type="submit" value="Submit" onClick={()=>{submitEntry()}} />
                        </li>

                        <li>
                            <div className="returnButton">
                                <button onClick={()=>{history.goBack()}}>Return</button>
                            </div>
                        </li>
                    </div>
                </ul>
            </form>
        </div>
    )   
}
export default EditEvent