import React, { useState, useEffect } from "react"
import { Link } from 'react-router-dom'

import axios from 'axios'
import {capitalize, logAxiosError } from '../../utils/functions'
import {MEMBER_MEMBERSHIP_URL} from '../../utils/urls'
import { AXIOS_TIMEOUT } from '../../utils/constants'

import './SingleMember.css'

const SingleMember = ({match, memberId, user, history, updateChildMessage }) => {
    let inMemberId = -1
    //console.log('match', match)
    
    if(match){
        inMemberId = match.params.id
    } else {
        inMemberId = memberId
    }

    const[member, setMember] = useState()
    const[isJunior, setIsJunior] = useState(false)
    const [authorizedUser, setAuthorizedUser] = useState(false)

    useEffect(()=> {
        if(!user){
            updateChildMessage("", "Please Login to continue...", "primary", 3000)
            history.push('/login')
        } else if(user.isStaff || user.isExec || user.isAdmin || user.isInstructor) {
            setAuthorizedUser(true)
        } else {
            setAuthorizedUser(false)
            history.push('/')
        }
    },[])

    useEffect(()=> {
        if(authorizedUser){
            const getSingleMember = async () => {
                let res
                let processRequest = true

                try {
                    res = await axios({
                        method: 'GET',
                        timeout: AXIOS_TIMEOUT,
                        url: MEMBER_MEMBERSHIP_URL,
                        params:{
                            memberId: inMemberId
                        }
                    })     
                } catch (error) {
                    console.log('Error fetching membership details')
                    logAxiosError(error)         
                    processRequest = false
                }

                if(processRequest){
                    const {data} = res
                    //console.log('data: ', data.data)
            
                    console.log('member data: ', data)
                    //data is returned as an array. We only need the first member
                    let singleMember = data.data[0] 
                    setMember(singleMember)
        
                        if(singleMember && singleMember.memberType === "junior"){
                            setIsJunior(true)
                        } else {
                            setIsJunior(false)
                        }
                }
            }  
            getSingleMember()
        }
    },[authorizedUser])

    return(
        <>
        {!member &&
            <>
                Member not found: {inMemberId}
            </>
        }
        {member &&
            <div className="SingleMember"> 
                <div className="card">
                    <div className="container">
                        <h4>
                            <b><i className="fas fa-id-card"></i>
                                {capitalize(member.firstName)} {capitalize(member.lastName)}
                            </b>
                        </h4>

                        {isJunior &&
                            <h6> Junior Member </h6>
                        }

                        {!isJunior &&
                        <>
                            {member && member.email &&
                                <div>
                                    <i className="fa fa-envelope"></i>  
                                    {member.email}
                                </div>
                            }
                             
                            {member && member.phoneNumber && 
                                <div>
                                    <i className="fas fa-phone-alt"></i>
                                    <span> {member.phoneNumber} </span>    
                                </div>
                            }
                        </>
                        }

                        <p>
                            <i className="fa fa-address-card"></i>
                            {member.address}, {member.city}, {member.postalCode}
                        </p>
                        <>
                            <h5>Emergency Contact</h5>
                            <i className="fas fa-user-nurse"></i>
                            {member.emergencyName}
                            <p>
                                <i className="fas fa-phone-alt"></i>
                                <span> {member.emergencyPhone} </span>    
                            </p>
                        </>
                    </div>   
                </div>
                
                {(user.isStaff || user.isAdmin) &&
                    <div>
                        <div>
                            <Link to={`/events/currentevents/${inMemberId}/${member.firstName}/${member.lastName}/0`}>Manage Events</Link>
                        </div>
                        
                        <div>
                            <Link to={`/credits/purchase/${member.membershipId}/${inMemberId}/${member.firstName}/${member.lastName}`}>Purchase Credits</Link>
                        </div>
                        <div>
                            <Link to={`/registration/update/${member.membershipId}`}>Membership Details</Link>
                        </div>

                        <div>
                            <Link to={`/member/update/${inMemberId}`}>Update Member</Link>
                        </div>
                    </div>
                }
            </div>
        }
    </>    
    )
}

export default SingleMember
