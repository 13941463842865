import axios from 'axios'

import { MEMBER_PROFILE_URL } from './urls'
import { logAxiosError } from './functions';
import { AXIOS_TIMEOUT } from './constants'

//import testList from './phonebook.json'

let cacheMember = null
let processRequest = true

export function requestMember(refresh) {
    if(refresh){
        cacheMember = null
    }
    if(!cacheMember){
        //fetch the member entry that matches the User
        
        const getMember = async () => {
            let res

            try{
                res = await axios({
                    method: 'GET',
                    timeout: AXIOS_TIMEOUT,
                    url: MEMBER_PROFILE_URL,
                })
                //console.log('getMember-res', res)
            } catch(error){
                console.log('Error fetching member information')
                logAxiosError(error)    
                processRequest = false  
            }
        
            let member
            if(processRequest){
                const {data} = res
                //console.log('data: ', data)
                member = data.data
            } else {
                member = {}
            }
            
            //console.log('member-data: ', member)
            return member
        }  
        
        cacheMember = getMember()
        return cacheMember
    }
    return cacheMember
}
