import React, {useState, useContext, useEffect} from 'react'
import axios from 'axios'
import Alert from 'react-bootstrap/Alert'

import {UserContext} from '../../context/UserContext'

import './Auth.css'
import * as Constants from '../../Constants'

import { AUTH_LOCAL_CHANGE_URL } from '../../utils/urls'
import { logAxiosError } from '../../utils/functions';
import { AXIOS_TIMEOUT } from '../../utils/constants'
import { MAX_LENGTH_TEXT } from '../../utils/constants'

const ChangePassword = ({history, updateChildMsg, configValues}) => {
    const { loginAllowed } = configValues
    //console.log('history', history)
    
    const [password, setPassword] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [passwordChanged, setPasswordChanged] = useState(false)
    const [error, setError] = useState('')
    
    const [user, setUser] = useContext(UserContext)

    let processRequest = true

    useEffect(()=>{
        if(!loginAllowed){
            //login is not allowed. Navigate user to the Home Page
            history.push('/')
        }

        setPassword('')
        setNewPassword('')
        setConfirmPassword('')
        setPasswordChanged(false)
    },[])

    useEffect(()=>{
        if(passwordChanged){
            //return to home page
            history.push('/')
        }
    },[passwordChanged])

    const handlePassword = (event) => {
        setPassword(event.target.value)
        setError('')
    }

    const handleNewPassword = (event) => {
        setNewPassword(event.target.value)
        
        if(event.target.value.length > MAX_LENGTH_TEXT ) {
            setError('password is too long')
        } else {
            setError('')
        }
    }

    const handleConfirmPassword = (event) => {
        setConfirmPassword(event.target.value)
        
        if(event.target.value.length > MAX_LENGTH_TEXT ) {
            setError('password is too long')
        } else {
            setError('')
        }
    }

    const validateInput = () => {
        let validInput = true
        
        if (validInput && newPassword.length < 9) {
            setError('Please enter a new password greater than 8 characters')
            validInput = false
        } 

        if (validInput && newPassword.length > MAX_LENGTH_TEXT) {
            setError('New password is too long')
            validInput = false
        }
        
        if (validInput &&  (newPassword !== confirmPassword)) {
            setError('Password mismatch. Please re-enter passwords')
            validInput = false
        }

        if(!validInput){
            //clear out the passwords
            setNewPassword('')
            setConfirmPassword('')
        }

        return validInput
    }
   
    const handleSubmit = async (event) => {
        event.preventDefault()

        var validInput = validateInput()
        //var errMessage = ''
        
        if (validInput){    
            processRequest = true

            //first verify that the current password is correct
            const postChangePassword = async () => {
                let res
                processRequest = true
                
                try {
                    res = await axios({
                        method: 'POST',
                        timeout: AXIOS_TIMEOUT,
                        url: AUTH_LOCAL_CHANGE_URL,
                        data: {
                            identifier: user.username,
                            password: password,
                            newPassword: newPassword,
                            confirmPassword: confirmPassword
                        }
                    })
                } catch (error) {
                    console.log('Error posting password change')
                    setError(error)
                    logAxiosError(error)
                    processRequest = false
                }

                if(processRequest){
                    const {data} = res
                    //console.log('change-pswd data', data)
                    
                    if (data && data.status === Constants.RETURN_CODE_OK) {    
                        let message = 'Your password has been updated successfully'
                            
                        updateChildMsg('', message , Constants.MSG_TYPE_SUCCESS, 3000)
                        setPasswordChanged(true)
                    } else if(data && data.data.message){
                        setError(data.data.message)
                        processRequest = false
                    } else {
                        setError('Error updating password. Please try again later')
                        processRequest = false
                    }
                } else {
                    setError('Request failed. Please try again later')
                    processRequest = false
                }

                if(!processRequest){
                    //reset all password entries if update was unsuccessfule
                    setPassword('')
                    setNewPassword('')
                    setConfirmPassword('')
                }
            }
            postChangePassword()
        }
    }          

    return (
        <div className="Auth">
            <h2> Change Password </h2>
            
            {error &&
                <Alert variant="danger" onClose={() => setError('')} dismissible>
                    <Alert.Heading>Error!</Alert.Heading>
                    <p>
                        {error}
                    </p>
                </Alert>                
            }

            <form onSubmit={handleSubmit}>
                <br></br>
                <h5>Userid: {user.email} </h5>
                <br></br>
                Current Password
                <input 
                    type="password"
                    value={password}
                    placeholder="password"
                    onChange={handlePassword}
                />
                <br></br>
                New Password
                <input 
                    type="password"
                    value={newPassword}
                    placeholder="password"
                    onChange={handleNewPassword}
                />
                <br></br>
                Confirm New Password
                <input 
                    type="password"
                    value={confirmPassword}
                    placeholder="password"
                    onChange={handleConfirmPassword}
                />
                <button type="submit"> Change Password </button>
            </form>
            <div className="Spacer"></div>
        </div>
    )    
}

export default ChangePassword
