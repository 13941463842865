import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import ReactMarkdown from 'react-markdown';
import {fromImageToUrl} from '../utils/urls'
import { compareValues } from '../utils/functions'
import CountdownTimer from './CountdownTimer'
import Participants from './Participants';
import { requestParticipantsList  } from '../utils/fetchParticipants'

const NO_PARTICIPANTS_MESSAGE = 'No participants found for this event'
const MAX_PARTICIPANTS_PER_PAGE = 20

const SingleEventSelf = (props) => {
    let {
        currentEvent, 
        idx, 
        mode,
        credits,
        handleOnSelect,
        handleReturn, 
    } = props

    let { 
        eventStartDateTime,
        bookingStartDateTime,
        bookingEndDateTime,
        title,
        url,
        description,
        showParticipants
    } = currentEvent

    const [registrationOpen, setRegistrationOpen] = useState(false)
    const [registrationClosed, setRegistrationClosed] = useState(false)
    const [timeoutId, setTimeoutId] = useState()
    const [timeoutId2, setTimeoutId2] = useState()
    const [timeToBooking, setTimeToBooking] = useState({
        days: 0,
        hours: 0, 
        minutes: 0,
        seconds: 0
    })

    const [timeToClosing, setTimeToClosing] = useState({
        days: 0,
        hours: 0, 
        minutes: 0,
        seconds: 0
    })

    const [eventStartDateTimeD, setEventStartDateTime] = useState()
    const [bookingStartDateTimeD, setBookingStartDateTime] = useState()
    const [bookingEndDateTimeD, setBookingEndDateTime] = useState()
    const [openingTimerStarted, setOpeningTimerStarted] = useState(false)
    const [closingTimerStarted, setClosingTimerStarted] = useState(false)
    const [bookingStartDateTimeLocal, setBookingStartDateTimeLocal] = useState()
    const [bookingEndDateTimeLocal, setBookingEndDateTimeLocal] = useState()

    const[eventMessage, setEventMessage] = useState()
    const[eventCreditMessage, setEventCreditMessage] = useState()
    const[showDetail, setShowDetail] = useState(false)
    const[participantsFetched, setParticipantsFetched] = useState(false)
    const[refreshParticipants, setRefreshParticipants] = useState(false)
    const[dataFound, setDataFound] = useState(false)
    const[showingParticipants, setShowingParticipants] = useState(false)
    const[enableShowParticipants, setEnableShowParticipants] =useState(false)
    const[pageNumber, setPageNumber] = useState(0)
    const[overflowData, setOverflowData] = useState(false)
    const[showNext, setShowNext] = useState(false)
    const[showPrev, setShowPrev] = useState(false)
    const[currentPage, setCurrentPage] = useState([{}])
    const[participants, setParticipants] = useState([{}])

    const TIMER_NAME_OPEN = "Registration Opens in:"
    const TIMER_NAME_CLOSE = "Registration closes in:"

    const _displayDateTime = (dateTime) => {
        let displayDateTime = new Date(dateTime).toLocaleDateString(undefined, {
            day:    'numeric',
            month:  'numeric',
            year:   'numeric',
            hour:   '2-digit',
            minute: '2-digit',
        });

        return displayDateTime.toString()
    }

    useEffect(()=>{
        setRegistrationOpen(false)
        setRegistrationClosed(false)
        
        setEventStartDateTime(_displayDateTime(eventStartDateTime))
        setBookingStartDateTime(_displayDateTime(bookingStartDateTime))
        setBookingEndDateTime(_displayDateTime(bookingEndDateTime))

        let tempDate = new Date(bookingStartDateTime)
        setBookingStartDateTimeLocal(tempDate)

        let tempDate2 = new Date(bookingEndDateTime)
        setBookingEndDateTimeLocal(tempDate2)

        setParticipantsFetched(false)

        //refresh participants the first time we enter the event
        setRefreshParticipants(true)

        setDataFound(false)
        setShowingParticipants(false)
        //console.log('current-event', currentEvent)
    },[])

    useEffect(()=>{
        setEventInfo(currentEvent)
    },[currentEvent, registrationOpen, registrationClosed])

    useEffect(() => {   
        //console.log('event-booking-bookingStartDateTime', bookingStartDateTimeLocal)

        if(bookingStartDateTimeLocal && !registrationOpen){
            const initTimeoutId = setTimeout(() => {
                //update timer every 1 second
                let now = new Date().getTime();
                let distance = bookingStartDateTimeLocal - now
                
                if(distance <= 0){
                    //user can register
                    setRegistrationOpen(true)

                    if(showParticipants){
                        setEnableShowParticipants(true)
                    }
                } else {
                    //calculate the time for opening of registration
    
                    let currentTimeToBooking ={}
                    // Time calculations for days, hours, minutes and seconds
                    currentTimeToBooking.days = Math.floor(distance / (1000 * 60 * 60 * 24));
                    currentTimeToBooking.hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                    currentTimeToBooking.minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
                    currentTimeToBooking.seconds = Math.floor((distance % (1000 * 60)) / 1000);
    
                    setTimeToBooking(currentTimeToBooking)
                }
            }, 1000);
    
            setTimeoutId(initTimeoutId)
    
            if(registrationOpen){
                clearTimeout(timeoutId)
            }
        }
    },[bookingStartDateTimeLocal, timeToBooking]) 

    useEffect(() => {   
        //console.log('event-booking-bookingEndDateTime', bookingEndDateTimeLocal)

        if(bookingEndDateTimeLocal && registrationOpen && !registrationClosed){
            const initTimeoutId2 = setTimeout(() => {
                //update timer every 1 second
                let now = new Date().getTime();
                let distance = bookingEndDateTimeLocal - now
                
                if(distance <= 0){
                    //registration has closed. User cannot register
                    setRegistrationClosed(true)
                } else {
                    //calculate the time for end of registration
    
                    let currentTimeToClosing ={}
                    // Time calculations for days, hours, minutes and seconds
                    currentTimeToClosing.days = Math.floor(distance / (1000 * 60 * 60 * 24));
                    currentTimeToClosing.hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                    currentTimeToClosing.minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
                    currentTimeToClosing.seconds = Math.floor((distance % (1000 * 60)) / 1000);
                    
                    setTimeToClosing(currentTimeToClosing)
                }
            }, 1000);
    
            setTimeoutId2(initTimeoutId2)
    
            if(registrationClosed){
                clearTimeout(timeoutId2)
            }
        }
    },[bookingEndDateTimeLocal, timeToClosing, registrationOpen]) 
        
    const setEventInfo = (e) => {
        if(mode === "detail"){
            setShowDetail(true)
        } else {
            setShowDetail(false)
        }
        
        let onlineSpotsAvailable = 0

        if(registrationOpen && !registrationClosed){
            if((e.currentOnlineParticipants < e.maxOnlineParticipants)                  
            && ((e.currentParticipants + e.currentWaitingList) < e.maxParticipants)) {
                let onlineSpots1 = e.maxOnlineParticipants - e.currentOnlineParticipants
                let onlineSpots2 = e.maxParticipants - (e.currentParticipants + e.currentWaitingList)
    
                if(onlineSpots1 > onlineSpots2){
                    onlineSpotsAvailable = onlineSpots2
                } else {
                    onlineSpotsAvailable = onlineSpots1
                }
            } 
        }
        
        let regularSpotsAvailable =  e.maxParticipants - (e.currentParticipants + e.currentWaitingList)

        let waitListSpotsAvailable = 0

        if(e.enableWaitlist && regularSpotsAvailable <= 0){
            waitListSpotsAvailable = (e.maxParticipants + e.waitlistLimit)
                                    - (e.currentParticipants + e.currentWaitingList)  
        }
        
        let message = ''
        let creditMessage = ''

        if(onlineSpotsAvailable > 0){
            message = `${onlineSpotsAvailable} online spots are available` 
        } else if(regularSpotsAvailable > 0){
            message = `${regularSpotsAvailable} spots are available. Please call the club to reserve your spot` 
        } else if(waitListSpotsAvailable > 0){
            message = `${waitListSpotsAvailable} waitlist spots are available. Please call the club to reserve a spot` 
        } else {
            message = `Sorry!!! Event is currently full` 
        }

        if(e.tokensPerSpot > 0){
            if(e.tokensPerSpot === 1){
                creditMessage = 'This event requires 1 credit per person to sign-up'
            } else {
                creditMessage = ` This event requires ${e.tokensPerSpot} credits per person to sign-up`
            }
        }

        setEventMessage(message)
        setEventCreditMessage(creditMessage)
    }

    
    //setup the display flags
    let showCountdownTimer = false
    let countDownToRegistration = false

    if(timeToBooking.seconds > 0 || openingTimerStarted){
        if(!openingTimerStarted){
            setOpeningTimerStarted(true)
        }
    }

    if(!registrationOpen && openingTimerStarted){
        //timer has started
        countDownToRegistration = true
    }

    if(timeToClosing.seconds > 0 || closingTimerStarted){
        if(!closingTimerStarted){
            setClosingTimerStarted(true)
        }
    }

    if(registrationOpen && !registrationClosed && closingTimerStarted){
        showCountdownTimer = true
    }

    const handleShowParticipants = () => {
        
        requestParticipantsList(refreshParticipants, currentEvent.id).then(participantsList => {
            if(participantsList && participantsList.length>0){
                //first sort the data by status
                participantsList.sort(compareValues('status'))

                if(participantsList.length > MAX_PARTICIPANTS_PER_PAGE){
                    //There are more than the maximum displayable participants per page
                    setPageNumber(1)
                    setShowNext(true)
                    setShowPrev(false)
                } else {
                    //there are less than the MAX participants to be displayed
                    setShowNext(false)
                    setShowPrev(false)
                }
                //build the first page
                setCurrentPage(buildCurrentPage(participantsList, 1))

                setParticipants(participantsList) 
                setDataFound(true)
                setShowingParticipants(true)
            } else {
                setDataFound(false)
            }

            setParticipantsFetched(true)
            setRefreshParticipants(false)
        }) 
    }

    const buildCurrentPage = (list, page) => {
        let startingEntry = MAX_PARTICIPANTS_PER_PAGE * (page-1)
        let endingEntry = startingEntry + MAX_PARTICIPANTS_PER_PAGE

        let newCurrentPageList = [...list].slice((startingEntry), (endingEntry))
        return newCurrentPageList
    }


    const handleHideParticipants = () => {
        setParticipantsFetched(false)
        setShowingParticipants(false)
    }

    const handleNextPage = () => {
        let nextPage = pageNumber + 1
        setCurrentPage(buildCurrentPage(participants, nextPage))
        setPageNumber(nextPage)
        setShowPrev(true)

        //determine if we need to show the chevron for next page
        if(participants.length < (MAX_PARTICIPANTS_PER_PAGE*(nextPage) + 1)){
            setShowNext(false)
        }
    }

    const handlePrevPage = () => {
        let prevPage = pageNumber - 1
        setCurrentPage(buildCurrentPage(participants, prevPage))
        setPageNumber(prevPage)
        setShowNext(true)

        if(prevPage === 1){
            //we are at the first page
            setShowPrev(false)
        }
    }

    return(
        <div className="card" key={idx}>
            <div className="container">
                <div>
                    {!showDetail &&
                        <h5 className="EventTitle" onClick={()=>{handleOnSelect(idx)}}>{title}</h5> 
                    }

                    {showDetail &&
                        <h5 className="EventTitle" onClick={()=>{handleReturn()}}>{title}</h5>    
                    }
                </div>   

                
                {!showingParticipants &&
                <>
                    <h5> Event Starts On: </h5>
                    <h5> {eventStartDateTimeD} </h5>

                    {showDetail && !registrationOpen &&
                        <>
                            <p></p>
                            <strong>Self Registration Starts On: </strong> {bookingStartDateTimeD}
                            <p></p>
                        </>
                    }
                    
                    {showDetail && registrationOpen && !registrationClosed &&
                        <>
                            <p></p>
                            <strong>Self Registration Ends On: </strong> {bookingEndDateTimeD}
                            <p></p>
                        </>
                    }

                    {eventCreditMessage &&
                        <>
                            <p> {eventCreditMessage} </p>
                            <p> Your current balance is {credits} Credit/s</p>
                        </>
                    }

                    {url &&
                        <img className="Image" src={fromImageToUrl(currentEvent, "event")} alt="Club Event"/>
                    }

                    {!url &&
                        <img src={fromImageToUrl('', 'event')} alt="Club Event"/>
                    }

                    {showDetail &&
                        <div className="EventDetails">
                            <div className="EventDescription">
                                <ReactMarkdown>{description}</ReactMarkdown>   
                            </div>
                        </div>
                    }

                    {registrationOpen && !showCountdownTimer &&
                        <>
                            <p></p>
                            
                            <Link to={`/events/family/${currentEvent.id}/${title}`}>
                                View Registration
                            </Link> 

                            <p></p>
                            {eventMessage}
                            <p></p>
                        </>                
                    }

                    {showCountdownTimer &&
                        <>
                            <p></p>
                            <div className="ActionButton">
                                <Link to={`/events/family/${currentEvent.id}/${title}`}>
                                    Register/ Cancel
                                </Link> 
                            </div>

                            <CountdownTimer 
                                timerName={TIMER_NAME_CLOSE}
                                days={timeToClosing.days}
                                hours={timeToClosing.hours}
                                minutes={timeToClosing.minutes}
                                seconds={timeToClosing.seconds}
                            />

                            <p></p>
                            {eventMessage}
                            <p></p>
                        </>
                    }

                    {countDownToRegistration && 
                        <CountdownTimer 
                            timerName={TIMER_NAME_OPEN}
                            days={timeToBooking.days}
                            hours={timeToBooking.hours}
                            minutes={timeToBooking.minutes}
                            seconds={timeToBooking.seconds}
                        />
                    }                
                </>        
                }
                
                {enableShowParticipants && showDetail &&
                    <>
                        {!participantsFetched &&
                        <div>
                            <button onClick={handleShowParticipants}> Show participants </button> 
                        </div>        
                        }

                        {showingParticipants &&
                        <div>
                            <h5> Participants: </h5>
                            <p></p>
                            <div className="ptcpttable">
                                <ul className='rt'>
                                    <li className="table-header">
                                        <div className="colmn1">First Name</div>
                                        <div className="colmn2">Last Name</div>
                                        <div className="colmn3">Status</div>
                                    </li>
                                    
                                    <Participants
                                        participantList={currentPage}
                                    />
                                </ul>        
                            </div>

                            <div className="pagination2">
                                    {showPrev && 
                                        <span className="clickable" onClick={handlePrevPage}>&laquo;</span>
                                    }    

                                    {(showNext || showPrev) &&
                                        <span>Page: {pageNumber}</span>
                                    }
                                
                                    {showNext &&
                                        <span className="clickable" onClick={handleNextPage}>&raquo;</span>
                                    }    
                            </div>

                            <button onClick={handleHideParticipants}> Hide participants </button> 
                        </div>                        
                        }

                        {participantsFetched && !dataFound &&
                        <>
                          <p>{NO_PARTICIPANTS_MESSAGE}</p>  
                        </>
                        }
                    </>
                }

                <div>
                    {!showDetail &&
                        <div className="EventFooter" onClick={()=>{handleOnSelect(idx)}}> View Event Details </div>
                    }

                    {showDetail &&
                        <div className="EventFooter" onClick={()=>{handleReturn()}}> Return to Event List </div>    
                    }
                </div>
            </div>        
        </div>
    ) 
}

export default SingleEventSelf