//redirect users to the home page if they reach an undefined route
import { useEffect } from 'react';

const Blank = ({history, location}) => {
    //console.log('location', location)
    useEffect(() => {
        //navigate the user to home page on an undefined route
        history.push('/')    
    },[])
    
    return null
}

export default Blank
