import React, { useState } from 'react';

import './Payment.css';
import { CreditCardInput, SquarePaymentsForm } from 'react-square-web-payments-sdk'

const APPLICATION_ID = process.env.REACT_APP_PMT_APPLICATION_ID
const LOCATION_ID = process.env.REACT_APP_PMT_LOCATION_ID;

const SquarePayment = (props) => {
  const { paymentFields, isProduction } = props
  const totalAmountChar = paymentFields.totalAmount.toString()
  const phoneChar = paymentFields.phone.toString()

  const [errorMessages, setErrorMessages] = useState([]);
  const [loadingMessage, setLoadingMessage ] = useState(false)

  const cardTokenizeResponseReceived = (token,buyer) => {
    if (token.errors) {
      setErrorMessages(token.errors.map(error => error.message));
      return;
    }
    
    setErrorMessages([]);
    setLoadingMessage(true)
    
    props.submitPayment(token.token, buyer.token)
  }

  const handleUpdateBilling = (e) => {
    e.preventDefault()
    
    props.updateBilling()
  }

  function buildPaymentRequest() {

    let lineItems = [
      {
        label: paymentFields.lineItemLabel,
        amount: totalAmountChar,
        pending: false,
      },
    ];
 
    //calculateTotal is a helper method that sums the lineItems and 
    //returns a new LineItem with the total amount.
    let total = totalAmountChar;
 
    const paymentRequestDetails = {
      countryCode: 'CA',
      currencyCode: 'CAD',
      lineItems,
      requestBillingContact: true,
      requestShippingContact: false,
      total,
    };
 
    return paymentRequestDetails;
  }

  const createVerificationDetails = () => {
    return {
      amount: totalAmountChar,
      currencyCode: 'CAD',
      intent: 'CHARGE',
      billingContact: {
        familyName: paymentFields.lastName,
        givenName: paymentFields.firstName,
        email: paymentFields.email,
        country: 'CA',
        city: paymentFields.city,
        addressLines: [paymentFields.address],
        postalCode: paymentFields.postal,
        phone: phoneChar,
      },
    };
  }

  return (
    <div className="Payment">
      <div className="container-card">
        <div id="form-container">
          <div>
            <h3> Enter Credit Card information below </h3>
          </div>
          <br></br>

          <SquarePaymentsForm
            applicationId={APPLICATION_ID}
            locationId={LOCATION_ID}
            cardTokenizeResponseReceived={cardTokenizeResponseReceived}   
            createVerificationDetails={createVerificationDetails} 
            createPaymentRequest={buildPaymentRequest}
          >  
            <CreditCardInput 
              text={`Pay $ ${totalAmountChar}`}
            />
          </SquarePaymentsForm>

          {errorMessages[0] && 
          <div className="sq-error-message">
            {errorMessages.map(errorMessage => (
              <li key={`sq-error-${errorMessage}`}>{errorMessage}</li>
            ))}
          </div>
          }
          
          {loadingMessage &&
            <>
              <br></br>
              <p> Please wait while your payment is being processed ... </p>
            </>
          }       
          
          <br></br>
          <p> Credit Card information is securely collected and processed by Square. </p> 
          <p> This information is not captured or stored on the Wallace Park Website </p>
          
          {!isProduction &&
              <p> This page is for Test Credit Cards only </p>
          }
          
          <div className="update" onClick={handleUpdateBilling}>
            {paymentFields.updateInfoMessage}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SquarePayment;