import React, {useState, useEffect} from 'react'
import axios from 'axios'
import Alert from 'react-bootstrap/Alert'

import './Auth.css'
import * as Constants from '../../Constants'

import { AUTH_FORGOT_PSWD_URL } from '../../utils/urls'
import { logAxiosError } from '../../utils/functions';
import { AXIOS_TIMEOUT } from '../../utils/constants'

const Forgot = ({history, updateChildMsg}) => {
    const [email, setEmail] = useState('')
    const [error, setError] = useState('')
    const [nextPage, setNextPage] = useState(false)

    useEffect(() => {
        if(nextPage){
            history.push('/')
        }
    }, [history, nextPage])
    

    const handleEmail = (event) => {
        setEmail(event.target.value)
        setError('')
    }

    const validateInput = () => {
        const validInput = true
        return validInput
    }
   
    const handleSubmit = async (event) => {
        event.preventDefault()

        var validInput = validateInput()
        
        if (validInput){      
            const postForgotPassword = async () => {
                let res

                try{
                    res = await axios({
                        method: 'POST',
                        timeout: AXIOS_TIMEOUT,
                        url: AUTH_FORGOT_PSWD_URL,
                        data: {
                            email: email
                        }
                    })
                } catch(error){
                    updateChildMsg('', Constants.RESET_PASSWORD_MSG2, Constants.MSG_TYPE_INFO, 2000)
                    logAxiosError(error)
                }

                const {data} = res

                if (data.status === Constants.RETURN_CODE_OK) {
                    updateChildMsg('Success !', Constants.RESET_PASSWORD_MSG, Constants.MSG_TYPE_SUCCESS, 2000)
                } else if (data.status === Constants.RETURN_CODE_NOT_AUTH) {
                    updateChildMsg('', Constants.RESET_PASSWORD_MSG2, Constants.MSG_TYPE_INFO, 4000)
                } else {
                    setError('Request failed. Please try again later')
                }
            }  
            postForgotPassword()
            
            setNextPage(true)
        }
    }          

    return (
        <div className="Auth">
            <h2> Forgot Password </h2>

            {error &&
                <Alert variant="danger" onClose={() => setError('')} dismissible>
                    <Alert.Heading>Error!</Alert.Heading>
                    <p>
                        {error}
                    </p>
                </Alert>                
            }

            <form onSubmit={handleSubmit}>
                Email 
                <input 
                    type="email"
                    value={email}
                    placeholder="Email Address"
                    onChange={handleEmail}
                />
                <br></br>
                A link will be sent to your email id to reset your password
                <br></br>

                <button type="submit"> Submit </button>
            </form>
            <div className="Spacer"></div>
        </div>
    )    
}

export default Forgot