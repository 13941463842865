import React from 'react'

//import './Members.css'

const Participants = ({ participantList }) =>  {   
    // authProfile indicator means user has access to the member profile. Available for staff and exec only
    //console.log('rendering member entry', membersList)
    
    if(participantList[0]){
        if(Object.keys(participantList[0]).length === 0){
            //console.log('return null')
            return null
        }
    } else {
        return null
    }
     
    return(
        participantList.map((entry, idx)=> { 
            let key = entry.id

            let fnIdx = `fn-${key}`
            let lnIdx = `ln-${key}`
            let stIdx = `pn-${key}`
 
            return (
                <li className="table-row">
                    <div className="colmn1" key={fnIdx}>{entry.firstName}</div>
                    <div className="colmn2" key={lnIdx}>{entry.lastName}</div>
                    <div className="colmn3" key={stIdx}>{entry.status}</div>
                </li>
            )
        })
    )
}  

export default Participants
