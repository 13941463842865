import React, { useState, useEffect } from "react"

import axios from 'axios'

import './Profile.css'
import {capitalize, logAxiosError } from '../../utils/functions'
import { requestMember } from '../../utils/fetchMember'
import {MEMBER_PROFILE_URL} from '../../utils/urls'
import { AXIOS_TIMEOUT } from '../../utils/constants'

const Profile = ({history, user, updateChildMessage}) => {

    const [member, setMember] = useState({})
    const [origPhoneNumber, setOrigPhoneNumber] = useState()
    const [origConsentNewsLetter, setOrigConsentNewsLetter] = useState()
    const [origConsentPhoneBook, setOrigConsentPhoneBook] = useState()
    const [refresh, setRefresh] = useState(false)
    
    const [updateProfile, setUpdateProfile] = useState(false)
    const [authorizedUser, setAuthorizedUser] = useState(false)

    useEffect(()=> {
        if(!user){
            updateChildMessage("", "Please Login to continue...", "primary", 3000)
            history.push('/login')
        } else if(user.isMember || user.isExec) {
            setAuthorizedUser(true)
        } else {
            setAuthorizedUser(false)
            history.push('/')
        }
    },[])

    useEffect(() => {
        setUpdateProfile(false)
        
        if(authorizedUser){
            requestMember(refresh).then(reqMember => {
                if(reqMember && reqMember.length > 0){
                    setMember(reqMember[0])
                    setOrigPhoneNumber(reqMember[0].phoneNumber)
                    if(reqMember[0].consentNewsLetter){
                        setOrigConsentNewsLetter(true)    
                    } else {
                        setOrigConsentNewsLetter(false)    
                    }
                    if(reqMember[0].consentPhoneBook){
                        setOrigConsentPhoneBook(true)
                    } else {
                        setOrigConsentPhoneBook(false)
                    }
                    
                } else {
                    setMember(null)
                }
                setRefresh(false)
            })
        }
    }, [refresh, authorizedUser])   

    const handleSubmitUpdate = (e) => {
        e.preventDefault()

        let profile = {...member}

        const updateProfile = async () => {
            let res
            let processRequest = true

            try {
                res = await axios({
                    method: 'PUT',
                    timeout: AXIOS_TIMEOUT,
                    url: MEMBER_PROFILE_URL,
                    data: {
                        profile: profile
                    }
                })     
            } catch (error) {
                console.log('Error updating user profile')
                logAxiosError(error) 
                processRequest = false           
            }
            
            if(processRequest){
                setRefresh(true)
                setUpdateProfile(false)
                updateChildMessage('', 'Profile updated successfully', 'success', 5000)
            }
        }  
        
        updateProfile()   
    }

    const handleChange = (e) => {
        let updatedMember = {...member}

        if (["consentPhoneBook", "consentNewsLetter"].includes(e.target.name)) {
            updatedMember[e.target.name] = e.target.checked
        } else {
            updatedMember[e.target.name] = e.target.value
        }    
        
        setMember(updatedMember)
    }

    const onChangeHandler = (e) => {
        return null
    }
    
    let consentNewsLetter = null
    let consentPhoneBook = null
    let phoneNumber = 0

    if(member){
        consentNewsLetter = member.consentNewsLetter
        consentPhoneBook = member.consentPhoneBook
        phoneNumber = member.phoneNumber
    }    

    return(
        <div className="Profile">
            {user && 
            <>
                <div className="card">
                    <div className="container">
                        <h4>
                            <b><i className="fas fa-id-card"></i>
                                {capitalize(user.firstName)} {capitalize(user.lastName)}
                            </b>
                        </h4>
                    
                        <p> 
                            <i className="fa fa-envelope"></i> 
                            {user.email}
                        </p>
                        <p> 
                            {origPhoneNumber && member &&
                                <>
                                    <i className="fas fa-phone-alt"></i>
                                    <span> {origPhoneNumber} </span>    
                                </>
                            }
                        </p>
                        
                        <div className="Preferences"> 
                            {member &&
                                <> 
                                    {origConsentNewsLetter &&
                                        <p><span className="Consent"><i className="fas fa-check"></i></span> 
                                            I consent to receiving the monthly newsletters via email 
                                        </p>
                                    }
                                
                                    
                                    {!origConsentNewsLetter && 
                                        <p><span className="NoConsent"><i className="fas fa-times"></i></span> 
                                            I do not wish to receive the monthly newsletters via email 
                                        </p>
                                    }
                                    {origConsentPhoneBook &&
                                        <p><span className="Consent"><i className="fas fa-check"></i> </span> 
                                            I wish to have my phone number listed in the members-only online phone book 
                                        </p>
                                    }
                                    {!origConsentPhoneBook &&
                                        <p><span className="NoConsent"><i className="fas fa-times"></i></span> 
                                            My phone number is not listed in the online phone book 
                                        </p>
                                    }
                                </>
                            }
                        </div>      
                    </div>
                </div>

                {member &&
                    <>
                        {!updateProfile &&
                            <div className="updateButton">
                                <button onClick={(e) => {setUpdateProfile(true)}}> Update Profile </button>
                            </div>    
                        }
        
                        {updateProfile &&
                        <>
                            <div className="updateButton">
                                <button onClick={(e) => {setUpdateProfile(false)}}> Cancel Update Profile </button>
                            </div>    
                        
                            <div className="form-style-10">
                                <form onSubmit={handleSubmitUpdate} onChange={handleChange}>
                                    <div className="section"><span>1</span>Update Personal Details</div>
                                    <div className="inner-wrap">
                                        <label htmlFor="phoneNumber">Phone Number<span className="required">*</span></label>
                                        <input 
                                            type="number" 
                                            name="phoneNumber" 
                                            maxLength="10"
                                            value={phoneNumber}
                                            onChange={onChangeHandler}
                                        />
                                    </div>    
        
                                    <div className="section"><span>2</span>Update Preferences</div>
                                    <div className="inner-wrap">
                                        <label htmlFor='consentNewsLetter' className="checkbox">  
                                            I wish to receive Newsletters via Email
                                            <input 
                                                type="checkbox"
                                                name="consentNewsLetter"
                                                checked={consentNewsLetter} 
                                                onChange={onChangeHandler}
                                            />
                                        </label>
        
                                        <label htmlFor='consentPhoneBook'  className="checkbox">
                                            Allow other members to view my Phone #
                                            <input
                                                type="checkbox"
                                                name="consentPhoneBook"
                                                checked={consentPhoneBook} 
                                                onChange={onChangeHandler}
                                            />
                                        </label>
                                    </div>
                                    <button type="submit"> Update My Profile </button>
                                </form>
                            </div>
                        </>
                        }
                    </>    
                }
            </>           
            }            
        </div>        
    )
}

export default Profile
