import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';

import './Home.css'

import {fromImageToUrl} from '../../utils/urls';

export default (props) => {
  const { data, user, configValues } = props
  
  const { welcomeTitle,
          welcomeText,
          leftBox,
          rightBox,
          bannerTitle,
          bannerText,
          firstLinkName,
          firstLinkAddress,
          secondLinkName,
          secondLinkAddress,
          homepageSlider
  } = data

  const [showMemberInfo, setShowMemberInfo] = useState(false)

  const memberInfo = `Attention Members: The Gate Code is ${configValues.gateCode}`

  useEffect(() => {
    window.scrollTo(0, 0)

    if(user && !user.isAuth){
      setShowMemberInfo(true)
    } else {
      setShowMemberInfo(false)
    }
  }, [user])
  
  let image = ''

  if (homepageSlider) {
      image = homepageSlider[0]
  }

  return (
    <div className="Home">
      <img className="Image" src={fromImageToUrl(image)} alt="Home Page"/>
      
      <div className="Welcome"> {welcomeTitle} </div>  
      
      {showMemberInfo &&
        <div className="Memberinfo"> {memberInfo} </div>  
      }
      
      <div className="Welcomemsg"> {welcomeText} </div>

      {firstLinkName &&
        <div className="Welcomemsg"> 
          <a href={firstLinkAddress} target="_blank">{firstLinkName} </a>
        </div>
      }
      
      <div className="Textcontainer"> 
        <div className="Para"> 
          <ReactMarkdown>{leftBox}</ReactMarkdown>
        </div>
        <div className="Para"> 
          <ReactMarkdown>{rightBox}</ReactMarkdown>
        </div>
      </div>
      
      <div className="Bulletin">
        <div className="B-title"> {bannerTitle} </div>
        <div className="B-content"> {bannerText} </div>  

        {secondLinkName &&
          <div className="B-content2"> 
            <a href={secondLinkAddress} target="_blank">{secondLinkName} </a>
          </div>
        }
      </div>
    </div>
  );
}
