import React, { useEffect, useState } from 'react';

const CourtBooking = () => {
    return(
        <div className="Courts">
            <div className="Headerbar">
                <span>
                    <h3> Court Bookings - Future </h3>
                </span>    
            </div>

        </div>
    )
}

export default CourtBooking;
