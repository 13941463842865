import React, { useEffect, useState } from 'react'

import TransactionEntry from './TransactionEntry'
import { requestTransactionList  } from '../../utils/fetchTransactions'
import { compareValues } from '../../utils/functions'

import ExportXLS from '../../components/ExportXLS'    

import './Members.css'

/* 
    Documentation - copied from members

    State Variables:
    membersEntries    - the total set of all members 
    searchText  - user entered text to search. It will search both first and last names for contained characters
                - it can search only 1 name (no spaces)
    searchResult    - the result of the search. This array is the total list of entries that will be shown to the user (over multiple pages)
                    - it is a subset of membersEntries. It equals the membersEntries when no search is specified
    
    currentPage     - The page# of the current page the user is on. This is updated everytime the user navigates to a new page

    totalCurrentEntries     - the total# of entries based on the current search criteria
    pageStartEntry          - the serial# of the first item being displayed on the screen
    pageEndEntry            - the serial# of the last item being displayed on the screen
    currentPageList         - the list of entries that are shown to the user on the active page
    entriesPerPage          - user selected variable. Determines how many entries will be shown per page

    Pagination Bar:                
        This Bar is shown on the bottom right only if there is more than 1 page to diplay
        firstPage   - The Page Number associated with the first page shown on the pagenation bar 
        secondPage  - The Page Number associated with the second page shown on the pagenation bar 
        thirdPage   - The Page Number associated with the third page shown on the pagenation bar 
        showChevron - Set if there is more than 1 page to display
    
    Sort Fields
    First Name Ascending    -> firstNameSortAscending -> Default Sort Order when entering the page
    First Name Descending   -> firstNameSortDescending
    Last Name Ascending     -> lastNameSortAscending
    Last Name Descending    -> lastNameSortDescending
    id Ascending            -> idSortAscending
    id Descending           -> idSortDescending
    Note: Only 1 sort will be active at any give time

    Modes:
    There are several modes that this screen supports
    selectedEventMode ->    A staff user is using this screen to search for member to signup for a 
                            particular event

*/
const Transactions = ({ history, user, updateTransactions, refreshTransactions, match }) =>  {
    const [transactionEntries, setTransactionEntries] = useState([{}])
    const [searchText, setSearchText] = useState('')
    const [searchResult, setSearchResult] = useState([{}])
    const [trnDateSortAscending, setTrnDateSortAscending] = useState(true)
    const [trnDateSortDescending, setTrnDateSortDescending] = useState(false)
    const [firstPage, setFirstPage] = useState()
    const [secondPage, setSecondPage] = useState()
    const [thirdPage, setThirdPage] = useState()
    const [firstPageStyle, setFirstPageStyle] = useState('')
    const [secondPageStyle, setSecondPageStyle] = useState('')
    const [thirdPageStyle, setThirdPageStyle] = useState('')
    const [showChevron, setShowChevron] = useState(false)
    const [currentPage, setCurrentPage] = useState()
    const [totalCurrentEntries, setTotalCurrentEntries] = useState()
    const [pageStartEntry, setPageStartEntry] = useState()
    const [pageEndEntry, setPageEndEntry] = useState()
    const [currentPageList, setCurrentPageList] = useState([{}])
    const [entriesPerPage, setEntriesPerPage] = useState(10)
    const [entriesFound, setEntriesFound] = useState(true)
    const [membershipId, setMembershipId] = useState(0)
    
    const [authorizedUser, setAuthorizedUser] = useState(false)
    const [transactionDetails, setTransactionDetails] = useState(false)
    const [transactionDownload, setTransactionDownload] = useState([{}])

    const [xlsData, setXlsData] = useState()

    let newEntriesPerPage = 10

    useEffect(()=> {
        if(!user){
            // child message not showing in this case. Investigate Later
            // updateChildMessage("", "Please Login to continue...", "primary", 0)
            history.push('/login')
        } else if(user.isMember || user.isExec) {
            setAuthorizedUser(true)

            //get membership id from the user object
            setMembershipId(user.membershipId)
        } else if(user.isAdmin || user.isStaff) {
            setAuthorizedUser(true)
            //get the membership id from the match object
            if(match && match.params.membershipId){
                setMembershipId(match.params.membershipId)
            }
        }
        setTransactionDetails(false)
    },[])

    useEffect(() => {
        if(authorizedUser){
            setTrnDateSortAscending(true)
            setTrnDateSortDescending(false)
            
            //defualt to 10 entries per page
            setEntriesPerPage(10)
          
            requestTransactionList(refreshTransactions, membershipId).then(tranList => {
                if(tranList && tranList.length>0){
                    let tranListMod = _addFormattedDate(tranList)
                    tranListMod.sort(compareValues('tranDate'))

                    //copy the transactions to a second object for downloading (if requested)
                    setTransactionDownload(JSON.parse(JSON.stringify(tranListMod)))

                    setTransactionEntries(tranListMod)
                    setSearchResult(tranListMod)
                    updateTransactions(false)
                    setEntriesFound(true)
                } else {
                    setEntriesFound(false)
                }
            })
        }
    }, [authorizedUser, refreshTransactions])   

    useEffect(()=>{
        if(authorizedUser){
            setPagination(searchResult, 1, 1, true)
        }
    },[entriesPerPage, searchResult, authorizedUser])

    const handleRefreshTransactions = () => {
        updateTransactions(true)
    }

    const _formatDateTime = (timestamp) => {
        let tempDate = new Date(timestamp)
        let displayDate =   (tempDate.getMonth()+1)
                        +'/'+ (tempDate.getDate())
                        +'/'+ (tempDate.getFullYear())
        return displayDate
    }

    const setPagination = (list, startPage=1, thisPage=1, rebuildPage=false) => {
        let totalEntries = list.length
        
        let totalPages = Math.ceil(totalEntries / entriesPerPage)

        if((thisPage !== currentPage) || rebuildPage){
            setCurrentPage(thisPage)
            //show the entries for this page
            setTotalCurrentEntries(totalEntries)
            let startingEntry = 0
            let endingEntry = 0
            let newCurrentPageList = [...list]

            if(totalEntries > 0){
                startingEntry = ((thisPage - 1) * entriesPerPage) + 1
                endingEntry = startingEntry + entriesPerPage - 1
                if (endingEntry > totalEntries){
                    endingEntry = totalEntries
                } 
                newCurrentPageList = [...list].slice((startingEntry-1), (endingEntry))
            }
            
            setPageStartEntry(startingEntry)
            setPageEndEntry(endingEntry)
            setCurrentPageList(newCurrentPageList)
        }

        switch(totalPages){
            case 0:
            case 1:    
                setFirstPage(null)
                setSecondPage(null)
                setThirdPage(null)                
                setShowChevron(false)
                break
            case 2:
                setFirstPage(1)
                setSecondPage(2)
                setThirdPage(null)                
                setShowChevron(true)

                if (thisPage === 1){
                    setFirstPageStyle('active')
                    setSecondPageStyle('')
                } else {
                    setFirstPageStyle('')
                    setSecondPageStyle('active')
                }

                break
            default:
                if((totalPages > 3) && (startPage + 2) > totalPages){
                    //there are more than 3 pages. Align the last set to show the last 3 pages
                    startPage = totalPages - 2
                }

                if((totalPages > 0) && (startPage <= 0)){
                    //show the first page if there are pages to display if an invalid start page is requested
                    startPage = 1
                }

                if(startPage <= totalPages){
                    setFirstPage(startPage)
                } else {
                    setFirstPage(null)
                } 
                
                if((startPage + 1) <= totalPages){
                    setSecondPage(startPage + 1)
                } else {
                    setSecondPage(null)
                } 

                if((startPage + 2) <= totalPages){
                    setThirdPage(startPage + 2)     
                } else {
                    setThirdPage(null)
                }   
                
                if (thisPage === startPage) {
                    //first page is active
                    setFirstPageStyle('active')
                    setSecondPageStyle('')
                    setThirdPageStyle('')
                } else if (thisPage === (startPage + 1)) {
                    //second page is active
                    setFirstPageStyle('')
                    setSecondPageStyle('active')
                    setThirdPageStyle('')
                } else {
                    // third page is active
                    setFirstPageStyle('')
                    setSecondPageStyle('')
                    setThirdPageStyle('active')
                }
                setShowChevron(true)
        }
    }

    const handleDateSort = () => {
        let currentTransactionList = searchResult

        if(trnDateSortAscending){
            setTrnDateSortAscending(false)
            setTrnDateSortDescending(true)
            
            currentTransactionList.sort(compareValues('tranDate', 'desc'))
            setSearchResult(currentTransactionList)
            setPagination(currentTransactionList, 1, 1, true)
        } else {
            setTrnDateSortAscending(true)
            setTrnDateSortDescending(false)
            
            currentTransactionList.sort(compareValues('tranDate'))
            setSearchResult(currentTransactionList)
            setPagination(currentTransactionList, 1, 1, true)
        }
    }

    const handleChange = (e) => {
        let searchValue = e.target.value
        setSearchText(searchValue)
        buildCurrentPage(searchValue)        
    }

    const buildCurrentPage = (searchName) => {
        let currentSearchResult = [{}]

        if(!searchName){
            currentSearchResult = transactionEntries 
        } else {
            //find all entries where first name part or last name part match the search criteria
            currentSearchResult = transactionEntries.filter(item => {
                if(item.description.toLowerCase().includes(searchName.toLowerCase())) {
                    return item
                }
            })                                            
        }
        setSearchResult(currentSearchResult)
    }

    const handlePrevPage = () => {
        if(currentPage <= 1){
            //no action to be performed
            return null
        }

        if(currentPage === firstPage){
            //we are already on the first page of this list. The page numbers need to be updated 
            setFirstPage(firstPage -1)
            setSecondPage(secondPage - 1)
            setThirdPage(thirdPage - 1)
            //decrement pages in sets of 3
            setPagination(searchResult, (currentPage - 3), (currentPage - 1))
        } else {
            setPagination(searchResult, firstPage, (currentPage - 1))
        }
    }

    const handleNextPage = () => {
        let maxPage = Math.ceil( searchResult.length / entriesPerPage)

        if (currentPage >= maxPage){
            //we are already at the last page. No action needed
            return null
        }

        if(currentPage === thirdPage){
            //we are already on the first page of this list. The page numbers need to be updated 
            setFirstPage(firstPage + 1)
            setSecondPage(secondPage + 1)
            setThirdPage(thirdPage + 1)
            setPagination(searchResult, (currentPage + 1), (currentPage + 1))
        } else {
            setPagination(searchResult, firstPage, (currentPage + 1))
        }
    }

    const handlePageClick = (pageNum) => {
        if(pageNum === "1"){
            //first page clicked
            setPagination(searchResult, firstPage, firstPage)
        } else if(pageNum === "2"){
            //second page requested
            setPagination(searchResult, firstPage, secondPage)
        } else {
            // third page requested
            setPagination(searchResult, firstPage, thirdPage)
        }
    }

    const handleEntriesPerPage = (e) => {
        newEntriesPerPage = parseInt(e.target.value, 10)

        setEntriesPerPage(newEntriesPerPage)
        //setPagination(searchResult, 1, 1, true)
    }

    const _addFormattedDate = (transactions) => {
        if(transactions && transactions.length > 0 ){
            for(let idx=0; idx < transactions.length; idx++){
                transactions[idx].tranDateFmt = _formatDateTime(transactions[idx].tranDate)

                //add an id to the entry for proper display
                transactions[idx].id = idx + 1
            }
        }
        return transactions
    }

    const formatDownload = () => {
        let outputData = [{}]
        
        if(transactionDownload && transactionDownload.length > 0 ){
            for(let idx=0; idx < transactionDownload.length; idx++){
                let entry = {}

                entry.TransactionDate = transactionDownload[idx].tranDateFmt
                entry.Description = transactionDownload[idx].description
                entry.Credits = transactionDownload[idx].credits
                entry.Type = transactionDownload[idx].transactionType
                entry.Balance = transactionDownload[idx].balance

                if(entry.Balance === -1){
                    //Balance is not available for payment transactions
                    
                    //calculate the balance based on the previous transaction
                    if(idx === 0){
                        //This is the first entry. Set the balance to equal #of credits purchased
                        if(entry.Type === 'credit'){
                            entry.Balance = entry.Credits
                        } else {
                            //first entry is negative and should theoretically never happen
                            entry.Balance = 'N/A'
                        }
                    } else {
                        //this is the 2nd or more transaction. 
                        if(entry.Type === 'credit'){
                            entry.Balance = entry.Credits + outputData[idx-1].Balance
                        } else {
                            //first entry is negative and should theoretically never happen
                            entry.Balance = outputData[idx-1].Balance - entry.Credits
                        }
                    }
                }

                if(transactionDownload[idx].eventId){
                    if(transactionDownload[idx].eventId > 0){
                        //amount is N/A for internal transactions
                        entry.Amount = ''    
                    } else {
                        entry.Amount = transactionDownload[idx].amount
                    }
                } else {
                    //no transaction exists for this user. Only purchases may exist
                    entry.Amount = transactionDownload[idx].amount
                }

                if(transactionDownload[idx].firstName){
                    if(transactionDownload[idx].firstName === -1){
                        //no name available for this transaction
                        entry.TransactionFor = ' '
                    } else {
                        entry.TransactionFor = transactionDownload[idx].firstName
                                        + ' ' + transactionDownload[idx].lastName
                    }
                    entry.TransactionFor = transactionDownload[idx].firstName
                                    + ' ' + transactionDownload[idx].lastName
                } else {
                    entry.TransactionFor = ' '
                }
                
                if(idx === 0){
                    outputData[0] = entry
                } else {
                    //for idx > 0, use the push function
                    outputData.push(entry)
                }
            }
        }

        setXlsData(outputData)
    }

    const handleTransactionDetails = (e) => {
        e.preventDefault()
        setTransactionDetails(true)

        //format data
        formatDownload()
    }

    const handleFileDownloaded = () => {
        //restore the original buttons after the file is downloaded
        setTransactionDetails(false)
    }

    const handleGoback = () => {
        history.goBack()
    }

    return(
        <>
        {!user &&
            <></>
        }
        {user && !entriesFound &&
            <>
                <div className="Members">
                    <h3> No transactions found </h3>
                </div>    
            </>    
        }

        <div className="Members">
            <div className="Header2">
                {xlsData && transactionDetails && 
                    <>
                        <ExportXLS
                            csvData={xlsData}
                            fileName={`transactions.csv`}
                            fileDownloaded={handleFileDownloaded}
                        />
                    </>
                }
            </div>
        </div>
        
        {user && entriesFound && 
        <div className="Members">
            <div className="Searchbar">
                <h3> Transactions </h3>
                <span className="SearchCard">
                    <form className="Searchbox" >
                        <div className="InputContainer">
                            <span>
                                <input 
                                    className="InputBox"
                                    placeholder= "search transactions..."
                                    type="search" 
                                    value={searchText}
                                    onChange={handleChange}
                                />
                            </span>
                        </div>
                    </form>    
                </span> 
            </div>
            
            <div className="Memberlist">
                <div className="table-scroll">
                    <table>
                        <thead>
                            <tr>
                                <th>
                                    <span className="text" onClick={handleDateSort}>Date
                                    {trnDateSortAscending &&
                                        <span><i className="fas fa-sort-up"></i></span>
                                    }
                                    {trnDateSortDescending && 
                                        <span><i className="fas fa-sort-down"></i></span>
                                    }
                                    </span>
                                </th>
                                <th>
                                    <span className="text">Description</span>
                                </th>
                                <th>
                                    <span className="text">Credits</span>
                                </th>
                                <th>
                                    <span className="text">Type</span>
                                </th>
                            </tr>
                        </thead>

                        <tbody>
                            <TransactionEntry
                                transactionList={currentPageList}
                            />
                        </tbody>               
                    </table>                    
                </div>    
            </div>
            <div className="pagefooter">
                <div className="PageSelection"> 
                    <form>   
                        <select 
                            name="perPage"  
                            size="1"
                            value={entriesPerPage} 
                            onChange={(e)=>{handleEntriesPerPage(e)}}
                        >
                            <option value="10">10 </option>
                            <option value="20">20 </option>
                            <option value="50">50 </option>
                        </select> 
                        <label htmlFor="perPage"> ...entries per page</label>
                    </form>                
                </div>

                <div> 
                    Showing {pageStartEntry} to {pageEndEntry} of {totalCurrentEntries}  
                </div>

                <div className="pagination">
                    {showChevron && 
                        <span onClick={handlePrevPage}>&laquo;</span>
                    }    
                    {firstPage &&
                        <span 
                            className={firstPageStyle}
                            onClick={()=>{handlePageClick("1")}}
                        >
                            {firstPage} 
                        </span>
                    }
                    {secondPage &&
                        <span 
                            className={secondPageStyle}
                            onClick={()=>{handlePageClick("2")}}
                        >
                            {secondPage} 
                        </span>
                    }
                    {thirdPage &&
                        <span 
                            className={thirdPageStyle}
                            onClick={()=>{handlePageClick("3")}}
                        >
                            {thirdPage} 
                        </span>
                    }
                
                    {showChevron &&
                        <span onClick={handleNextPage}>&raquo;</span>
                    }    
                </div>
            </div>
            
            {!transactionDetails &&
                <div className="bottomButtons">
                    <button className="memberDetails" onClick={handleTransactionDetails}> Generate Transaction Details </button>
                    <button className="memberDetails" onClick={handleRefreshTransactions}> Refresh Transactions </button>
                </div>
            }
            
            {transactionDetails &&
                <>
                    Click on the Excel Icon above to download the transaction details
                </>
            }
        </div>
        }
        </>
    )
}  

export default Transactions
