import React, { useContext, useState, useEffect } from 'react';
import { Navbar, Nav, NavDropdown }from 'react-bootstrap';
import { Link } from 'react-router-dom';

import {UserContext} from '../context/UserContext'

import './NavBar.css'

import Rzone from './rzone24.png';

//const calendarUrl2 = 'https://drive.google.com/file/d/1oKFP5tK6QTMNVRWKhgAKLV8tjJt86TqP/view?usp=sharing';

const calendarUrl = 'https://docs.google.com/spreadsheets/d/1BX11Yzz3sHSQi0LEr2s5EtSroHw7P2s9N6jfSLfcyOU/edit?usp=sharing'
const memberFeedback = 'https://docs.google.com/forms/d/e/1FAIpQLSdYnlajJOp9u_3tXt6k6WuLsDgHb7z8WpfCXPzOhiPosV-zWQ/viewform?usp=sf_link';
const rZoneUrl = 'https://opl.ca/getmedia/6ed650a6-54d0-4f13-8e94-239ec28c11ba/rzone_1.pdf'

const NavBar = ({configValues, resourceLinks}) => {
    //console.log('props', configValues)
    
    const { signupAllowed, 
            loginAllowed, 
            showCamera, 
            currentSeason,
            prioritizeRegistration,
            purchaseCredits, 
            allowRegister,
            juniorOnly,
            showWaitlistForm,
            showWaitlistInfo
        } = configValues

    const [user] = useContext(UserContext)
    const [menuTitle, setMenuTitle] = useState()
    const [userName, setUserName] =useState('')
    
    const [showLeftMenu, setShowLeftMenu] = useState(false)
    const [showRegister, setShowRegister] = useState(false)
    const [showProfile, setShowProfile] = useState(false)
    const [showPhoneBook, setShowPhoneBook] = useState(false)
    const [showClubEvents, setShowClubEvents] = useState(false)
    const [showPurchaseTokens, setShowPurchaseTokens] = useState(false)
    const [showPmtSummary, setShowPmtSummary] = useState(false)
    const [showCreditsRep, setShowCreditsRep] = useState(false)
    const [showStaffRegForm, setShowStaffRegForm] = useState(false)
    const [showMemberList, setShowMemberList] = useState(false)
    const [showEventBookings, setShowEventBookings] = useState(false)
    const [showManageEvents, setShowManageEvents] = useState(false)
    const [showCredits, setShowCredits] = useState()
    const [showNotification, setShowNotification] = useState()
    const [showFeedback, setShowFeedback] = useState()
    const [showEventCalendar, setShowEventCalendar] = useState()
    const [showWaitlist, setShowWaitlist] = useState(false)
    const [showWaitlistMembers, setShowWaitlistMembers] = useState(false)
        
    useEffect(()=>{
        if(user && user.credits > 0){
            setShowCredits(true)    
        } else {
            setShowCredits(false)
        }
    },[user])

    useEffect(()=>{
        //set all the restricted routes to false
        let leftMenu = false
        let profile = false
        let phoneBook = false
        let purchaseTokens = false
        let pmtSummary = false
        let creditsRep = false
        let staffRegfForm = false
        let memberList = false
        let manageEvents = false
        let eventBookings = false
        let register = false
        let notifications = false
        let clubEvents = false
        let feedback = false
        let eventCalendar = false
        let waitlist = false
        let waitlistMembers = false

        if(user){
            let {
                isMember,
                isStaff,
                isAuth,
                isExec,
                isInstructor,
                isAdmin
            } = user
            
            setUserName(`${user.firstName} ${user.lastName}`)
    
            if(isMember){
                setMenuTitle("Member")
                leftMenu = true
                profile = true
                phoneBook = true
                purchaseTokens = purchaseCredits
                clubEvents = true
                feedback = true
            } else if(isStaff){
                setMenuTitle("Staff")
                leftMenu = true
                staffRegfForm = true
                memberList = true
                manageEvents = true
                eventBookings = true
                notifications = true
                feedback = true
                waitlistMembers = true
            } else if(isAdmin){
                setMenuTitle("Admin")
                leftMenu = true
                staffRegfForm = true
                memberList = true
                pmtSummary = true
                creditsRep = true
                manageEvents = true
                eventBookings = true
                notifications = true
                feedback = true
                eventCalendar = true
                waitlistMembers = true
            } else if(isExec){
                setMenuTitle("Executive")
                leftMenu = true
                profile = true
                memberList = true
                pmtSummary = true
                creditsRep = true
                notifications = true
                purchaseTokens = purchaseCredits
                clubEvents = true
                manageEvents = true
                feedback = true
                waitlistMembers = true
            } else if(isInstructor){
                setMenuTitle("Instructor")
                leftMenu = true
                memberList = true
            } else if(isAuth){
                //default waitlist to false for Auth user
                waitlist = false

                if(allowRegister){
                    if(prioritizeRegistration){
                        //check if the user can register
                        if(user.registrationAllowed){
                            register = true 
                            waitlist = false
                        } else if(juniorOnly) {
                            //allow junior registrations even if only prioritized 
                            //registrations are ON.
                            register = true 

                            if(showWaitlistForm){
                                //show the waitlist if the waitlist form is active
                                waitlist = true
                            } else {
                                //club is closed for any application
                                waitlist = false    
                            }
                        } else {
                            if(showWaitlistForm){
                                //show the waitlist if the waitlist form is active
                                waitlist = true
                            } else {
                                //club is closed for any application
                                waitlist = false    
                            }
                        }
                    } 
                     else {
                        //open registration or junior only registration
                        register = true

                        if(juniorOnly){
                            waitlist = true
                        }
                    }
                } else {
                    if(showWaitlistForm){
                        waitlist = true
                    } else {
                        //club is closed for any application
                        waitlist = false    
                    }
                }
            } 
        } else {
            //public route - default the waitlist to true
            waitlist = true
            
            if(allowRegister){
                //if Open Registration is available, do not show the waitlist
                if(!prioritizeRegistration && !juniorOnly){
                    waitlist = false
                } else {
                    waitlist = true
                }
            } else {
                waitlist = true
            }
        }

        if(showWaitlistInfo){
            //continue with earlier determination of showing the waitlist info page
        } else {
            //override any earlier determination of showing the waitlist info page
            waitlist = false
        }

        setShowLeftMenu(leftMenu)
        setShowRegister(register)
        setShowProfile(profile)
        setShowPhoneBook(phoneBook)
        setShowClubEvents(clubEvents)
        setShowPurchaseTokens(purchaseTokens)
        setShowPmtSummary(pmtSummary)
        setShowCreditsRep(creditsRep)
        setShowNotification(notifications)
        setShowStaffRegForm(staffRegfForm)
        setShowMemberList(memberList)
        setShowManageEvents(manageEvents)
        setShowEventBookings(eventBookings)
        setShowFeedback(feedback)
        setShowEventCalendar(eventCalendar)
        setShowWaitlist(waitlist)
        setShowWaitlistMembers(waitlistMembers)
    },[user, configValues])

    return(
        <div className="NavBar">
            <Navbar className="color-nav" collapseOnSelect expand="lg">
                <Navbar.Brand as={Link} to="/">
                    <span className="nav-text">
                        <i className="fas fa-home"></i>
                    </span>    
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="mr-auto">
                        {showLeftMenu &&
                            <NavDropdown 
                                title ={
                                    <Navbar.Text>
                                        <span className="nav-text">{menuTitle}</span>
                                    </Navbar.Text>
                                }
                                id="basic-nav-dropdown" 
                            >   
                                {showStaffRegForm &&
                                    <>
                                        <NavDropdown.Item  as={Link} to="/register/staff">Registration Form</NavDropdown.Item>
                                        <NavDropdown.Item  as={Link} to="/register/phone">Registration by Phone</NavDropdown.Item>
                                        <NavDropdown.Divider />
                                    </>
                                }

                                {showMemberList &&
                                    <NavDropdown.Item  as={Link} to="/memberlist">Member List</NavDropdown.Item>
                                }

                                {showEventBookings &&
                                    <NavDropdown.Item  as={Link} to="/events/currentevents">Bookings</NavDropdown.Item>
                                }

                                {showManageEvents &&
                                    <NavDropdown.Item  as={Link} to="/events/manage">Manage Events</NavDropdown.Item>
                                }

                                {showPhoneBook &&
                                    <NavDropdown.Item  as={Link} to="/phonebook">Phonebook</NavDropdown.Item>
                                }
                                
                                {showCamera &&
                                    <NavDropdown.Item  as={Link} to="/camera">Live Action</NavDropdown.Item>    
                                }
                                
                                {showClubEvents && 
                                    <NavDropdown.Item  as={Link} to="/events/currentevents">Club Events</NavDropdown.Item>    
                                }
                                
                                {showPurchaseTokens && 
                                    <NavDropdown.Item  as={Link} to="/credits/purchase">Purchase Credits</NavDropdown.Item>    
                                }
                                
                                {showPmtSummary &&
                                    <NavDropdown.Item  as={Link} to="/financials/pmtSummary">Payment Summary</NavDropdown.Item>
                                }    

                                {showCreditsRep &&
                                    <NavDropdown.Item  as={Link} to="/financials/creditsreport">Credits Report</NavDropdown.Item>
                                }

                                {showNotification &&
                                    <NavDropdown.Item  as={Link} to="/notifications">Update Notifications</NavDropdown.Item>
                                }    

                                {showWaitlistMembers &&
                                    <NavDropdown.Item  as={Link} to="/waitlist">Waitlist Applicants</NavDropdown.Item>
                                }    

                                {showFeedback &&
                                    <NavDropdown.Item 
                                        href={memberFeedback} target="_blank" >
                                        Feedback?
                                    </NavDropdown.Item>
                                }
                            </NavDropdown>
                        }
                    </Nav>

                    <Nav className="mr-auto">  
                        <NavDropdown 
                            title ={
                                <Navbar.Text>
                                    <span className="nav-text">Calendar</span>
                                </Navbar.Text>
                            }
                            id="basic-nav-dropdown" 
                        >   
                            <>
                                {showEventCalendar && 
                                    <NavDropdown.Item  as={Link} to="/calendar">Event Calendar</NavDropdown.Item>
                                }
                                
                                <NavDropdown.Item 
                                    href={calendarUrl} target="_blank" >
                                    Weekly Overview
                                </NavDropdown.Item>
                            </>    
                        </NavDropdown>
                    </Nav>        

                    <Nav className="mr-auto">
                        <Nav.Link as={Link} to="/events/home">
                            <span className="nav-text">Club Tennis</span> 
                        </Nav.Link>

                        <Nav.Link as={Link} to="/lessons">
                            <span className="nav-text">Lessons</span> 
                        </Nav.Link>

                        <Nav.Link as={Link} to="/membership">
                            <span className="nav-text">Membership</span> 
                        </Nav.Link>    
                    </Nav> 

                    <Nav className="mr-auto">  
                        <NavDropdown 
                            title ={
                                <Navbar.Text>
                                    <span className="nav-text">Resources</span>
                                </Navbar.Text>
                            }
                            id="basic-nav-dropdown" 
                        >   
                            <>
                                {resourceLinks.map((resource, idx)=>{
                                    return(
                                        <div key={resource.linkOrder}>
                                            <NavDropdown.Item 
                                                href={resource.linkUrl} target="_blank" >
                                                {resource.linkName}
                                            </NavDropdown.Item>
                                        </div>
                                    )        
                                    })
                                }       
                            </>    
                        </NavDropdown>
                    </Nav>        

                    {showRegister &&
                        <Nav.Link as={Link} to="/register/info">
                            <span className="nav-text">Register for {currentSeason}</span>    
                        </Nav.Link>
                    }

                    {showWaitlist &&
                        <Nav.Link as={Link} to="/register/waitlist">
                            <span className="nav-text">Waitlist Info</span>    
                        </Nav.Link>
                    }

                    <Navbar.Collapse className="justify-content-end">
                        <Navbar.Brand href={rZoneUrl} target="_blank" >
                            <span className="nav-text">
                                <img src={Rzone} alt="rzone-logo"/>
                            </span>    
                        </Navbar.Brand>

                        <Nav> 
                            {user &&
                                <NavDropdown 
                                    title ={
                                        <Navbar.Text>
                                            <span className="nav-text">{userName} </span>
                                        </Navbar.Text>
                                    }
                                    id="basic-nav-dropdown" 
                                >    
                                    {showProfile &&
                                        <>
                                            <NavDropdown.Item  as={Link} to="/profile">Profile</NavDropdown.Item>        
                                            <NavDropdown.Item  as={Link} to="/viewregistration">View My Registration</NavDropdown.Item>
                                            <NavDropdown.Item  as={Link} to="/transactions">View My Transactions</NavDropdown.Item>
                                            {showCredits &&
                                                <NavDropdown.Item> Credits Balance: {user.credits} </NavDropdown.Item>
                                            }
                                        </>
                                    }       
                                    <NavDropdown.Divider />
                                    <NavDropdown.Item  as={Link} to="/password">
                                        <span>Change Password</span> 
                                    </NavDropdown.Item>
                                    <NavDropdown.Item  as={Link} to="/signout">
                                        <span>Logout</span> 
                                    </NavDropdown.Item>
                                </NavDropdown>
                            }
                            {!user &&
                                <>
                                    {loginAllowed &&
                                        <Nav.Link as={Link} to="/login">
                                            <span className="nav-text">Login</span> 
                                        </Nav.Link>
                                     }
                                    
                                    {signupAllowed && 
                                        <Nav.Link as={Link} to="/signup">
                                            <span className="nav-text">Signup</span> 
                                        </Nav.Link>
                                    }
                                </>
                            }
                        </Nav>
                    </Navbar.Collapse>       
                </Navbar.Collapse>
            </Navbar>             
        </div>
    )
}

export default NavBar