import React, {useState, useEffect} from 'react'
import axios from 'axios'
import Alert from 'react-bootstrap/Alert'
import queryString from 'query-string'

import './Auth.css'
import * as Constants from '../../Constants'

import { AUTH_RESET_PSWD_URL } from '../../utils/urls'
import { logAxiosError } from '../../utils/functions';
import { AXIOS_TIMEOUT } from '../../utils/constants'

const Reset = ({history, location, updateChildMsg}) => {
    const [password, setPassword] = useState('')
    const [passwordConfirmation, setPasswordConfirmation] = useState('')
    const [error, setError] = useState('')
    const [success, setSuccess] = useState('')
    const [code, setCode] = useState('')

    const parsed = queryString.parse(location.search)

    useEffect(() => {
        setCode(parsed.code)
    }, [parsed.code])
        
    useEffect(() => {
        if(success){
            //first replace the History to pretend we are coming from the Home Page to the Login Page
            history.replace('/')
            history.push('/login')
        }
    }, [history, success])

    const handlePassword = (event) => {
        setPassword(event.target.value)
        setError('')
    }

    const handlePasswordConfirmation = (event) => {
        setPasswordConfirmation(event.target.value)
        setError('')
    }

    const validateInput= () => {
        var validInput = true;

        //console.log('code', code)
        
        if (validInput && password.length < 9) {
            setError('Please enter a password greater than 8 characters')
            validInput = false
        } 
        
        if (validInput &&  (password !== passwordConfirmation)) {
            setError('Password mismatch')
            setPassword('')
            setPasswordConfirmation('')
            validInput = false
        } 
        
        return validInput
    }

    const handleSubmit = async (event) => {
        event.preventDefault()

        const validInput = validateInput()
        
        if (validInput){    
            const postResetPassword = async () => {
                let res 

                try{
                    res = await axios({
                        method: 'POST',
                        url: AUTH_RESET_PSWD_URL,
                        timeout: AXIOS_TIMEOUT,
                        data: {
                            code,
                            password,
                            passwordConfirmation
                        }
                    })
                } catch(error){
                    setError('Error when connecting to server ' + error)
                    logAxiosError(error)
                }
                              
                const {data} = res

                if (data.status === Constants.RETURN_CODE_OK) {
                    setSuccess('success')
                    updateChildMsg('Success !', Constants.SUCCESS_PASSWORD_MSG, Constants.MSG_TYPE_SUCCESS, 2000)

                } else if (data.status === Constants.RETURN_CODE_NOT_AUTH) {
                    setError('Error resetting password')
                    //Clear the form
                    setPassword('')
                    setPasswordConfirmation('')
                } else {
                    setError('Request failed. Please try again later')
                    //Clear the form
                    setPassword('')
                    setPasswordConfirmation('')
                }
            }  
            postResetPassword()  
        }
    }

    return (
        <div className="Auth">
            <h2> Reset Password </h2>

            {error &&
                <Alert variant="danger" onClose={() => setError('')} dismissible>
                    <Alert.Heading>Error!</Alert.Heading>
                    <p>
                        {error}
                    </p>
                </Alert>                
            }

            <form onSubmit={handleSubmit}>
                New Password
                <input 
                    type="password"
                    value={password}
                    placeholder="new password"
                    onChange={handlePassword}
                />  
                <br></br>
                Re-enter password

                <input 
                    type="password"
                    value={passwordConfirmation}
                    placeholder="re-enter password"
                    onChange={handlePasswordConfirmation}
                />
                <br></br>
                After you reset your password, please login with your changed password

                <button type="submit"> Reset Password </button>
            </form>
            <div className="Spacer"></div>
        </div>
    )    
}

export default Reset
