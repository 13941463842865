//This file is for global constants not including Error Codes, URLs and Messages

export const MAX_LENGTH_TEXT = 40
export const MAX_LENGTH_MULTI_TEXT = 200
export const MAX_LENGTH_EMAIL = 50

export const MAX_CREDITS = 30
export const MAX_CREDITS_TOTAL = 200
export const MIN_CREDITS = 5
export const MAX_CREDITS_ONE_TIME = 100

//NTRP ratings
export const MAX_NTRP_RATING = 7
export const MIN_NTRP_RATING = 1

//1 dollar service fee for purchase of credits
export const SERVICE_FEE = 0
export const PRICE_PER_CREDIT = 1


export const MIN_YEAR_OF_BIRTH = 1900

//setting axios timeout to 20 seconds
export const AXIOS_TIMEOUT = 1000 * 20 * 1

//setting axios timeout to 60 seconds for long running transactions
export const AXIOS_LONG_TIMEOUT = 1000 * 60 * 1

//setting axios timeout to 5 minutes for extra-long running transactions
export const AXIOS_EXTRA_LONG_TIMEOUT = 1000 * 60 * 5

//timeout for idle session after which warning will be issued (currently set at 2 hrs)
export const IDLE_TIMEOUT = 1000 * 60 * 60 * 2
//export const IDLE_TIMEOUT = 1000 * 5

//timeout after warning is issued
export const WARN_TIMEOUT = 1000 * 30
//export const WARN_TIMEOUT = 1000 * 5

export const POSTAL_RGEX = /^([A-Z][0-9])*$/
export const NAME_RGEX = /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/
export const ADDRESS_RGEX = /^['#.0-9a-zA-Z\s,-]+$/
export const EMAIL_RGEX = /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/

export const NTRP_RATING_OPTIONS =  [
    <option> Select NTRP rating range </option>,
    <option value="2.5">less than 3.0 - Beginner</option>,
    <option value="3.5">3.0 to 4.0 - Intermeditate</option>,     
    <option value="4.5">4.0 to 5.0 - Advanced</option>,
    <option value="5.5">5.0+ - Open Level</option>
]
