import React, { useEffect, useState } from "react";
import axios from 'axios'

import * as Constants from '../../Constants'
import { FETCH_MEMBER_URL, UPDATE_MEMBER_URL  } from '../../utils/urls'
import { AXIOS_TIMEOUT } from '../../utils/constants'
import { logAxiosError } from '../../utils/functions'

import { validateMemberUpdate } from '../../utils/validations'

import MemberDetail from './MemberDetail'

import {
    MAX_NTRP_RATING,
    MIN_NTRP_RATING
} from '../../utils/constants'

const UpdateMember = (props) => {
    const { user, history, updateChildMessage, match, updateMembers  } = props
    const [authorizedUser, setAuthorizedUser] = useState(false)

    const [member, setMember] = useState([])   

    useEffect(()=> {
        if(!user){
           updateChildMessage("", "Please Login to continue...", "primary", 3000)
            history.push('/login')
        } else if(user.isStaff || user.isAdmin) {
            setAuthorizedUser(true)
        } else {
            setAuthorizedUser(false)
            history.push('/')
        }
    },[user])

    useEffect(()=>{
        if(authorizedUser){
            const getMemberDetails = async() => {
                let res
                let memberId = 0
                let processRequest = true

                if(match){
                    memberId = match.params.memberId
                }

                //console.log('match-params: ', match.params)

                try {
                    res = await axios({
                        method: 'GET',
                        timeout: AXIOS_TIMEOUT,
                        url: FETCH_MEMBER_URL,
                        params: {
                            memberId: memberId
                        }
                    })
                } catch (error) {
                    console.log('Error fetching member information')
                    logAxiosError(error)       
                    processRequest = false
                }
                
                if(processRequest){
                    //console.log('member info:' , res )
                    const {data} = res
                    const member = data.data
                    console.log('member: ', member)
                    setMember(member)  
                }
            }

            getMemberDetails()
        }
    },[authorizedUser])

    const handleChange = (e) => {
        // clear errors, if any
        updateChildMessage('', '', '', 0)

        if (["email", "phoneNumber", "ntrpAssessed"].includes(e.target.className) ) {
            let updatedMember = [...member]
            if(e.target.className === "ntrpAssessed"){
                if(e.target.value >= MIN_NTRP_RATING 
                    && e.target.value <= MAX_NTRP_RATING){
                    //do not allow a value more than 7 for NTRP rating
                    updatedMember[e.target.dataset.id][e.target.className] = e.target.value
                }
            } else {
                updatedMember[e.target.dataset.id][e.target.className] = e.target.value
            }
            
            setMember(updatedMember)
        } else if (["consentEmailContact", "consentPhoneBook", "consentNewsLetter"].includes(e.target.className)) {
            let updatedMember = [...member]
            updatedMember[e.target.dataset.id][e.target.className] = e.target.checked
            setMember(updatedMember)
        } 
    }

    const handleMemberUpdate = (e) => {
        e.preventDefault()

        //validations
        const validateFields = {
            ntrpAssessed: member[0].ntrpAssessed,
            phoneNumber:  member[0].phoneNumber,
            email: member[0].email
        }

        let validations = validateMemberUpdate(validateFields)

        if(validations.validatedResult){
            //post the update
            const postMemberUpdate = async () => {
                let res 
                let processFlag = true
                let messageTitle = ''
                let messageType = ''
                let message = ''

                //console.log('idempotency key', idempotencyKey)

                try{
                    res = await axios({
                        method: 'PUT',
                        timeout: AXIOS_TIMEOUT,
                        url: UPDATE_MEMBER_URL,
                        data: {
                            member: member
                        }  
                        })
                } catch (error){
                    console.log('member update error')
                    messageTitle = 'Error' 
                    message = 'Error submitting Member Update'
                    messageType = Constants.MSG_TYPE_ERROR
                    logAxiosError(error)
                    processFlag = false
                }
                
                if(processFlag){
                    const {data} = res
                    //console.log('update res: ', data)
            
                    messageTitle = 'Success'
                    message = "Member updated successfully"
                    messageType = 'success'

                    let refresh = true
                    updateMembers(refresh)
                } 

                updateChildMessage(messageTitle, message , messageType, 5000)
                window.scrollTo(0, 0)
            }  

            postMemberUpdate()
        } else {
            //display the validation error message
            updateChildMessage('Error', validations.validatedMessage, 'danger', 0)
            window.scrollTo(0, 0)
        }
    }    

    return(
        <> 
            <div className="form-style-10">
                {member[0] &&
                    <h1>{member[0].firstName} {member[0].lastName}<span>Update member details</span></h1>
                }
                
                <form onSubmit={handleMemberUpdate} onChange={handleChange}>
                    <MemberDetail
                        member={member}
                    />

                    <button type="submit"> Update Member Details </button>
                </form>
            </div>
        </>
    )
}

export default UpdateMember