import React from 'react'
import { getNtrpLabel } from '../../utils/functions';

const MembersInfoReadOnly = (props) => {
    const { members } = props
    
    if(!members){
        return null
    } 

    return(
        members.map((member, idx)=> {
            let adult = false
            let label = "Adult"
            let ntrpSelfLabel = ''

            if(member.memberType === "adult"){
                adult = true
                label = "Adult"
            } else {
                label = "Junior"
            }
      
            var memberHeading = 'Member Details'
      
            if(idx === 0){
              memberHeading = `1st Member - ${label}`
            } else if (idx === 1) {
              memberHeading = `2nd Member - ${label}` 
            } else if (idx === 2) {
              memberHeading = `3rd Member - ${label}`
            } else if (idx > 2) {
              memberHeading = `${idx+1}th Member - ${label}`        
            }

            ntrpSelfLabel = getNtrpLabel(members[idx].ntrpSelf)
            
            return (
                <div key={idx}>
                    <div className="inner-wrap">
                        <span>
                            {memberHeading}  
                        </span>
                        
                        <p></p>  
                        <label>First Name</label>
                        <p className="readonly">{members[idx].firstName}</p>
                        
                        <label>Last Name</label>
                        <p className="readonly">{members[idx].lastName}</p>
                        
                        <label>NTRP rating - Self Assessed</label>
                        <p className="readonly">{ntrpSelfLabel}</p>

                        { members[idx].yearOfBirth &&
                            <div>
                                <label>Year of Birth</label>  
                                <p className="readonly">{members[idx].yearOfBirth}</p>
                            </div>
                        }

                        { members[idx].email &&
                            <div>
                                <label>Email</label>  
                                <p className="readonly">{members[idx].email}</p>
                            </div>
                        }

                        { members[idx].phoneNumber && members[idx].phoneNumber > 0 &&
                            <div>
                                <label>Phone Number</label>  
                                <p className="readonly">{members[idx].phoneNumber}</p>
                            </div>
                        }
                    </div>
                </div>
            )
        })
    )
}

export default MembersInfoReadOnly