import React, {useState, useEffect} from 'react'
import axios from 'axios'
import CreditsAndBilling from './CreditsAndBilling'

import { validateBillingInputs } from '../../utils/validations'

import { generateIdempotencyKey, logAxiosError } from '../../utils/functions'
import SquarePayment from '../../components/SquarePayment'

import * as Constants from './../../Constants'

import {MAX_CREDITS_TOTAL, 
        MAX_CREDITS_ONE_TIME,
        MIN_CREDITS, 
        SERVICE_FEE, 
        PRICE_PER_CREDIT, 
        AXIOS_TIMEOUT, 
        AXIOS_LONG_TIMEOUT } from './../../utils/constants'

import { MEMBER_MEMBERSHIP_URL, CREDITS_PAYMENT_URL } from './../../utils/urls'

const DEPLOYED_ENV = process.env.REACT_APP_DEPLOYED_ENV;

const UPDATE_INFO_MESSAGE = 'Click here to update Credits and Billing information if needed '

const CreditsForm = ({ user, configValues, history, updateChildMessage, updateUser, updateTransactions }) => {
    //override default constants from config table values
    //console.log('configValues', configValues)

    const [formSubmitted, setFormSubmitted] = useState(false)
    const [billingInfoCompleted, setBillingInfoCompleted] = useState(false)
    const [idempotencyKey, setIdempotencyKey] = useState()  
    const [confirmEntries, setConfirmEntries] = useState(false)
    const [creditsFormSubmitted, setCreditsFormSubmitted] = useState(false)
    const [authorizedUser, setAuthorizedUser] = useState(false)

    const [billingFields, setBillingFields] = useState({})
    const [paymentFields, setPaymentFields] = useState({})
    const [isProduction, setIsProduction] = useState()

    const [dataLoaded, setDataLoaded] = useState(false)

    const [maxCredits, setMaxCredits] = useState()
    const [serviceFee, setServiceFee] = useState()
    const [totalServiceFee, setTotalServiceFee] = useState(0)
    const [pricePerCredit, setPricePerCredit] = useState()

    const [applyHST, setApplyHST] = useState(false)
    const [maxCreditsAllowed, setMaxCreditsAllowed] = useState()
    const [creditBlocks, setCreditBlocks] = useState()
    
    let idempotency_key_local

    useEffect(()=> {
        if(DEPLOYED_ENV === 'production'){
            setIsProduction(true)
        } else {
            setIsProduction(false)
        }

        if(!user){
            updateChildMessage("", "Please Login to continue...", "primary", 3000)
            history.push('/login')
        } else if(user.isMember || user.isExec) {
            setAuthorizedUser(true)
        } else {
            setAuthorizedUser(false)
            history.push('/')
        }

        if(configValues.addHST && configValues.addHstToCredits){
            //only apply HST if overall HST is being applied and HST for credits is specifically enabled
            setApplyHST(true)
        }

        setCreditBlocks(configValues.creditBlocks)

        if(configValues.maxCreditsAllowed && configValues.maxCreditsAllowed > 0){
            setMaxCredits(configValues.maxCreditsAllowed)
        } else {setMaxCredits(MAX_CREDITS_TOTAL)}
    
        if(configValues.serviceFeeCredits && configValues.serviceFeeCredits >= 0){
            setServiceFee(configValues.serviceFeeCredits)

            //set total fee to the initial service fee to start
            setTotalServiceFee(configValues.serviceFeeCredits)
            
            let initialMessage = `Please note that a service fee of $${configValues.serviceFeeCredits} will be charged
            for every block of ${configValues.creditBlocks} credits purchased`
            
            updateChildMessage('Information', initialMessage, 'primary', 0)
        } else{setServiceFee(SERVICE_FEE) }
    
        if(configValues.pricePerCredit && configValues.pricePerCredit > 0){
            setPricePerCredit(configValues.pricePerCredit)
        } else{setPricePerCredit(PRICE_PER_CREDIT) }    

        //setup billing fields.
        let initBillingFields = {
            firstName: '',
            lastName: '',
            address: '',
            city: '',
            postal: '',
            phone: '',
            email: '',
            credits: 0,
            creditsSubTotal: 0,
            totalAmount: 0,
            hst: 0
        }
        setBillingFields(initBillingFields)
    },[])

    //set the initial dataLoaded field
    useEffect(() => {
        if(!dataLoaded){
            if(billingFields.firstName > ' '){
                //once the name is set, we can show the input form
                setDataLoaded(true)
            }
        }
    },[billingFields])

    //generate an idempotency key once when the form is loaded
    useEffect (() => {
        if(user && authorizedUser){
            idempotency_key_local = generateIdempotencyKey()
            setIdempotencyKey(idempotency_key_local)
            setCreditsFormSubmitted(false)
        }
    },[user, authorizedUser])

    //Redirect to Home Page once the form is submitted
    useEffect(() => {
        if(formSubmitted){
            history.push('/')
        }
    })

    useEffect(()=>{
        //first get membership info
        if(user && authorizedUser){
            const getMemberInfo = async () => {
                let res

                try {
                    res = await axios({
                        method: 'GET',
                        timeout: AXIOS_TIMEOUT,
                        url: MEMBER_MEMBERSHIP_URL,
                        params: {
                            memberId: user.memberId
                        }
                    })    
                } catch (error) {
                    console.log('Error fetching membership information')        
                    logAxiosError(error)
                }

                const { data } = res.data

                //update user credits if needed
                
                if(user.credits !== data[0].credits){
                    if(!data[0].credits){
                        //set users credits to 0 if no credits are returned
                        user.credits = 0
                        data[0].credits = 0
                    } else {
                        user.credits = data[0].credits
                    }

                    let copiedUser = JSON.parse(JSON.stringify(user));
                    copiedUser.credits = data[0].credits
                    updateUser(copiedUser)
                    //console.log('copied-user1', copiedUser)
                }

                //set maximum purchasable credits for the user based on current #credits
                let tempCredits = maxCredits - user.credits
                //console.log('tempCredits: ', tempCredits)

                if(tempCredits >= MIN_CREDITS){
                    //user can purchase 5 or more credits
                    if(tempCredits >= MAX_CREDITS_ONE_TIME){
                        //user can purchase the maximum # of credits if needed
                        setMaxCreditsAllowed(MAX_CREDITS_ONE_TIME)    
                    } else {
                        //user can at the most purchase the difference in credits
                        setMaxCreditsAllowed(tempCredits)
                    }
                } else {
                    //user cannot purchase any more credits
                    setMaxCreditsAllowed(0)
                }

                //calculate the price for the minimum credits
                let creditsSubTotal = MIN_CREDITS * pricePerCredit
                let hst = 0
                let totalAmount = 0

                if(applyHST){
                    hst = creditsSubTotal * configValues.percentHST/100
                    totalAmount = creditsSubTotal + hst + serviceFee
                } else {
                    totalAmount = creditsSubTotal + serviceFee
                }

                //setup billing fields. Set the initial credits to 5
                let updatedBillingFields = {
                    firstName: user.firstName,
                    lastName: user.lastName,
                    email: user.email,
                    address: data[0].address,
                    city: data[0].city,
                    postal: data[0].postalCode,
                    phone: data[0].phoneNumber.toString(),
                    credits: 5,
                    creditsSubTotal: creditsSubTotal,
                    totalAmount: totalAmount,
                    hst: hst
                }
            
                setBillingFields(updatedBillingFields)   
            } 
            getMemberInfo()
        }
    },[user, authorizedUser])

    const updateBilling = () => {
        //update membership info
        setFormSubmitted(false)
        setBillingInfoCompleted(false)
    }
    
    const trimBillingInputFields = () => {
        let updatedBillingFields = {...billingFields}
        updatedBillingFields.postal = updatedBillingFields.postal.trim()
        //console.log('updatedBillingFields.phone', updatedBillingFields.phone)

        if(typeof(updatedBillingFields.phone) === "number"){
            //phone is already in Number format
        } else {
            updatedBillingFields.phone = updatedBillingFields.phone.trim()
        }
        
        setBillingFields(updatedBillingFields)
    }

    const handleBillingSubmit = (e) => {
        e.preventDefault()

        //validate Number of Credits

        let validateCredits = false
        let validationMessage = ''

        if(billingFields.credits > 0){
            if(billingFields.credits > maxCreditsAllowed){
                //user is attempting to purchase too many credits
                validationMessage = `Attempting to purchase too many credits. You can hold a maximum of ${maxCredits} credits only`
            } else if(billingFields.credits < MIN_CREDITS){
                //all good - proceed to validate billing fields
                validationMessage = `You need to purchase a minimum of ${MIN_CREDITS} Credits to proceed`
            } else {
                validateCredits = true
            }
        } else {
            validationMessage = `You need to purchase a minimum of ${MIN_CREDITS} Credits to proceed`
        }

        if(!validateCredits){
            updateChildMessage('Error', validationMessage, 'danger', 0)
            window.scrollTo(0, 0)
        } else {
            //credits are valid

            //trim input fields
            trimBillingInputFields()

            //first validate all input fields
            let validateBillingFields = {
                firstName: billingFields.firstName, 
                lastName: billingFields.lastName, 
                address: billingFields.address, 
                city: billingFields.city, 
                postal: billingFields.postal, 
                phone: billingFields.phone, 
                email: billingFields.email, 
                confirmEntries: confirmEntries
            }

            let validations = validateBillingInputs(validateBillingFields)
            
            if(validations.validatedResult){
                setBillingInfoCompleted(true)
                //setup payment fields

                let updatedPaymentFields = {
                    irstName: billingFields.firstName, 
                    lastName: billingFields.lastName, 
                    address: billingFields.address, 
                    city: billingFields.city, 
                    postal: billingFields.postal, 
                    phone: billingFields.phone, 
                    email: billingFields.email, 
                    totalAmount: billingFields.totalAmount, 
                    lineItemLabel: 'Credits Purchase', 
                    updateInfoMessage: UPDATE_INFO_MESSAGE
                }

                setPaymentFields(updatedPaymentFields)
            } else{
                updateChildMessage('Error', validations.validatedMessage, 'danger', 0)
                window.scrollTo(0, 0)
            }
        }
    }

    //handle billing and Credits change
    const handleBillingChange = (e) => {
        // clear errors, if any
        updateChildMessage('', '', '', 0)
        
        let updatedBillingFields = {...billingFields}

        if(e.target.name === "confirmEntries"){
            setConfirmEntries(e.target.checked)
        }else{
            if(e.target.name==="postal"){
                updatedBillingFields[e.target.name] = e.target.value.toUpperCase()
            } else if(e.target.name==="credits"){
                if(e.target.value >= 0
                    && e.target.value <= maxCreditsAllowed){
                    //only update the number of credits if it is less than the maximum allowed
                    updatedBillingFields[e.target.name] = e.target.value
                }
            } else{
                updatedBillingFields[e.target.name] = e.target.value
            }
            //console.log('updatedBillingFields', updatedBillingFields)
            
            updatedBillingFields.credits = parseInt(updatedBillingFields.credits)
            updatedBillingFields.creditsSubTotal = updatedBillingFields.credits * pricePerCredit

            //calculation of service fee
            let calcServiceFee = 0

            if(serviceFee > 0){
                calcServiceFee = (Math.floor((updatedBillingFields.credits - 1)/ creditBlocks) + 1) * serviceFee
            }

            if(applyHST){
                let calculatedHST = updatedBillingFields.creditsSubTotal * configValues.percentHST/100
                updatedBillingFields.totalAmount = updatedBillingFields.creditsSubTotal + calculatedHST + calcServiceFee
                updatedBillingFields.hst = calculatedHST 
            } else {
                updatedBillingFields.totalAmount = (updatedBillingFields.creditsSubTotal + calcServiceFee)
                updatedBillingFields.hst = 0
            }

            setTotalServiceFee(calcServiceFee)

            //(updatedBillingFields[credits]) + pricePerCredit
            setBillingFields(updatedBillingFields)
        }    
    }

    const submitCreditsPayment = (cnon, verficationToken) => {
        // submit the registration form and Payment and transfer to home page
        // submit form logic to be added here
        
        let messageTitle = 'Default'
        let message = 'Default'
        let messageType = Constants.MSG_TYPE_SUCCESS  

        if(!creditsFormSubmitted){
            //do not allow the user to submit the form multiple times
            setCreditsFormSubmitted(true)

            const postCreditsPayment = async () => {
                let processFlag = true
                let res
                let allowResubmit = false
                
                try{
                    res = await axios({
                        method: 'POST',
                        timeout: AXIOS_LONG_TIMEOUT,
                        url: CREDITS_PAYMENT_URL,
                        data: {
                            user: user,
                            cnon: cnon,
                            verficationToken: verficationToken,
                            idempotencyKey: idempotencyKey,
                            billing: billingFields,
                            memberId: user.memberId,
                            membershipId: user.membershipId
                        }  
                    })
                    console.log('axios-res-data', res.data)    
                } catch (error){
                    messageTitle = 'System Error' 
                    message = 'System Error submitting payment - please try again later'
                    messageType = Constants.MSG_TYPE_ERROR
                    logAxiosError(error)
                    processFlag = false
                    updateChildMessage(messageTitle, message, messageType, 5000)
                }

                if(processFlag){
                    //console.log('reg user:', user)
                    const {data} = res.data

                    /*
                    if(data){
                        console.log('postCreditsPayment-data', data)
                        console.log('credits:', data.credits)
                        console.log('cms status:', data.cmsStatus)
                        console.log('status:', data.status)
                    }
                    */
                
                    if (res.data.status === Constants.RETURN_CODE_OK) {
                        //call to Strapi was good. Check the Strapi return status
                        if(res.data.cmsStatus.success){
                            let copiedUser = JSON.parse(JSON.stringify(user));
                            copiedUser.credits = data.credits

                            updateUser(copiedUser)

                            //Auto update the transactions that are on the transaction list page
                            updateTransactions(true)
                            
                            //console.log('user', copiedUser)
                            
                            //updateUser(user)
                            
                            messageTitle = 'Payment Successful' 
                            message = `${billingFields.credits} Credits have been added to your account`
                            messageType = Constants.MSG_TYPE_SUCCESS    
                        } else if (res.data.cmsStatus.paymentFailed ){
                            messageTitle = 'Error - Payment Failed' 
                            message = 'Credit Card processing error. Please check your card and try again'
                            messageType = Constants.MSG_TYPE_ERROR
                            allowResubmit = true
                            processFlag = false
                        } else {
                            //generic failure
                            messageTitle = 'Error - Purchase Tokens Failed' 
                            message = 'Please try again later or contact the club to report an error'
                            messageType = Constants.MSG_TYPE_ERROR
                            allowResubmit = true
                            processFlag = false
                        }
                    } else if (res.data.status === Constants.RETURN_CODE_NOT_AUTH) {
                        messageTitle = 'Error' 
                        message = 'User not authorized'
                        messageType = Constants.MSG_TYPE_ERROR
                        processFlag = false
                    } else {
                        messageTitle = 'Error' 
                        message = 'Error submitting form. Please try again'
                        messageType = Constants.MSG_TYPE_ERROR
                        allowResubmit = true
                        processFlag = false
                    }
                          
                    updateChildMessage(messageTitle, message, messageType, 5000)
          
                    //send user back to homepage after form submission
                    if(processFlag){
                        //request was successfull
                        setFormSubmitted(true)
                    } else {
                        //request failed
                        setBillingInfoCompleted(false)
                        
                        if(allowResubmit){
                            //generate a new idempotency key and allow user to submit payment
                            idempotency_key_local = generateIdempotencyKey()
                            setIdempotencyKey(idempotency_key_local)
                            setCreditsFormSubmitted(false)
                            setConfirmEntries(false)
                        }
                    } 
                }  
            }    
            postCreditsPayment()
        }  
    }

    return(
        <div className="CreditsForm">
            <>        
                {
                    !billingInfoCompleted  && dataLoaded &&
                    <div className="form-style-10">
                        <h1>Credit & Billing<span>Purchase Credits and Enter Billing details</span></h1>
                        <form onSubmit={handleBillingSubmit} onChange={handleBillingChange}>
                            <CreditsAndBilling
                                billingFields={billingFields}
                                confirmEntries={confirmEntries}
                                serviceFee={totalServiceFee}
                                availableCredits={user.credits}
                                maxCreditsAllowed={maxCreditsAllowed}
                            />
                        </form>
                    </div>
                }    

                {
                    billingInfoCompleted &&
                    <SquarePayment
                        paymentFields={paymentFields}
                        isProduction={isProduction}
                        submitPayment={ submitCreditsPayment }
                        updateBilling= { updateBilling }
                    />
                }
            </>           
        </div>
    )
}

export default CreditsForm
