import React from 'react'

const BillingDetails = (props) => {
    const { 
        billingFields,
        confirmEntries
    } = props

    let {
        firstName,
        lastName,
        address,
        city,
        postal,
        phone,
        email,
        membershipLabel,
        membershipFee,
        hst,
        totalAmount
    } = billingFields

    const onChangeHandler = (e) =>{
        return null
    }

    const handleUpdateMembership = (e) => {
        e.preventDefault()
        props.updateMembership()
    }

    return(
        <>
            <div className="section"><span>1</span>Billing Name & Address</div>
            <div className="inner-wrap">
            <label htmlFor="firstName">Billing First Name <span className="required">*</span> </label>
                <input 
                    type="text" 
                    name="firstName" 
                    value={firstName}
                    onChange={onChangeHandler}
                />

                <label htmlFor="lastName">Billing Last Name<span className="required">*</span> </label>
                <input 
                    type="text" 
                    name="lastName" 
                    value={lastName}
                    onChange={onChangeHandler}
                />

                <label htmlFor="address">Billing Address<span className="required">*</span> </label>
                <textarea 
                    name="address" 
                    value={address}
                    onChange={onChangeHandler}
                />

                <label htmlFor="city">City<span className="required">*</span> </label>
                <input 
                    type="text" 
                    name="city" 
                    value={city}
                    onChange={onChangeHandler}
                />

                <label htmlFor="postal">Postal Code<span className="required">*</span> </label>
                <input 
                    type="text" 
                    name="postal"
                    maxLength="7" 
                    value={postal} 
                    onChange={onChangeHandler}
                />
            </div>

            <div className="section"><span>2</span>Email & Phone</div>
            <div className="inner-wrap">
                <label htmlFor="phone">Email<span className="required">*</span> </label>
                <input 
                    type="email" 
                    name="email" 
                    value={email}
                    onChange={onChangeHandler}
                />

                <label htmlFor="phone">Phone<span className="required">*</span> </label>
                <input 
                    type="text" 
                    name="phone" 
                    maxLength="10"
                    value={phone}
                    onChange={onChangeHandler}
                />
            </div>

            <div className="section"><span>S</span>Summary</div>
            <div className="inner-summarywrap">
                <p>Membership Type: {membershipLabel} </p>
                
                <table>
                    <tbody>
                        <tr>
                            <td>Membership Fee:</td>
                            <td>${membershipFee.toFixed(2)}</td>
                        </tr>
                        <tr>
                            <td>HST:</td>
                            <td>${hst.toFixed(2)}</td>
                        </tr>                                        
                        <tr>
                            <td>Total Amount:</td>
                            <td>${totalAmount.toFixed(2)}</td>
                        </tr>                                        
                    </tbody>
                </table>
                <p></p>
                <p className="update" onClick={handleUpdateMembership}>
                    Update Membership Information 
                </p>
            </div>

            <div className="button-section">
                <span className="privacy-policy">
                    <label className="checkbox" htmlFor="confirmEntries">
                        I have verified membership and billing information
                        <input 
                            type="checkbox" 
                            name="confirmEntries"
                            checked={confirmEntries}
                            onChange={onChangeHandler}
                        /> 
                    </label>
                </span>
                <button type="submit"> Enter Credit Card Details</button>
            </div>
        </>  
    )
}

export default BillingDetails