import React, { useEffect, useState } from 'react'
import axios from 'axios'

import { PMTSUMMARY_URL, PAYMENT_URL } from '../../utils/urls'
import { logAxiosError } from '../../utils/functions';
import { AXIOS_TIMEOUT } from '../../utils/constants'

//import testPmtSummary from '../../utils/pmtSummary.json'

import ExportXLS from '../../components/ExportXLS'    

import './PmtSummary.css'

const PmtSummary = ({ history, user, configValues, updateChildMessage }) => {
    //const [pmtSummary, setPmtSummary] = useState({})
    
    const [pmtSummary, setPmtSummary] = useState({
        totalMemberships: 0,
        totalAmount:0,
        squarePmtAmt:0,
        squarePmtQty:0,
        calculatedFees:0,
        calculatedNet:0,
        totalSingle:0,
        totalSingleAmt:0,
        totalCouple:0,
        totalCoupleAmt:0,
        totalDouble:0,
        totalDoubleAmt:0,
        totalJunior:0,
        totalJuniorAmt:0,
        totalFamily:0,
        totalFamilyAmt:0,
        totalCredits:0,
        totalCreditsAmt:0
    })
    const [paymentDetails, setPaymentDetails] = useState(false)
    const [xlsData, setXlsData] = useState()
    const [authorizedUser, setAuthorizedUser] = useState(false)
    const [requestedSeason, setRequestedSeason] = useState()
    const [currentYear, setCurrentYear] = useState()

    useEffect(()=> {
        if(!user){
            updateChildMessage("", "Please Login to continue...", "primary", 3000)
            history.push('/login')
        } else if(user.isExec || user.isAdmin) {
            setAuthorizedUser(true)
            //setup the default requested season
            setCurrentYear(configValues.currentSeason)
            setRequestedSeason(configValues.currentSeason)
        } else {
            setAuthorizedUser(false)
            history.push('/')
        }
    },[])

    useEffect(()=>{
        setPaymentDetails(false)

        if(authorizedUser){
            const getPmtSummary = async () => {
                let res 

                try{
                    res = await axios({
                        method: 'GET',
                        timeout: AXIOS_TIMEOUT,
                        url: PMTSUMMARY_URL,
                        params: {
                            requestedSeason: requestedSeason
                        }
                    })
                } catch(error) {
                    console.log('Error fetching payment summary')
                    logAxiosError(error)
                }
                
                const {data} = res
                
                //console.log('data:', data)
                setPmtSummary(data.data)
            }  
            getPmtSummary()
        }
        
       //test
       // setPmtSummary(testPmtSummary)
    },[authorizedUser, requestedSeason])

    const onChangeHandler = (e) => {
        setRequestedSeason(e.target.value)
    }

    const formatDate = (unixTimestamp) => {
        // Create a new JavaScript Date object based on the timestamp
         
        let date = new Date(unixTimestamp);
        
        let month = date.getMonth() + 1
        let day = date.getDate()
        let year = date.getFullYear()
        
        let formattedDate = month + '/' + day + '/' + year
        
        return(formattedDate)
    }

    const formatDownload = (data) => {
        //format the event data for download
        //console.log('data-format', data)
        
        let outputData = [{}]
        
        if(data && data.length > 0 ){
            for(let idx=0; idx < data.length; idx++){
                let entry = {}

                entry.FirstName = data[idx].firstName
                entry.LastName = data[idx].lastName
                entry.Amount = data[idx].amount

                if(data[idx].hstAmount){
                    entry.HST = data[idx].hstAmount
                } else {
                    entry.HST = 0    
                }
                
                if(data[idx].chequeNumber){
                    entry.ChequeNumber = data[idx].chequeNumber
                } else {
                    entry.ChequeNumber = ''
                }

                if(data[idx].item === "single"){
                    entry.Item = "Single Adult Membership"
                }

                if(data[idx].item === "double"){
                    entry.Item = "Single Adult & Junior Membership"
                }

                if(data[idx].item === "couple"){
                    entry.Item = "Couple Membership"
                }

                if(data[idx].item === "junior"){
                    entry.Item = "Junior Membership"
                }

                if(data[idx].item === "family"){
                    entry.Item = "Family Membership"
                }

                if(data[idx].item === "credits"){
                    entry.Item = "Credits"
                }

                entry.Quantity = data[idx].itemQuantity
                entry.PaymentType = data[idx].paymentType
                entry.ReceiptNumber = data[idx].receiptNumber

                entry.Season= data[idx].season

                if(data[idx].squarePayment){
                    entry.Gateway = "Square"
                } else {
                    entry.Gateway = "N/A"
                }

                entry.TransactionType = data[idx].transactionType

                entry.SubmittedBy= data[idx].username

                //entry.CreatedAt = data[idx].created_at.substring(0, 10)
                entry.CreatedAt = formatDate(data[idx].created_at) 
                
                if(idx === 0){
                    outputData[0] = entry
                } else {
                    //for idx > 0, use the push function
                    outputData.push(entry)
                }
            }
        }

        setXlsData(outputData)
    }

    const handlePaymentDetails = (e) => {
        e.preventDefault()

        const getPaymentDetails = async () => {
            let res

            try{
                res = await axios({
                    method: 'GET',
                    timeout: AXIOS_TIMEOUT,
                    url: PAYMENT_URL,
                    params: {
                        requestedSeason: requestedSeason
                    }
                })
            } catch(error) {
                console.log('Error fetching payment details')
                logAxiosError(error)
            }

            const {data} = res.data
            
            //console.log('data:', data)
            
            setPaymentDetails(true)

            //console.log('status', data.status)
            //format data
            if(data.status.success){
                formatDownload(data.data)
            } else {
                //error getting payment details
                updateChildMessage("Error", "Error fetching payment details", "danger", 5000)
            }
        }  
        getPaymentDetails()       
    }

    const handleFileDownloaded = () => {

    }


    return(
        <div className="PmtSummary">
            <h3> Payment Summary </h3>
  
            <div className="Header2">
                {xlsData && paymentDetails && 
                    <>
                        <ExportXLS
                            csvData={xlsData}
                            fileName={`paymentDetails.csv`}
                            fileDownloaded={handleFileDownloaded}
                        />
                    </>
                }
            </div>

            <div className="Filters">
                <form className="Filterbox" >
                    <label>Reporting Season: </label>
                    <select 
                        name="season"  
                        size="1"
                        value={requestedSeason} 
                        onChange={onChangeHandler}
                    >
                        <option value={currentYear}>{currentYear} </option>
                        <option value={currentYear-1}>{currentYear-1} </option>
                        <option value={currentYear-2}>{currentYear-2} </option>
                    </select> 
                </form>    
            </div>

            <div className="Memberships">
                <div className="table-scroll">
                    <table>
                        <thead>
                            <tr>
                                <th>
                                    <span className="text" >Membership Type</span>
                                </th>
                                <th>
                                    <span className="text">Quantity</span>
                                </th>
                                <th>
                                    <span className="text">Amount</span>
                                </th>
                            </tr>
                        </thead>
                        
                        <tbody>
                            <tr>
                                <td>Single Membership</td>    
                                <td>{pmtSummary.totalSingle}</td>
                                <td>{pmtSummary.totalSingleAmt}</td>
                            </tr>
                            <tr>
                                <td>Couple Membership</td>    
                                <td>{pmtSummary.totalCouple}</td>
                                <td>{pmtSummary.totalCoupleAmt}</td>
                            </tr>
                            <tr>
                                <td>Single Adult & Junior</td>    
                                <td>{pmtSummary.totalDouble}</td>
                                <td>{pmtSummary.totalDoubleAmt}</td>
                            </tr>
                            <tr>
                                <td>Junior Membership</td>    
                                <td>{pmtSummary.totalJunior}</td>
                                <td>{pmtSummary.totalJuniorAmt}</td>
                            </tr>
                            <tr>
                                <td>Family Membership</td>    
                                <td>{pmtSummary.totalFamily}</td>
                                <td>{pmtSummary.totalFamilyAmt}</td>
                            </tr>
                        </tbody>               
                    </table>                    
                </div>    
            </div>


            <div className="SummaryHeader">
                <table>
                    <thead>
                        <tr>
                            <th>
                                <span className="text" >Item Summary</span>
                            </th>
                            <th>
                                <span className="text">Totals</span>
                            </th>
                        </tr>
                    </thead>
                </table>                    
            </div>    

            <div className="Summary">
                <div className="table-scroll">
                    <table>
                        <tbody>
                            <tr>
                                <td>Total Memberships</td>    
                                <td>{pmtSummary.totalMemberships}</td>
                            </tr>
                            <tr>
                                <td>Total Memberships Amount</td>    
                                <td>{pmtSummary.totalMembershipsAmt}</td>
                            </tr>
                            <tr>
                                <td>Total Credits</td>    
                                <td>{pmtSummary.totalCredits}</td>
                            </tr>
                            <tr>
                                <td>Total Credits Amount</td>    
                                <td>{pmtSummary.totalCreditsAmt}</td>
                            </tr>
                            <tr>
                                <td>Total Amount</td>    
                                <td>{pmtSummary.totalAmount}</td>
                            </tr>
                            <tr>
                                <td>Square Payments</td>    
                                <td>{pmtSummary.squarePmtAmt}</td>
                            </tr>
                            <tr>
                                <td>Square Transactions</td>    
                                <td>{pmtSummary.squarePmtQty}</td>
                            </tr>
                            <tr>
                                <td>Calculated Fees</td>    
                                <td>{pmtSummary.calculatedFees}</td>
                            </tr>
                            <tr>
                                <td>Net Amount</td>    
                                <td>{pmtSummary.calculatedNet}</td>
                            </tr>
                            <tr>
                                <td>Total HST</td>    
                                <td>{pmtSummary.totalHst}</td>
                            </tr>
                        </tbody>               
                    </table>                    
                </div>

                {!paymentDetails &&
                    <div>
                        <button onClick={handlePaymentDetails}> Generate Payment Details </button>
                    </div>
                }
                
                {paymentDetails &&
                    <>
                        Click on the Excel Icon above to download the payment details
                    </>
                }
            </div>    
        </div>
    )
}

export default PmtSummary