import axios from 'axios'

import { FEES_URL } from './urls'
import { logAxiosError } from './functions';
import { AXIOS_TIMEOUT } from './constants';

let cacheFees = null

export function requestFees(refresh) {
    if(refresh){
        cacheFees = null
    }
    if(!cacheFees){
        //fetch the membership fees and return the list 
        
        const getFees = async () => {
            let res

            try{
                res = await axios({
                    method: 'GET',
                    timeout: AXIOS_TIMEOUT,
                    url: FEES_URL,
                })
            } catch(error){
                console.log('Error fetching membership fees')
                logAxiosError(error)                 
            }
            
            
            const {data} = res
            //console.log('data: ', data.data)

            //console.log('get fees: ', data)
            let cacheFees = data
            return cacheFees
        }  
        
        cacheFees = getFees()
        return cacheFees
    }
    return cacheFees
}
