import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';

import './Rulebook.css'

import {requestRulebook} from '../utils/fetchRulebook'

const Rulebook = ({ mode, handleAcceptOnClick }) => {

  const[rulebookData, setRulebookData] = useState({})
  const [isTerms, setIsTerms] = useState(false)
  
  useEffect(() => {     
    requestRulebook().then(rulebookData => {
      
      let data = {}
      data.title = rulebookData.title
      //data.lessonsText = rulebookData.lessonsText
      data.upperLeftBox = rulebookData.upperLeftBox
      data.upperRightBox = rulebookData.upperRightBox
      data.bannerTitle = rulebookData.bannerTitle
      data.bannerText = rulebookData.bannerText
      data.lowerLeftBox = rulebookData.lowerLeftBox
      data.lowerRightBox = rulebookData.lowerRightBox 
      data.bottomBannerText = rulebookData.bottomBannerText
      data.bottomBannerLink = rulebookData.bottomBannerLink
      
      if(mode === "acceptTerms"){
        setIsTerms(true)
        data.bottomBannerText = "Click here to Accept Terms and return to the Registration Form"
      } else {
        setIsTerms(false)
      }

      setRulebookData(data)
      window.scrollTo(0, 0)
    })
  }, [])   
  
  return (
    <div className="Rulebook">
      
      <div className="RulebookTitle"> {rulebookData.title} </div>  
      <div className="RulebookTitleText">  </div>

      <div className="Textcontainer"> 
        <div className="Para"> 
          <ReactMarkdown>{rulebookData.upperLeftBox}</ReactMarkdown>
        </div>
        <div className="Para"> 
          <ReactMarkdown>{rulebookData.upperRightBox}</ReactMarkdown>
        </div>
      </div>
      
      <div className="Bulletin">
        <div className="B-title"> {rulebookData.bannerTitle} </div>
        <div className="B-content"> {rulebookData.bannerText} </div> 
        {isTerms && 
          <div className="B-content2" onClick={handleAcceptOnClick}> 
            {rulebookData.bottomBannerText} 
          </div>   
        }
      </div>
      
      <div className="Textcontainer"> 
        <div className="Para"> 
          <ReactMarkdown>{rulebookData.lowerLeftBox}</ReactMarkdown>
        </div>
        <div className="Para"> 
          <ReactMarkdown>{rulebookData.lowerRightBox}</ReactMarkdown>
        </div>
      </div>

      {!isTerms &&
        <div className="Bulletin">
          <a href={rulebookData.bottomBannerLink} target="_blank">
            {rulebookData.bottomBannerText}
          </a>
        </div>
      }
      
      {isTerms && 
        <div className="Bulletin" onClick={handleAcceptOnClick}>
          <div className="B-content2"> {rulebookData.bottomBannerText} </div>  
        </div>
      }
    </div>
  );
}

export default Rulebook
