import axios from 'axios'

import { MEMBERS_URL } from './urls'
import { logAxiosError } from './functions';
import { AXIOS_TIMEOUT } from './constants'

//import testList from './phonebook.json'

let cacheMembers = null

export function requestMembersList(refresh) {
    if(refresh){
        cacheMembers = null
    }
    if(!cacheMembers){
        //fetch the list of members
        
        const getMemberList = async () => {
            let res

            try{
                res = await axios({
                    method: 'GET',
                    timeout: AXIOS_TIMEOUT,
                    url: MEMBERS_URL,
                })
            } catch(error){
                console.log('Error fetching member list')
                logAxiosError(error)     
            }
        
            const {data} = res
            //console.log('data: ', data.data)

            //console.log('phone-data: ', data)
            let membersList = data.data
            return membersList
        }  
        
        cacheMembers = getMemberList()
        return cacheMembers
    }
    return cacheMembers
}
