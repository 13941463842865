import React, {useState, useEffect} from 'react'
import axios from 'axios'

import ContactDetails from './ContactDetails'
import MembersInfo from './MembersInfo'
import ContactDetailsReadOnly from './ContactDetailsReadOnly'
import MembersInfoReadOnly from './MembersInfoReadOnly'

import { validateMembershipInputs } from '../../utils/validations'
import { capitalize, logAxiosError } from '../../utils/functions'

import './RegistrationForm.css'

import * as Constants from '../../Constants'
import { WAITLIST_URL, USER_PRIOR_REGISTER_URL } from '../../utils/urls'
import { AXIOS_TIMEOUT } from '../../utils/constants'

import {
    MAX_NTRP_RATING,
    MIN_NTRP_RATING
} from '../../utils/constants'

function WaitlistForm (props) {
    const { history, user, configValues, updateChildMessage } = props
    
    //define all State Variables to be used. Initialize values wherever needed
    
    const [authorizedUser, setAuthorizedUser] = useState(false)
    const [addedToWaitlist, setAddedToWaitlist] = useState(false)
    const [priorWaitlistFound, setPriorWaitlistFound] = useState(false)
    
    const [firstTimeMemberInput, setFirstTimeMemberInput] = useState(true)
    const [adults, setAdults] = useState(0)
    const [juniors, setJuniors] = useState(0)
    const [maxAdults, setMaxAdults] = useState(0)
    const [maxJuniors, setMaxJuniors] = useState(0)
    
    const [contactFields, setContactFields] = useState(
        {
        address: '',
        city: '',
        postal: '',
        primaryPhone: '',
        emergencyContact: '',
        emergencyPhone: ''
        }
    )

    const [members, setMembers] = useState([])          

    const [waitlistFormSubmitted, setWaitlistFormSubmitted] = useState(false)

    let {
        prioritizeRegistration,
        allowRegister,
        juniorOnly,
        showWaitlistForm
    } = configValues

    useEffect(()=> {
        if(!user){
           updateChildMessage("", "Please Login to continue...", "primary", 3000)
            history.push('/login')
        } else if(user.isAuth) {
            let waitlist = false

            if(allowRegister){
                if(configValues.maxJuniors > 0){
                    setMaxJuniors(configValues.maxJuniors) 
                }

                if(configValues.maxAdults > 0){
                    setMaxAdults(configValues.maxAdults)
                }

                if(prioritizeRegistration){
                    //do not show waitlist form for users who can register
                    if(user.registrationAllowed){
                        waitlist = false
                    } else {
                        //allow waitlist if user is not prioritized
                        waitlist = true
                    }
                } 
                 else {
                    if(juniorOnly){
                        waitlist = true
                    }
                }
            } else {
                if(showWaitlistForm){
                    waitlist = true
                } else {
                    //club is closed for any application
                    waitlist = false    
                }
            }

            if(waitlist){
                //user is allowed to access the waitlist info
                setAuthorizedUser(true)
            } else {
                setAuthorizedUser(false)
                history.push('/')    
            }
        } else {
            setAuthorizedUser(false)
            history.push('/')
        }
    },[user])
     
    useEffect(()=>{
        //send the user to the homepage after submitting the waitlist form
        if(addedToWaitlist){
            history.push('/')
        }
    },[addedToWaitlist]
    )

    useEffect(() => {
        //check if the user has already submitted a waitlist application
        //Pre-populate the form fields with the prior application

        if(firstTimeMemberInput){
            const getPriorRegistration = async() => {
                let res 
                let processRequest = true

                try {
                    res = await axios({
                        method: 'GET',
                        timeout: AXIOS_TIMEOUT,
                        url: USER_PRIOR_REGISTER_URL,
                    })
                } catch (error) {
                    console.log('Error fetching registration information')
                    logAxiosError(error)       
                    processRequest = false
                }
                
                if(processRequest){
                    const {data} = res
                    const { members, membership } = data.data

                    //populate the fields

                    if(membership.id > 0 && membership.membershipStatus === 'waitlist'){
                        //if membership is found in 'waitlist' status, populate the membership fields
                        let priorContactFields = {}
                    
                        priorContactFields.address = membership.address
                        priorContactFields.city = membership.city
                        priorContactFields.postal = membership.postalCode
                        priorContactFields.primaryPhone = membership.primaryPhone
                        priorContactFields.emergencyContact= membership.emergencyName
                        priorContactFields.emergencyPhone = membership.emergencyPhone
                        priorContactFields.yearOfBirth = membership.yearOfBirth
                        priorContactFields.ntrpSelf = membership.ntrpSelf

                        setContactFields(priorContactFields)
                        
                        setMembers(members)  

                        setAdults(membership.totalAdults)
                        setJuniors(membership.totalJuniors)
                        
                        setFirstTimeMemberInput(false)
                        setPriorWaitlistFound(true)
                    }
                }    
            }

            getPriorRegistration()
        }
    },[firstTimeMemberInput])
    

    useEffect (() => {
        setWaitlistFormSubmitted(false)
        window.scrollTo(0, 0)
    },[])

    const trimInputFields = () => {
        let updatedContactFields = {...contactFields}

        updatedContactFields.postal = updatedContactFields.postal.trim()
        updatedContactFields.primaryPhone = updatedContactFields.primaryPhone.trim()
        updatedContactFields.emergencyPhone = updatedContactFields.emergencyPhone.trim()
        
        setContactFields(updatedContactFields)
    
        let updatedMembers = [...members]

        for(let idx=0; idx < members.length; idx++){
            updatedMembers[idx].firstName = updatedMembers[idx].firstName.trim()
            updatedMembers[idx].lastName = updatedMembers[idx].lastName.trim()
            updatedMembers[idx].email = updatedMembers[idx].email.trim()
            updatedMembers[idx].phoneNumber = updatedMembers[idx].phoneNumber.trim()
        }

        setMembers(updatedMembers)
    }

    const returnHome = (e) => {
        e.preventDefault()

        history.push('/')
    }

    const handleRegFormSubmit = (e) => {
        e.preventDefault()

        //trim input fields
        trimInputFields()

        //first validate all input fields
        
        const validateFields = {
            adults: adults,
            juniors: juniors, 
            terms: true, 
            address: contactFields.address, 
            city: contactFields.city, 
            postal: contactFields.postal, 
            primaryPhone: contactFields.primaryPhone, 
            emergencyContact: contactFields.emergencyContact, 
            emergencyPhone: contactFields.emergencyPhone, 
            members: members,
            currentYear: configValues.currentSeason,
            maxAgeJunior: configValues.maxAgeJunior,
            maxAdults: maxAdults,
            maxJuniors: maxJuniors
        }

        let validations = validateMembershipInputs(validateFields)
        
        if(validations.validatedResult){
            //scroll to top of screen
            window.scrollTo(0, 0)
            submitWaitList()
        } else {
            //display the validation error message
            updateChildMessage('Error', validations.validatedMessage, 'danger', 0)
            window.scrollTo(0, 0)
        }
    }

    const deleteMember = (memberId) => {
        let updatedMembers = [...members]
        let memberType = updatedMembers[memberId].memberType

        let message = 'Removed Member: '
                    + updatedMembers[memberId].firstName
                    + ' ' 
                    + updatedMembers[memberId].lastName

        updateChildMessage('Alert', message, 'danger', 5000)            

        if(memberType==="adult"){
            let updatedAdults = adults - 1
            setAdults(updatedAdults)
        } else {
            let updatedJuniors = juniors - 1
            setJuniors(updatedJuniors)
        }

        updatedMembers.splice(memberId,1)
        setMembers(updatedMembers)

        //scroll to top of the form
        window.scrollTo(0, 0)
    }

    const addMember = (memberType) =>{
        if(memberType==="adult"){
            let updatedAdults = adults + 1
            setAdults(updatedAdults)
        } else {
            let updatedJuniors = juniors + 1
            setJuniors(updatedJuniors)
        }

        let updatedMembers = [...members, {
            memberType: memberType,
            firstName:"", 
            lastName:"",
            email:"",
            yearOfBirth: "",
            ntrpSelf: "",
            consentEmailContact: false,
            showNewsLetterConsent: true,
            primaryMember: false,
            consentNewsLetter: false,
            phoneNumber:"",
            consentPhoneBook: false
        }]

        if(memberType==="adult" && firstTimeMemberInput){
            //default the member details to the login user
            let index = updatedMembers.length - 1
            
            updatedMembers[index].firstName = user.firstName
            updatedMembers[index].lastName = user.lastName
            updatedMembers[index].email = user.email
            
            if(contactFields.primaryPhone){
                updatedMembers[index].phoneNumber = contactFields.primaryPhone
            }

            updatedMembers[index].consentNewsLetter = user.consentMarketingMaterial
            
            if(user.consentMarketingMaterial){
                //if user has already consented to receiving news, do not ask for consent again
                updatedMembers[index].showNewsLetterConsent = false
            }
            
            updatedMembers[index].primaryMember = true
            setFirstTimeMemberInput(false)
        }
        
        setMembers(updatedMembers)
    }

    const handleAddAdult = () => {
        addMember("adult")
    }

    const handleAddJunior = () => {
        addMember("junior")
    }

    const handleChange = (e) => {
        // clear errors, if any
        updateChildMessage('', '', '', 0)

        if (["firstName", "lastName", "email", "phoneNumber", "yearOfBirth", "ntrpSelf"].includes(e.target.className) ) {
            let updatedMembers = [...members]
            if(e.target.className === "yearOfBirth"){
                if(e.target.value.length <= 4){
                    //do not allow more than 4 digits to be entered for year of Birth
                    updatedMembers[e.target.dataset.id][e.target.className] = e.target.value
                }
            } else if(e.target.className === "ntrpSelf"){
                if(e.target.value >= MIN_NTRP_RATING 
                    && e.target.value <= MAX_NTRP_RATING){
                    //do not allow a value more than 7 for NTRP rating
                    updatedMembers[e.target.dataset.id][e.target.className] = e.target.value
                }
            } else {
                updatedMembers[e.target.dataset.id][e.target.className] = e.target.value
            }
            
            if(updatedMembers[e.target.dataset.id].primaryMember 
                && e.target.className === "email" 
                && !updatedMembers[e.target.dataset.id].showNewsLetterConsent ){
                //when primary members email id is being updated, show the consent field
                //primary member email is being updated.
                //console.log('primary member email is being updated')
                //show the newsletter consent
                updatedMembers[e.target.dataset.id].showNewsLetterConsent = true
            }

            setMembers(updatedMembers)
        } else if (["consentEmailContact", "consentPhoneBook", "consentNewsLetter"].includes(e.target.className)) {
            let updatedMembers = [...members]
            updatedMembers[e.target.dataset.id][e.target.className] = e.target.checked
            setMembers(updatedMembers)
        } else {
            let updatedContactFields = {...contactFields}

            if(e.target.name === "postal"){
                updatedContactFields[e.target.name] = e.target.value.toUpperCase()
            } else {
                updatedContactFields[e.target.name] = e.target.value
            }

            setContactFields(updatedContactFields)               
        }
    }

    const submitWaitList = () => {
        // submit the waitlist information
        
        let messageTitle = 'Default'
        let message = 'Default'
        let messageType = Constants.MSG_TYPE_SUCCESS  

        if(!waitlistFormSubmitted){
            //do not allow the user to submit the form multiple times
            setWaitlistFormSubmitted(true)
            let allowResubmit = false
            let processFlag = true
   
            const postWaitlist = async () => {
                let res 

                try{
                    res = await axios({
                        method: 'POST',
                        timeout: AXIOS_TIMEOUT,
                        url: WAITLIST_URL,
                        data: {
                            user: user,
                            address: contactFields.address,
                            city: contactFields.city,
                            postal: contactFields.postal,
                            primaryPhone: contactFields.primaryPhone,
                            emergencyContact: contactFields.emergencyContact,
                            emergencyPhone: contactFields.emergencyPhone,
                            adults: adults, 
                            juniors: juniors,
                            members: members,
                        }  
                        })
                } catch (error){
                    console.log('self waitlist error')
                    messageTitle = 'Error' 
                    message = 'Error submitting waitlist form'
                    messageType = Constants.MSG_TYPE_ERROR
                    logAxiosError(error)
                    processFlag = false
                    allowResubmit = true
                }
                
                if(processFlag){
                    const {data} = res
            
                    if (data.status === Constants.RETURN_CODE_OK) {
                        //call to Strapi was good. Check the Strapi return status
                        if(data.data.status.success){
                            messageTitle = 'Successfully added to Waitlist' 
                            message = 'Thank you ' + capitalize(user.firstName) + ' for your interest in WPTC. We will contact you to register on a priority basis'
                            messageType = Constants.MSG_TYPE_SUCCESS   
                            setAddedToWaitlist(true) 
                        }  else {
                            //generic failure
                            messageTitle = 'Error - adding to the waitlist failed' 
                            message = 'Please try again later or contact the club to report an error'
                            messageType = Constants.MSG_TYPE_ERROR
                            allowResubmit = true
                        }
                    } else if (data.status === Constants.RETURN_CODE_NOT_AUTH) {
                        messageTitle = 'Error' 
                        message = 'User not authorized'
                        messageType = Constants.MSG_TYPE_ERROR
                    } else {
                        messageTitle = 'Error - submit failed' 
                        message = 'Error submitting form. Please try again'
                        messageType = Constants.MSG_TYPE_ERROR
                        allowResubmit = true
                    }
                            
                    //send user back to homepage after form submission
                    //user will be sent to Home screen Automatically

                    if(allowResubmit){
                        setWaitlistFormSubmitted(false)
                    }
                } else {
                    messageTitle = 'Error - submit failed' 
                    message = 'Bad response from server. Please try again later ...'
                    messageType = Constants.MSG_TYPE_ERROR
                    allowResubmit = true
                }

                updateChildMessage(messageTitle, message, messageType, 10000)
            }  
            postWaitlist() 
        }
    }

    if(!user){
        return(null)
    }
    
    return (
        <>
            {!priorWaitlistFound &&
                <div className="form-style-10">
                    <h1>Waitlist Form<span>This waitlist is for the {configValues.currentSeason} Tennis Season only</span></h1>
                    <form onSubmit={handleRegFormSubmit} onChange={handleChange}>
                        <ContactDetails
                            firstName={user.firstName}
                            lastName={user.lastName}
                            email={user.email}
                            address={contactFields.address}
                            city={contactFields.city}
                            postal={contactFields.postal}
                            primaryPhone={contactFields.primaryPhone}
                            emergencyContact={contactFields.emergencyContact}
                            emergencyPhone={contactFields.emergencyPhone}
                        />

                        <div className="section"><span>4</span>Members</div>
                        <MembersInfo
                            members={members}
                            deleteMember={deleteMember}
                            waitlist={true}
                        />

                        {adults < maxAdults ?
                            <div className="section-addadult">
                                <span>
                                    <i className="fas fa-plus-circle"
                                        onClick={handleAddAdult}
                                    ></i>
                                </span>Add Adult
                            </div> : <></>
                        }    
                        
                        {juniors < maxJuniors ?
                            <div className="section-addjunior">
                                <span>
                                <i className="fas fa-plus-circle"
                                    onClick={handleAddJunior}
                                >    
                                </i>
                                </span>Add Junior
                            </div> : <></>
                        }

                        <button type="submit"> Add to Waitlist </button>
                    </form>
                </div>
            }    

            {priorWaitlistFound &&
                <div className="form-style-10">
                    <h1>Waitlist Request <span>You have already submitted this request for the {configValues.currentSeason} Tennis Season</span></h1>
                    <form onSubmit={returnHome}>
                        <ContactDetailsReadOnly
                            firstName={user.firstName}
                            lastName={user.lastName}
                            email={user.email}
                            address={contactFields.address}
                            city={contactFields.city}
                            postal={contactFields.postal}
                            primaryPhone={contactFields.primaryPhone}
                            emergencyContact={contactFields.emergencyContact}
                            emergencyPhone={contactFields.emergencyPhone}
                        />

                        <div className="section"><span>4</span>Members</div>
                        <MembersInfoReadOnly
                            members={members}
                        />

                        <button type="submit"> Return to the Home Page</button>
                    </form>
                </div>
            }    
        </>
    )
}

export default WaitlistForm