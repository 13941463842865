import axios from 'axios'

import { EVENTS_SINGLE_URL } from './urls'
import { logAxiosError } from './functions';
import { AXIOS_TIMEOUT } from './constants'

//import testList from './phonebook.json'

let cacheParticipants = null

export function requestParticipantsList(refresh, eventId) {
    if(refresh){
        cacheParticipants = null
    }
    if(!cacheParticipants){
        //fetch the list of participants
        
        const getParticipantList = async () => {
            let res

            try{
                res = await axios({
                    method: 'GET',
                    timeout: AXIOS_TIMEOUT,
                    url: EVENTS_SINGLE_URL,
                    params: {
                        eventId: eventId
                    }
                })
            } catch(error){
                console.log('Error fetching participant list')
                logAxiosError(error)     
            }
        
            const {data} = res
            //console.log('data: ', data.data)

            //console.log('phone-data: ', data)
            let participantList = data.data.participants
            return participantList
        }  
        
        cacheParticipants = getParticipantList()
        return cacheParticipants
    }
    return cacheParticipants
}
