import React, { useEffect, useState } from 'react'

//import {fetchEvents} from '../../utils/fetchEvents'

/* Documentation
1. This page is the landing page to manage events or book events. It will show the list of bookable events
2. Each Event 

*/

import axios from 'axios'
import { 
    EVENTS_FIND_URL,
    EVENTS_DELETE_URL
} from '../../utils/urls'

import { logAxiosError } from '../../utils/functions';
import { AXIOS_TIMEOUT } from '../../utils/constants'

import SingleEventDetail from '../../components/SingleEventDetail'
import AddNewEvent from '../../components/AddNewEvent'

import './CurrentEvents.css'

const ManageEvents = ({user, history, updateChildMessage, match, updateUser}) => {
    const [events, setEvents] = useState([{}])
    const [refresh, setRefresh] = useState(false)
    const [singleEvent, setSingleEvent] = useState(false)
    const [index, setIndex] = useState()    
    const [eventsAvailable, setEventsAvailable] = useState(false)
    const [loadingEvents, setLoadingEvents] = useState(true)
    const [isStaff, setIsStaff] = useState(false)
    const [manageEventMode, setManageEventMode] = useState(false)
    const [requestSubmitted, setRequestSubmitted] = useState(false)
    const [authorizedUser, setAuthorizedUser] = useState(false)

    //console.log('match', match)
    
    useEffect(()=> {
        if(!user){
            history.push('/login')
        } else if(user.isStaff || user.isExec || user.isAdmin) {
            setAuthorizedUser(true)
        } else {
            setAuthorizedUser(false)
            history.push('/')
        }

        setSingleEvent(false)
        setRequestSubmitted(false)
    },[])

    useEffect(()=>{
        if(authorizedUser){
            setRefresh(true)
        }
    },[authorizedUser]) 
    
    useEffect(()=>{
        if(authorizedUser){
            setIsStaff(true)
            
            setManageEventMode(true)
            
            //console.log('fetching events')
            setLoadingEvents(true)
            //console.log('memberId', inMemberId)
            let processRequest = true
            let status
            let data
            let res
            let showEvents = "all"

            const getEvents = async () => {
                try{
                    res = await axios({
                        method: 'GET',
                        timeout: AXIOS_TIMEOUT,
                        url: EVENTS_FIND_URL,
                    })    
                    setLoadingEvents(false)

                    //console.log('event-res', res)
                } catch(error){
                    logAxiosError(error)
                    processRequest = false
                }
                    
                if(processRequest){
                    data = res.data.data.data
                    status = res.data.data.status

                    console.log('data:', data)
                    console.log('status:', status)

                    if(status.success && status.notFound){
                        //no data found for the specified criteria
                        processRequest = false
                        //console.log('no data found')
                        setEventsAvailable(false)
                    }

                    if(status.failed){
                        processRequest = false
                        setEventsAvailable(false)     
                        console.log('failed fetching data', res)
                    }
                } else {
                    //error message
                    updateChildMessage('Severe Error', 'Error fetching events - try again later', 'danger', 0)
                    setLoadingEvents(false)
                    setEventsAvailable(false)
                }
                
                if(processRequest){
                    //finished loading events
                    setLoadingEvents(false)
                    setEventsAvailable(true)
                    setEvents(data)
                }
            }  
            getEvents()
        }  
    },[refresh])

    const handleRefresh = (request) => {
        if(request){
            setRefresh(!refresh)
        }
        if(singleEvent){
            setSingleEvent(false)
        }
    }

    const handleReturn = () => {
        if(singleEvent){
            setSingleEvent(false)
        }
    }

    const handleOnSelect = (idx) => {
        setIndex(idx)
        setSingleEvent(true)
    }
 
    const submitDelete = async (eventId, versionNumber) => {
        
        try{
            //console.log('delete-params', eventId, versionNumber)

            const postRequest = async () => {
                const res = await axios({
                    method: 'DELETE',
                    timeout: AXIOS_TIMEOUT,
                    url: EVENTS_DELETE_URL,
                    params: {
                        eventId: eventId,
                        versionId: versionNumber
                    }
                })

                //console.log('res', res)
                
                let { data } = res
                //console.log('post-event-res', data)
                
                if(data && data.status.success){
                    updateChildMessage('Success', 'Event Deleted Successfully', 'success', 3000)
                } else{
                    let errorMessage = ''

                    if(data.status.versionMismatch){
                        errorMessage = 'Event has been updated. Please refresh and try again'
                    }

                    if(data.status.rowNotFound){
                        errorMessage = 'Event could not be found for Delete !!!'
                    }

                    if(!errorMessage){
                        errorMessage = 'Severe Error. Please try again later ...'
                    }
                    
                    updateChildMessage('Error', errorMessage, 'danger', 5000)
                }

                //console.log('regular mode')    
                setRefresh(!refresh)
                setSingleEvent(false)           
            }
            postRequest()    
        } catch(error){
            logAxiosError(error)
        }
    }

    return(
        <div className="CurrentEvents">   
            {eventsAvailable &&
            <>
                <div className="EventBar">
                    <h3> Events </h3>
                    
                    <h4> Manage Events </h4>                                
                    <span>
                        <button className="RefreshButton" onClick={handleRefresh}>Refresh</button>
                    </span>
                </div>
                
                {!singleEvent &&
                    <div className="Events">
                    {events && events.length > 0 &&
                        <div className="CurrentEventsList">
                            <>
                                {events.map((currentEvent, idx) =>{
                                    return(
                                        <div key={currentEvent.id}> 
            
                                            <SingleEventDetail
                                                currentEvent={currentEvent}
                                                idx={idx}
                                                mode="summary"
                                                handleOnSelect={handleOnSelect}
                                                handleReturn={handleReturn}
                                            />
                                        </div>
                                    )
                                })}

                                <div className="CurrentEventsList">
                                    {isStaff && manageEventMode &&
                                        <AddNewEvent />
                                    }
                                </div>
                            </>
                        </div>
                    }
                </div>
                }

                {singleEvent&&
                    <div className="SingleEvent">
                        <div>        
                            <SingleEventDetail
                                currentEvent={events[index]}
                                idx={index}
                                mode="detail"
                                handleOnSelect={handleOnSelect}
                                handleReturn={handleReturn}
                                submitDelete={submitDelete}
                            />
                        </div>
                    </div>
                }        
            </>               
            }
            {loadingEvents &&
                 <div className="Events">
                     <h3> Loading Events. Please Wait ... </h3>
                 </div>
            }
            {!loadingEvents && !eventsAvailable &&
                <div className="Events">
                    <h3> There are no events setup as yet ... </h3>

                    <AddNewEvent />                
                </div>    
            }
        </div>
    ) 
}

export default ManageEvents
