import React from 'react'

const PaymentDetailsStaff = (props) => {
    const { 
        paymentFields,
        confirmEntries,
        membershipLabel,
    } = props

    let {
        paymentType,
        membershipFee,
        hst,
        paymentAmount,
        paymentReceiptNum,
        paymentChequeNum,
        paymentNotes
    } = paymentFields

    const onChangeHandler = (e) =>{
        return null
    }

    const handleUpdateMembership = (e) => {
        e.preventDefault()
        props.updateMembership()
    }

    return(
        <>
            <div className="section"><span>S</span>Summary</div>
            <div className="inner-summarywrap">
                <p>Membership Type: {membershipLabel} </p>
                
                <table>
                    <tr>
                        <td>Membership Fee:</td>
                        <td>${membershipFee.toFixed(2)}</td>
                    </tr>
                    <tr>
                        <td>HST:</td>
                        <td>${hst.toFixed(2)}</td>
                    </tr>                                        
                    <tr>
                        <td>Total Amount:</td>
                        <td>${paymentAmount.toFixed(2)}</td>
                    </tr>                                        
                </table>

                <p className="update" onClick={handleUpdateMembership}>
                    Update Membership Information 
                </p>
            </div>

            <div className="section"><span>1</span>Payment Type & Amount</div>
            <div className="inner-wrap">
                <label htmlFor="paymentType">Payment Type <span className="required">*</span> </label>
                <select 
                    name="paymentType"  
                    size="1"
                    value={paymentType} 
                    onChange={onChangeHandler}
                >
                    <option>Select a payment type </option>
                    <option value="cash">Cash </option>
                    <option value="cheque">Cheque </option>
                    <option value="card">Card </option>
                </select> 

                <label htmlFor="paymentAmount">Payment Amount<span className="required">*</span> </label>
                <input 
                    type="text" 
                    name="paymentAmount" 
                    value={paymentAmount}
                    onChange={onChangeHandler}
                />
            </div>    
            
            <div className="section"><span>2</span>Payment Reference Info</div>
            <div className="inner-wrap">
                <label htmlFor="paymentReceiptNum">Payment Receipt #<span className="required">*</span> </label>
                <input 
                    type="text" 
                    name="paymentReceiptNum" 
                    value={paymentReceiptNum}
                    onChange={onChangeHandler}
                />

                <label htmlFor="paymentChequeNum">Payment Cheque #</label>
                <input 
                    type="text" 
                    name="paymentChequeNum" 
                    value={paymentChequeNum}
                    onChange={onChangeHandler}
                />
                
                <label htmlFor="paymentNotes">Payment Notes</label>
                <input 
                    type="text" 
                    name="paymentNotes" 
                    value={paymentNotes}
                    onChange={onChangeHandler}
                />
            </div>

            <div className="button-section">
                <span className="privacy-policy">
                    <label className="checkbox" htmlFor="confirmEntries">
                        I have verified membership and billing information
                        <input 
                            type="checkbox" 
                            name="confirmEntries"
                            checked={confirmEntries}
                            onChange={onChangeHandler}
                        /> 
                    </label>
                </span>
                <button type="submit"> Submit Registration </button>
            </div>
        </>  
    )
}

export default PaymentDetailsStaff