import React, { useState, useEffect } from 'react';
import axios from 'axios'
import ReactMarkdown from 'react-markdown';

import { Link } from 'react-router-dom';

import './EventInfo.css'

import { EVENT_INFO_URL, fromImageToUrl } from '../../utils/urls'
import { logAxiosError } from '../../utils/functions';
import { AXIOS_TIMEOUT } from '../../utils/constants'

const EventInfo = ({user}) => {
    const[eventInfo, setEventInfo] = useState({})

    useEffect(() => {
        try{           
          const getEventInfo = async () => {
            const res = await axios({
              method: 'GET',
              timeout: AXIOS_TIMEOUT,
              url: EVENT_INFO_URL
            })

            const {data} = res
            setEventInfo(data.data)
          }  
          getEventInfo()
        }  
        catch(error) {
            logAxiosError(error)
        }  
    }, [])  

    let image = ''

    const { mainTitle, subTitle, leftBox, rightBox, linkText } = eventInfo

    let viewEventsLink = false

    //console.log('user', user)
    if(user){
        if(user.isMember || user.isExec){
            viewEventsLink = true
        }
    }

    return (
        <div className="EventInfo">
            <img className="Image" src={fromImageToUrl(image)} alt="Event Info Page"/>
                
            <div className="PgTitle"> {mainTitle} </div>  
            <div className="PgSubTitle"> {subTitle} </div>

            <div className="Textcontainer"> 
                <div className="Para">
                    <ReactMarkdown>{leftBox}</ReactMarkdown>
                </div>
                <div className="Para"> 
                    <ReactMarkdown>{rightBox}</ReactMarkdown>
                </div>
            </div>
            
            {linkText && viewEventsLink &&
                <div className="Link-box">
                    <Link to="/events/currentevents">{linkText} </Link>
                </div>
            }

            {!viewEventsLink &&
                <div className="Link-box">
                    Members Login needed to access Online Bookings
                </div>
            }
        </div>
    )    
}

export default EventInfo
