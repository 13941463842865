import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

//import {fetchEvents} from '../../utils/fetchEvents'

/* Documentation
1. This page is the landing page to manage events or book events. It will show the list of bookable events
2. There are several modes in which this page operates based on the type of user - member v/s staff and from where they have navigated
3. 

Modes:
1. manageEventBookingMode -> In this mode a staff user is booking a single member for a single event.
*/

import axios from 'axios'
import { 
    EVENTS_URL, 
    EVENTS_SELF_REGISTER_URL, 
    EVENTS_SELF_CANCEL_URL,
    EVENTS_STAFF_REGISTER_URL,
    EVENTS_STAFF_CANCEL_URL,
    EVENTS_STAFF_CANCEL_EVENT_URL,
    EVENTS_STAFF_WAITLIST_URL  
} from '../../utils/urls'

import { logAxiosError } from '../../utils/functions';
import { AXIOS_TIMEOUT, AXIOS_EXTRA_LONG_TIMEOUT } from '../../utils/constants'

import SingleEventSelf from '../../components/SingleEventSelf'
import SingleEventStaff from '../../components/SingleEventStaff'

import './CurrentEvents.css'

const INITIAL_MESSAGE = 'Please make sure you have sufficient credits before attempting ' + 
 'to register for a paid event'

const CurrentEvents = ({user, history, updateChildMessage, match, updateUser}) => {
    const [events, setEvents] = useState([{}])
    const [refresh, setRefresh] = useState(false)
    const [singleEvent, setSingleEvent] = useState(false)
    const [index, setIndex] = useState()    
    const [eventsAvailable, setEventsAvailable] = useState(false)
    const [loadingEvents, setLoadingEvents] = useState(true)
    const [isStaff, setIsStaff] = useState(false)
    const [inputMemberId, setInputMemberId] = useState()
    const [inFirstName, setInFirstName] = useState()
    const [inLastName, setInLastName] = useState()
    const [manageEventMode, setManageEventMode] = useState(false)
    const [manageEventBookingMode, setManageEventBookingMode] = useState(false)
    const [requestSubmitted, setRequestSubmitted] = useState(false)
    const [authorizedUser, setAuthorizedUser] = useState(false)

    //console.log('match', match)
    useEffect(()=> {
        if(!user){
            // child message not showing in this case. Investigate Later
            // updateChildMessage("", "Please Login to continue...", "primary", 0)
            history.push('/login')
        } else if(user.isMember || user.isExec) {
            setAuthorizedUser(true)
            updateChildMessage('Important', INITIAL_MESSAGE, 'primary', 0)
        } else if(user.isStaff || user.isAdmin) {
            setAuthorizedUser(true)
        } else {
            setAuthorizedUser(false)
            history.push('/')
        }

        setSingleEvent(false)
        setRequestSubmitted(false)
    },[])
    
    useEffect(()=>{
        if(authorizedUser){
            setRefresh(true)
        }
    },[authorizedUser]) 
    
    useEffect(()=>{
        if(authorizedUser){
            let inMemberId = -1
            let inEventId = -1
            
            if(user.isStaff || user.isAdmin){
                setIsStaff(true)
                //Admin has same access as Staff here
                //set the memberId that the staff is signing up
                //in this mode, we are dealing with only 1 event. We need to hide the "Refresh" button and instead, show the "Return" button
                if(match){
                    inMemberId = match.params.id
                    inEventId = match.params.eventId
                    setInFirstName(match.params.firstname)
                    setInLastName(match.params.lastname)
                    
                    if(inMemberId > 0){
                        //member if specified 
                        setManageEventMode(false)

                        if(inEventId > 0 ){
                            //if both member id and event id are set, we are in a managed booking mode
                            setManageEventBookingMode(true)
                        }

                    } else {
                        //no specific member id
                        setManageEventMode(true)
                        inMemberId = 0
                    }
                } else {
                    inMemberId = inputMemberId
                    setManageEventMode(true)
                    inMemberId = 0
                    inEventId = 0
                }

                setInputMemberId(inMemberId)

            } else {
                //regular member
                inMemberId = user.memberId
                setInputMemberId(inMemberId)
            } 
            
            //console.log('fetching events')
            setLoadingEvents(true)
            //console.log('memberId', inMemberId)
            let processRequest = true
            let status
            let data
            let res

            const getEvents = async () => {
                //console.log('member-id', inMemberId)
                //console.log('event-id', inEventId)

                try{
                    res = await axios({
                        method: 'GET',
                        timeout: AXIOS_TIMEOUT,
                        url: EVENTS_URL,
                        params: {
                            memberId: inMemberId,
                            eventId: inEventId
                        }
                    })    
                    setLoadingEvents(false)
                } catch(error){
                    logAxiosError(error)
                    processRequest = false
                }
                    
                if(processRequest){
                    data = res.data.data.data
                    status = res.data.data.status

                    //console.log('events_url - status', status)
                    //console.log('res: ', data)

                    if(status.success && status.notFound){
                        //no data found for the specified criteria
                        processRequest = false
                        //console.log('no data found')
                        setEventsAvailable(false)
                    }

                    if(status.failed){
                        processRequest = false
                        setEventsAvailable(false)
                        console.log('failed fetching data', res)
                    }
                } else {
                    //error message
                    updateChildMessage('Severe Error', 'Error fetching events - try again later', 'danger', 0)
                    setLoadingEvents(false)
                    setEventsAvailable(false)
                }
                
                if(processRequest){
                                        
                    //console.log('get events: ', data)
                
                    let currentEvents = [{}]
                    // events that the member registered for, but cancelled
                    let tempCancelledEvents = [{}]

                    if(data.events && data.events.length > 0){
                        let firstEntry = true
                        let firstCancelledEntry = true
                        
                        
                        for(let idx=0; idx<data.events.length; idx++){
                            let event = {...data.events[idx]}
                            event.isAvailable = false
                            event.isAvailableOnline = false
                            event.isAvailableWaitlist = false
                            event.isMemberRegistered = false
                            event.isAvaialbleStaff = false

                            //console.log('event-member', event.memberId)
                            //console.log('user-member', user.memberId)

                            //first check if member is already registered
                            //console.log('memberId2', inMemberId)

                            if((inMemberId) 
                                && parseInt( event.memberId) === parseInt(inMemberId)
                                && (event.status === "registered" || event.status === "waitlist" || event.status === "confirmed") 
                            ){
                                event.isMemberRegistered = true
                            }
                            
                            if((event.currentOnlineParticipants < event.maxOnlineParticipants)                  
                            && ((event.currentParticipants + event.currentWaitingList) < event.maxParticipants)) {
                                event.isAvailableOnline = true
                            } 
                            
                            if((event.currentParticipants + event.currentWaitingList) < event.maxParticipants) {
                                //staff booking is available
                                event.isAvailable = true
                            } 
                            
                            if(event.enableWaitlist && (event.currentWaitingList < event.waitlistLimit)) {
                                event.isAvailableWaitlist = true
                            }

                            if((event.currentOnlineParticipants < event.maxOnlineParticipants) ||
                                (event.enableWaitlist && (event.currentWaitingList < event.waitlistLimit)) 
                                ) {
                                event.isAvaialbleStaff = true
                            } 

                            if((event.status === "cancelled") || (event.status === "eventcancelled")){
                                //move this record to a temporary array
                                if(firstCancelledEntry){
                                    tempCancelledEvents[0] = event    
                                    firstCancelledEntry = false
                                } else {
                                    tempCancelledEvents.push(event)
                                }
                            } else {
                                if(firstEntry){
                                    currentEvents[0] = event    
                                    firstEntry = false
                                } else {
                                    currentEvents.push(event)
                                }
                            }
                            
                            //console.log('adding event', event.title)
                        }
                        
                        //check if any of the cancelled events are in the currentEvents array.
                        //if not, add the row to current events
                        //console.log('cancelled events', tempCancelledEvents)

                        if(Object.keys(tempCancelledEvents[0]).length > 0){
                            //console.log('ac')
                            if(currentEvents.length > 0){
                                let cancEvent
                                for(let idx=0; idx<tempCancelledEvents.length; idx++){
                                    cancEvent = currentEvents.find(currEvent => 
                                        currEvent.id === tempCancelledEvents[idx].id
                                    )       
                                    
                                    //if(Object.keys(cancEvent).length === 0){
                                    if(!cancEvent){
                                        //console.log('ad')    
                                        //no corresponding current event found.
                                        //add cancelled event to the current event
                                        if(Object.keys(currentEvents[0]).length > 0){
                                            //we have at least 1 current event in the array
                                            currentEvents.push(tempCancelledEvents[idx])
                                        } else {
                                            //set the first current event to the cancelled 
                                            currentEvents[0] = tempCancelledEvents[idx]    
                                        }
        
                                        //console.log('current-events-ad', currentEvents)
                                    }
                                }
                            } else {
                                //Member has signed up and cancelled from all current events
                                //set currentEvents to tempCancelledEvents
                                currentEvents = tempCancelledEvents
                            }
                        }

                        //finished loading events
                        setLoadingEvents(false)

                        //check if there are any events available to be displayed
                        if(Object.keys(currentEvents[0]).length > 0){
                            //console.log('events available')
                            setEventsAvailable(true)
                        } else {
                            //console.log('events NOT available')
                            setEventsAvailable(false)
                        }

                        setEvents(currentEvents)
                        //console.log('current events:', currentEvents)
                    } else {
                        //there are no events at this time
                        setLoadingEvents(false)
                        setEventsAvailable(false)
                    }
                }
            }  
            getEvents()
        }  
    },[refresh])

    useEffect(() => {
        //console.log('manageEventBookingMode', manageEventBookingMode)
        //console.log('requestSubmitted', requestSubmitted)

        let isMounted = true;

        if(manageEventBookingMode && requestSubmitted){
            let eventTitle = events[0].title
            let eventId = events[0].id

            //console.log("selectedEvent", eventId)

            history.push(`/events/event/${eventId}/${eventTitle}`)   
            return () => { isMounted = false };
        }

    },[requestSubmitted])

    const handleRefresh = (request) => {
        if(request){
            setRefresh(!refresh)
        }
        if(singleEvent){
            setSingleEvent(false)
        }
    }

    const handleGoback = () => {
        history.goBack()
    }

    const handleReturn = () => {
        if(singleEvent){
            setSingleEvent(false)
        }
    }

    const handleOnSelect = (idx) => {
        setIndex(idx)
        setSingleEvent(true)
    }

    const submitCancel = (eventId) => {
        submitRequest("cancellation", eventId)
    }

    const submitCancelEvent = (eventId) => {
        submitRequest("cancelevent", eventId)
    }

    const submitBooking = (eventId) => {
        submitRequest("register", eventId)
    }
 
    const submitWaitList = (eventId) => {
        submitRequest("waitlist", eventId)
    }
 
    const submitRequest = async (request, eventId) => {
        let URL = ''
        let axiosTimeout = AXIOS_TIMEOUT

        if(isStaff){
            if(request === "register"){
                URL = EVENTS_STAFF_REGISTER_URL
            } else if (request === "cancellation"){
                URL = EVENTS_STAFF_CANCEL_URL
            } else if(request === "waitlist"){
                URL = EVENTS_STAFF_WAITLIST_URL
            } else if(request === "cancelevent"){
                //use extra long timeout when cancelling an event
                URL = EVENTS_STAFF_CANCEL_EVENT_URL
                axiosTimeout = AXIOS_EXTRA_LONG_TIMEOUT
            }
        } else {
            //setup member URLS
            if(request === "register"){
                URL = EVENTS_SELF_REGISTER_URL
            } else {
                URL = EVENTS_SELF_CANCEL_URL
            }
        }

        try{
            //console.log('postUrl', URL)

            const postRequest = async () => {
                const res = await axios({
                    method: 'POST',
                    timeout: axiosTimeout,
                    url: URL,
                    data: {
                        memberId: inputMemberId,
                        eventId: eventId,
                        request: request
                    }
                })

                //display messages upon return
                //console.log('res', res)
                
                let { data } = res.data
                //console.log('post-event-res', data)
                
                if(data && data.processLog.success){
                    //check if any credits need to be added or subtracted
                    if(!isStaff){
                        //console.log('event-register-data', data)
                        //console.log('user.credits', user.credits)

                        user.credits = data.runningCredit

                        /*
                        if(request === "register"){
                            user.credits = user.credits - data.tokensPaid
                        } else if(request === "cancellation"){
                            // tokensPreviouslyPaid
                            user.credits = user.credits + data.tokensPreviouslyPaid
                        }
                        */
    
                        updateUser(user)
                    }
                    
                    if(request === "register"){
                        updateChildMessage('Success', 'Booking successful', 'success', 3000)
                    } else {
                        updateChildMessage('Success', 'Cancellation successful', 'success', 3000)
                    }
                    
                } else{
                    //check the status and put out appropriate message
                    let msg = 'Booking Failed -> '
                    let errorMessage = ''

                    if(data.processLog.blacklist){
                        errorMessage = 'Booking not allowed - Member is blacklisted'
                    } else {
                        errorMessage = 'Sorry !!! Booking Failed - Unknown Reason'
                    }
                    
                    console.log('error-data', data)
                    if(data){
                        if(data.processLog.failedUpdatingEvent){
                            errorMessage = msg + "System busy - Please try again later"
                        }
                        if(data.processLog.onlineBookingFull){
                            errorMessage = msg + "Online Booking Full"
                        }
                        if(data.processLog.insuffficientCredits){
                            errorMessage = msg + "Member has insufficient credits"
                        }
                    } else {
                        errorMessage = "System Failure. Please try again later"
                    }

                    updateChildMessage('Error', errorMessage, 'danger', 20000)
                }
                
                if(manageEventBookingMode && data && data.processLog.success){
                    //console.log('setting request submitted')
                    setRequestSubmitted(true)
                }else {
                    //console.log('regular mode')    
                    setRefresh(!refresh)
                    setSingleEvent(false)
                }
            }
            postRequest()    
        } catch(error){
            logAxiosError(error)
        }
    }

    return(
        <div className="CurrentEvents">
           
            {eventsAvailable &&
            <>
                <div className="EventBar">
                    <h3> Events </h3>
                    {isStaff &&
                        <>  
                            {!manageEventMode &&
                                <>
                                    <span className="MemberInfo"> 
                                        <h4>Member: {inFirstName} {inLastName} </h4>
                                    </span>
                                    <h4> Id: {inputMemberId} </h4>    
                                </>
                            }
                            {manageEventMode &&
                                <h4> Event Bookings </h4>                                
                            }
                            {manageEventBookingMode &&
                                <span>
                                    <button className="RefreshButton" onClick={handleGoback}>Return</button>
                                </span>
                            }
                            {!manageEventBookingMode &&
                                <span>
                                    <button className="RefreshButton" onClick={handleRefresh}>Refresh</button>
                                </span>
                            }
                        </>
                    }
                </div>
                
                {!singleEvent &&
                    <div className="Events">
                        {events && events.length > 0 &&
                            <div className="CurrentEventsList">
                                <>
                                    {events.map((currentEvent, idx) =>{
                                        return(
                                            <div key={currentEvent.id}> 
                                                {isStaff &&
                                                    <SingleEventStaff
                                                        currentEvent={currentEvent}
                                                        idx={idx}
                                                        mode="summary"
                                                        handleOnSelect={handleOnSelect}
                                                        handleReturn={handleReturn}
                                                        submitBooking={submitBooking}
                                                        submitWaitList={submitWaitList}
                                                        submitCancel={submitCancel}
                                                        submitCancelEvent={submitCancelEvent}
                                                        manageEventMode={manageEventMode}
                                                    />
                                                }
                                                {!isStaff &&
                                                    <SingleEventSelf
                                                        user={user}
                                                        currentEvent={currentEvent}
                                                        idx={idx}
                                                        mode="summary"
                                                        credits={user.credits}
                                                        handleOnSelect={handleOnSelect}
                                                        handleReturn={handleReturn}
                                                        submitBooking={submitBooking}
                                                        submitCancel={submitCancel}
                                                    />
                                                }
                                            </div>
                                        )
                                    })}
                                </>
                            </div>
                        }
                    </div>
                }
                {singleEvent&&
                    <div className="SingleEvent">
                        <div>        
                            {isStaff &&
                                <SingleEventStaff
                                    currentEvent={events[index]}
                                    idx={index}
                                    mode="detail"
                                    handleOnSelect={handleOnSelect}
                                    handleReturn={handleReturn}
                                    submitBooking={submitBooking}
                                    submitWaitList={submitWaitList}
                                    submitCancel={submitCancel}
                                    submitCancelEvent={submitCancelEvent}
                                    manageEventMode={manageEventMode}
                                />
                            }
                            {!isStaff &&
                                <SingleEventSelf
                                    user={user}
                                    currentEvent={events[index]}
                                    idx={index}
                                    mode="detail"
                                    credits={user.credits}
                                    handleOnSelect={handleOnSelect}
                                    handleReturn={handleReturn}
                                    submitBooking={submitBooking}
                                    submitCancel={submitCancel}
                                />
                            }
                        </div>
                    </div>
                }            
            </>               
            }
            {loadingEvents &&
                 <div className="Events">
                     <h3> Loading Events. Please Wait ... </h3>
                 </div>
            }
            {!loadingEvents && !eventsAvailable &&
                <div className="Events">
                    <h3> Sorry!!! There are no events currently available for booking </h3>
                </div>    
            }
        </div>
    ) 
}

export default CurrentEvents