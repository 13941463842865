import React, { useEffect, useState } from 'react'

import CreditsEntry from './CreditsEntry'
import { requestCreditsList  } from '../../utils/fetchCredits'
import { compareValues } from '../../utils/functions'

import ExportXLS from '../../components/ExportXLS'    

import './CreditsRep.css'


/* 
    Documentation
    State Variables:
    creditsEntry    - the total set of all members 
    searchText  - user entered text to search. It will search both first and last names for contained characters
                - it can search only 1 name (no spaces)
    searchResult    - the result of the search. This array is the total list of entries that will be shown to the user (over multiple pages)
                    - it is a subset of creditsEntry. It equals the creditsEntry when no search is specified
    
    currentPage     - The page# of the current page the user is on. This is updated everytime the user navigates to a new page

    totalCurrentEntries     - the total# of entries based on the current search criteria
    pageStartEntry          - the serial# of the first item being displayed on the screen
    pageEndEntry            - the serial# of the last item being displayed on the screen
    currentPageList         - the list of entries that are shown to the user on the active page
    entriesPerPage          - user selected variable. Determines how many entries will be shown per page

    Pagination Bar:                
        This Bar is shown on the bottom right only if there is more than 1 page to diplay
        firstPage   - The Page Number associated with the first page shown on the pagenation bar 
        secondPage  - The Page Number associated with the second page shown on the pagenation bar 
        thirdPage   - The Page Number associated with the third page shown on the pagenation bar 
        showChevron - Set if there is more than 1 page to display
    
    Sort Fields
    First Name Ascending    -> firstNameSortAscending -> Default Sort Order when entering the page
    First Name Descending   -> firstNameSortDescending
    Last Name Ascending     -> lastNameSortAscending
    Last Name Descending    -> lastNameSortDescending
    id Ascending            -> idSortAscending
    id Descending           -> idSortDescending
    Note: Only 1 sort will be active at any give time

*/
const CreditsRep = ({ history, user, configValues, updateCredits, refreshCredits, match }) =>  {
    const [creditsEntry, setcreditsEntry] = useState([{}])
    const [searchText, setSearchText] = useState('')
    const [searchResult, setSearchResult] = useState([{}])
    const [firstNameSortAscending, setFirstNameSortAscending] = useState(true)
    const [firstNameSortDescending, setFirstNameSortDescending] = useState(false)
    const [lastNameSortAscending, setLastNameSortAscending] = useState(false)
    const [lastNameSortDescending, setLastNameSortDescending] = useState(false)
    const [firstPage, setFirstPage] = useState()
    const [secondPage, setSecondPage] = useState()
    const [thirdPage, setThirdPage] = useState()
    const [firstPageStyle, setFirstPageStyle] = useState('')
    const [secondPageStyle, setSecondPageStyle] = useState('')
    const [thirdPageStyle, setThirdPageStyle] = useState('')
    const [showChevron, setShowChevron] = useState(false)
    const [currentPage, setCurrentPage] = useState()
    const [totalCurrentEntries, setTotalCurrentEntries] = useState()
    const [pageStartEntry, setPageStartEntry] = useState()
    const [pageEndEntry, setPageEndEntry] = useState()
    const [currentPageList, setCurrentPageList] = useState([{}])
    const [entriesPerPage, setEntriesPerPage] = useState(10)
    const [entriesFound, setEntriesFound] = useState(true)
    

    const [authorizedUser, setAuthorizedUser] = useState(false)
    const [authProfile, setAuthProfile] = useState(false)
    const [creditDetails, setCreditDetails] = useState(false)
    const [xlsData, setXlsData] = useState()

    const [requestedSeason, setRequestedSeason] = useState()
    const [currentYear, setCurrentYear] = useState()
    
    let newEntriesPerPage = 10

    useEffect(()=> {
        if(!user){
            // child message not showing in this case. Investigate Later
            // updateChildMessage("", "Please Login to continue...", "primary", 0)
            history.push('/login')
        } else if(user.isStaff || user.isExec || user.isAdmin) {
            setAuthorizedUser(true)
            setAuthProfile(true)
            setCurrentYear(configValues.currentSeason)
            setRequestedSeason(configValues.currentSeason)
            
            updateCredits(true)
        } else {
            setAuthorizedUser(false)
            history.push('/')
        }

        setCreditDetails(false)
    },[])
    
    useEffect(() => {
        if(authorizedUser){
            setFirstNameSortAscending(true)
            setFirstNameSortDescending(false)
            setLastNameSortAscending(false)
            setLastNameSortDescending(false)
            
            //defualt to 10 entries per page
            setEntriesPerPage(10)
            
            requestCreditsList(refreshCredits, requestedSeason).then(creditsList => {
                //sort the members by first name ascending
                //console.log('creditsList', creditsList)
                //console.log('refreshing member list')

                if(creditsList && creditsList.length>0){
                    creditsList.sort(compareValues('firstName'))
                    setcreditsEntry(creditsList)
                    setSearchResult(creditsList)
                    updateCredits(false)
                    setEntriesFound(true)
                } else {
                    setEntriesFound(false)
                }
            })
        }
    }, [authorizedUser, refreshCredits, requestedSeason])   

    useEffect(()=>{
        if(authorizedUser){
            setPagination(searchResult, 1, 1, true)
        }
    },[entriesPerPage, searchResult, authorizedUser])

    const handleRefreshCredits = () => {
        updateCredits(true)
    }

    const setPagination = (list, startPage=1, thisPage=1, rebuildPage=false) => {
        let totalEntries = list.length
        
        let totalPages = Math.ceil(totalEntries / entriesPerPage)
        //console.log('params: ', totalPages, startPage, thisPage)

        if((thisPage !== currentPage) || rebuildPage){
            setCurrentPage(thisPage)
            //show the entries for this page
            setTotalCurrentEntries(totalEntries)
            let startingEntry = 0
            let endingEntry = 0
            let newCurrentPageList = [...list]

            if(totalEntries > 0){
                startingEntry = ((thisPage - 1) * entriesPerPage) + 1
                endingEntry = startingEntry + entriesPerPage - 1
                if (endingEntry > totalEntries){
                    endingEntry = totalEntries
                } 
                newCurrentPageList = [...list].slice((startingEntry-1), (endingEntry))
            }
            
            setPageStartEntry(startingEntry)
            setPageEndEntry(endingEntry)
            setCurrentPageList(newCurrentPageList)
            //console.log('current list: ', newCurrentPageList)
        }

        switch(totalPages){
            case 0:
            case 1:    
                setFirstPage(null)
                setSecondPage(null)
                setThirdPage(null)                
                setShowChevron(false)
                break
            case 2:
                setFirstPage(1)
                setSecondPage(2)
                setThirdPage(null)                
                setShowChevron(true)

                if (thisPage === 1){
                    setFirstPageStyle('active')
                    setSecondPageStyle('')
                } else {
                    setFirstPageStyle('')
                    setSecondPageStyle('active')
                }

                break
            default:
                if((totalPages > 3) && (startPage + 2) > totalPages){
                    //there are more than 3 pages. Align the last set to show the last 3 pages
                    startPage = totalPages - 2
                }

                if((totalPages > 0) && (startPage <= 0)){
                    //show the first page if there are pages to display if an invalid start page is requested
                    startPage = 1
                }

                if(startPage <= totalPages){
                    setFirstPage(startPage)
                } else {
                    setFirstPage(null)
                } 
                
                if((startPage + 1) <= totalPages){
                    setSecondPage(startPage + 1)
                } else {
                    setSecondPage(null)
                } 

                if((startPage + 2) <= totalPages){
                    setThirdPage(startPage + 2)     
                } else {
                    setThirdPage(null)
                }   
                
                if (thisPage === startPage) {
                    //first page is active
                    setFirstPageStyle('active')
                    setSecondPageStyle('')
                    setThirdPageStyle('')
                } else if (thisPage === (startPage + 1)) {
                    //second page is active
                    setFirstPageStyle('')
                    setSecondPageStyle('active')
                    setThirdPageStyle('')
                } else {
                    // third page is active
                    setFirstPageStyle('')
                    setSecondPageStyle('')
                    setThirdPageStyle('active')
                }
                setShowChevron(true)
        }
    }

    const handleFirstNameSort = () => {
        let currentMemberList = searchResult

        if(firstNameSortAscending){
            setFirstNameSortDescending(true)
            setFirstNameSortAscending(false)
            setLastNameSortAscending(false)
            setLastNameSortDescending(false)
            
            currentMemberList.sort(compareValues('firstName', 'desc'))
            setSearchResult(currentMemberList)
            setPagination(currentMemberList, 1, 1, true)
        } else {
            setFirstNameSortAscending(true)
            setFirstNameSortDescending(false)
            setLastNameSortAscending(false)
            setLastNameSortDescending(false)
            
            currentMemberList.sort(compareValues('firstName'))
            setSearchResult(currentMemberList)
            setPagination(currentMemberList, 1, 1, true)
        }
    }

    const handleLastNameSort = () => {
        let currentMemberList = searchResult

        if(lastNameSortAscending){
            setFirstNameSortDescending(false)
            setFirstNameSortAscending(false)
            setLastNameSortAscending(false)
            setLastNameSortDescending(true)
            
            currentMemberList.sort(compareValues('lastName', 'desc'))
            setSearchResult(currentMemberList)
            setPagination(currentMemberList, 1, 1, true)
        } else {
            setFirstNameSortAscending(false)
            setFirstNameSortDescending(false)
            setLastNameSortAscending(true)
            setLastNameSortDescending(false)
            
            currentMemberList.sort(compareValues('lastName'))
            setSearchResult(currentMemberList)
            setPagination(currentMemberList, 1, 1, true)
        }
    }

    const handleChange = (e) => {
        let searchValue = e.target.value
        setSearchText(searchValue)
        buildCurrentPage(searchValue)        
    }

    const buildCurrentPage = (searchName) => {
        //console.log('search name: ', searchName)
        let currentSearchResult = [{}]

        if(!searchName){
            currentSearchResult = creditsEntry 
        } else {
            //find all entries where first name part or last name part match the search criteria
            currentSearchResult = creditsEntry.filter(item => {
                if((item.firstName.toLowerCase().includes(searchName.toLowerCase())) || 
                   (item.lastName.toLowerCase().includes(searchName.toLowerCase()))) {
                    return item
                }
            })                                            
        }
        setSearchResult(currentSearchResult)
    }

    const handlePrevPage = () => {
        if(currentPage <= 1){
            //no action to be performed
            return null
        }

        if(currentPage === firstPage){
            //we are already on the first page of this list. The page numbers need to be updated 
            setFirstPage(firstPage -1)
            setSecondPage(secondPage - 1)
            setThirdPage(thirdPage - 1)
            //decrement pages in sets of 3
            setPagination(searchResult, (currentPage - 3), (currentPage - 1))
        } else {
            setPagination(searchResult, firstPage, (currentPage - 1))
        }
    }

    const handleNextPage = () => {
        let maxPage = Math.ceil( searchResult.length / entriesPerPage)

        if (currentPage >= maxPage){
            //we are already at the last page. No action needed
            return null
        }

        if(currentPage === thirdPage){
            //we are already on the first page of this list. The page numbers need to be updated 
            setFirstPage(firstPage + 1)
            setSecondPage(secondPage + 1)
            setThirdPage(thirdPage + 1)
            setPagination(searchResult, (currentPage + 1), (currentPage + 1))
        } else {
            setPagination(searchResult, firstPage, (currentPage + 1))
        }
    }

    const handlePageClick = (pageNum) => {
        if(pageNum === "1"){
            //first page clicked
            setPagination(searchResult, firstPage, firstPage)
        } else if(pageNum === "2"){
            //second page requested
            setPagination(searchResult, firstPage, secondPage)
        } else {
            // third page requested
            setPagination(searchResult, firstPage, thirdPage)
        }
    }

    const handleEntriesPerPage = (e) => {
        newEntriesPerPage = parseInt(e.target.value, 10)

        setEntriesPerPage(newEntriesPerPage)
        //setPagination(searchResult, 1, 1, true)
    }

    const onChangeHandler = (e) => {
        setRequestedSeason(e.target.value)
        updateCredits(true)
    }

    const formatDownload = () => {
        //format the event data for download
        //console.log('data-format', data)
        
        let outputData = [{}]
        
        if(creditsEntry && creditsEntry.length > 0 ){
            for(let idx=0; idx < creditsEntry.length; idx++){
                let entry = {}

                entry.FirstName = creditsEntry[idx].firstName
                entry.LastName = creditsEntry[idx].lastName
                entry.Email = creditsEntry[idx].primaryEmail
                entry.CreditsPurchased = creditsEntry[idx].purchasedCredits
                entry.CreditsAvailable = creditsEntry[idx].availableCredits
                
                if(idx === 0){
                    outputData[0] = entry
                } else {
                    //for idx > 0, use the push function
                    outputData.push(entry)
                }
            }
        }

        setXlsData(outputData)
    }

    const handleCreditDetails = (e) => {
        e.preventDefault()
        setCreditDetails(true)

        //format data
        formatDownload()
    }

    const handleFileDownloaded = () => {
        //no processing needed
    }

    return(
        <>
        {!user &&
            <></>
        }
        {user && !entriesFound &&
            <>
                <div className="Credits">
                    <h3> No credit details found </h3>

                    <div className="Filters">
                    <form className="Filterbox" >
                        <label>Reporting Season: </label>
                        <select 
                            name="season"  
                            size="1"
                            value={requestedSeason} 
                            onChange={onChangeHandler}
                        >
                            <option value={currentYear}>{currentYear} </option>
                            <option value={currentYear-1}>{currentYear-1} </option>
                            <option value={currentYear-2}>{currentYear-2} </option>
                            <option value={currentYear-3}>{currentYear-3} </option>
                        </select> 
                    </form>    
                </div>
                </div>    
            </>    
        }

        <div className="Credits">
            <div className="Header2">
                {xlsData && creditDetails && 
                    <>
                        <ExportXLS
                            csvData={xlsData}
                            fileName={`creditDetails.csv`}
                            fileDownloaded={handleFileDownloaded}
                        />
                    </>
                }
            </div>
        </div>
        
        {user && entriesFound && 
        <div className="Credits">
            <div className="Searchbar">
                <h3> Credits Report </h3>
            
                <div className="Filters">
                    <form className="Filterbox" >
                        <label>Reporting Season: </label>
                        <select 
                            name="season"  
                            size="1"
                            value={requestedSeason} 
                            onChange={onChangeHandler}
                        >
                            <option value={currentYear}>{currentYear} </option>
                            <option value={currentYear-1}>{currentYear-1} </option>
                            <option value={currentYear-2}>{currentYear-2} </option>
                            <option value={currentYear-3}>{currentYear-3} </option>
                        </select> 
                    </form>    
                </div>

                <span className="SearchCard">
                    <form className="Searchbox" >
                        <div className="InputContainer">
                            <span>
                                <input 
                                    className="InputBox"
                                    placeholder= "search members..."
                                    type="search" 
                                    value={searchText}
                                    onChange={handleChange}
                                />
                            </span>
                        </div>
                    </form>    
                </span> 
            </div>

            <div className="Memberlist">
                <div className="table-scroll">
                    <table>
                        <thead>
                            <tr>
                                <th>
                                    <span className="text" onClick={handleFirstNameSort}>First Name
                                    {firstNameSortAscending &&
                                        <span><i className="fas fa-sort-up"></i></span>
                                    }
                                    {firstNameSortDescending && 
                                        <span><i className="fas fa-sort-down"></i></span>
                                    }
                                    </span>
                                </th>
                                <th>
                                    <span className="text" onClick={handleLastNameSort}>Last Name
                                    {lastNameSortAscending &&
                                        <span><i className="fas fa-sort-up"></i></span>
                                    }
                                    {lastNameSortDescending && 
                                        <span><i className="fas fa-sort-down"></i></span>
                                    }
                                    </span>
                                </th>

                                <th><span className="text">Credits Purchased</span></th>
                                <th><span className="text">Credits Balance</span></th>
                            </tr>
                        </thead>

                        <tbody>
                            <CreditsEntry
                                creditsList={currentPageList}
                                authProfile={authProfile}
                            />
                        </tbody>               
                    </table>                    
                </div>    
            </div>
            <div className="pagefooter">
                <div className="PageSelection"> 
                    <form>   
                        <select 
                            name="perPage"  
                            size="1"
                            value={entriesPerPage} 
                            onChange={(e)=>{handleEntriesPerPage(e)}}
                        >
                            <option value="10">10 </option>
                            <option value="20">20 </option>
                            <option value="50">50 </option>
                        </select> 
                        <label htmlFor="perPage"> ...entries per page</label>
                    </form>                
                </div>

                <div> 
                    Showing {pageStartEntry} to {pageEndEntry} of {totalCurrentEntries}  
                </div>

                <div className="pagination">
                    {showChevron && 
                        <span onClick={handlePrevPage}>&laquo;</span>
                    }    
                    {firstPage &&
                        <span 
                            className={firstPageStyle}
                            onClick={()=>{handlePageClick("1")}}
                        >
                            {firstPage} 
                        </span>
                    }
                    {secondPage &&
                        <span 
                            className={secondPageStyle}
                            onClick={()=>{handlePageClick("2")}}
                        >
                            {secondPage} 
                        </span>
                    }
                    {thirdPage &&
                        <span 
                            className={thirdPageStyle}
                            onClick={()=>{handlePageClick("3")}}
                        >
                            {thirdPage} 
                        </span>
                    }
                
                    {showChevron &&
                        <span onClick={handleNextPage}>&raquo;</span>
                    }    
                </div>
            </div>
            
            {!creditDetails &&
                <div className="bottomButtons">
                    <button className="creditDetails" onClick={handleCreditDetails}> Generate Credit Details </button>
                    <button className="creditDetails" onClick={handleRefreshCredits}> Refresh Credits List </button>
                </div>
            }
            
            {creditDetails &&
                <>
                    Click on the Excel Icon above to download the credit details
                </>
            }
        </div>
        }
        </>
    )
}  

export default CreditsRep
