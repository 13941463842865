import React from 'react'
import { Link } from 'react-router-dom';

import {fromImageToUrl} from '../utils/urls'

const AddNewEvent = () => {
    return(
        <div className="card">
            <Link to="/events/create">
                <div className="container">
                    <h5 className="EventTitle" >Add New Event</h5> 
                    <p></p>
                    <h1 className="EventTitle" > 
                        <i class="fas fa-plus"></i> 
                    </h1>
                    
                    <img src={fromImageToUrl('', 'event')} alt="Add Event"/>
                </div>        
            </Link>
        </div>
    ) 
}

export default AddNewEvent