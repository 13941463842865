import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import axios from 'axios'

import EventEntry from './EventEntry'
import { compareValues, logAxiosError, getNtrpLabel  } from '../../utils/functions'
import { 
        EVENTS_SINGLE_URL,
        EVENTS_STAFF_REGISTER_URL,
        EVENTS_STAFF_CANCEL_URL,
        EVENTS_STAFF_WAITLIST_URL,
        EVENTS_STAFF_ATTENDED_URL
    } from '../../utils/urls'

import { AXIOS_TIMEOUT } from '../../utils/constants'

import ExportXLS from '../../components/ExportXLS'    

//Testing
//import testPhoneList from '../../utils/eventList.json'

import './EventList.css'

/* 
    Documentation
    State Variables:
    eventListEntries    - the total set of all eventList entries 
    searchText  - user entered text to search. It will search both first and last names for contained characters
                - it can search only 1 name (no spaces)
    searchResult    - the result of the search. This array is the total list of entries that will be shown to the user (over multiple pages)
                    - it is a subset of eventListEntries. It equals the eventListEntries when no search is specified
    
    currentPage     - The page# of the current page the user is on. This is updated everytime the user navigates to a new page

    totalCurrentEntries     - the total# of entries based on the current search criteria
    pageStartEntry          - the serial# of the first item being displayed on the screen
    pageEndEntry            - the serial# of the last item being displayed on the screen
    currentPageList         - the list of entries that are shown to the user on the active page
    entriesPerPage          - user selected variable. Determines how many entries will be shown per page

    Pagination Bar:                
        This Bar is shown on the bottom right only if there is more than 1 page to diplay
        firstPage   - The Page Number associated with the first page shown on the pagenation bar 
        secondPage  - The Page Number associated with the second page shown on the pagenation bar 
        thirdPage   - The Page Number associated with the third page shown on the pagenation bar 
        showChevron - Set if there is more than 1 page to display
    
    Sort Fields
    First Name Ascending    -> firstNameSortAscending -> Default Sort Order when entering the page
    First Name Descending   -> firstNameSortDescending
    Last Name Ascending     -> lastNameSortAscending
    Last Name Descending    -> lastNameSortDescending
    Note: Only 1 sort will be active at any give time

*/
const EventList = ({ history, user, match, updateChildMessage }) =>  {
    const [eventListEntries, setEventListEntries] = useState([{}])
    const [searchText, setSearchText] = useState('')
    const [searchResult, setSearchResult] = useState([{}])
    const [statusSortAscending, setStatusSortAscending] = useState(true)
    const [statusSortDescending, setStatusSortDescending] = useState(false)
    const [firstNameSortAscending, setFirstNameSortAscending] = useState(false)
    const [firstNameSortDescending, setFirstNameSortDescending] = useState(false)
    const [lastNameSortAscending, setLastNameSortAscending] = useState(false)
    const [lastNameSortDescending, setLastNameSortDescending] = useState(false)
    const [firstPage, setFirstPage] = useState()
    const [secondPage, setSecondPage] = useState()
    const [thirdPage, setThirdPage] = useState()
    const [firstPageStyle, setFirstPageStyle] = useState('')
    const [secondPageStyle, setSecondPageStyle] = useState('')
    const [thirdPageStyle, setThirdPageStyle] = useState('')
    const [showChevron, setShowChevron] = useState(false)
    const [currentPage, setCurrentPage] = useState()
    const [totalCurrentEntries, setTotalCurrentEntries] = useState()
    const [pageStartEntry, setPageStartEntry] = useState()
    const [pageEndEntry, setPageEndEntry] = useState()
    const [currentPageList, setCurrentPageList] = useState([{}])
    const [entriesPerPage, setEntriesPerPage] = useState(10)
    const [entriesFound, setEntriesFound] = useState(true)
    const [promotionSpotsAvailable, setPromotionSpotsAvailable] = useState()
    const [refresh, setRefresh] = useState()
    const [xlsData, setXlsData] = useState()
    const [eventIsToday, setEventIsToday] = useState(false)
    const [authorizedUser, setAuthorizedUser] = useState(false)

    let eventId = match.params.id
    let eventTitle = match.params.title
    let selectedEvent = {}
    let eventMsg = ''
    let pSpotsAvailable = false
    let rSpotsAvialable = false
    let wSpotsAvailable = false

    //console.log('promotionSpotsAvailable', promotionSpotsAvailable)

    let newEntriesPerPage = 10

    // noshow and attended buttons are only show on or after the event date
    
    useEffect(()=> {
        if(!user){
            // child message not showing in this case. Investigate Later
            // updateChildMessage("", "Please Login to continue...", "primary", 0)
            history.push('/login')
        } else if(user.isStaff || user.isAdmin) {
            setAuthorizedUser(true)
        } else {
            setAuthorizedUser(false)
            history.push('/')
        }
    },[])

    useEffect(() => {
        if(authorizedUser){
            setStatusSortAscending(true)
            setStatusSortDescending(false)
            setFirstNameSortAscending(false)
            setFirstNameSortDescending(false)
            setLastNameSortAscending(false)
            setLastNameSortDescending(false)
            setRefresh(false)

            //defualt to 10 entries per page
            setEntriesPerPage(10)

            const getEventList = async () => {
                let res
                let processRequest = true

                try {
                    res = await axios({
                        method: 'GET',
                        timeout: AXIOS_TIMEOUT,
                        url: EVENTS_SINGLE_URL,
                        params: {
                            eventId: eventId
                        }
                    })          
                } catch (error) {
                    processRequest = false
                    console.log('Error Fetching Event')            
                    logAxiosError(error)
                    setEntriesFound(false)
                }

                if(processRequest){
                    const {data} = res
                    //console.log('res: ', res)

                    selectedEvent = data.data.selectedEvent
                    //console.log('eventData', selectedEvent)
                    
                    //check if the event is happening today
                    let eventDateTime = new Date(selectedEvent.eventStartDateTime)
                    let eventYear = eventDateTime.getFullYear()
                    let eventMonth = eventDateTime.getMonth()
                    let eventDate = eventDateTime.getDate()

                    let now = new Date()
                    let nowYear = now.getFullYear()
                    let nowMonth = now.getMonth()
                    let nowDate = now.getDate()

                    /*
                    if((eventYear === nowYear)
                        && (eventMonth === nowMonth)
                        && (eventDate === nowDate)
                    ){
                        setEventIsToday(true)
                    } else {
                        setEventIsToday(false)
                    }
                    */

                    // set event-is-today when the event is happening today or has just passed
                    if((nowYear >= eventYear)
                        && (nowMonth >= eventMonth)
                        && (nowDate >= eventDate)
                    ){
                        setEventIsToday(true)
                    } else {
                        setEventIsToday(false)
                    }

                    EventInfo(selectedEvent)

                    if(eventMsg > ' '){
                        updateChildMessage('', eventMsg, 'primary', 8000)
                    }

                    const rawEventData =  data.data.participants   
                    const eventData =  formatData(rawEventData)

                    eventData.sort(compareValues('displayStatus'))

                    //create downloadable csv file
                    formatDownload(eventData)
                    
                    setEventListEntries(eventData)
                    setSearchResult(eventData)
                    //updateeventList(false)
                    setEntriesFound(true)
                }
            }

            getEventList()           
        }
    }, [refresh, authorizedUser])   

    useEffect(()=>{
        if(authorizedUser){
            setPagination(searchResult, 1, 1, true)
        }
    },[entriesPerPage, searchResult, authorizedUser])   

    const formatData = (inputData) => {
        //concatenate status and Registration Number into a single "Status" field
        let outputData = [...inputData]
        let confirmedNumberString = ''

        if(outputData.length > 0){
            for(let idx=0; idx<outputData.length; idx++){
                confirmedNumberString = outputData[idx].confirmedNumber.toString()
                if(confirmedNumberString.length === 1){
                    confirmedNumberString = "00" + confirmedNumberString
                } else if(confirmedNumberString.length === 2){
                    confirmedNumberString = "0" + confirmedNumberString
                } else {
                    //leave the number as is
                }

                outputData[idx].displayStatus = outputData[idx].status + "-" + confirmedNumberString
            }
        }

        return outputData
    }

    const formatDownload = (data) => {
        //format the event data for download
        //console.log('data-format', data)
        
        let outputData = [{}]
        
        if(data && data.length > 0 ){
            for(let idx=0; idx < data.length; idx++){
                let entry = {}

                //console.log('data-idx', data[idx])
                
                entry.FirstName = data[idx].firstName
                entry.LastName = data[idx].lastName
                entry.Status = data[idx].status
                
                if(data[idx].status === "waitlist"){
                    entry.WaitList = data[idx].waitlistNumber
                } else {
                    entry.WaitList = 0
                }

                entry.Mode = data[idx].registerationMode
                entry.EntryEmail = data[idx].userEmail
                entry.CreditsPaid = data[idx].tokensPaid
                entry.PhoneNumber = data[idx].phoneNumber
                entry.MemberEmail = data[idx].email
                entry.NtrpSelf = getNtrpLabel(data[idx].ntrpSelf)
                entry.NtrpClubAssessed = getNtrpLabel(data[idx].ntrpAssessed)

                if(idx === 0){
                    outputData[0] = entry
                } else {
                    //for idx > 0, use the push function
                    outputData.push(entry)
                }
            }
        }
        
        setXlsData(outputData)
    }

    const EventInfo = (e) => {
        //takes eventInfo parameter "e"

        if(e.currentParticipants < e.maxParticipants){
            let r =  e.maxParticipants - e.currentParticipants
            if(e.currentWaitingList === 0){
                //r = regularSpots
                if(r === 1){
                    eventMsg = '1 spot is available. '
                    //setEventMessage('1 spot is available')    
                } else {
                    eventMsg = r + ' spots are available. '
                    //setEventMessage(r + 'spots are available')
                }
                rSpotsAvialable = true    
            } else {
                //there are members on the waitlst
                if(r === 1){
                    eventMsg = '1 spot is available for Wait Listed members. '
                    //setEventMessage('1 spot is available')    
                } else {
                    eventMsg = r + ' spots are available for Wait Listed members. '
                    //setEventMessage(r + 'spots are available')
                }
                //setEventMessage(r + 'spots are available for Wait Listed members')
                pSpotsAvailable = true
            }
        }

        if(e.enableWaitlist && !rSpotsAvialable){
            //if no regular spots are available, show waitlist spots (if available)
            let w = (e.maxParticipants + e.waitlistLimit)
                - (e.currentParticipants + e.currentWaitingList)  
            
            if(w === 1){
                eventMsg = eventMsg + '1 waitlist spot is available'
                //setEventMessage('1 waitlist spot is available')    
            } else {
                eventMsg = eventMsg + w + ' waitlist spots are available'
                //setEventMessage(w + 'waitlist spots are available')
            }
            wSpotsAvailable = true    
        }

        if((e.maxParticipants + e.waitlistLimit) === (e.currentParticipants + e.currentWaitingList)){
            eventMsg = 'Event is Full'
        }

        setPromotionSpotsAvailable(pSpotsAvailable)
    }

    
    const handleConfirmCancel = (memberId) => {
        //console.log('confirm-cancel:', memberId)
        submitRequest("cancellation", memberId)
    }

    const handleConfirmWaitlist = (memberId) => {
        //console.log('confirm:', memberId)
        submitRequest("waitlist", memberId)
    }

    const handleAttended = (memberId) => {
        //console.log('confirm:', memberId)
        submitRequest("attended", memberId)
    }

    const handleNoshow = (memberId) => {
        //console.log('confirm:', memberId)
        submitRequest("noshow", memberId)
    }
 
    const submitRequest = async (request, memberId) => {
        //console.log('submitting PUT request')
        let URL = ''

        if(request === "register"){
            URL = EVENTS_STAFF_REGISTER_URL
        } else if (request === "cancellation"){
            URL = EVENTS_STAFF_CANCEL_URL
        } else if(request === "waitlist"){
            URL = EVENTS_STAFF_WAITLIST_URL
        }  else if (request === "noshow"){
            URL = EVENTS_STAFF_CANCEL_URL
        }  else if (request === "attended"){
            URL = EVENTS_STAFF_ATTENDED_URL
        }        

        //console.log('event-id', eventId)

        const postRequest = async () => {
            let res
            let processRequest = true

            try{
                res = await axios({
                    method: 'POST',
                    timeout: AXIOS_TIMEOUT,
                    url: URL,
                    data: {
                        memberId: memberId,
                        eventId: eventId,
                        request: request
                    }
                })

               //console.log('event post res', res)
            } catch (error) {
                console.log('Error posting event request', URL)
                logAxiosError(error)       
                processRequest = false
            }
            
            //display messages upon return
            //console.log('res', res)
            if(processRequest){
                let { data } = res.data
                setRefresh(true)
                
                if(data && data.processLog.success){
                    if(request === "register"){
                        updateChildMessage('Success', 'Booking successful', 'success', 5000)
                    } else if(request === "waitlist"){
                        updateChildMessage('Success', 'Promotion successful', 'success', 5000)
                    } else if(request === "noshow"){
                        updateChildMessage('Success', 'Member marked as No-Show', 'success', 5000)
                    } else if(request === "attended"){
                        updateChildMessage('Success', 'Member marked as Attended', 'success', 5000)
                    } else {
                        updateChildMessage('Success', 'Cancellation successful', 'success', 5000)
                    }
                } else{
                    //check the status and put out appropriate message
                    let msg = 'Booking Failed -> '
                    let errorMessage = 'Sorry !!! Booking Failed - Unknown Reason'
                    console.log('error-data', data)
                    if(data){
                        if(data.processLog.failedUpdatingEvent){
                            errorMessage = msg + "System busy - Please try again later"
                        }
                        if(data.processLog.onlineBookingFull){
                            errorMessage = msg + "Online Booking Full"
                        }
                        if(data.processLog.insuffficientCredits){
                            errorMessage = msg + "Member has insufficient credits"
                        }
                    } else {
                        errorMessage = "System Failure. Please try again later"
                    }

                    updateChildMessage('Error', errorMessage, 'danger', 5000)
                }
            }
        }
        postRequest()    
    }

    const setPagination = (list, startPage=1, thisPage=1, rebuildPage=false) => {
        let totalEntries = list.length
        
        let totalPages = Math.ceil(totalEntries / entriesPerPage)
        //console.log('params: ', totalPages, startPage, thisPage)

        if((thisPage !== currentPage) || rebuildPage){
            setCurrentPage(thisPage)
            //show the entries for this page
            setTotalCurrentEntries(totalEntries)
            let startingEntry = 0
            let endingEntry = 0
            let newCurrentPageList = [...list]

            if(totalEntries > 0){
                startingEntry = ((thisPage - 1) * entriesPerPage) + 1
                endingEntry = startingEntry + entriesPerPage - 1
                if (endingEntry > totalEntries){
                    endingEntry = totalEntries
                } 
                newCurrentPageList = [...list].slice((startingEntry-1), (endingEntry))
            }
            
            setPageStartEntry(startingEntry)
            setPageEndEntry(endingEntry)
            setCurrentPageList(newCurrentPageList)
            //console.log('current list: ', newCurrentPageList)
        }

        switch(totalPages){
            case 0:
            case 1:    
                setFirstPage(null)
                setSecondPage(null)
                setThirdPage(null)                
                setShowChevron(false)
                break
            case 2:
                setFirstPage(1)
                setSecondPage(2)
                setThirdPage(null)                
                setShowChevron(true)

                if (thisPage === 1){
                    setFirstPageStyle('active')
                    setSecondPageStyle('')
                } else {
                    setFirstPageStyle('')
                    setSecondPageStyle('active')
                }

                break
            default:
                if((totalPages > 3) && (startPage + 2) > totalPages){
                    //there are more than 3 pages. Align the last set to show the last 3 pages
                    startPage = totalPages - 2
                }

                if((totalPages > 0) && (startPage <= 0)){
                    //show the first page if there are pages to display if an invalid start page is requested
                    startPage = 1
                }

                if(startPage <= totalPages){
                    setFirstPage(startPage)
                } else {
                    setFirstPage(null)
                } 
                
                if((startPage + 1) <= totalPages){
                    setSecondPage(startPage + 1)
                } else {
                    setSecondPage(null)
                } 

                if((startPage + 2) <= totalPages){
                    setThirdPage(startPage + 2)     
                } else {
                    setThirdPage(null)
                }   
                
                if (thisPage === startPage) {
                    //first page is active
                    setFirstPageStyle('active')
                    setSecondPageStyle('')
                    setThirdPageStyle('')
                } else if (thisPage === (startPage + 1)) {
                    //second page is active
                    setFirstPageStyle('')
                    setSecondPageStyle('active')
                    setThirdPageStyle('')
                } else {
                    // third page is active
                    setFirstPageStyle('')
                    setSecondPageStyle('')
                    setThirdPageStyle('active')
                }
                setShowChevron(true)
        }
    }

    const handleFirstNameSort = () => {
        let currentEventList = searchResult

        if(firstNameSortAscending){
            setFirstNameSortDescending(true)
            setFirstNameSortAscending(false)
            setLastNameSortAscending(false)
            setLastNameSortDescending(false)
            setStatusSortAscending(false)
            setStatusSortDescending(false)

            currentEventList.sort(compareValues('firstName', 'desc'))
            setSearchResult(currentEventList)
            setPagination(currentEventList, 1, 1, true)
        } else {
            setFirstNameSortAscending(true)
            setFirstNameSortDescending(false)
            setLastNameSortAscending(false)
            setLastNameSortDescending(false)
            setStatusSortAscending(false)
            setStatusSortDescending(false)

            currentEventList.sort(compareValues('firstName'))
            setSearchResult(currentEventList)
            setPagination(currentEventList, 1, 1, true)
        }
    }

    const handleLastNameSort = () => {
        let currentEventList = searchResult

        if(lastNameSortAscending){
            setFirstNameSortDescending(false)
            setFirstNameSortAscending(false)
            setLastNameSortAscending(false)
            setLastNameSortDescending(true)
            setStatusSortAscending(false)
            setStatusSortDescending(false)

            currentEventList.sort(compareValues('lastName', 'desc'))
            setSearchResult(currentEventList)
            setPagination(currentEventList, 1, 1, true)
        } else {
            setFirstNameSortAscending(false)
            setFirstNameSortDescending(false)
            setLastNameSortAscending(true)
            setLastNameSortDescending(false)
            setStatusSortAscending(false)
            setStatusSortDescending(false)

            currentEventList.sort(compareValues('lastName'))
            setSearchResult(currentEventList)
            setPagination(currentEventList, 1, 1, true)
        }
    }

    const handleStatusSort = () => {
        let currentEventList = searchResult

        if(statusSortAscending){
            setFirstNameSortDescending(false)
            setFirstNameSortAscending(false)
            setLastNameSortAscending(false)
            setLastNameSortDescending(false)
            setStatusSortAscending(false)
            setStatusSortDescending(true)

            currentEventList.sort(compareValues('displayStatus', 'desc'))
            setSearchResult(currentEventList)
            setPagination(currentEventList, 1, 1, true)
        } else {
            setFirstNameSortAscending(false)
            setFirstNameSortDescending(false)
            setLastNameSortAscending(false)
            setLastNameSortDescending(false)
            setStatusSortAscending(true)
            setStatusSortDescending(false)

            currentEventList.sort(compareValues('displayStatus'))
            setSearchResult(currentEventList)
            setPagination(currentEventList, 1, 1, true)
        }
    }

    const handleChange = (e) => {
        let searchValue = e.target.value
        setSearchText(searchValue)
        buildCurrentPage(searchValue)        
    }

    const buildCurrentPage = (searchName) => {
        //console.log('search name: ', searchName)
        let currentSearchResult = [{}]

        if(!searchName){
            currentSearchResult = eventListEntries 
        } else {
            //find all entries where first name part or last name part match the search criteria
            currentSearchResult = eventListEntries.filter(item => {
                if((item.firstName.toLowerCase().includes(searchName.toLowerCase())) || 
                   (item.lastName.toLowerCase().includes(searchName.toLowerCase()))) {
                    return item
                }
            })                                            
        }
        setSearchResult(currentSearchResult)
    }

    const handlePrevPage = () => {
        if(currentPage <= 1){
            //no action to be performed
            return null
        }

        if(currentPage === firstPage){
            //we are already on the first page of this list. The page numbers need to be updated 
            setFirstPage(firstPage -1)
            setSecondPage(secondPage - 1)
            setThirdPage(thirdPage - 1)
            //decrement pages in sets of 3
            setPagination(searchResult, (currentPage - 3), (currentPage - 1))
        } else {
            setPagination(searchResult, firstPage, (currentPage - 1))
        }
    }

    const handleNextPage = () => {
        let maxPage = Math.ceil( searchResult.length / entriesPerPage)

        if (currentPage >= maxPage){
            //we are already at the last page. No action needed
            return null
        }

        if(currentPage === thirdPage){
            //we are already on the first page of this list. The page numbers need to be updated 
            setFirstPage(firstPage + 1)
            setSecondPage(secondPage + 1)
            setThirdPage(thirdPage + 1)
            setPagination(searchResult, (currentPage + 1), (currentPage + 1))
        } else {
            setPagination(searchResult, firstPage, (currentPage + 1))
        }
    }

    const handlePageClick = (pageNum) => {
        if(pageNum === "1"){
            //first page clicked
            setPagination(searchResult, firstPage, firstPage)
        } else if(pageNum === "2"){
            //second page requested
            setPagination(searchResult, firstPage, secondPage)
        } else {
            // third page requested
            setPagination(searchResult, firstPage, thirdPage)
        }
    }

    const handleEntriesPerPage = (e) => {
        newEntriesPerPage = parseInt(e.target.value, 10)

        setEntriesPerPage(newEntriesPerPage)
        //setPagination(searchResult, 1, 1, true)
    }

    const handleFileDownloaded = () => {
        //add any action needed after the excel button has been clicked. 
        //currently no action needed here
    }

    return(
        <>
        {user && !entriesFound &&
            <div className="eventList">
                <h3> No members found </h3>
            </div>    
        }
        {user && entriesFound &&
        <div className="EventList">
            <div className="Searchbar">
                <h3> Event Info </h3>
                <span className="EventInfo"> 
                    <h4>{eventTitle} </h4>
                </span>
                <span className="SearchCard">
                    <form className="Searchbox" >
                        <div className="InputContainer">
                            <span>
                                <input 
                                    className="InputBox"
                                    placeholder= "search members..."
                                    type="search" 
                                    value={searchText}
                                    onChange={handleChange}
                                />
                            </span>
                        </div>
                    </form>    
                </span> 
            </div>
            
            <div className="Header2">
                <Link to={`/staff/memberlist/${eventId}`} className="link">
                    <i className="fas fa-plus-circle"></i> Add Member
                </Link> 

                {xlsData &&
                    <ExportXLS
                    csvData={xlsData}
                    fileName={`${eventTitle}.csv`}
                    fileDownloaded={handleFileDownloaded}
                />
                }
            </div>

            <div className="EventList">
                <div className="table-scroll">
                    <table>
                        <thead>
                            <tr>
                                <th>
                                    <span className="text" onClick={handleFirstNameSort}>First Name
                                    {firstNameSortAscending &&
                                        <span><i className="fas fa-sort-up"></i></span>
                                    }
                                    {firstNameSortDescending && 
                                        <span><i className="fas fa-sort-down"></i></span>
                                    }
                                    </span>
                                </th>
                                <th>
                                    <span className="text" onClick={handleLastNameSort}>Last Name
                                    {lastNameSortAscending &&
                                        <span><i className="fas fa-sort-up"></i></span>
                                    }
                                    {lastNameSortDescending && 
                                        <span><i className="fas fa-sort-down"></i></span>
                                    }
                                    </span>
                                </th>

                                <th>
                                    <span className="text" onClick={handleStatusSort}>Status
                                    {statusSortAscending &&
                                        <span><i className="fas fa-sort-up"></i></span>
                                    }
                                    {statusSortDescending && 
                                        <span><i className="fas fa-sort-down"></i></span>
                                    }
                                    </span>
                                </th>
                                <th>
                                    <span className="text">Action</span>
                                </th>
                            </tr>
                        </thead>

                        <tbody>
                            <EventEntry
                                EventListItem={currentPageList}
                                promotionSpotsAvailable={promotionSpotsAvailable}
                                handleConfirmCancel={handleConfirmCancel}
                                handleConfirmWaitlist={handleConfirmWaitlist}
                                handleAttended={handleAttended}
                                handleNoshow={handleNoshow}
                                eventIsToday={eventIsToday}
                            />
                        </tbody>               
                    </table>                    
                </div>    
            </div>
            <div className="pagefooter">
                <div className="PageSelection"> 
                    <form>   
                        <select 
                            name="perPage"  
                            size="1"
                            value={entriesPerPage} 
                            onChange={(e)=>{handleEntriesPerPage(e)}}
                        >
                            <option value="10">10 </option>
                            <option value="20">20 </option>
                            <option value="50">50 </option>
                        </select> 
                        <label htmlFor="perPage"> ...entries per page</label>
                    </form>                
                </div>

                <div> 
                    Showing {pageStartEntry} to {pageEndEntry} of {totalCurrentEntries}  
                </div>

                <div className="pagination">
                    {showChevron && 
                        <span onClick={handlePrevPage}>&laquo;</span>
                    }    
                    {firstPage &&
                        <span 
                            className={firstPageStyle}
                            onClick={()=>{handlePageClick("1")}}
                        >
                            {firstPage} 
                        </span>
                    }
                    {secondPage &&
                        <span 
                            className={secondPageStyle}
                            onClick={()=>{handlePageClick("2")}}
                        >
                            {secondPage} 
                        </span>
                    }
                    {thirdPage &&
                        <span 
                            className={thirdPageStyle}
                            onClick={()=>{handlePageClick("3")}}
                        >
                            {thirdPage} 
                        </span>
                    }
                
                    {showChevron &&
                        <span onClick={handleNextPage}>&raquo;</span>
                    }    
                </div>
            </div>
            
        </div>
        }
        </>
    )
}  

export default EventList
