import React from 'react'

const ContactDetailsReadOnly = (props) => {
    const { 
        firstName,
        lastName,
        email,
        address,
        city,
        postal,
        primaryPhone,
        emergencyContact,
        emergencyPhone
    } = props

    return(
        <>
            <div className="section"><span>1</span>Login Details</div>
            <div className="inner-wrap">
                <label>First Name </label>
                <p className="readonly">{firstName}</p>

                <label>Last Name </label>
                <p className="readonly">{lastName}</p>

                <label>Email </label>
                <p className="readonly">{email}</p>
            </div>

            <div className="section"><span>2</span>Address & Phone</div>
            <div className="inner-wrap">
                <label>Address</label>
                <p className="readonly">{address}</p>
                
                <label>City</label>
                <p className="readonly">{city}</p>
                
                <label>Postal Code</label>
                <p className="readonly">{postal}</p>
                
                <label>Primary Phone</label>
                <p className="readonly">{primaryPhone}</p>
            </div>

            <div className="section"><span>3</span>Emergency Contact Information</div>
            <div className="inner-wrap">
                <label>Emergency Contact Full Name</label>
                <p className="readonly">{emergencyContact}</p>
                
                <label>Emergency Contact Phone</label>
                <p className="readonly">{emergencyPhone}</p>
            </div>
        </>  
    )
}

export default ContactDetailsReadOnly