import React, { useEffect, useState } from 'react'
import axios from 'axios'

import{ MEMBER_REGISTER_URL, 
        STAFF_UPDATE_APPROVAL_URL}
        from '../../utils/urls'

import { logAxiosError } from '../../utils/functions';
import { AXIOS_TIMEOUT } from '../../utils/constants'

import './RegistrationForm.css'

import ViewMembers from "./ViewMembers"

const UpdateWaitlistMembership = (props) =>  {

    let { user, history, updateChildMessage, match, updateWaitlist } = props

    const [address, setAddress] = useState()
    const [city, setCity] = useState()
    const [postal, setPostal] = useState()
    const [primaryEmail, setPrimaryEmail] = useState()
    const [primaryPhone, setPrimaryPhone] = useState()
    const [adults, setAdults] = useState()
    const [juniors, setJuniors] = useState()
    const [membership, setMembership] = useState()
    const [applicationApproved, setApplicationApproved] = useState()
    const [viewMembers, setViewMembers] = useState({})
    const [authorizedUser, setAuthorizedUser] = useState(false)
    const [sendEmail, setSendEmail] = useState()
    const [approvalInfoText, setApprovalInfoText] = useState()
    const [approvalButtonText, setApprovalButtonText] = useState()
    const [showMembers, setShowMembers] = useState(false)
    const [renewalId, setRenewalId] = useState(0)
    const [applicantId, setApplicantId] = useState()

    let processRequest = true

    useEffect(()=> {
        if(!user){
            updateChildMessage("", "Please Login to continue...", "primary", 3000)
            history.push('/login')
        } else if(user.isStaff || user.isExec || user.isInstructor || user.isAdmin) {
            setAuthorizedUser(true)
            setSendEmail(false)
        } else {
            setAuthorizedUser(false)
            history.push('/')
        }
    },[])

    useEffect(()=>{
        if(applicationApproved){
            setApprovalInfoText('This application has been approved')
            setApprovalButtonText('Revoke Approval')
        } else {
            setApprovalInfoText('This application is pending approval')
            setApprovalButtonText('Approve Application')                
        }    
    },[applicationApproved])

    useEffect(() => {
        if(authorizedUser){
            const getRegisterationData = async () => {
                let res 
                processRequest = true
                let membershipId = 0

                if(match){
                    membershipId = match.params.membershipId

                    setApplicantId(match.params.applicantId)

                    if(match.params.renewalId && match.params.renewalId > 1){
                        setRenewalId(match.params.renewalId)
                        setApplicationApproved(true)
                    } else {
                        setRenewalId(0)
                        setApplicationApproved(false)
                    }
                } 

                try {
                    res = await axios({
                        method: 'GET',
                        timeout: AXIOS_TIMEOUT,
                        url: MEMBER_REGISTER_URL,
                        params: {
                            membershipId: membershipId
                        }
                    })
                } catch (error) {
                    console.log('Error fetching membership information')
                    logAxiosError(error)       
                    processRequest = false
                }
                
                if(processRequest){
                    const {data} = res

                    const { members, membership } = data.data

                    //console.log('membership ', membership)

                    //populate the fields

                    setAddress(membership.address)
                    setCity(membership.city)
                    setPostal(membership.postalCode)
                    setPrimaryEmail(membership.primaryEmail)
                    setPrimaryPhone(membership.primaryPhone)
                    
                    setAdults(membership.totalAdults)
                    setJuniors(membership.totalJuniors)
                    
                    setViewMembers(members)  

                    if (members.length > 0){
                        setShowMembers(true)
                    } else {
                        setShowMembers(false)
                    }
                }
            }  
            getRegisterationData()
        }
    }, [authorizedUser])   
   
    let membershipLabel = "Adult"

    //determine the value for membership label
    if(membership){
        switch (membership) {
            case "single":
              membershipLabel = "Adult"
              break;
            case "couple":
              membershipLabel = "Couple"
              break;
            case "family":
              membershipLabel = "Family"
              break;
            case "junior":
              membershipLabel = "Junior"
              break;
            case "double":
              membershipLabel = "Single Adult and Single Junior"
              break;
            default:
              membershipLabel = "Adult"  
          }
    }

    /*
    const handleReturnHome = () => {
        history.push('/')
    }
    */

    const onChangeHandlerEmail = (e) => {
        setSendEmail(e.target.checked)
    }

    const handleApproval = async (e) => {
        //toggle between approving and revoking approval
        e.preventDefault()

        let res
        let processRequest = true
        let updatedApplicationApproved = !applicationApproved
                
        try{
            res = await axios({
                method: 'POST',
                timeout: AXIOS_TIMEOUT,
                url: STAFF_UPDATE_APPROVAL_URL,
                data: {
                    applicantId: applicantId,
                    applicationApproved: updatedApplicationApproved,
                    sendEmail: sendEmail
                }
            })
        } catch (error){
            console.log('Error updating tags')
            logAxiosError(error)       
            processRequest = false
        }
                
        if(processRequest){
            let { data }= res.data

            if(data.status.success){
                if(updatedApplicationApproved){
                    updateChildMessage("Success", "Applicant approved successfully", "success",5000)
                } else {
                    updateChildMessage("Success", "Application revoked successfully", "success",5000)
                }
                
                updateWaitlist(true)
                //set the flag based on the renewal# returned
                setApplicationApproved(updatedApplicationApproved)
            } else {
                updateChildMessage("Error", "Waitlist update failed", "danger", 5000)
            }
            //scroll to the top of the page
            window.scroll(0,0)
        }
    }

    return(
        <div className="form-style-10">
            <h1>Waitlist Applicant Details<span>View, Approve or Revoke application </span></h1>
            
            <form onSubmit={handleApproval}>
                <div className="section"><span>1</span>Address & Contact Information</div>
                <div className="inner-wrap">
                    <label>Primary Email </label>
                    <p className="readonly">{primaryEmail}</p>
                
                    <label>Primary Phone </label>
                    <p className="readonly">{primaryPhone}</p>

                    <label>Address </label>
                    <p className="readonly">{address}</p>

                    <label>City </label>
                    <p className="readonly">{city}</p>

                    <label>Postal Code </label>
                    <p className="readonly">{postal}</p>
                </div>                

                <div className="section"><span>2</span>Summary</div>
                <div className="inner-wrap">
                    <label>Number of Adults</label>
                    <p className="readonly">{adults}</p>

                    <label>Number of Juniors</label>
                    <p className="readonly">{juniors}</p>
                </div>    
                
                <div className="section"><span>3</span>Requested Applicants</div>
                {showMembers &&
                    <ViewMembers 
                        members={viewMembers} 
                    />
                }

                <div className="section"><span>T</span>Approve/Reject Application</div>
                <div className="inner-wrap">
                    <p> {approvalInfoText} </p>
                </div>
                
                {(user.isStaff || user.isAdmin || user.isExec) &&
                <>
                    <div className="button-section">
                        <label className="checkbox" htmlFor="sendEmail">
                            Automatically notify applicant via email

                            <input 
                                type="checkbox" 
                                name="sendEmail"
                                checked={sendEmail}
                                onChange={onChangeHandlerEmail}
                            /> 
                        </label>

                        <button type="submit"> {approvalButtonText} </button>
                    </div>    
                </>
                }
            </form>  
        </div>  
    )   
}  

export default UpdateWaitlistMembership
