import React from 'react'
import {Link} from 'react-router-dom'

import './Footer.css'

const Footer = () => {
    return(
        <>
            <div className="Footer">
            <div className="FooterTopLine"></div>
            <div className="FooterArea">
                <div>
                    <div className="F-title"> WALLACE PARK TENNIS CLUB  </div>
                    <div className="F-content">
                        <i className="fas fa-phone-alt"></i>
                        <span> 905-845-7649 </span>
                        <br/>
                        <i className="fa fa-address-card"></i>
                        <span> 245 Reynolds Street, Oakville, ON L6J 3L2 </span>
                        <br/>
                        <i className="fa fa-envelope"></i>    
                        <span> wallaceparkoakville@gmail.com </span>
                    </div>
                </div>

                <div className="IframeMap">
                    <iframe 
                        title="map"
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2896.446138325069!2d-79.6729441846632!3d43.451282273482214!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882b5c939cd78bc1%3A0x4f09dec620624cf0!2sWallace%20Park%20Tennis%20Club!5e0!3m2!1sen!2sca!4v1603851750925!5m2!1sen!2sca" 
                        frameBorder="0" 
                        allowFullScreen="" 
                        aria-hidden="false" 
                        tabIndex="0"
                    />    
                </div>
            </div>
            </div>
        </>
              
    )
}

export default Footer
