
export const HOMEPAGE_URL = "/api/homepage"
export const CONFIG_URL = "/api/static/configuration"
export const BOOKING_SETTINGS_URL = "/api/static/bookingsettings"

export const MAILCHIMP_POST_URL = "https://wallaceparktennis.us1.list-manage.com/subscribe/post"

export const RULEBOOK_URL = "/api/static/rulebook"
export const LESSONS_URL = "/api/static/lessons"
export const INSTRUCTIONS_URL = "/api/static/instructions"
export const WAITLIST_INFO_URL = "/api/static/waitlist"
export const MEMBERSHIP_INFO_URL = "/api/static/membership"
export const STATIC_URL = "/api/static"
export const CURRENT_USER_URL = "/api/static/currentuser"
export const SIGNOUT_URL = "/api/static/signout"
export const FEES_URL = "/api/static/fees/summary"
export const RESOURCES_URL = "/api/static/resources"
export const WAITLIST_URL = "/api/static/register/waitlist"

export const CREDITS_PAYMENT_URL = "/api/payments/credits"
export const CREDITS_PAYMENT_STAFF_URL = "/api/payments/credits/staff"

export const MEMBERSHIP_RENEWAL_URL = "/api/member/renewals/renewalId"
export const EVENTS_REGISTER_URL = "/api/member/events/register"
export const EVENTS_STAFF_URL = "/api/member/events/all"
export const MEMBER_REGISTER_URL = "/api/member/register"
export const MEMBER_PROFILE_URL = "/api/member/profile"
export const MEMBER_MEMBERSHIP_URL = "/api/member/membership"
export const PHONEBOOK_URL = "/api/member/phonebook"
export const USER_PRIOR_REGISTER_URL = "/api/member/priorreg"
export const USER_SUBMITED_WAITLIST_URL = "/api/member/subwaitlist"
export const MEMBERSHIP_TRAN_URL = "/api/member/transactions"

//events
export const EVENT_INFO_URL = "/api/clubevents/eventinfo"
export const EVENTS_URL = "/api/clubevents/current"

export const EVENTS_SINGLE_URL = "/api/clubevents/fetchevent"
export const EVENTS_SELF_REGISTER_URL = "/api/clubevents/register/self"
export const EVENTS_SELF_CANCEL_URL = "/api/clubevents/register/selfcancel"
export const FAMILY_MEMBERS_URL = "/api/clubevents/familymembers"

export const EVENTS_STAFF_REGISTER_URL = "/api/clubevents/register/staff"
export const EVENTS_STAFF_CANCEL_URL = "/api/clubevents/register/staffcancel"
export const EVENTS_STAFF_CANCEL_EVENT_URL = "/api/clubevents/cancelevent"
export const EVENTS_STAFF_WAITLIST_URL = "/api/clubevents/register/staffwaitlist"
export const EVENTS_STAFF_ATTENDED_URL = "/api/clubevents/register/staffattended"

export const EVENTS_FIND_URL = "/api/clubevents/find"
export const EVENTS_FINDONE_URL = "/api/clubevents/findone"
export const EVENTS_CREATE_URL = "/api/clubevents/create"
export const EVENTS_CREATE_WITH_IMG_URL = "/api/clubevents/createwithimage"
export const EVENTS_UPDATE_URL = "/api/clubevents/update"
export const EVENTS_UPDATE_MULTIPART_URL = "/api/clubevents/update/multipart"
export const EVENTS_DELETE_URL = "/api/clubevents/delete"

export const AUTH_LOCAL_REGISTER_URL = "/api/auth/local/register"
export const AUTH_LOCAL_URL = "/api/auth/local"
export const AUTH_FORGOT_PSWD_URL = "/api/auth/forgot-password"
export const AUTH_RESET_PSWD_URL = "/api/auth/reset-password"
export const AUTH_CONFIRM_EMAIL_URL = "/api/auth/email-confirmation"
export const AUTH_LOCAL_CHANGE_URL = "/api/auth/local/change"

export const STAFF_REGISTER_URL = "/api/staff/register"
export const PHONE_REGISTER_URL = "/api/staff/register/phone"

export const STAFF_CANCEL_REGISTER_URL = "/api/staff/register/cancel"
export const STAFF_UPDATE_MEMBERSHIP_URL = "/api/staff/membership/update"
export const STAFF_UPDATE_APPROVAL_URL = "/api/staff/waitlist/approve"

export const PMTSUMMARY_URL = "/api/staff/pmtsummary"
export const PAYMENT_URL = "/api/staff/payments/details"
export const CREDITS_URL = "/api/staff/payments/credits"
export const MEMBERS_URL = "/api/staff/members"
export const FETCH_MEMBER_URL = "/api/staff/getmember"
export const UPDATE_MEMBER_URL = "/api/staff/updatemember"
export const WAITLIST_LIST_URL = "/api/staff/waitlist"

export const PROD_API_URL = process.env.REACT_APP_API_URL
export const DEV_API_URL = process.env.REACT_APP_API_URL_DEV  

export const API_URL = PROD_API_URL || DEV_API_URL

/**
 * Given an Image, return the URL
 *  
 * @param {any} image 
 */
export const fromImageToUrl = (image, source) => {
    //console.log('image: ', image)
    let staticImage = "/images/wptc-pic1.JPG" 

    if(!image){
        if(!source){
            return staticImage
        }

        switch (source){
            case "profile":
                staticImage = "/images/tennis-ball.jpg"
                break
            case "event":    
                staticImage = "/images/tennis-ball.jpg"
                break
            default:
                staticImage = "/images/wptc-pic1.JPG"    
        }
        return staticImage
    }

    if(image.url.indexOf("/") === 0){
        return(`${API_URL}${image.url}`)
    }

    return image.url
}
