import React from 'react'

const ContactDetails = (props) => {
    const { 
        firstName,
        lastName,
        email,
        address,
        city,
        postal,
        primaryPhone,
        emergencyContact,
        emergencyPhone
    } = props

    const onChangeHandler = (e) =>{
        return null
    }

    return(
        <>
            <div className="section"><span>1</span>Login Details</div>
            <div className="inner-wrap">
                <label>First Name </label>
                <p className="readonly">{firstName}</p>

                <label>Last Name </label>
                <p className="readonly">{lastName}</p>

                <label>Email </label>
                <p className="readonly">{email}</p>
            </div>

            <div className="section"><span>2</span>Address & Phone</div>
            <div className="inner-wrap">
                <label htmlFor="address">Address<span className="required">*</span> </label>
                <textarea 
                    name="address" 
                    value={address}
                    onChange={onChangeHandler}
                />

                <label htmlFor="city">City<span className="required">*</span> </label>
                <input 
                    type="text" 
                    name="city" 
                    value={city}
                    onChange={onChangeHandler}
                />

                <label htmlFor="postal">Postal Code (no spaces)<span className="required">*</span> </label>
                <input 
                    type="text" 
                    name="postal" 
                    maxLength="7"
                    value={postal} 
                    onChange={onChangeHandler}
                />

                <label htmlFor="primaryPhone">Phone (numbers only - no spaces)<span className="required">*</span> </label>
                <input 
                    type="number" 
                    name="primaryPhone" 
                    maxLength="10"
                    value={primaryPhone}
                    onChange={onChangeHandler}
                />
            </div>

            <div className="section"><span>3</span>Emergency Contact Information</div>
            <div className="inner-wrap">
                <label htmlFor="emergencyContact">Emergency Contact Full Name<span className="required">*</span> </label>
                <input 
                    type="text" 
                    name="emergencyContact" 
                    value={emergencyContact}
                    onChange={onChangeHandler}
                />

                <label htmlFor="emergencyPhone">Emergency Contact Phone (numbers only - no spaces)<span className="required">*</span> </label>
                <input 
                    type="number" 
                    name="emergencyPhone" 
                    maxLength="10"
                    value={emergencyPhone} 
                    onChange={onChangeHandler}
                />
            </div>
        </>  
    )
}

export default ContactDetails