import React, { useEffect, useState } from 'react'

import './EventList.css'

const FamilyEntry = (props) =>  {
    let { 
        EventListItem, 
        handleConfirmCancel, 
        handleRegister,
        enableButtons
    } = props

    const [confirmCancel, setConfirmCancel] = useState(false)
    const [confirmMember, setConfirmMember] = useState()

    useEffect(()=> {
        setConfirmCancel(false)
    },[])

    const handleCancelBooking = (e) => {
        //console.log('cancel ', e)
        setConfirmCancel(true)
        setConfirmMember(e)
    }

    const handleCancelMember = (member) => {
        setConfirmCancel(false)
        handleConfirmCancel(member)
    }

    return(
        EventListItem.map((entry, idx)=> { 
            let fnIdx = `fn-${entry.memberId}`
            let lnIdx = `ln-${entry.memberId}`
            let stIdx = `st-${entry.memberId}`
            let acIdx = `ac-${entry.memberId}`
 
            let confirmCancelMember = false
            let showRegister = false
            let showCancelRegister = false

            //console.log('status', entry.status)
            //console.log('eventIsToday', eventIsToday)
            if(!entry.status || entry.status === "cancelled"){
                //member is not registered
                showRegister = true
            }

            if((entry.status === "confirmed") || (entry.status === "waitlist")){
                showCancelRegister = true
            }

            //console.log('entry-member', entry)
            
            if(confirmCancel){
                if(entry.memberId === confirmMember){
                    confirmCancelMember = true
                }
            }

            return (
                <>
                    <tr key={entry.memberId}>
                        <td key={fnIdx}>{entry.firstName}</td>
                        <td key={lnIdx}>{entry.lastName}</td>
    
                        {entry.status &&
                            <td key={stIdx}>{entry.status}</td>
                        }

                        {!entry.status &&
                            <td key={stIdx}>Not Registered</td>
                        }
                        
                        {enableButtons &&
                            <td key={acIdx}>
                                {confirmCancelMember && showCancelRegister &&
                                    <button className="confirmCancel" onClick={() =>{handleCancelMember(entry.memberId)}}> Confirm Cancellation </button>
                                }
                                {!confirmCancelMember && showCancelRegister &&
                                    <button className="cancelBookng" onClick={() =>{handleCancelBooking(entry.memberId)}}> Cancel Booking </button>
                                }
                                {showRegister &&
                                    <button 
                                        className="Register" 
                                        onClick={() =>{handleRegister(entry.memberId)}}
                                    >
                                        Register
                                    </button>
                                }
                            </td>
                        }

                        {!enableButtons&&
                            <td key={acIdx}>
                                Registration Closed
                            </td>
                        }
                    </tr>    
                </>
            )
        })
    )
}  

export default FamilyEntry
