//The front-end validation file must be kept in-sync with the back-end validation file - i.e The 2 files should be identical
// These validations are currently for the Registration Form only

import {
    POSTAL_RGEX,
    NAME_RGEX,
    ADDRESS_RGEX,
    EMAIL_RGEX,
    MAX_LENGTH_TEXT,
    MAX_LENGTH_MULTI_TEXT,
    MAX_LENGTH_EMAIL,
    MIN_YEAR_OF_BIRTH,
    MAX_NTRP_RATING,
    MIN_NTRP_RATING
} from './constants'

export const validateUserInfoInputs = (validateFields) => {
    let { firstName, lastName, email } = validateFields

    let validations = {
        validatedFields: {},
        validatedResult: false,
        validatedMessage: ''
    }

    let validationResult = true
    let validationMessage = ""
    let regexResult = true

    let validationArray = {
        firstNameIsValid: false,
        lastNameIsValid: false,
        emailIsValid: false,
    }

    //validate First Name. Minium 2 characters
    if(validationResult){
        validationArray.firstNameIsValid = true
    
        if(firstName){
            let trimmedFirstName = firstName.trim()

            if(trimmedFirstName.length > 1 && 
                trimmedFirstName.length <= MAX_LENGTH_TEXT){
                regexResult = NAME_RGEX.test(trimmedFirstName)

                if(!regexResult){
                    validationArray.firstNameIsValid = false
                }
            } else {
                validationArray.firstNameIsValid = false
            }
        } else {
            validationArray.firstNameIsValid = false
        }

        if(!validationArray.firstNameIsValid){
            validationMessage = "Please provide a valid applicant First Name"
            validationResult = false
        }
    }

    //validate Last Name. Minium 2 characters
    if(validationResult){
        validationArray.lastNameIsValid = true
    
        if(lastName){
            let trimmedLastName = lastName.trim()

            if(trimmedLastName.length > 1 && 
                trimmedLastName.length <= MAX_LENGTH_TEXT){
                regexResult = NAME_RGEX.test(trimmedLastName)
                
                if(!regexResult){
                    validationArray.lastNameIsValid = false    
                }
            } else {
                validationArray.lastNameIsValid = false
            }
        }
                    
        if(!validationArray.lastNameIsValid){
            validationMessage = "Please provide a valid applicant Last Name"
            validationResult = false
        }
    }    
    
    //valdiate Email
    if(validationResult){
        validationArray.emailIsValid = true
        
        //console.log('billing email: ', billingFields.email)
        if(email){
            let trimmedEmail = email.trim()

            if(trimmedEmail.length > 4 &&
                trimmedEmail.length <= MAX_LENGTH_EMAIL){
                regexResult = EMAIL_RGEX.test(trimmedEmail)
                
                if(!regexResult){
                    validationArray.emailIsValid = false    
                }
            } else {
                validationArray.emailIsValid = false
            }
        } else {
            validationArray.emailIsValid = false
        }
        
        if(!validationArray.emailIsValid){
            validationMessage = "Please provide a valid Email"
            validationResult = false
        } else {
            // email format is valid. Check that it has not already been used
            // this maybe added later if needed
        }
    }
    
    validations.validatedFields = {...validationArray}
    validations.validatedResult = validationResult
    validations.validatedMessage = validationMessage

    return validations
}

export const validateMemberUpdate = (validateFields) => {
    let { ntrpAssessed, phoneNumber, email } = validateFields

    let validations = {
        validatedFields: {},
        validatedResult: false,
        validatedMessage: ''
    }

    let validationResult = true
    let validationMessage = ""
    let regexResult = true

    let validationArray = {
        ntrpRatingIsValid: false,
        phoneIsValid: false,
        emailIsValid: false,
    }

    //validate NTRP assessed rating
    if(validationResult){
        validationArray.ntrpRatingIsValid = true
        if(ntrpAssessed){
            let validNtrpRating = false

            if(ntrpAssessed >= MIN_NTRP_RATING && ntrpAssessed <= MAX_NTRP_RATING){
                //Rating is within range. Check the allowable values
                if((ntrpAssessed*10)%5 === 0){
                    //the rating is divisible by 0.5
                    validNtrpRating = true
                }
            } 

            if(!validNtrpRating){
                //NTRP rating is invalid
                validationArray.ntrpRatingIsValid = false
                validationMessage = "Assessed NTRP rating for Member is invalid."
                validationResult = false
            }  
        } else {
            validationArray.ntrpRatingIsValid = false
            validationMessage = "NTRP rating range must be selected."
            validationResult = false
        }
    }    

    //valdiate Phone Number
    if(validationResult){
        validationArray.phoneIsValid = true

        if(phoneNumber && phoneNumber > 0){
            if(phoneNumber.toString().length !== 10){
                validationArray.phoneIsValid = false 
            }
        } 
        
        if(!validationArray.phoneIsValid){
            validationMessage = "Please provide a valid 10 digit Phone Number. No spaces or hyphens"
            validationResult = false
        }
    }
    
    //valdiate Email
    if(validationResult){
        validationArray.emailIsValid = true
        
        if(email && email > ' '){
            let trimmedEmail = email.trim()

            if(trimmedEmail.length > 4 &&
                trimmedEmail.length <= MAX_LENGTH_EMAIL){
                regexResult = EMAIL_RGEX.test(trimmedEmail)
                
                if(!regexResult){
                    validationArray.emailIsValid = false    
                }
            } else {
                validationArray.emailIsValid = false
            }
        } 

        if(!validationArray.emailIsValid){
            validationMessage = "Please provide a valid Email"
            validationResult = false
        } else {
            // email format is valid. Check that it has not already been used
            // this maybe added later if needed
        }
    }
    
    validations.validatedFields = {...validationArray}
    validations.validatedResult = validationResult
    validations.validatedMessage = validationMessage

    return validations
}

export const validateStaffPaymentInputs = (validateFields) => {
    let { paymentType, paymentAmount, confirmEntries } = validateFields

    let validations = {
        validatedFields: {},
        validatedResult: false,
        validatedMessage: ''
    }

    let validationResult = true
    let validationMessage = ""

    let validationArray = {
        paymentTypeIsValid: false,
        paymentAmountIsValid: false,
        confirmEntriesIsValid: false
    }

     //validate payment type
     if(validationResult){
        validationArray.paymentTypeIsValid = false

        if(paymentType > ' '){
            validationArray.paymentTypeIsValid = true
        }

        if(!validationArray.paymentTypeIsValid){
            validationMessage = "Please provide a valid payment type"
            validationResult = false
        }
     }    

     //validate payment amount
     if(validationResult){
        validationArray.paymentAmountIsValid = false

        if(paymentAmount > 0){
            validationArray.paymentAmountIsValid = true
        }

        if(!validationArray.paymentAmountIsValid){
            validationMessage = "Please provide a valid payment amount"
            validationResult = false
        }
     }

     //valdiate Confirmation of Entries
    if(validationResult){
        validationArray.confirmEntriesIsValid = true
        if(!confirmEntries){
            validationArray.confirmEntriesIsValid = false
            validationResult = false
            validationMessage = "Please indicate that you have verified all entries before proceeding"
        }
    }

    validations.validatedFields = {...validationArray}
    validations.validatedResult = validationResult
    validations.validatedMessage = validationMessage

    return validations
}

export const validateMembershipInputs = (validateFields) => {

    let { 
        adults, 
        juniors, 
        terms, 
        address, 
        city, 
        postal, 
        primaryPhone, 
        emergencyContact, 
        emergencyPhone, 
        members,
        currentYear,
        maxAgeJunior,
        ntrpRating,
        maxAdults,
        maxJuniors 
    } = validateFields

    let validations = {
        validatedFields: {},
        validatedResult: false,
        validatedMessage: ''
    }

    let validationResult = true
    let validationMessage = ""

    let validationArray = {
        totalMembersIsValid: false,
        termsIsValid: false,
        addressIsValid: false,
        cityIsValid: false,
        postalIsValid: false,
        primaryPhoneIsValid: false,
        emergencyNameIsValid: false,
        emergencyPhoneIsValid: false,
        members:[]
    }


    if(members && members.length > 0){
        for(let idx=0; idx < members.length; idx++){
            validationArray.members[idx] = {
                firstNameIsValid: false,
                lastNameIsValid: false,
                emailIsValid: false,
                phoneIsValid: false,
                yearOfBirthIsValid: false,
                ntrpRatingIsValid: false
            }
        }
    }

    let regexResult = true

    //validate that there is at least 1 member
    if(validationResult){
        validationArray.totalMembersIsValid = true

        if(adults === 0 && juniors === 0){
            //no members have been added. Throw error
            validationMessage = "Please select at least 1 adult or junior member"
            validationArray.totalMembersIsValid = false
            validationResult = false
        }
    }
    
    //Validate there are no more than max adults on the membership
    if(validationResult){
        validationArray.totalMembersIsValid = true

        if(adults > maxAdults){
            //Too many adults. Throw error
            validationMessage = `Max ${maxAdults} permitted per membership`
            validationArray.totalMembersIsValid = false
            validationResult = false
        }
    }

    //Validate there are no more than max Juniors on the membership
    if(validationResult){
        validationArray.totalMembersIsValid = true

        if(juniors > maxJuniors){
            //Too many juniors. Throw error
            validationMessage = `Max ${maxJuniors} permitted per membership`
            validationArray.totalMembersIsValid = false
            validationResult = false
        }
    }
    
    //validate address. Minium 6 characters
    if(validationResult){
        validationArray.addressIsValid = true

        if(address){
            let trimmedAddress = address.trim()

            if(trimmedAddress.length > 5 && 
                trimmedAddress.length <= MAX_LENGTH_MULTI_TEXT){
                regexResult = ADDRESS_RGEX.test(trimmedAddress)
                if(!regexResult){
                    validationArray.addressIsValid = false    
                }
            } else {
                validationArray.addressIsValid = false
            }
        } else {
            validationArray.addressIsValid = false
        }
            
        if(!validationArray.addressIsValid){
            validationMessage = "Please provide a valid address"
            validationResult = false
        }
    }

    //validate city. Minimum 3 characters
    if(validationResult){
        validationArray.cityIsValid = true
        
        if(city){
            let trimmedCity = city.trim()

            if(trimmedCity.length > 2 && 
                trimmedCity.length <= MAX_LENGTH_TEXT){
                regexResult = NAME_RGEX.test(trimmedCity)
                
                if(!regexResult){
                    validationArray.cityIsValid = false    
                }
            } else {
                validationArray.cityIsValid = false
            }
        } else {
            validationArray.cityIsValid = false
        }
        
        if(!validationArray.cityIsValid){
            validationMessage = "Please provide a valid city"
            validationResult = false
        }
    }

    //valdiate Postal Code
    if(validationResult){
        validationArray.postalIsValid = true
        
        if(postal){
            let trimmedPostal = postal.trim()

            if(trimmedPostal.length === 6){                
                regexResult = POSTAL_RGEX.test(postal.trim())
                
                if(!regexResult){
                    validationArray.postalIsValid = false    
                }
            } else {
                validationArray.postalIsValid = false
            }    
        } else {
            validationArray.postalIsValid = false
        } 
         
        if(!validationArray.postalIsValid){
            validationMessage = "Please provide a valid Postal Code. Format H1H1H1 - no spaces"
            validationResult = false
        }
    }
    
    //valdiate Primary Phone Number
    if(validationResult){
        validationArray.primaryPhoneIsValid = true

        if(primaryPhone){
            let trimmedPrimaryPhone = primaryPhone.trim()
            
            if(trimmedPrimaryPhone.length !== 10){
                validationArray.primaryPhoneIsValid = false    
            }
        } else {
            validationArray.primaryPhoneIsValid = false  
        }
        
        if(!validationArray.primaryPhoneIsValid){
            validationMessage = "Please provide a valid 10 digit Phone Number. No spaces or hyphens"
            validationResult = false
        }
    }

    //valdiate Emergency Contact Name. 
    //Contact Name has both first and last names in 1 field
    
    if(validationResult){
        validationArray.emergencyNameIsValid = true
        
        if(emergencyContact){
            let trimmedEmergencyContact = emergencyContact.trim()

            if(trimmedEmergencyContact.length > 4 && 
                trimmedEmergencyContact.length <= (MAX_LENGTH_TEXT*2)){
                regexResult = NAME_RGEX.test(trimmedEmergencyContact)
                
                if(!regexResult){
                    validationArray.emergencyNameIsValid = false    
                }
            } else {
                validationArray.emergencyNameIsValid = false
            }
        }  else {
            validationArray.emergencyNameIsValid = false
        }
        
        if(!validationArray.emergencyNameIsValid){
            validationMessage = "Please provide an Emergency Contact Name"
            validationResult = false
        }
    }

    //valdiate Emergency Phone Number
    if(validationResult){
        validationArray.emergencyPhoneIsValid = true
        
        if(emergencyPhone){
            let trimmedEmergencyPhone = emergencyPhone

            if(trimmedEmergencyPhone.length !== 10){
                 validationArray.emergencyPhoneIsValid = false    
            }
        }
        
        if(!validationArray.emergencyPhoneIsValid || !emergencyPhone){
            validationMessage = "Please provide a valid Emergency 10 digit Phone Number. No spaces or hyphens"
            validationResult = false
        }
    }

    //validate Members
    let localMembers = [...members]

    for(let idx=0; idx < localMembers.length; idx++){
        let firstName = localMembers[idx].firstName
        let lastName = localMembers[idx].lastName
        let nameString = ` (${firstName} ${lastName}) `

        //valdiate First Name
        if(validationResult){
            validationArray.members[idx].firstNameIsValid = true

            if(firstName){
                let trimmedFirstName = firstName.trim()

                if(trimmedFirstName.length > 1 &&
                    trimmedFirstName.length <= MAX_LENGTH_TEXT){
                    regexResult = NAME_RGEX.test(trimmedFirstName)

                    if(!regexResult){
                        validationArray.members[idx].firstNameIsValid = false
                    }
                } else {
                    validationArray.members[idx].firstNameIsValid = false
                }    
            } else {
                validationArray.members[idx].firstNameIsValid = false
            }    
                            
            if(!validationArray.members[idx].firstNameIsValid){
                validationMessage = "First Name for Member " + (idx+1) + ' is invalid'
                validationResult = false
            }
        }    

        //valdiate Last Name
        if(validationResult){
            validationArray.members[idx].lastNameIsValid = true
            
            if(lastName){
                let trimmedLastName = lastName.trim()

                if(trimmedLastName.length > 1 &&
                    trimmedLastName.length <= MAX_LENGTH_TEXT){
                    regexResult = NAME_RGEX.test(trimmedLastName)

                    if(!regexResult){
                        validationArray.members[idx].lastNameIsValid = false
                    }
                } else {
                    validationArray.members[idx].lastNameIsValid = false
                }
            } else {
                validationArray.members[idx].lastNameIsValid = false
            }
            
            if(!validationArray.members[idx].lastNameIsValid){
                validationMessage = "Last Name for Member " + (idx+1) + ' is invalid'
                validationResult = false
            }
        }

        //valdiate email
        if(validationResult){
            validationArray.members[idx].emailIsValid = true
            //Note: Email is not required. Only validate if entered

            if(localMembers[idx].email){
                let trimmedEmail = localMembers[idx].email.trim()
                
                if(trimmedEmail.length > 4 &&
                    trimmedEmail.length <= MAX_LENGTH_EMAIL){
                    regexResult = EMAIL_RGEX.test(trimmedEmail)

                    if(!regexResult){
                        validationArray.members[idx].emailIsValid = false
                    }
                } else {
                    validationArray.members[idx].emailIsValid = false
                }
            }
            
            if(!validationArray.members[idx].emailIsValid){
                validationMessage = "Email for Member " + (idx+1) + nameString + 'is invalid'
                validationResult = false
            }
        }

        //valdiate Phone Number
        if(validationResult){
            validationArray.members[idx].phoneIsValid = true

            if(localMembers[idx].phoneNumber){
                let trimmedPhoneNumber = localMembers[idx].phoneNumber.trim()

                if(trimmedPhoneNumber.length > 0 && trimmedPhoneNumber > 0){
                    //phone number has been provided
                    if(trimmedPhoneNumber.length !== 10){
                        validationArray.members[idx].phoneIsValid = false
                    }
                }    
            }
            
            if(!validationArray.members[idx].phoneIsValid){
                validationMessage = "Phone Number for Member " + (idx+1) + nameString + 'is invalid'
                validationResult = false
            }        
        }

        //valdiate NTRP rating. 
        if(validationResult){
            validationArray.members[idx].ntrpRatingIsValid = true

            if(localMembers[idx].ntrpSelf){
                ntrpRating = localMembers[idx].ntrpSelf

                let validNtrpRating = false

                if(ntrpRating >= MIN_NTRP_RATING && ntrpRating <= MAX_NTRP_RATING){
                    //Rating is within range. Check the allowable values
                    if((ntrpRating*10)%5 === 0){
                        //the rating is divisible by 0.5
                        validNtrpRating = true
                    }
                } 

                if(!validNtrpRating){
                    //NTRP rating is invalid
                    validationArray.members[idx].ntrpRatingIsValid = false
                    validationMessage = "NTRP rating for Member " + (idx+1) + nameString + 'is invalid.'
                    validationResult = false
                }  
            } else {
                validationArray.members[idx].ntrpRatingIsValid = false
                validationMessage = "NTRP rating for Member " + (idx+1) + nameString + 'is required.'
                validationResult = false
            }
        }    

        //valdiate Year Of Birth. 
        if(validationResult){
            validationArray.members[idx].yearOfBirthIsValid = true
            let memberType =localMembers[idx].memberType
            
            let yearOfBirth = 0

            if(localMembers[idx].yearOfBirth){
                yearOfBirth = localMembers[idx].yearOfBirth

                if(yearOfBirth < MIN_YEAR_OF_BIRTH || yearOfBirth > currentYear){
                    //Year of Birth is entered and is invalid
                    validationArray.members[idx].yearOfBirthIsValid = false
                    validationMessage = "Year of Birth for Member " + (idx+1) + nameString + 'is invalid'
                    validationResult = false
                }  
            }

            if(validationResult && (memberType === "junior")){
                //Year of Birth is mandatory for juniors
                
                if( yearOfBirth > 0){
                    //check for Age
                    if((currentYear - yearOfBirth) > maxAgeJunior){
                        validationMessage = "Member " + (idx+1) + nameString + "does not qualify as a Junior" 
                        validationArray.members[idx].yearOfBirthIsValid = false
                        validationResult = false    
                    }
                } else {
                    validationMessage = "Year of Birth is Mandatory for Junior Member " + (idx+1) + nameString
                    validationArray.members[idx].yearOfBirthIsValid = false
                    validationResult = false
                }
            }
        }
    }

    //valdiate Terms and Conditions
    if(validationResult){
        validationArray.termsIsValid = true
        if(!terms){
            validationArray.termsIsValid = false
            validationResult = false
            validationMessage = "Please agree to the Club Rules and Code of Conduct before proceeding"
        }
    }

    validations.validatedFields = {...validationArray}
    validations.validatedResult = validationResult
    validations.validatedMessage = validationMessage

    return validations
}

export const validateBillingInputs = (validateBillingFields) => {
    let validations = {
        validatedFields: {},
        validatedResult: false,
        validatedMessage: ''
    }

    let { firstName, lastName, address, city, postal, phone, email, confirmEntries } = validateBillingFields

    let validationResult = true
    let validationMessage = ""

    let validationArray = {
        confirmEntriesIsValid: false,
        firstNameIsValid: false,
        lastNameIsValid: false,
        addressIsValid: false,
        cityIsValid: false,
        postalIsValid: false,
        phoneIsValid: false,
        emailIsValid: false,
    }

    let regexResult = true

    //validate Billing First Name. Minium 2 characters
    if(validationResult){
        validationArray.firstNameIsValid = true
    
        if(firstName){
            let trimmedFirstName = firstName.trim()

            if(trimmedFirstName.length > 1 && 
                trimmedFirstName.length <= MAX_LENGTH_TEXT){
                regexResult = NAME_RGEX.test(trimmedFirstName)

                if(!regexResult){
                    validationArray.firstNameIsValid = false
                }
            } else {
                validationArray.firstNameIsValid = false
            }
        } else {
            validationArray.firstNameIsValid = false
        }

        if(!validationArray.firstNameIsValid){
            validationMessage = "Please provide a valid billing First Name"
            validationResult = false
        }
    }

    //validate Last Name. Minium 2 characters
    if(validationResult){
        validationArray.lastNameIsValid = true
    
        if(lastName){
            let trimmedLastName = lastName.trim()

            if(trimmedLastName.length > 1 &&
                trimmedLastName.length <= MAX_LENGTH_TEXT){
                regexResult = NAME_RGEX.test(trimmedLastName)
                
                if(!regexResult){
                    validationArray.lastNameIsValid = false    
                }
            } else {
                validationArray.lastNameIsValid = false
            }
        }
                    
        if(!validationArray.lastNameIsValid){
            validationMessage = "Please provide a valid billing Last Name"
            validationResult = false
        }
    }        

    //validate address. Minium 6 characters
    if(validationResult){
        validationArray.addressIsValid = true
    
        if(address){
            let trimmedAddress = address.trim()

            if(trimmedAddress.length > 5 && 
                trimmedAddress.length <= MAX_LENGTH_MULTI_TEXT){
                regexResult = ADDRESS_RGEX.test(trimmedAddress)
                
                if(!regexResult){
                    validationArray.addressIsValid = false    
                }
            } else {
                validationArray.addressIsValid = false
            }
        } else {
            validationArray.addressIsValid = false
        }

        if(!validationArray.addressIsValid){
            validationMessage = "Please provide a valid billing address"
            validationResult = false
        }
    }

    //validate city. Minium 3 characters
    if(validationResult){
        validationArray.cityIsValid = true
        
        if(city){
            let trimmedCity = city.trim()

            if(trimmedCity.length > 2 &&
                trimmedCity.length <= MAX_LENGTH_TEXT){
                regexResult = NAME_RGEX.test(trimmedCity)
                
                if(!regexResult){
                    validationArray.cityIsValid = false    
                }
            } else {
                validationArray.cityIsValid = false
            }
        } else {
            validationArray.cityIsValid = false
        }
        
        if(!validationArray.cityIsValid){
            validationMessage = "Please provide a valid city"
            validationResult = false
        }
    }

    //valdiate Postal Code
    if(validationResult){
        validationArray.postalIsValid = true
        
        if(postal){
           let trimmedPostal = postal.trim() 

           if(trimmedPostal.length === 6){
                regexResult = POSTAL_RGEX.test(trimmedPostal)
                
                regexResult = true
                if(!regexResult){
                    validationArray.postalIsValid = false    
                }
            } else {
                validationArray.postalIsValid = false
            }

        } else {
            validationArray.postalIsValid = false
        }
        
        if(!validationArray.postalIsValid){
            validationMessage = "Please provide a valid Postal Code - Format H1H1H1 - no spaces "
            validationResult = false
        }
    }
    
    //valdiate Billing Phone Number
    if(validationResult){
        validationArray.phoneIsValid = true
        
        if(phone){
            let trimmedPhone = phone

            if(trimmedPhone.length !== 10){
                validationArray.phoneIsValid = false    
            }
        } else {
            validationArray.phoneIsValid = false    
        }
    
        if(!validationArray.phoneIsValid){
            validationMessage = "Please provide a valid Billing Phone Number"
            validationResult = false
        }
    }

    //valdiate Email
    if(validationResult){
        validationArray.emailIsValid = true
        
        //console.log('billing email: ', billingFields.email)
        if(email){
            let trimmedEmail = email.trim()

            if(trimmedEmail.length > 4 &&
                trimmedEmail.length <= MAX_LENGTH_EMAIL){
                regexResult = EMAIL_RGEX.test(trimmedEmail)
                
                if(!regexResult){
                    validationArray.emailIsValid = false    
                }
            } else {
                validationArray.emailIsValid = false
            }
        } else {
            validationArray.emailIsValid = false
        }
        
        if(!validationArray.emailIsValid){
            validationMessage = "Please provide a valid Email"
            validationResult = false
        }
    }

    //valdiate Confirmation of Entries
    if(validationResult){
        validationArray.confirmEntriesIsValid = true
        if(!confirmEntries){
            validationArray.confirmEntriesIsValid = false
            validationResult = false
            validationMessage = "Please indicate that you have verified all entries before proceeding"
        }
    }
    
    validations.validatedFields = {...validationArray}
    validations.validatedResult = validationResult
    validations.validatedMessage = validationMessage

    return validations
}