import React from 'react'
import { CSVLink } from 'react-csv'
import './Icons.css'

const ExportReactCSV = ({csvData, fileName, fileDownloaded}) => {
    return (
        <div>
            <CSVLink data={csvData} filename={fileName} onClick={()=>{fileDownloaded()}}>
                <>
                    <i className="fas fa-file-excel fa-lg"></i>
                    <div className="IconName">
                        to Excel
                    </div>
                </>
            </CSVLink>
        </div>
    )
}

export default ExportReactCSV