import React, { useEffect, useState } from 'react'

import PhonebookEntry from './PhonebookEntry'
//import { requestPhoneList  } from '../../utils/fetchPhonebook'
import { compareValues } from '../../utils/functions'

//Testing
import testPhoneList from '../../utils/phonebook.json'

import './Phonebook.css'

// Documentation -> See under Phonebook.js 

const user = {
    firstName: "Jack"
}

const PhonebookTest = ({history}) =>  {
    const [phonebookEntries, setPhonebookEntries] = useState([{}])
    const [searchText, setSearchText] = useState('')
    const [searchResult, setSearchResult] = useState([{}])
    const [firstNameSortAscending, setFirstNameSortAscending] = useState(true)
    const [firstNameSortDescending, setFirstNameSortDescending] = useState(false)
    const [lastNameSortAscending, setLastNameSortAscending] = useState(false)
    const [lastNameSortDescending, setLastNameSortDescending] = useState(false)
    const [firstPage, setFirstPage] = useState()
    const [secondPage, setSecondPage] = useState()
    const [thirdPage, setThirdPage] = useState()
    const [firstPageStyle, setFirstPageStyle] = useState('')
    const [secondPageStyle, setSecondPageStyle] = useState('')
    const [thirdPageStyle, setThirdPageStyle] = useState('')
    const [showChevron, setShowChevron] = useState(false)
    const [currentPage, setCurrentPage] = useState()
    const [totalCurrentEntries, setTotalCurrentEntries] = useState()
    const [pageStartEntry, setPageStartEntry] = useState()
    const [pageEndEntry, setPageEndEntry] = useState()
    const [currentPageList, setCurrentPageList] = useState([{}])
    const [entriesPerPage, setEntriesPerPage] = useState(10)

    let newEntriesPerPage = 10

    useEffect(() => {
        if(user){
            setFirstNameSortAscending(true)
            setFirstNameSortDescending(false)
            setLastNameSortAscending(false)
            setLastNameSortDescending(false)
            //defualt to 10 entries per page
            setEntriesPerPage(10)
            
            /*
            requestPhoneList().then(phoneList => {
                //sort the phone list by first name ascending
                phoneList.sort(compareValues('firstName'))

                setPhonebookEntries(phoneList)
                setSearchResult(phoneList)
                setPagination(phoneList)               
            })
            */
           // Testing
           testPhoneList.sort(compareValues('firstName'))
           setPhonebookEntries(testPhoneList)
           setSearchResult(testPhoneList)
           //setPagination(testPhoneList, 1, 1, true)
           //
        }
    }, [])   

    useEffect(() => {
        if(!user){
            //navigate the user to home page if not logged in
            history.push('/')
        }           
    })

    useEffect(()=>{
        //console.log('reset pages')
        setPagination(searchResult, 1, 1, true)
    },[entriesPerPage, searchResult])
    

    const setPagination = (list, startPage=1, thisPage=1, rebuildPage=false) => {
        let totalEntries = list.length
        
        let totalPages = Math.ceil(totalEntries / entriesPerPage)
        //console.log('params: ', totalPages, startPage, thisPage)

        if((thisPage !== currentPage) || rebuildPage){
            setCurrentPage(thisPage)
            //show the entries for this page
            setTotalCurrentEntries(totalEntries)
            let startingEntry = 0
            let endingEntry = 0
            let newCurrentPageList = [...list]

            if(totalEntries > 0){
                startingEntry = ((thisPage - 1) * entriesPerPage) + 1
                endingEntry = startingEntry + entriesPerPage - 1
                if (endingEntry > totalEntries){
                    endingEntry = totalEntries
                } 
                newCurrentPageList = [...list].slice((startingEntry-1), (endingEntry))
            }
            
            setPageStartEntry(startingEntry)
            setPageEndEntry(endingEntry)
            setCurrentPageList(newCurrentPageList)
            //console.log('current list: ', newCurrentPageList)
        }

        switch(totalPages){
            case 0:
            case 1:    
                setFirstPage(null)
                setSecondPage(null)
                setThirdPage(null)                
                setShowChevron(false)
                break
            case 2:
                setFirstPage(1)
                setSecondPage(2)
                setThirdPage(null)                
                setShowChevron(true)

                if (thisPage === 1){
                    setFirstPageStyle('active')
                    setSecondPageStyle('')
                } else {
                    setFirstPageStyle('')
                    setSecondPageStyle('active')
                }

                break
            default:
                if((totalPages > 3) && (startPage + 2) > totalPages){
                    //there are more than 3 pages. Align the last set to show the last 3 pages
                    startPage = totalPages - 2
                }

                if((totalPages > 0) && (startPage <= 0)){
                    //show the first page if there are pages to display even if an invalid start page is requested
                    startPage = 1
                }

                if(startPage <= totalPages){
                    setFirstPage(startPage)
                } else {
                    setFirstPage(null)
                } 
                
                if((startPage + 1) <= totalPages){
                    setSecondPage(startPage + 1)
                } else {
                    setSecondPage(null)
                } 

                if((startPage + 2) <= totalPages){
                    setThirdPage(startPage + 2)     
                } else {
                    setThirdPage(null)
                }   
                
                if (thisPage === startPage) {
                    //first page is active
                    setFirstPageStyle('active')
                    setSecondPageStyle('')
                    setThirdPageStyle('')
                } else if (thisPage === (startPage + 1)) {
                    //second page is active
                    setFirstPageStyle('')
                    setSecondPageStyle('active')
                    setThirdPageStyle('')
                } else {
                    // third page is active
                    setFirstPageStyle('')
                    setSecondPageStyle('')
                    setThirdPageStyle('active')
                }
                setShowChevron(true)
        }
    }

    const handleFirstNameSort = () => {
        let currentPhoneList = searchResult

        if(firstNameSortAscending){
            setFirstNameSortDescending(true)
            setFirstNameSortAscending(false)
            setLastNameSortAscending(false)
            setLastNameSortDescending(false)

            currentPhoneList.sort(compareValues('firstName', 'desc'))
            setSearchResult(currentPhoneList)
            setPagination(currentPhoneList, 1, 1, true)
        } else {
            setFirstNameSortAscending(true)
            setFirstNameSortDescending(false)
            setLastNameSortAscending(false)
            setLastNameSortDescending(false)

            currentPhoneList.sort(compareValues('firstName'))
            setSearchResult(currentPhoneList)
            setPagination(currentPhoneList, 1, 1, true)
        }
    }

    const handleLastNameSort = () => {
        let currentPhoneList = searchResult

        if(lastNameSortAscending){
            setFirstNameSortDescending(false)
            setFirstNameSortAscending(false)
            setLastNameSortAscending(false)
            setLastNameSortDescending(true)

            currentPhoneList.sort(compareValues('lastName', 'desc'))
            setSearchResult(currentPhoneList)
            setPagination(currentPhoneList, 1, 1, true)
        } else {
            setFirstNameSortAscending(false)
            setFirstNameSortDescending(false)
            setLastNameSortAscending(true)
            setLastNameSortDescending(false)

            currentPhoneList.sort(compareValues('lastName'))
            setSearchResult(currentPhoneList)
            setPagination(currentPhoneList, 1, 1, true)
        }
    }

    const handleChange = (e) => {
        let searchValue = e.target.value
        setSearchText(searchValue)
        buildCurrentPage(searchValue)        
    }

    const buildCurrentPage = (searchName) => {
        //console.log('search name: ', searchName)
        let currentSearchResult = [{}]

        if(!searchName){
            currentSearchResult = phonebookEntries 
        } else {
            //find all entries where first name part or last name part match the search criteria
            currentSearchResult = phonebookEntries.filter(item => {
                if((item.firstName.toLowerCase().includes(searchName.toLowerCase())) || 
                   (item.lastName.toLowerCase().includes(searchName.toLowerCase()))) {
                    return item
                }
            })                                            
        }
        setSearchResult(currentSearchResult)
        //setPagination(currentSearchResult, 1, 1, true)
    }

    const handlePrevPage = () => {
        //console.log('current page: ', currentPage)
        //console.log('first page: ', firstPage)
        //console.log('second page: ', secondPage)
        //console.log('third page: ', thirdPage)


        if(currentPage <= 1){
            //no action to be performed
            return null
        }

        if(currentPage === firstPage){
            //we are already on the first page of this list. The page numbers need to be updated 
            setFirstPage(firstPage -1)
            setSecondPage(secondPage - 1)
            setThirdPage(thirdPage - 1)
            //decrement pages in sets of 3
            setPagination(searchResult, (currentPage - 3), (currentPage - 1))
        } else {
            setPagination(searchResult, firstPage, (currentPage - 1))
        }
    }

    const handleNextPage = () => {
        let maxPage = Math.ceil( searchResult.length / entriesPerPage)

        if (currentPage >= maxPage){
            //we are already at the last page. No action needed
            return null
        }

        if(currentPage === thirdPage){
            //we are already on the first page of this list. The page numbers need to be updated 
            setFirstPage(firstPage + 1)
            setSecondPage(secondPage + 1)
            setThirdPage(thirdPage + 1)
            setPagination(searchResult, (currentPage + 1), (currentPage + 1))
        } else {
            setPagination(searchResult, firstPage, (currentPage + 1))
        }
    }

    const handlePageClick = (pageNum) => {
        if(pageNum === "1"){
            //first page clicked
            setPagination(searchResult, firstPage, firstPage)
        } else if(pageNum === "2"){
            //second page requested
            setPagination(searchResult, firstPage, secondPage)
        } else {
            // third page requested
            setPagination(searchResult, firstPage, thirdPage)
        }
    }

    const handleEntriesPerPage = (e) => {
        newEntriesPerPage = parseInt(e.target.value, 10)

        setEntriesPerPage(newEntriesPerPage)
        //setPagination(searchResult, 1, 1, true)
    }

    
    return(
        <>
        {!user &&
            <></>
        }
        {user &&
        <div className="Phonebook">
            <div className="Searchbar">
                <h3>Phonebook</h3> 
                      
                <span className="SearchCard">
                    <form className="Searchbox" >
                        <div className="InputContainer">
                            <span>
                                <input 
                                    className="InputBox"
                                    placeholder= "search members..."
                                    type="search" 
                                    value={searchText}
                                    onChange={handleChange}
                                />
                            </span>
                        </div>
                    </form>    
                </span> 
            </div>
            
            <div className="Phonelist">
                <div className="table-scroll">
                    <table>
                        <thead>
                            <tr>
                                <th>
                                    <span className="text" onClick={handleFirstNameSort}>First Name
                                    {firstNameSortAscending &&
                                        <span><i className="fas fa-sort-up"></i></span>
                                    }
                                    {firstNameSortDescending && 
                                        <span><i className="fas fa-sort-down"></i></span>
                                    }
                                    </span>
                                </th>
                                <th>
                                    <span className="text" onClick={handleLastNameSort}>Last Name
                                    {lastNameSortAscending &&
                                        <span><i className="fas fa-sort-up"></i></span>
                                    }
                                    {lastNameSortDescending && 
                                        <span><i className="fas fa-sort-down"></i></span>
                                    }
                                    </span>
                                </th>

                                <th><span className="text">Phone Number</span></th>
                            </tr>
                        </thead>

                        <tbody>
                            <PhonebookEntry
                                phonebookList={currentPageList}
                            />
                        </tbody>               
                    </table>                    
                </div>    
            </div>
            <div className="pagefooter">
                <div className="PageSelection"> 
                    <form>   
                        <select 
                            name="perPage"  
                            size="1"
                            value={entriesPerPage} 
                            onChange={(e)=>{handleEntriesPerPage(e)}}
                        >
                            <option value="10">10 </option>
                            <option value="20">20 </option>
                            <option value="50">50 </option>
                        </select> 
                        <label htmlFor="perPage"> ...entries per page</label>
                    </form>                
                </div>
                
                <div> 
                    Showing {pageStartEntry} to {pageEndEntry} of {totalCurrentEntries}  
                </div>

                <div className="pagination">
                    {showChevron && 
                        <span onClick={handlePrevPage}>&laquo;</span>
                    }    
                    {firstPage &&
                        <span 
                            className={firstPageStyle}
                            onClick={()=>{handlePageClick("1")}}
                        >
                            {firstPage} 
                        </span>
                    }
                    {secondPage &&
                        <span 
                            className={secondPageStyle}
                            onClick={()=>{handlePageClick("2")}}
                        >
                            {secondPage} 
                        </span>
                    }
                    {thirdPage &&
                        <span 
                            className={thirdPageStyle}
                            onClick={()=>{handlePageClick("3")}}
                        >
                            {thirdPage} 
                        </span>
                    }
                
                    {showChevron &&
                        <span onClick={handleNextPage}>&raquo;</span>
                    }    
                </div>
            </div>
            
        </div>
        }
        </>
    )
}  

export default PhonebookTest
