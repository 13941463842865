import axios from 'axios'

import { MEMBERSHIP_TRAN_URL } from './urls'
import { logAxiosError } from './functions';
import { AXIOS_TIMEOUT } from './constants'

let cacheTransactions = null

export function requestTransactionList(refresh, membershipId) {
    if(refresh){
        cacheTransactions = null
    }
    if(!cacheTransactions){
        //fetch the transactions corresponding to the membership
        
        const getTransactionList = async () => {
            let res

            try{
                res = await axios({
                    method: 'GET',
                    timeout: AXIOS_TIMEOUT,
                    url: MEMBERSHIP_TRAN_URL,
                    params: {
                        membershipId: membershipId
                    }
                })
            } catch(error){
                console.log('Error fetching transactions')
                logAxiosError(error)     
            }
        
            const {data} = res

            let transactionList = data.data
            return transactionList
        }  
        
        cacheTransactions = getTransactionList()
        return cacheTransactions
    }
    return cacheTransactions
}
