import React from 'react';

export default () => {

    return (
        <div className="Blank">
            <h3> Contact Page goes here</h3>
        </div>
    )
}
