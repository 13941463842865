import React, { useEffect, useState } from 'react'
import axios from 'axios'

import{ MEMBER_REGISTER_URL, 
        STAFF_UPDATE_MEMBERSHIP_URL,
        STAFF_CANCEL_REGISTER_URL }  
        from '../../utils/urls'

import { logAxiosError } from '../../utils/functions';
import { AXIOS_TIMEOUT, AXIOS_LONG_TIMEOUT } from '../../utils/constants'

import './RegistrationForm.css'

import ViewMembers from "./ViewMembers"

const UpdateRegistration = (props) =>  {

    let { user, history, updateChildMessage, match, updateMembers } = props
    let showMembers = false

    const [address, setAddress] = useState()
    const [city, setCity] = useState()
    const [postal, setPostal] = useState()
    const [primaryEmail, setPrimaryEmail] = useState()
    const [primaryPhone, setPrimaryPhone] = useState()
    const [emergencyContact, setEmergencyContact] = useState()
    const [emergencyPhone, setEmergencyPhone] = useState()
    const [adults, setAdults] = useState()
    const [juniors, setJuniors] = useState()
    const [membership, setMembership] = useState()
    const [tagsCollected, setTagsCollected] = useState()
    const [viewMembers, setViewMembers] = useState({})
    const [authorizedUser, setAuthorizedUser] = useState(false)
    const [confirmCancel, setConfirmCancel] = useState(false)
    const [reason, setReason] = useState("duplicate")
    const [notes, setNotes] = useState()
    const [refundType, setRefundType] = useState("square")
    const [sendEmail, setSendEmail] = useState()
    const [membershipId, setMembershipId] = useState()
    const [registrationCancelled, setRegistrationCancelled] = useState()
    const [tagsText, setTagsText] = useState()
    const [tagsButtonText, setTagsButtonText] = useState()

    let processRequest = true

    useEffect(()=> {
        if(!user){
            updateChildMessage("", "Please Login to continue...", "primary", 3000)
            history.push('/login')
        } else if(user.isStaff || user.isExec || user.isInstructor || user.isAdmin) {
            setAuthorizedUser(true)
            setRegistrationCancelled(false)
            setSendEmail(false)
        } else {
            setAuthorizedUser(false)
            history.push('/')
        }
    },[])

    useEffect(()=>{
        //return to the previous page once the registration has been cancelled successfully
        //console.log('reg-can-set')
        if(registrationCancelled){
            //console.log('going back')
            history.push('/memberlist')
        }
    },[registrationCancelled])

    useEffect(()=>{
        if(tagsCollected){
            setTagsText('Shoe tags have been collected')
            setTagsButtonText('Shoe Tags Returned')
        } else {
            setTagsText('Shoe tags have not been collected')
            setTagsButtonText('Shoe Tags Collected')                
        }    
    },[tagsCollected])

    useEffect(() => {
        if(authorizedUser){
            const getRegisterationData = async () => {
                let res 
                let memId
                processRequest = true

                if(match){
                    memId = match.params.membershipId
                    setMembershipId(memId)
                } 

                try {
                    res = await axios({
                        method: 'GET',
                        timeout: AXIOS_TIMEOUT,
                        url: MEMBER_REGISTER_URL,
                        params: {
                            membershipId: memId
                        }
                    })
                } catch (error) {
                    console.log('Error fetching registration information')
                    logAxiosError(error)       
                    processRequest = false
                }
                
                if(processRequest){
                    const {data} = res

                    const { members, membership } = data.data

                    //console.log('membership ', membership)

                    //populate the fields

                    setAddress(membership.address)
                    setCity(membership.city)
                    setPostal(membership.postalCode)
                    setPrimaryEmail(membership.primaryEmail)
                    setPrimaryPhone(membership.primaryPhone)
                    setEmergencyContact(membership.emergencyName)
                    setEmergencyPhone(membership.emergencyPhone)
                    setAdults(membership.totalAdults)
                    setJuniors(membership.totalJuniors)
                    setMembership(membership.membershipType)
                    setTagsCollected(membership.tagsCollected)

                    setViewMembers(members)  
                    setConfirmCancel(false)

                    //default notes
                    setNotes('Amount refunded in Square')
                }
            }  
            getRegisterationData()
        }
    }, [authorizedUser])   
   
    let membershipLabel = "Adult"

    //determine the value for membership label
    if(membership){
        switch (membership) {
            case "single":
              membershipLabel = "Adult"
              break;
            case "couple":
              membershipLabel = "Couple"
              break;
            case "family":
              membershipLabel = "Family"
              break;
            case "junior":
              membershipLabel = "Junior"
              break;
            case "double":
              membershipLabel = "Single Adult and Single Junior"
              break;
            default:
              membershipLabel = "Adult"  
          }
    }

    /*
    const handleReturnHome = () => {
        history.push('/')
    }
    */

    const onChangeHandlerReason = (e) => {
        setReason(e.target.value)
    }

    const onChangeHandlerNotes = (e) => {
        setNotes(e.target.value)
    }

    const onChangeHandlerEmail = (e) => {
        setSendEmail(e.target.checked)
    }

    const onChangeHandlerRefundType = (e) => {
        setRefundType(e.target.value)
    }
    
    const handleTags = async (e) => {
        //toggle between tags collected and tags not collected
        e.preventDefault()

        let res
        let processRequest = true
        let updatedTagsCollected = !tagsCollected
                
        try{
            res = await axios({
                method: 'POST',
                timeout: AXIOS_TIMEOUT,
                url: STAFF_UPDATE_MEMBERSHIP_URL,
                data: {
                    membershipId: membershipId,
                    tagsCollected: updatedTagsCollected
                }
            })
            //console.log('res', res)
        } catch (error){
            console.log('Error updating tags')
            logAxiosError(error)       
            processRequest = false
        }
                
        if(processRequest){
            let { data }= res.data
            //console.log('data', data)

            if(data.status.success){
                updateChildMessage("Success", "Shoe Tags updated successfully", "success",5000)
                setTagsCollected(data.data.tagsCollected)
            } else {
                updateChildMessage("Error", "Shoe Tags update failed", "danger", 5000)
            }
            //scroll to the top of the page
            window.scroll(0,0)
        }
    }

    const handleCancelRegistration = async (e) => {
        e.preventDefault()
        
        let res
        let processRequest = true
        let status

        //console.log('reason', reason)
        //console.log('refundtype', refundType)

        if(reason > " " && refundType > " "){
            //continue processing
        } else {
            updateChildMessage("Validation Error", "Please provide cancellation reason and refund type", "danger", 7000)
            processRequest = false
        }

        if(processRequest){
            try{
                res = await axios({
                    method: 'POST',
                    timeout: AXIOS_LONG_TIMEOUT,
                    url: STAFF_CANCEL_REGISTER_URL,
                    data: {
                        membershipId: membershipId,
                        reason: reason,
                        notes: notes,
                        refundType: refundType,
                        sendEmail: sendEmail
                    }
                })
    
                //console.log('Cancel Response', res)
                //console.log('status', res.data.data.status)
                status = res.data.data.status
                //console.log('status', status)
                
            } catch (error){
                console.log('Error cancelling registration')
                logAxiosError(error)       
                processRequest = false
            }
        }
        
        if(processRequest){
            if(status.success){
                //check the status of the response
                updateMembers(true)
                setRegistrationCancelled(true)
                updateChildMessage('Success', "Membership successfully cancelled" , "success", 5000)    
            } else {
                processRequest = false
                console.log('status', status)
            }
        }

        if(!processRequest){
            updateChildMessage('Attention', "Error cancelling membership" , "danger", 5000)   
            setConfirmCancel(false) 
            window.scroll(0,0)
        }
    }

    if (viewMembers.length > 0){
        showMembers = true
    }

    return(
        <div className="form-style-10">
            <h1>Registration Details<span>View and Update Membership </span></h1>
            
            <form>
                <div className="section"><span>1</span>Address & Contact Information</div>
                <div className="inner-wrap">
                    <label>Primary Email </label>
                    <p className="readonly">{primaryEmail}</p>
                
                    <label>Primary Phone </label>
                    <p className="readonly">{primaryPhone}</p>

                    <label>Address </label>
                    <p className="readonly">{address}</p>

                    <label>City </label>
                    <p className="readonly">{city}</p>

                    <label>Postal Code </label>
                    <p className="readonly">{postal}</p>

                    <label>Emergency Contact </label>
                    <p className="readonly">{emergencyContact}</p>

                    <label>Emergency Phone </label>
                    <p className="readonly">{emergencyPhone}</p>
                </div>                

                <div className="section"><span>2</span>Membership Information</div>
                <div className="inner-wrap">
                    <label>Membership Type</label>
                    <p className="readonly">{membershipLabel}</p>

                    <label>Number of Adults</label>
                    <p className="readonly">{adults}</p>

                    <label>Number of Juniors</label>
                    <p className="readonly">{juniors}</p>
                </div>    
                
                <div className="section"><span>3</span>Members</div>
                {showMembers &&
                    <ViewMembers 
                        members={viewMembers} 
                    />
                }

                <div className="section"><span>T</span>Shoe Tags</div>
                <div className="inner-wrap">
                    <p> {tagsText} </p>
                </div>
                
                {(user.isStaff || user.isAdmin) &&
                <>
                    {confirmCancel &&
                        <div>
                            <div className="section"><span>C</span>Cancellation Reason & Notes</div>
                            <div className="inner-wrap">
                                <label htmlFor="reason">Cancellation Reason <span className="required">*</span> </label>
                                <select 
                                    name="reason"  
                                    size="1"
                                    value={reason} 
                                    onChange={onChangeHandlerReason}
                                >
                                    <option value="duplicate">Duplicate Membership </option>
                                    <option value="memberInitiated">Member Requested </option>
                                    <option value="clubInitiated">Club Initiated </option>
                                    <option value="switchMembership">Switch to a different membership </option>
                                </select> 

                                <label htmlFor="notes">Payment Notes</label>
                                <input 
                                    type="text" 
                                    name="notes" 
                                    value={notes}
                                    onChange={onChangeHandlerNotes}
                                />    

                                <label htmlFor="refundType">Refund Method <span className="required">*</span> </label>
                                <select 
                                    name="refundType"  
                                    size="1"
                                    value={refundType} 
                                    onChange={onChangeHandlerRefundType}
                                >
                                    <option value="square">Online Square </option>
                                    <option value="card">Credit Card </option>
                                    <option value="cash">Cash </option>
                                    <option value="cheque">Cheque </option>
                                    <option value="other">Other </option>
                                </select>
                            </div>    
                        </div>    
                    }

                    <div className="button-section">
                        {!confirmCancel &&
                            <div className="buttons">
                                <button onClick={(e)=>{handleTags(e)}}> {tagsButtonText} </button>
                                <button onClick={()=>{setConfirmCancel(true)}}> Cancel Membership </button>
                            </div>
                        }
                        {confirmCancel &&
                            <> 
                                <label className="checkbox" htmlFor="sendEmail">
                                    Notify Member of cancellation via email

                                    <input 
                                        type="checkbox" 
                                        name="sendEmail"
                                        checked={sendEmail}
                                        onChange={onChangeHandlerEmail}
                                    /> 
                                </label>

                                <button onClick={handleCancelRegistration}> Confirm Cancel Membership </button>
                            </>
                        }
                    </div>    
                </>
                }

            </form>  
        </div>  
    )   
}  

export default UpdateRegistration
