import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';

import './Lessons.css'

import {requestLessons} from '../../utils/fetchLessons'

const Lessons = () => {

  const[lessonData, setLessonData] = useState({})

  useEffect(() => {    
        requestLessons().then(lessonsData => {
            let data = {}
            data.lessonsTitle = lessonsData.lessonsTitle
            data.lessonsText = lessonsData.lessonsText
            data.upperLeftBox = lessonsData.upperLeftBox
            data.upperRightBox = lessonsData.upperRightBox
            data.bannerTitle = lessonsData.bannerTitle
            data.bannerText = lessonsData.bannerText
            data.lowerLeftBox = lessonsData.lowerLeftBox
            data.lowerRightBox = lessonsData.lowerRightBox 
            data.bottomBannerText = lessonsData.bottomBannerText

            //console.log('lessons-data', data)
            setLessonData(data)
            window.scrollTo(0, 0)
        })
  }, [])   
  
  return (
    <div className="Lessons">
      
      <div className="LessonsTitle"> {lessonData.lessonsTitle} </div>  
      <div className="LessonsTitleText"> {lessonData.lessonsText} </div>

      <div className="Textcontainer"> 
        <div className="Para"> 
          <ReactMarkdown>{lessonData.upperLeftBox}</ReactMarkdown>
        </div>
        <div className="Para"> 
          <ReactMarkdown>{lessonData.upperRightBox}</ReactMarkdown>
        </div>
      </div>
      
      <div className="Bulletin">
        <div className="B-title"> {lessonData.bannerTitle} </div>
      </div>
      
      <div className="Textcontainer"> 
        <div className="Para"> 
          <ReactMarkdown>{lessonData.lowerLeftBox}</ReactMarkdown>
        </div>
        <div className="Para"> 
          <ReactMarkdown>{lessonData.lowerRightBox}</ReactMarkdown>
        </div>
      </div>

      <div className="Bulletin">
        <div className="B-content"> {lessonData.bottomBannerText} </div>  
      </div>
    </div>
  );
}

export default Lessons
