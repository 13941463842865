import axios from 'axios'

import { LESSONS_URL } from './urls'
import { logAxiosError } from './functions';
import { AXIOS_TIMEOUT } from './constants'

let cacheLessons = null

export function requestLessons(refresh) {
    if(refresh){
        cacheLessons = null
    }
    if(!cacheLessons){
        //fetch the configuration variables
        
        const getLessons = async () => {
            let res 

            try{
                res = await axios({
                    method: 'GET',
                    timeout: AXIOS_TIMEOUT,
                    url: LESSONS_URL,
                })
                //console.log('config-res', res)
            } catch(error) {
                console.log('Error fetching lessons')
                logAxiosError(error)           
            }
        
            let lessonData = res.data
            return lessonData
        }  
        cacheLessons = getLessons()
        return cacheLessons
    }
    return cacheLessons
}
