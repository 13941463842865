import axios from 'axios'

import { PHONEBOOK_URL } from './urls'
import { logAxiosError } from './functions';
import { AXIOS_TIMEOUT } from './constants'

//import testList from './phonebook.json'

let cachePhoneBook = null

export function requestPhoneList(refresh) {
    if(refresh){
        cachePhoneBook = null
    }
    if(!cachePhoneBook){
        //fetch the phone book entries and return the list 
        
        const getPhoneList = async () => {
            let res 

            try{
                res = await axios({
                    method: 'GET',
                    timeout: AXIOS_TIMEOUT,
                    url: PHONEBOOK_URL,
                })
            } catch(error) {
                console.log('Error fetching phonebook')
                logAxiosError(error)           
            }
        
            const {data} = res
            //console.log('data: ', data.data)

            //console.log('phone-data: ', data)
            let phoneList = data.data
            return phoneList
        }  
        
        cachePhoneBook = getPhoneList()
        return cachePhoneBook
    }
    return cachePhoneBook
}
