import React, { useEffect, useState } from 'react'

import axios from 'axios'

import CountdownTimer from '../../components/CountdownTimer'
import FamilyEntry from './FamilyEntry'
import { compareValues, logAxiosError } from '../../utils/functions'
import { 
        FAMILY_MEMBERS_URL,
        EVENTS_SELF_REGISTER_URL,
        EVENTS_SELF_CANCEL_URL
    } from '../../utils/urls'

import { AXIOS_TIMEOUT } from '../../utils/constants'

import './EventList.css'

/* 
    Documentation
    State Variables:
    eventListEntries    - the total set of all eventList entries 
    searchText  - user entered text to search. It will search both first and last names for contained characters
                - it can search only 1 name (no spaces)
    searchResult    - the result of the search. This array is the total list of entries that will be shown to the user (over multiple pages)
                    - it is a subset of eventListEntries. It equals the eventListEntries when no search is specified
    
    currentPage     - The page# of the current page the user is on. This is updated everytime the user navigates to a new page

    totalCurrentEntries     - the total# of entries based on the current search criteria
    pageStartEntry          - the serial# of the first item being displayed on the screen
    pageEndEntry            - the serial# of the last item being displayed on the screen
    currentPageList         - the list of entries that are shown to the user on the active page
    entriesPerPage          - user selected variable. Determines how many entries will be shown per page

    Pagination Bar:                
        This Bar is shown on the bottom right only if there is more than 1 page to diplay
        firstPage   - The Page Number associated with the first page shown on the pagenation bar 
        secondPage  - The Page Number associated with the second page shown on the pagenation bar 
        thirdPage   - The Page Number associated with the third page shown on the pagenation bar 
        showChevron - Set if there is more than 1 page to display
    
    Sort Fields
    First Name Ascending    -> firstNameSortAscending -> Default Sort Order when entering the page
    First Name Descending   -> firstNameSortDescending
    Last Name Ascending     -> lastNameSortAscending
    Last Name Descending    -> lastNameSortDescending
    Note: Only 1 sort will be active at any give time

*/
const FamilyList = ({ history, user, match, updateChildMessage, updateUser}) =>  {
    const [eventListEntries, setEventListEntries] = useState([{}])
    const [searchText, setSearchText] = useState('')
    const [searchResult, setSearchResult] = useState([{}])
    const [statusSortAscending, setStatusSortAscending] = useState(true)
    const [statusSortDescending, setStatusSortDescending] = useState(false)
    const [firstNameSortAscending, setFirstNameSortAscending] = useState(false)
    const [firstNameSortDescending, setFirstNameSortDescending] = useState(false)
    const [lastNameSortAscending, setLastNameSortAscending] = useState(false)
    const [lastNameSortDescending, setLastNameSortDescending] = useState(false)
    const [firstPage, setFirstPage] = useState()
    const [secondPage, setSecondPage] = useState()
    const [thirdPage, setThirdPage] = useState()
    const [firstPageStyle, setFirstPageStyle] = useState('')
    const [secondPageStyle, setSecondPageStyle] = useState('')
    const [thirdPageStyle, setThirdPageStyle] = useState('')
    const [showChevron, setShowChevron] = useState(false)
    const [currentPage, setCurrentPage] = useState()
    const [totalCurrentEntries, setTotalCurrentEntries] = useState()
    const [pageStartEntry, setPageStartEntry] = useState()
    const [pageEndEntry, setPageEndEntry] = useState()
    const [currentPageList, setCurrentPageList] = useState([{}])
    const [entriesPerPage, setEntriesPerPage] = useState()
    const [entriesFound, setEntriesFound] = useState(true)
    const [refresh, setRefresh] = useState()
    const [authorizedUser, setAuthorizedUser] = useState(false)

    const [registrationOpen, setRegistrationOpen] = useState(false)
    const [registrationClosed, setRegistrationClosed] = useState(false)
    const [timeoutId, setTimeoutId] = useState()
    const [timeoutId2, setTimeoutId2] = useState()
    const [timeToBooking, setTimeToBooking] = useState({
        days: 0,
        hours: 0, 
        minutes: 0,
        seconds: 0
    })

    const [timeToClosing, setTimeToClosing] = useState({
        days: 0,
        hours: 0, 
        minutes: 0,
        seconds: 0
    })

    const [eventStartDateTimeD, setEventStartDateTime] = useState()
    const [bookingStartDateTimeD, setBookingStartDateTime] = useState()
    const [bookingEndDateTimeD, setBookingEndDateTime] = useState()
    const [bookingStartDateTimeLocal, setBookingStartDateTimeLocal] = useState()
    const [bookingEndDateTimeLocal, setBookingEndDateTimeLocal] = useState()
    const [openingTimerStarted, setOpeningTimerStarted] = useState(false)
    const [closingTimerStarted, setClosingTimerStarted] = useState(false)

    const[enableButtons, setEnableButtons] = useState(false)
    const[eventMessage, setEventMessage] = useState()
    const[eventCreditMessage, setEventCreditMessage] = useState()

    const TIMER_NAME_OPEN = "Registration Opens in:"
    const TIMER_NAME_CLOSE = "Registration closes in:"

    let eventId = match.params.id
    let eventTitle = match.params.title
    let selectedEvent = {}
    let eventMsg = ''

    //console.log('promotionSpotsAvailable', promotionSpotsAvailable)

    let newEntriesPerPage = 8

    // noshow and attended buttons are only show on or after the event date
    
    const _displayDateTime = (dateTime) => {
        let options = { 
            weekday: 'long', 
            year: 'numeric', 
            month: 'long', 
            day: 'numeric',
            hour:   '2-digit',
            minute: '2-digit',
        };

        let displayDateTime = new Date(dateTime).toLocaleDateString(undefined, options);

        return displayDateTime.toString()
    }

    useEffect(()=> {
        if(!user){
            // child message not showing in this case. Investigate Later
            // updateChildMessage("", "Please Login to continue...", "primary", 0)
            history.push('/login')
        } else if(user.isMember || user.isExec) {
            setAuthorizedUser(true)
        } else {
            setAuthorizedUser(false)
            history.push('/')
        }
    },[])

    useEffect(()=>{
        setRegistrationOpen(false)
        setRegistrationClosed(false)
        setClosingTimerStarted(false)
        setOpeningTimerStarted(false)
        //console.log('current-event', currentEvent)
    },[])

    useEffect(() => {   
        //console.log('event-booking-bookingStartDateTime', bookingStartDateTimeLocal)

        if(bookingStartDateTimeLocal && !registrationOpen){
            const initTimeoutId = setTimeout(() => {
                //update timer every 1 second
                let now = new Date().getTime();
                let distance = bookingStartDateTimeLocal - now
                
                if(distance <= 0){
                    //user can register
                    setRegistrationOpen(true)
                } else {
                    //calculate the time for opening of registration
    
                    let currentTimeToBooking ={}
                    // Time calculations for days, hours, minutes and seconds
                    currentTimeToBooking.days = Math.floor(distance / (1000 * 60 * 60 * 24));
                    currentTimeToBooking.hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                    currentTimeToBooking.minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
                    currentTimeToBooking.seconds = Math.floor((distance % (1000 * 60)) / 1000);
    
                    setTimeToBooking(currentTimeToBooking)

                    if(!openingTimerStarted){
                        setOpeningTimerStarted(true)
                    }
                }
            }, 1000);
    
            setTimeoutId(initTimeoutId)
    
            if(registrationOpen){
                clearTimeout(timeoutId)
            }
        }
    },[bookingStartDateTimeLocal, timeToBooking]) 

    useEffect(() => {   
        //console.log('event-booking-bookingEndDateTime', bookingEndDateTimeLocal)

        if(bookingEndDateTimeLocal && registrationOpen && !registrationClosed){
            const initTimeoutId2 = setTimeout(() => {
                //update timer every 1 second
                let now = new Date().getTime();
                let distance = bookingEndDateTimeLocal - now
                
                if(distance <= 0){
                    //registration has closed. User cannot register
                    setRegistrationClosed(true)
                } else {
                    //calculate the time for end of registration
    
                    let currentTimeToClosing ={}
                    // Time calculations for days, hours, minutes and seconds
                    currentTimeToClosing.days = Math.floor(distance / (1000 * 60 * 60 * 24));
                    currentTimeToClosing.hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                    currentTimeToClosing.minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
                    currentTimeToClosing.seconds = Math.floor((distance % (1000 * 60)) / 1000);
                    
                    setTimeToClosing(currentTimeToClosing)

                    if(!closingTimerStarted){
                        setClosingTimerStarted(true)
                    }
                }
            }, 1000);
    
            setTimeoutId2(initTimeoutId2)
    
            if(registrationClosed){
                clearTimeout(timeoutId2)
            }
        }
    },[bookingEndDateTimeLocal, timeToClosing, registrationOpen]) 

    useEffect(() => {
        if(registrationOpen && !registrationClosed){
            setEnableButtons(true)
        } else {
            setEnableButtons(false)
        }
    },[registrationOpen, registrationClosed])

    useEffect(() => {
        if(authorizedUser){
            setStatusSortAscending(true)
            setStatusSortDescending(false)
            setFirstNameSortAscending(false)
            setFirstNameSortDescending(false)
            setLastNameSortAscending(false)
            setLastNameSortDescending(false)
            setRefresh(false)

            //defualt to 8 entries per page
            if(!entriesPerPage){
                setEntriesPerPage(8)
            }

            const getFamilyList = async () => {
                let res
                let processRequest = true

                try {
                    res = await axios({
                        method: 'GET',
                        timeout: AXIOS_TIMEOUT,
                        url: FAMILY_MEMBERS_URL,
                        params: {
                            membershipId: user.membershipId,
                            eventId: eventId
                        }
                    })          
                } catch (error) {
                    processRequest = false
                    console.log('Error Fetching Event')            
                    logAxiosError(error)
                    setEntriesFound(false)
                }

                if(processRequest){
                    const {data} = res
                    //console.log('res: ', res)

                    selectedEvent = data.data.selectedEvent
                    
                    //console.log('eventData', selectedEvent)

                    EventInfo(selectedEvent)

                    if(eventMsg > ' '){
                        updateChildMessage('', eventMsg, 'primary', 8000)
                    }

                    const eventMembers =  data.data.familyMembers   
                    
                    //start of code

                    //console.log('get events: ', data)
                
                    let familyMembers = [{}]
                    // members that were registered but cancelled
                    let tempCancelledMembers = [{}]

                    if( eventMembers && eventMembers.length > 0){
                        let firstEntry = true
                        let firstCancelledEntry = true
                        
                        for(let idx=0; idx<eventMembers.length; idx++){
                            let temp = {...eventMembers[idx]}
                            
                            //console.log('event-member', temp)
                            
                            if((temp.status === "cancelled") || (temp.status === "eventcancelled")){
                                //move this record to a temporary array
                                if(firstCancelledEntry){
                                    tempCancelledMembers[0] = temp
                                    firstCancelledEntry = false
                                } else {
                                    tempCancelledMembers.push(temp)
                                }
                            } else {
                                if(firstEntry){
                                    familyMembers[0] = temp    
                                    firstEntry = false
                                } else {
                                    familyMembers.push(temp)
                                }
                            }
                        }
                        
                        //check if any of the cancelled members are in the familyMembers array.
                        //if not, add the row to Family Members
                        //console.log('cancelled events', tempCancelledEvents)

                        if(Object.keys(tempCancelledMembers[0]).length > 0){
                            if(familyMembers.length > 0){
                                let cancMember
                                for(let idx=0; idx<tempCancelledMembers.length; idx++){
                                    cancMember = familyMembers.find(currMember => 
                                        currMember.memberId === tempCancelledMembers[idx].memberId
                                    )       
                                    
                                    //if(Object.keys(cancEvent).length === 0){
                                    if(!cancMember){
                                        //member not found in family members array
                                        //add cancelled member to the family members array
                                        if(Object.keys(familyMembers[0]).length > 0){
                                            //we have at least 1 member in the array
                                            familyMembers.push(tempCancelledMembers[idx])
                                        } else {
                                            //set the first member to the cancelled member
                                            familyMembers[0] = tempCancelledMembers[idx]    
                                        }
        
                                        //console.log('current-events-ad', currentEvents)
                                    }
                                }
                            } else {
                                //Member has signed up and cancelled from all current events
                                //set currentEvents to tempCancelledEvents
                                familyMembers = tempCancelledMembers
                            }
                        }
                    }

                    const eventData = familyMembers
                    //end of code
                     

                    eventData.sort(compareValues('displayStatus'))

                    setEventListEntries(eventData)
                    setSearchResult(eventData)
                    //updateeventList(false)
                    setEntriesFound(true)
                    }
            }

            getFamilyList()           
        }
    }, [refresh, authorizedUser])   

    useEffect(()=>{
        if(authorizedUser){
            setPagination(searchResult, 1, 1, true)
        }
    },[entriesPerPage, searchResult, authorizedUser])   


    const EventInfo = (e) => {
        //takes eventInfo parameter "e"

        setEventStartDateTime(_displayDateTime(e.eventStartDateTime))
        setBookingStartDateTime(_displayDateTime(e.bookingStartDateTime))
        setBookingEndDateTime(_displayDateTime(e.bookingEndDateTime))

        let tempDate = new Date(e.bookingStartDateTime)
        setBookingStartDateTimeLocal(tempDate)

        let tempDate2 = new Date(e.bookingEndDateTime)
        setBookingEndDateTimeLocal(tempDate2)

        let onlineSpotsAvailable = 0

        if((e.currentOnlineParticipants < e.maxOnlineParticipants)                  
        && ((e.currentParticipants + e.currentWaitingList) < e.maxParticipants)) {
            let onlineSpots1 = e.maxOnlineParticipants - e.currentOnlineParticipants
            let onlineSpots2 = e.maxParticipants - (e.currentParticipants + e.currentWaitingList)

            if(onlineSpots1 > onlineSpots2){
                onlineSpotsAvailable = onlineSpots2
            } else {
                onlineSpotsAvailable = onlineSpots1
            }
        } 

        let regularSpotsAvailable =  e.maxParticipants - (e.currentParticipants + e.currentWaitingList)

        let waitListSpotsAvailable = 0

        if(e.enableWaitlist && regularSpotsAvailable <= 0){
            waitListSpotsAvailable = (e.maxParticipants + e.waitlistLimit)
                                    - (e.currentParticipants + e.currentWaitingList)  
        }
        
        let message = ''
        let creditMessage = ''

        if(onlineSpotsAvailable > 0){
            message = `${onlineSpotsAvailable} online spots are available` 
        } else if(regularSpotsAvailable > 0){
            message = `${regularSpotsAvailable} spots are available. Please call the club to reserve your spot` 
        } else if(waitListSpotsAvailable > 0){
            message = `${waitListSpotsAvailable} waitlist spots are available. Please call the club to reserve a spot` 
        } else {
            message = `Sorry!!! Event is currently full` 
        }

        if(e.tokensPerSpot > 0){
            if(e.tokensPerSpot === 1){
                creditMessage = 'This event requires 1 credit per person to sign-up'
            } else {
                creditMessage = ` This event requires ${e.tokensPerSpot} credits per person to sign-up`
            }
        }

        setEventCreditMessage(creditMessage)
        setEventMessage(message)
    }
    
    const handleConfirmCancel = (memberId) => {
        //console.log('confirm-cancel:', memberId)
        submitRequest("cancellation", memberId)
    }

    const handleRegister = (memberId) => {
        //console.log('register:', memberId)
        submitRequest("register", memberId)
    }

    const submitRequest = async (request, memberId) => {
        //console.log('submitting PUT request')
        let URL = ''

        if(request === "register"){
            URL = EVENTS_SELF_REGISTER_URL
        } else if (request === "cancellation"){
            URL = EVENTS_SELF_CANCEL_URL
        }        

        //console.log('event-id', eventId)

        const postRequest = async () => {
            let res
            let processRequest = true

            try{
                res = await axios({
                    method: 'POST',
                    timeout: AXIOS_TIMEOUT,
                    url: URL,
                    data: {
                        memberId: memberId,
                        eventId: eventId,
                        request: request
                    }
                })

               //console.log('event post res', res)
            } catch (error) {
                console.log('Error posting event request', URL)
                logAxiosError(error)       
                processRequest = false
            }
            
            //display messages upon return
            //console.log('res', res)
            if(processRequest){
                let { data } = res.data
                setRefresh(true)
                
                if(data && data.processLog.success){
                    if(request === "register"){
                        updateChildMessage('Success', 'Booking successful', 'success', 5000)
                    } else {
                        updateChildMessage('Success', 'Cancellation successful', 'success', 5000)
                    }

                    user.credits = data.runningCredit
                    updateUser(user)
                } else{
                    //check the status and put out appropriate message
                    let msg = 'Booking Failed -> '
                    let errorMessage = 'Sorry !!! Booking Failed - Unknown Reason'
                    console.log('error-data', data)
                    if(data){
                        if(data.processLog.memberAlreadySignedUp){
                            errorMessage = msg + "Member has already been signed up for this event"
                        }
                        if(data.processLog.failedUpdatingEvent){
                            errorMessage = msg + "System busy - Please try again later"
                        }
                        if(data.processLog.onlineBookingFull){
                            errorMessage = msg + "Online Booking Full"
                        }
                        if(data.processLog.insuffficientCredits){
                            errorMessage = msg + "Member has insufficient credits"
                        }
                        if(data.processLog.blacklist){
                            errorMessage = msg + "Member is blacklisted"
                        }
                    } else {
                        errorMessage = "System Failure. Please try again later"
                    }

                    updateChildMessage('Error', errorMessage, 'danger', 10000)
                }
            }
            window.scroll(0,0)
        }
        postRequest()    
    }

    const setPagination = (list, startPage=1, thisPage=1, rebuildPage=false) => {
        let totalEntries = list.length
        
        let totalPages = Math.ceil(totalEntries / entriesPerPage)
        //console.log('params: ', totalPages, startPage, thisPage)

        if((thisPage !== currentPage) || rebuildPage){
            setCurrentPage(thisPage)
            //show the entries for this page
            setTotalCurrentEntries(totalEntries)
            let startingEntry = 0
            let endingEntry = 0
            let newCurrentPageList = [...list]

            if(totalEntries > 0){
                startingEntry = ((thisPage - 1) * entriesPerPage) + 1
                endingEntry = startingEntry + entriesPerPage - 1
                if (endingEntry > totalEntries){
                    endingEntry = totalEntries
                } 
                newCurrentPageList = [...list].slice((startingEntry-1), (endingEntry))
            }
            
            setPageStartEntry(startingEntry)
            setPageEndEntry(endingEntry)
            setCurrentPageList(newCurrentPageList)
            //console.log('current list: ', newCurrentPageList)
        }

        switch(totalPages){
            case 0:
            case 1:    
                setFirstPage(null)
                setSecondPage(null)
                setThirdPage(null)                
                setShowChevron(false)
                break
            case 2:
                setFirstPage(1)
                setSecondPage(2)
                setThirdPage(null)                
                setShowChevron(true)

                if (thisPage === 1){
                    setFirstPageStyle('active')
                    setSecondPageStyle('')
                } else {
                    setFirstPageStyle('')
                    setSecondPageStyle('active')
                }

                break
            default:
                if((totalPages > 3) && (startPage + 2) > totalPages){
                    //there are more than 3 pages. Align the last set to show the last 3 pages
                    startPage = totalPages - 2
                }

                if((totalPages > 0) && (startPage <= 0)){
                    //show the first page if there are pages to display if an invalid start page is requested
                    startPage = 1
                }

                if(startPage <= totalPages){
                    setFirstPage(startPage)
                } else {
                    setFirstPage(null)
                } 
                
                if((startPage + 1) <= totalPages){
                    setSecondPage(startPage + 1)
                } else {
                    setSecondPage(null)
                } 

                if((startPage + 2) <= totalPages){
                    setThirdPage(startPage + 2)     
                } else {
                    setThirdPage(null)
                }   
                
                if (thisPage === startPage) {
                    //first page is active
                    setFirstPageStyle('active')
                    setSecondPageStyle('')
                    setThirdPageStyle('')
                } else if (thisPage === (startPage + 1)) {
                    //second page is active
                    setFirstPageStyle('')
                    setSecondPageStyle('active')
                    setThirdPageStyle('')
                } else {
                    // third page is active
                    setFirstPageStyle('')
                    setSecondPageStyle('')
                    setThirdPageStyle('active')
                }
                setShowChevron(true)
        }
    }

    const handleFirstNameSort = () => {
        let currentEventList = searchResult

        if(firstNameSortAscending){
            setFirstNameSortDescending(true)
            setFirstNameSortAscending(false)
            setLastNameSortAscending(false)
            setLastNameSortDescending(false)
            setStatusSortAscending(false)
            setStatusSortDescending(false)

            currentEventList.sort(compareValues('firstName', 'desc'))
            setSearchResult(currentEventList)
            setPagination(currentEventList, 1, 1, true)
        } else {
            setFirstNameSortAscending(true)
            setFirstNameSortDescending(false)
            setLastNameSortAscending(false)
            setLastNameSortDescending(false)
            setStatusSortAscending(false)
            setStatusSortDescending(false)

            currentEventList.sort(compareValues('firstName'))
            setSearchResult(currentEventList)
            setPagination(currentEventList, 1, 1, true)
        }
    }

    const handleLastNameSort = () => {
        let currentEventList = searchResult

        if(lastNameSortAscending){
            setFirstNameSortDescending(false)
            setFirstNameSortAscending(false)
            setLastNameSortAscending(false)
            setLastNameSortDescending(true)
            setStatusSortAscending(false)
            setStatusSortDescending(false)

            currentEventList.sort(compareValues('lastName', 'desc'))
            setSearchResult(currentEventList)
            setPagination(currentEventList, 1, 1, true)
        } else {
            setFirstNameSortAscending(false)
            setFirstNameSortDescending(false)
            setLastNameSortAscending(true)
            setLastNameSortDescending(false)
            setStatusSortAscending(false)
            setStatusSortDescending(false)

            currentEventList.sort(compareValues('lastName'))
            setSearchResult(currentEventList)
            setPagination(currentEventList, 1, 1, true)
        }
    }

    const handleStatusSort = () => {
        let currentEventList = searchResult

        if(statusSortAscending){
            setFirstNameSortDescending(false)
            setFirstNameSortAscending(false)
            setLastNameSortAscending(false)
            setLastNameSortDescending(false)
            setStatusSortAscending(false)
            setStatusSortDescending(true)

            currentEventList.sort(compareValues('status', 'desc'))
            setSearchResult(currentEventList)
            setPagination(currentEventList, 1, 1, true)
        } else {
            setFirstNameSortAscending(false)
            setFirstNameSortDescending(false)
            setLastNameSortAscending(false)
            setLastNameSortDescending(false)
            setStatusSortAscending(true)
            setStatusSortDescending(false)

            currentEventList.sort(compareValues('status'))
            setSearchResult(currentEventList)
            setPagination(currentEventList, 1, 1, true)
        }
    }

    const handleChange = (e) => {
        let searchValue = e.target.value
        setSearchText(searchValue)
        buildCurrentPage(searchValue)        
    }

    const buildCurrentPage = (searchName) => {
        //console.log('search name: ', searchName)
        let currentSearchResult = [{}]

        if(!searchName){
            currentSearchResult = eventListEntries 
        } else {
            //find all entries where first name part or last name part match the search criteria
            currentSearchResult = eventListEntries.filter(item => {
                if((item.firstName.toLowerCase().includes(searchName.toLowerCase())) || 
                   (item.lastName.toLowerCase().includes(searchName.toLowerCase()))) {
                    return item
                }
            })                                            
        }
        setSearchResult(currentSearchResult)
    }

    const handlePrevPage = () => {
        if(currentPage <= 1){
            //no action to be performed
            return null
        }

        if(currentPage === firstPage){
            //we are already on the first page of this list. The page numbers need to be updated 
            setFirstPage(firstPage -1)
            setSecondPage(secondPage - 1)
            setThirdPage(thirdPage - 1)
            //decrement pages in sets of 3
            setPagination(searchResult, (currentPage - 3), (currentPage - 1))
        } else {
            setPagination(searchResult, firstPage, (currentPage - 1))
        }
    }

    const handleNextPage = () => {
        let maxPage = Math.ceil( searchResult.length / entriesPerPage)

        if (currentPage >= maxPage){
            //we are already at the last page. No action needed
            return null
        }

        if(currentPage === thirdPage){
            //we are already on the first page of this list. The page numbers need to be updated 
            setFirstPage(firstPage + 1)
            setSecondPage(secondPage + 1)
            setThirdPage(thirdPage + 1)
            setPagination(searchResult, (currentPage + 1), (currentPage + 1))
        } else {
            setPagination(searchResult, firstPage, (currentPage + 1))
        }
    }

    const handlePageClick = (pageNum) => {
        if(pageNum === "1"){
            //first page clicked
            setPagination(searchResult, firstPage, firstPage)
        } else if(pageNum === "2"){
            //second page requested
            setPagination(searchResult, firstPage, secondPage)
        } else {
            // third page requested
            setPagination(searchResult, firstPage, thirdPage)
        }
    }

    const handleEntriesPerPage = (e) => {
        newEntriesPerPage = parseInt(e.target.value, 10)

        setEntriesPerPage(newEntriesPerPage)
        //setPagination(searchResult, 1, 1, true)
    }

    return(
        <>
        {user && !entriesFound &&
            <div className="eventList">
                <h3> No family members found </h3>
            </div>    
        }
        {user && entriesFound &&
        <div className="EventList">
            <div className="Searchbar">
                <h3> Event </h3>
                <span className="EventInfo"> 
                    <h4>{eventTitle} </h4>
                </span>
                <span className="SearchCard">
                    <form className="Searchbox" >
                        <div className="InputContainer">
                            <span>
                                <input 
                                    className="InputBox"
                                    placeholder= "search members..."
                                    type="search" 
                                    value={searchText}
                                    onChange={handleChange}
                                />
                            </span>
                        </div>
                    </form>    
                </span> 
            </div>

            <div className="EventList">
                <div className="EventInfo">
                    <div className="Card">
                        <>
                            <h5> Event Starts On:  </h5>
                            <h5>{eventStartDateTimeD}</h5>
                        </>
                        
                        {!registrationOpen &&
                            <>
                                <p></p>
                                Self Registration Starts On: 
                                <p>{bookingStartDateTimeD}</p>
                                <p></p>
                            </>
                        }
                        
                        {registrationOpen && !registrationClosed &&
                            <>
                                <p></p>
                                Self Registration Ends On: 
                                <p>{bookingEndDateTimeD}</p>
                                <p></p>
                                <p>{eventMessage}</p>
                                <p>{eventCreditMessage}</p>
                            </>
                        }
                    </div>

                    <div className="Card">
                        {!registrationOpen && openingTimerStarted &&
                            <CountdownTimer 
                                timerName={TIMER_NAME_OPEN}
                                days={timeToBooking.days}
                                hours={timeToBooking.hours}
                                minutes={timeToBooking.minutes}
                                seconds={timeToBooking.seconds}
                            />
                        }

                        {registrationOpen && !registrationClosed && closingTimerStarted &&
                            <CountdownTimer 
                                timerName={TIMER_NAME_CLOSE}
                                days={timeToClosing.days}
                                hours={timeToClosing.hours}
                                minutes={timeToClosing.minutes}
                                seconds={timeToClosing.seconds}
                            />
                        }

                        {registrationOpen && registrationClosed &&
                            <>
                                <p>Self Registration/ Cancellation is closed</p>
                                <p>Please call the club if you want to register or cancel</p>
                            </>
                        }

                    </div>
                </div>
                
            </div>

            <div className="EventList">
                <div className="table-scroll">
                    <table>
                        <thead>
                            <tr>
                                <th>
                                    <span className="text" onClick={handleFirstNameSort}>First Name
                                    {firstNameSortAscending &&
                                        <span><i className="fas fa-sort-up"></i></span>
                                    }
                                    {firstNameSortDescending && 
                                        <span><i className="fas fa-sort-down"></i></span>
                                    }
                                    </span>
                                </th>
                                <th>
                                    <span className="text" onClick={handleLastNameSort}>Last Name
                                    {lastNameSortAscending &&
                                        <span><i className="fas fa-sort-up"></i></span>
                                    }
                                    {lastNameSortDescending && 
                                        <span><i className="fas fa-sort-down"></i></span>
                                    }
                                    </span>
                                </th>

                                <th>
                                    <span className="text" onClick={handleStatusSort}>Status
                                    {statusSortAscending &&
                                        <span><i className="fas fa-sort-up"></i></span>
                                    }
                                    {statusSortDescending && 
                                        <span><i className="fas fa-sort-down"></i></span>
                                    }
                                    </span>
                                </th>
                                <th>
                                    <span className="text">Action</span>
                                </th>
                            </tr>
                        </thead>

                        <tbody>
                            <FamilyEntry
                                EventListItem={currentPageList}
                                handleRegister={handleRegister}
                                handleConfirmCancel={handleConfirmCancel}
                                enableButtons={enableButtons}
                            />
                        </tbody>               
                    </table>                    
                </div>    
            </div>
            <div className="pagefooter">
                <div className="PageSelection"> 
                    <form>   
                        <select 
                            name="perPage"  
                            size="1"
                            value={entriesPerPage} 
                            onChange={(e)=>{handleEntriesPerPage(e)}}
                        >
                            <option value="4">4 </option>
                            <option value="8">8 </option>
                            <option value="12">12 </option>
                        </select> 
                        <label htmlFor="perPage"> ...entries per page</label>
                    </form>                
                </div>

                <div> 
                    Showing {pageStartEntry} to {pageEndEntry} of {totalCurrentEntries}  
                </div>

                <div className="pagination">
                    {showChevron && 
                        <span onClick={handlePrevPage}>&laquo;</span>
                    }    
                    {firstPage &&
                        <span 
                            className={firstPageStyle}
                            onClick={()=>{handlePageClick("1")}}
                        >
                            {firstPage} 
                        </span>
                    }
                    {secondPage &&
                        <span 
                            className={secondPageStyle}
                            onClick={()=>{handlePageClick("2")}}
                        >
                            {secondPage} 
                        </span>
                    }
                    {thirdPage &&
                        <span 
                            className={thirdPageStyle}
                            onClick={()=>{handlePageClick("3")}}
                        >
                            {thirdPage} 
                        </span>
                    }
                
                    {showChevron &&
                        <span onClick={handleNextPage}>&raquo;</span>
                    }    
                </div>
            </div>
            
        </div>
        }
        </>
    )
}  

export default FamilyList
