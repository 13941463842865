import React from 'react'
import { getNtrpLabel } from '../../utils/functions';
import { NTRP_RATING_OPTIONS } from '../../utils/constants'

const MemberDetail = (props) => {
    const { member } = props
    
    if(!member){
        return null
    } 

    const onChangeHandler = (e) =>{
        return null
    }

    return(
        member.map((member, idx)=> {
            let adult = false
            let label = "Adult"
            let ntrpSelfLabel = ''

            if(member.memberType === "adult"){
                adult = true
                label = "Adult"
            } else {
                label = "Junior"
            }
            
            let ntrpSelfId = `ntrpSelf-${idx}`
            let ntrpAssessedId = `ntrpAssessed-${idx})`
            let emailId = `email-${idx}`
            let consentNewsLetterId = `consentNewsLetter-${idx}`
      
            let phoneId = `phone-${idx}`
            let consentPhoneBookId = `consentPhoneBook-${idx}`
      
            var memberHeading = `${label} Member`
      
            ntrpSelfLabel = getNtrpLabel(member.ntrpSelf)
            
            return (
                <div key={idx}>
                    <div className="inner-wrap">
                        <span>
                            {memberHeading}  
                        </span>
        
                        <p></p>
                        <label 
                            htmlFor={ntrpSelfId}>NTRP rating - Self Assessed: 
                            <p></p>
                            {ntrpSelfLabel}
                        </label>

                        <p></p>
                        <label 
                            htmlFor={ntrpSelfId}>NTRP Club Rating <span className="required">*</span> 
                        </label>

                        <select 
                            name={ntrpAssessedId}
                            className="ntrpAssessed"  
                            size="1"
                            data-id={idx}
                            id={ntrpAssessedId}
                            value={member.ntrpAssessed}  
                            onChange={onChangeHandler}
                        >    
                            {NTRP_RATING_OPTIONS}
                        </select> 

                        {adult &&
                        <> 
                            <p></p>  
                            <label htmlFor={emailId}>Email</label>
                            <input
                                type="email"
                                name={emailId}
                                data-id={idx}
                                id={emailId}
                                value={member.email} 
                                className="email"
                                onChange={onChangeHandler}
                            />

                            {member.email && 
                                <label htmlFor={consentNewsLetterId} className="checkbox">
                                    <p></p>  
                                    Member wishes to receive club news
                                    <input 
                                        type="checkbox"
                                        name={consentNewsLetterId}
                                        data-id={idx}
                                        id={consentNewsLetterId}
                                        checked={member.consentNewsLetter} 
                                        className="consentNewsLetter"
                                        onChange={onChangeHandler}
                                    />
                                </label>     
                            }

                            <p></p>  
                            <label htmlFor={phoneId}>Phone Number (numbers only - no spaces)</label>
                            <input
                                type="number"
                                name={phoneId}
                                data-id={idx}
                                id={phoneId}
                                value={member.phoneNumber} 
                                className="phoneNumber"
                                maxLength="10"
                                onChange={onChangeHandler}
                            />
                        
                            {member.phoneNumber &&
                            <>
                                <label htmlFor={consentPhoneBookId}  className="checkbox">
                                    <p></p>  
                                    Add phone number to club directory
                                    <input
                                        type="checkbox"
                                        name={consentPhoneBookId}
                                        data-id={idx}
                                        id={consentPhoneBookId}
                                        checked={member.consentPhoneBook} 
                                        className="consentPhoneBook"
                                        onChange={onChangeHandler}
                                    />
                                </label>
                            </>
                            }
                        </>
                        }
                    </div>
                </div>
            )
        })
    )
}

export default MemberDetail