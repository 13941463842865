import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import './EventList.css'

const EventEntry = (props) =>  {
    let { 
        EventListItem, 
        promotionSpotsAvailable, 
        handleConfirmCancel, 
        handleConfirmWaitlist,
        handleNoshow,
        handleAttended,
        eventIsToday 
    } = props

    const [confirmCancel, setConfirmCancel] = useState(false)
    const [confirmMember, setConfirmMember] = useState()

    useEffect(()=> {
        setConfirmCancel(false)
    },[])

    const handleCancelBooking = (e) => {
        //console.log('cancel ', e)
        setConfirmCancel(true)
        setConfirmMember(e)
    }

    return(
        EventListItem.map((entry, idx)=> { 
            let fnIdx = `fn-${entry.memberId}`
            let lnIdx = `ln-${entry.memberId}`
            let stIdx = `st-${entry.memberId}`
            let acIdx = `ac-${entry.memberId}`
 
            let showPromote = false
            let confirmCancelMember = false
            let showNoshow = false
            let showAttended = false
            let attendanceConfirmed = false

            //console.log('status', entry.status)
            //console.log('eventIsToday', eventIsToday)
            if(entry.status === "attended"){
                attendanceConfirmed = true
            }

            if(entry.status === "confirmed" && eventIsToday){
                showNoshow = true
                showAttended = true
            }

            if(promotionSpotsAvailable){
              //check if member is on waitlist
              if(entry.status === "waitlist"){
                showPromote = true
              }
            }

            //console.log('confirmMember', confirmMember)
            //console.log('confirmCancel', confirmCancel)
            //console.log('entry.id', entry.id)

            if(confirmCancel){
                if(entry.memberId === confirmMember){
                    confirmCancelMember = true
                }
            }

            return (
                <>
                    <tr key={entry.memberId}>
                        <td key={fnIdx}><Link to={`/staff/singlemember/${entry.memberId}`} className="link">{entry.firstName}</Link></td>
                        <td key={lnIdx}><Link to={`/staff/singlemember/${entry.memberId}`} className="link">{entry.lastName}</Link></td>
                        <td key={stIdx}>{entry.displayStatus}</td>
                        <td key={acIdx}>
                            {confirmCancelMember && !attendanceConfirmed &&
                                <button className="confirmCancel" onClick={() =>{handleConfirmCancel(entry.memberId)}}> Confirm Cancellation </button>
                            }
                            {!confirmCancelMember && !attendanceConfirmed &&
                                <button className="cancelBookng" onClick={() =>{handleCancelBooking(entry.memberId)}}> Cancel Booking </button>
                            }
                            {showPromote &&
                                <button 
                                    className="Promote" 
                                    onClick={() =>{handleConfirmWaitlist(entry.memberId)}}
                                >
                                    Promote 
                                </button>
                            }
                            {showAttended &&
                                <button 
                                    className="Attended" 
                                    onClick={() =>{handleAttended(entry.memberId)}}
                                >
                                    Attended
                                </button>
                            }
                            {showNoshow &&
                                <button 
                                    className="Noshow" 
                                    onClick={() =>{handleNoshow(entry.memberId)}}
                                >
                                    No Show 
                                </button>
                            }
                            {attendanceConfirmed &&
                                <button 
                                    className="attendanceConfirmed" 
                                    disabled
                                >
                                    Attendance Confirmed 
                                </button>
                            }
                        </td>
                    </tr>    
                </>
            )
        })
    )
}  

export default EventEntry
