import React from 'react'
import {Link} from 'react-router-dom'

import './Members.css'

const WaitlistEntry = ({ membersList }) =>  {   
    if(membersList[0]){
        if(Object.keys(membersList[0]).length === 0){
            //console.log('return null')
            return null
        }
    } else {
        return null
    }
     
    return(
        membersList.map((entry, idx)=> { 
            let key = entry.id

            let idIdx = `id-${key}`
            let fnIdx = `fn-${key}`
            let lnIdx = `ln-${key}`
            let adIdx = `ad-${key}`
            let jnIdx = `jn-${key}`

            let linkedUrl = `/waitlist/update/${entry.id}/${entry.applicantId}/${entry.renewal}`

            return (
                <>
                {key && 
                    <tr key={key.toString()}>
                        <td key={idIdx}><Link to={linkedUrl} className="link">{entry.id}</Link></td>    
                        <td key={fnIdx}><Link to={linkedUrl} className="link">{entry.firstName}</Link></td>  
                        <td key={lnIdx}><Link to={linkedUrl} className="link">{entry.lastName}</Link></td>   
                        <td key={adIdx}><Link to={linkedUrl} className="link">{entry.totalAdults}</Link></td>
                        <td key={jnIdx}><Link to={linkedUrl} className="link">{entry.totalJuniors}</Link></td>
                    </tr>   
                }
                </>
            )
        })
    )
}  

export default WaitlistEntry
