//This Registration Form is for Staff to manually enroll new members

import React, {useState, useEffect } from 'react'
import axios from 'axios'

import ContactDetailsStaff from './ContactDetailsStaff'
import MembersInfo from './MembersInfo'
import PaymentDetailsStaff from './PaymentDetailsStaff'

import {requestFees} from '../../utils/fetchFees'

import {    validateMembershipInputs, 
            validateUserInfoInputs,
            validateStaffPaymentInputs } from '../../utils/validations'

import './RegistrationForm.css'

import * as Constants from '../../Constants'
import {STAFF_REGISTER_URL} from '../../utils/urls'
import { logAxiosError } from '../../utils/functions';
import { AXIOS_LONG_TIMEOUT } from '../../utils/constants'

import {
    MAX_NTRP_RATING,
    MIN_NTRP_RATING
} from '../../utils/constants'

function RegistrationFormStaff (props) {
    const { history, updateChildMessage, user, configValues, updatePhonebook }  = props
    const [authorizedUser, setAuthorizedUser] = useState(false)

    let maxAdults = 2
    let maxJuniors = 6

    if(configValues.maxJuniors > 0){
        maxJuniors = configValues.maxJuniors
    }

    if(configValues.maxAdults > 0){
        maxAdults = configValues.maxAdults
    }

    if(configValues.juniorOnly){
        //only juniors are allowed to register
        maxAdults = 0
    }
    
    //create a dummy user for testing !!!
    /*    
    const user = {
        username: "Frank123@abc.com",
        email: "Frank123@abc.com",
        firstName: "Frank",
        lastName: "Dias"
    } 
    */  

    useEffect(()=> {
        if(!user){
            updateChildMessage("", "Please Login to continue...", "primary", 3000)
            history.push('/login')
        } else if(user.isStaff || user.isAdmin) {
            setAuthorizedUser(true)
        } else {
            setAuthorizedUser(false)
            history.push('/')
        }
    },[])

    
    //define all State Variables to be used. Initialize values wherever needed
    const [firstTimeMemberInput, setFirstTimeMemberInput] = useState(true)
    const [formSubmitted, setFormSubmitted] = useState(false)
    const [membershipFormCompleted, setMembershipFormCompleted] = useState(false)
    const [adults, setAdults] = useState(0)
    const [juniors, setJuniors] = useState(0)
    const [membership, setMembership] = useState()
    const [membershipLabel, setMembershipLabel] = useState()
    const [membershipFee, setMembershipFee] = useState()
    const [hst, setHst] = useState()
    const [totalFee, setTotalFee] = useState()
    const [terms, setTerms] = useState(false)
    const [confirmEntries, setConfirmEntries] = useState(false)

    const [paymentFields, setPaymentFields] = useState(
        {
            paymentType: '',
            membershipFee: 0,
            hst: 0,
            paymentAmount: 0,
            paymentReceiptNum: '',
            paymentChequeNum: '',
            paymentNotes: ''
        }
    )
    const [userInfoFields, setUserInfoFields] = useState(
        {
            primaryFirstName: '',
            primaryLastName: '',
            primaryEmail: '',
        }
    )

    const [contactFields, setContactFields] = useState(
        {
        address: '',
        city: '',
        postal: '',
        primaryPhone: '',
        emergencyContact: '',
        emergencyPhone: ''
        }
    )

    const [members, setMembers] = useState([])       
    
    const [regFormSubmitted, setRegFormSubmitted] = useState(false)

    //define default fees
    const [membershipFees, setMembershipFees] = useState({
        single: 120,
        couple: 180,
        double: 140,
        junior: 60,
        family: 220
    })

    const [applyHST, setApplyHST] = useState(false)
    const [hstIncluded, setHstIncluded] = useState(false)
    const [hstLabel, setHstLabel] = useState('HST:')
    
    useEffect(()=>{
        let refreshFees = false
        requestFees(refreshFees).then(data => {
            setMembershipFees({
                single: data.currentFees.single,
                couple: data.currentFees.couple,
                double: data.currentFees.double,
                junior: data.currentFees.junior,
                family: data.currentFees.family
            })
        })

        if(configValues.addHST){
            setApplyHST(true)

            //if HST is applicable, check if it is included in the price
            if(configValues.hstIncluded){
                setHstIncluded(true)
                setHstLabel('HST (included):')
            }
        }
    },[])

    //scroll to the top once when the form is loaded
    useEffect (() => {
        window.scrollTo(0, 0)
        //make sure registration form can be submitted
        setRegFormSubmitted(false)
    },[])

    //Redirect to Home Page once the form is submitted
    useEffect(() => {
        if(formSubmitted){
          history.push('/')
        }
    })

    //whenever the # of adults or juniors change, update the Membership Type
    useEffect(() => {
        const updateMembership = () => {
          //determine membership based on adults and juniors. Defualt to Single Adult
          let currentFee = 0
          let calculatedHST = 0
          let totalAmount = 0
          
          if(adults <= 0 && juniors === 0){
            setMembership('')
            setMembershipLabel('')
          }  else if(adults === 1 && juniors === 0){
            setMembership('single')
            setMembershipLabel('Single Adult')
            currentFee = membershipFees.single
          } else if(adults === 2 && juniors === 0){
            setMembership('couple')
            setMembershipLabel('Couple')
            currentFee = membershipFees.couple
          } else if(adults === 1 && juniors === 1){
            setMembership('double')
            setMembershipLabel('Single Adult and Junior')
            currentFee = membershipFees.double
          } else if(adults === 0 && juniors > 0 && juniors <4 ){
            setMembership('junior')
            setMembershipLabel('Junior')
            currentFee = membershipFees.junior * juniors
          } else {
            setMembership('family')
            setMembershipLabel('Family')
            currentFee = membershipFees.family
          }  

          if(applyHST){
            if(!hstIncluded){
                // HST is not included. Calculate the HST on the base and add to the total  
                calculatedHST = currentFee * configValues.percentHST/100
                totalAmount = currentFee + calculatedHST
              } else {
                // HST is not included. Calculate the HST on the base and add to the total  
                calculatedHST = currentFee * configValues.percentHST/(100 + configValues.percentHST)
                totalAmount = currentFee
              }
              calculatedHST = Math.round(calculatedHST * 100)/100
          } else {
            totalAmount = currentFee
          }

          //console.log('configValues', configValues)
          //console.log('applyHST ', applyHST)
          //console.log('calculatedHST ', calculatedHST)
          //console.log('totalAmunt ', totalAmount)

          setMembershipFee(currentFee)
          setHst(calculatedHST)
          setTotalFee(totalAmount)
        }
        updateMembership()
    }, [adults, juniors])

    const updateMembership = () => {
        //update membership info
        setFormSubmitted(false)
        setMembershipFormCompleted(false)
    }

    const trimInputFields = () => {
        let updatedUserInfoFields = {...userInfoFields}

        updatedUserInfoFields.primaryFirstName = updatedUserInfoFields.primaryFirstName.trim()
        updatedUserInfoFields.primaryLastName = updatedUserInfoFields.primaryLastName.trim()
        updatedUserInfoFields.primaryEmail = updatedUserInfoFields.primaryEmail.trim()

        setUserInfoFields(updatedUserInfoFields)

        let updatedContactFields = {...contactFields}

        updatedContactFields.postal = updatedContactFields.postal.trim()
        updatedContactFields.primaryPhone = updatedContactFields.primaryPhone.trim()
        updatedContactFields.emergencyPhone = updatedContactFields.emergencyPhone.trim()
        
        setContactFields(updatedContactFields)
    
        let updatedMembers = [...members]

        for(let idx=0; idx < members.length; idx++){
            updatedMembers[idx].firstName = updatedMembers[idx].firstName.trim()
            updatedMembers[idx].lastName = updatedMembers[idx].lastName.trim()
            updatedMembers[idx].email = updatedMembers[idx].email.trim()
            updatedMembers[idx].phoneNumber = updatedMembers[idx].phoneNumber.trim()

            if(updatedMembers[idx].yearOfBirth > ''){
                updatedMembers[idx].yearOfBirth = updatedMembers[idx].yearOfBirth.trim()
            }
        }

        setMembers(updatedMembers)
    }

    const handleRegFormSubmit = (e) => {
        e.preventDefault()

        //trim input fields
        trimInputFields()

        //first validate all input fields
        
        const validateFields = {
            firstName: userInfoFields.primaryFirstName,
            lastName: userInfoFields.primaryLastName,
            email: userInfoFields.primaryEmail,
            adults: adults,
            juniors: juniors, 
            terms: terms, 
            address: contactFields.address, 
            city: contactFields.city, 
            postal: contactFields.postal, 
            primaryPhone: contactFields.primaryPhone, 
            emergencyContact: contactFields.emergencyContact, 
            emergencyPhone: contactFields.emergencyPhone, 
            members: members,
            currentYear: configValues.currentSeason,
            maxAgeJunior: configValues.maxAgeJunior
        }

        let validations = validateUserInfoInputs(validateFields)
        
        if(validations.validatedResult){
            validations = validateMembershipInputs(validateFields)
        }
        
        if(validations.validatedResult){
            //passed all validations
            
            //setup Payment Amount
            setPaymentFields({
                paymentAmount: totalFee,
                membershipFee: membershipFee,
                hst: hst
            })

            //scroll to top of screen
            window.scrollTo(0, 0)
            setMembershipFormCompleted(true)
            setRegFormSubmitted(false)
        } else {
            //display the validation error message
            updateChildMessage('Error', validations.validatedMessage, 'danger', 0)
            window.scrollTo(0, 0)
        }
    }
    
    //handle payment field changes
    const handlePaymentChange = (e) => {
        // clear errors, if any
        updateChildMessage('', '', '', 0)
        
        let updatedPaymentFields = {...paymentFields}

        if(e.target.name === "confirmEntries"){
            setConfirmEntries(e.target.checked)
        }else{ 
            updatedPaymentFields[e.target.name] = e.target.value
            setPaymentFields(updatedPaymentFields)
        }   
    }

    const deleteMember = (memberId) => {
        let updatedMembers = [...members]
        let memberType = updatedMembers[memberId].memberType

        let message = 'Removed Member: '
                    + updatedMembers[memberId].firstName
                    + ' ' 
                    + updatedMembers[memberId].lastName

        updateChildMessage('Alert', message, 'danger', 5000)            

        if(memberType==="adult"){
            let updatedAdults = adults - 1
            setAdults(updatedAdults)
        } else {
            let updatedJuniors = juniors - 1
            setJuniors(updatedJuniors)
        }

        updatedMembers.splice(memberId,1)
        setMembers(updatedMembers)

        //scroll to top of the form
        window.scrollTo(0, 0)
    }

    const addMember = (memberType) =>{
        if(memberType==="adult"){
            let updatedAdults = adults + 1
            setAdults(updatedAdults)
        } else {
            let updatedJuniors = juniors + 1
            setJuniors(updatedJuniors)
        }

        let updatedMembers = [...members, {
            memberType: memberType,
            firstName:"", 
            lastName:"",
            email:"",
            yearOfBirth: "",
            ntrpSelf: "",
            consentEmailContact: false,
            showNewsLetterConsent: true,
            primaryMember: false,
            consentNewsLetter: false,
            phoneNumber:"",
            consentPhoneBook: false
        }]

        if(memberType==="adult" && firstTimeMemberInput){
            //default the member details to the applicant user
            let index = updatedMembers.length - 1
            
            if(userInfoFields.primaryFirstName > ' '){
                updatedMembers[index].firstName = userInfoFields.primaryFirstName
            }
            
            if(userInfoFields.primaryLastName > ' '){
                updatedMembers[index].lastName = userInfoFields.primaryLastName
            }

            if(userInfoFields.primaryEmail > ' '){
                updatedMembers[index].email = userInfoFields.primaryEmail
            }
            
            if(contactFields.primaryPhone){
                updatedMembers[index].phoneNumber = contactFields.primaryPhone
            }
            setFirstTimeMemberInput(false)
        }

        setMembers(updatedMembers)
    }

    const handleAddAdult = () => {
        addMember("adult")
    }

    const handleAddJunior = () => {
        addMember("junior")
    }

    const handleChange = (e) => {
        // clear errors, if any
        updateChildMessage('', '', '', 0)
        if (["firstName", "lastName", "email", "phoneNumber", "yearOfBirth", "ntrpSelf"].includes(e.target.className) ) {
            let updatedMembers = [...members]
            if(e.target.className === "yearOfBirth"){
                if(e.target.value.length <= 4){
                    //do not allow more than 4 digits to be entered for year of Birth
                    updatedMembers[e.target.dataset.id][e.target.className] = e.target.value
                }
            } else if(e.target.className === "ntrpSelf"){
                if(e.target.value >= MIN_NTRP_RATING 
                    && e.target.value <= MAX_NTRP_RATING){
                    //do not allow a value more than 7 for NTRP rating
                    updatedMembers[e.target.dataset.id][e.target.className] = e.target.value
                }
            } else {
                updatedMembers[e.target.dataset.id][e.target.className] = e.target.value
            }
            setMembers(updatedMembers)
        } else if (["consentEmailContact", "consentPhoneBook", "consentNewsLetter"].includes(e.target.className)) {
            let updatedMembers = [...members]
            updatedMembers[e.target.dataset.id][e.target.className] = e.target.checked
            setMembers(updatedMembers)
        } else if(["primaryFirstName", "primaryLastName", "primaryEmail"].includes(e.target.name)){
            let updatedUserInfoFields = {...userInfoFields}
            updatedUserInfoFields[e.target.name] = e.target.value
            setUserInfoFields(updatedUserInfoFields)
        } else {
            let updatedContactFields = {...contactFields}

            if(e.target.name === "terms"){
                setTerms(e.target.checked)
            }else{
                if(e.target.name === "postal"){
                    updatedContactFields[e.target.name] = e.target.value.toUpperCase()
                } else {
                    updatedContactFields[e.target.name] = e.target.value
                }

                setContactFields(updatedContactFields)
            }    
        }
    }

    const submitMembership = (e) => {
        e.preventDefault()

        let messageTitle = 'Default'
        let message = 'Default'
        let messageType = Constants.MSG_TYPE_SUCCESS  

        if(!regFormSubmitted){
            //do not allow resubmission of the form
            setRegFormSubmitted(true)

            //validate the Payment Inputs before submitting the form
            const validatePaymentFields = {
                paymentType: paymentFields.paymentType,
                paymentAmount: paymentFields.paymentAmount,
                confirmEntries: confirmEntries
            }

            let validations = validateStaffPaymentInputs(validatePaymentFields)

            //console.log('submit-membership-validations: ', validations)
            
            
            if(validations.validatedResult){
                // submit the registration form and transfer to the home page
                const postStaffRegistration = async () => {
                    console.log('submitting registration')
                    
                    let res

                    try{
                        res = await axios({
                            method: 'POST',
                            timeout: AXIOS_LONG_TIMEOUT,
                            url: STAFF_REGISTER_URL,
                            data: {
                                userInfoFields: userInfoFields,
                                terms: terms,
                                address: contactFields.address,
                                city: contactFields.city,
                                postal: contactFields.postal,
                                primaryPhone: contactFields.primaryPhone,
                                emergencyContact: contactFields.emergencyContact,
                                emergencyPhone: contactFields.emergencyPhone,
                                adults: adults, 
                                juniors: juniors,
                                membership: membership,
                                members: members,
                                paymentFields: paymentFields
                            }  
                        })
                    } catch(error){
                        messageTitle = 'Error' 
                        message = 'Error submitting Reistration'
                        messageType = Constants.MSG_TYPE_ERROR
                        logAxiosError(error)
                    }
                    
                
                    //console.log('res:', res)
                    const {data} = res
                    //console.log('reg-res-data', data)
            
                    if (data.status === Constants.RETURN_CODE_OK) {
                        let memberName = `${userInfoFields.primaryFirstName} ${userInfoFields.primaryLastName} `
                        
                        messageTitle = 'Registration   Successful' 
                        message = `${memberName} has been registered for the ${configValues.currentSeason} Season`
                        messageType = Constants.MSG_TYPE_SUCCESS    

                        updatePhonebook(true)
                    } else if (data.status === Constants.RETURN_CODE_NOT_AUTH) {
                        messageTitle = 'Error' 
                        message = 'User not authorized'
                        messageType = Constants.MSG_TYPE_ERROR
                    } else {
                        messageTitle = 'Error' 
                        message = 'Error submitting form. Please try again'
                        messageType = Constants.MSG_TYPE_ERROR
                    }
                            
                    updateChildMessage(messageTitle, message, messageType, 5000)
            
                    //send user back to homepage after form submission
                    setFormSubmitted(true)
                }  
                
                postStaffRegistration()  
            } else {
                //display the validation error message
                updateChildMessage('Error', validations.validatedMessage, 'danger', 0)
                window.scrollTo(0, 0)
            }
        }
    }

    const onChangeHandler = () =>{
        return null
    }

    return (
        <div className="Reg-staff">
            {
                !membershipFormCompleted &&
                <div className="form-style-10">
                    <h1>Registration Form<span>Register an applicant for the {configValues.currentSeason} Tennis Season</span></h1>

                    <form onSubmit={handleRegFormSubmit} onChange={handleChange}>
                        <ContactDetailsStaff
                            primaryFirstName={userInfoFields.primaryFirstName}
                            primaryLastName={userInfoFields.primaryLastName}
                            primaryEmail={userInfoFields.primaryEmail}
                            address={contactFields.address}
                            city={contactFields.city}
                            postal={contactFields.postal}
                            primaryPhone={contactFields.primaryPhone}
                            emergencyContact={contactFields.emergencyContact}
                            emergencyPhone={contactFields.emergencyPhone}
                        />

                        <div className="section"><span>4</span>Members</div>
                        <MembersInfo
                            members={members}
                            deleteMember={deleteMember}
                            waitlist={false}
                        />

                        {adults < maxAdults ?
                            <div className="section-addadult">
                                <span>
                                    <i className="fas fa-plus-circle"
                                        onClick={handleAddAdult}
                                    ></i>
                                </span>Add Adult
                            </div> : <></>
                        }    
                        
                        {juniors < maxJuniors ?
                            <div className="section-addjunior">
                                <span>
                                <i className="fas fa-plus-circle"
                                    onClick={handleAddJunior}
                                >    
                                </i>
                                </span>Add Junior
                            </div> : <></>
                        }
                        
                        <div className="section"><span>S</span>Summary</div>
                        <div className="inner-summarywrap">
                            {membershipLabel &&
                                <p>Membership Type: {membershipLabel} </p>
                            }

                            <table>
                                <tbody>
                                    <tr>
                                        <td>Total Adults:</td>
                                        <td>{adults}</td>
                                    </tr>                                        
                                    <tr>
                                        <td>Total Juniors:</td>
                                        <td>{juniors}</td>
                                    </tr>                                        
                                </tbody>
                            </table>

                            {membershipLabel &&
                                <table>
                                    <tbody>
                                        <tr>
                                            <td>Membership Fee:</td>
                                            <td>${membershipFee.toFixed(2)}</td>
                                        </tr>
                                        <tr>
                                            <td>{hstLabel}</td>
                                            <td>${hst.toFixed(2)}</td>
                                        </tr>                                        
                                        <tr>
                                            <td>Total Amount:</td>
                                            <td>${totalFee.toFixed(2)}</td>
                                        </tr>                                        
                                    </tbody>
                                </table>
                            }
                        </div>

                        <div className="button-section">
                            <span className="privacy-policy">
                                <label className="checkbox" htmlFor="terms">
                                    <input 
                                        type="checkbox" 
                                        name="terms"
                                        checked={terms} 
                                        onChange={onChangeHandler}
                                    /> 
                                    <a href="/rulebook" target="_blank">Applicant agrees to the Club Rules and Code of Conduct</a>
                                </label>
                            </span>
                            <button type="submit"> Pay Membership Fee </button>
                        </div>
                    </form>
                </div>
            }    

            {
                membershipFormCompleted && 

                <div className="form-style-10">
                    <h1>Payment Details<span>Enter Payment details</span></h1>
                    <form onSubmit={submitMembership} onChange={handlePaymentChange}>
                        <PaymentDetailsStaff
                            paymentFields={paymentFields}
                            membershipLabel={membershipLabel}
                            confirmEntries={confirmEntries}
                            updateMembership={ updateMembership }
                        />
                    </form>
                </div>
             }    
        </div>
    )
}

export default RegistrationFormStaff