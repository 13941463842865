import React, { useEffect, useState } from 'react'
import axios from 'axios'

import{ MEMBER_REGISTER_URL }  from '../../utils/urls'
import { logAxiosError } from '../../utils/functions';
import { AXIOS_TIMEOUT } from '../../utils/constants'

import './RegistrationForm.css'

import ViewMembers from "./ViewMembers"

//Notes: Test if Staff can access this page. If not, remove reference to Staff view

const ViewRegistration = (props) =>  {

    let { user, history, updateChildMessage } = props
    let showMembers = false

    const [address, setAddress] = useState()
    const [city, setCity] = useState()
    const [postal, setPostal] = useState()
    const [primaryEmail, setPrimaryEmail] = useState()
    const [primaryPhone, setPrimaryPhone] = useState()
    const [emergencyContact, setEmergencyContact] = useState()
    const [emergencyPhone, setEmergencyPhone] = useState()
    const [adults, setAdults] = useState()
    const [juniors, setJuniors] = useState()
    const [membership, setMembership] = useState()
    const [viewMembers, setViewMembers] = useState({})
    const [authorizedUser, setAuthorizedUser] = useState(false)

    useEffect(()=> {
        if(!user){
            updateChildMessage("", "Please Login to continue...", "primary", 3000)
            history.push('/login')
        } else if(user.isMember || user.isExec ) {
            setAuthorizedUser(true)
        } else {
            setAuthorizedUser(false)
            history.push('/')
        }
    },[])

    useEffect(() => {
        if(authorizedUser){
            const getRegisterationData = async () => {
                let res 
                let processRequest = true
                let membershipId = user.membershipId

                //console.log('user', user)

                try {
                    res = await axios({
                        method: 'GET',
                        timeout: AXIOS_TIMEOUT,
                        url: MEMBER_REGISTER_URL,
                        params: {
                            membershipId: membershipId
                        }
                    })
                } catch (error) {
                    console.log('Error fetching registration information')
                    logAxiosError(error)       
                    processRequest = false
                }
                
                if(processRequest){
                    const {data} = res

                    const { members, membership } = data.data

                    //console.log('membership ', membership)

                    //populate the fields

                    setAddress(membership.address)
                    setCity(membership.city)
                    setPostal(membership.postalCode)
                    setPrimaryEmail(membership.primaryEmail)
                    setPrimaryPhone(membership.primaryPhone)
                    setEmergencyContact(membership.emergencyName)
                    setEmergencyPhone(membership.emergencyPhone)
                    setAdults(membership.totalAdults)
                    setJuniors(membership.totalJuniors)
                    setMembership(membership.membershipType)

                    setViewMembers(members)  
                }
            }  
            getRegisterationData()
        }
    }, [authorizedUser])   
   
    let membershipLabel = "Adult"

    //determine the value for membership label
    if(membership){
        switch (membership) {
            case "single":
              membershipLabel = "Adult"
              break;
            case "couple":
              membershipLabel = "Couple"
              break;
            case "family":
              membershipLabel = "Family"
              break;
            case "junior":
              membershipLabel = "Junior"
              break;
            case "double":
              membershipLabel = "Single Adult and Single Junior"
              break;
            default:
              membershipLabel = "Adult"  
          }
    }

    const handleReturnHome = () => {
        history.push('/')
    }

    if (viewMembers.length > 0){
        showMembers = true
    }

    return(
        <div className="form-style-10">
            <h1>Registration Details<span>View your registration details below</span></h1>
            
            <form onSubmit={handleReturnHome}>
                <div className="section"><span>1</span>Address & Contact Information</div>
                <div className="inner-wrap">
                    {!user.isStaff && 
                    <> 
                        <label>First Name </label>
                        <p className="readonly">{user.firstName}</p>

                        <label>Last Name </label>
                        <p className="readonly">{user.lastName}</p>

                        <label>Email </label>
                        <p className="readonly">{user.email}</p>
                    </>
                    }
                    {user.isStaff &&
                    <>
                        <label>Primary Email </label>
                        <p className="readonly">{primaryEmail}</p>
                    </>
                    }
                    
                    <label>Primary Phone </label>
                    <p className="readonly">{primaryPhone}</p>

                    <label>Address </label>
                    <p className="readonly">{address}</p>

                    <label>City </label>
                    <p className="readonly">{city}</p>

                    <label>Postal Code </label>
                    <p className="readonly">{postal}</p>

                    <label>Emergency Contact </label>
                    <p className="readonly">{emergencyContact}</p>

                    <label>Emergency Phone </label>
                    <p className="readonly">{emergencyPhone}</p>
                </div>                

                <div className="section"><span>2</span>Membership Information</div>
                <div className="inner-wrap">
                    <label>Membership Type</label>
                    <p className="readonly">{membershipLabel}</p>

                    <label>Number of Adults</label>
                    <p className="readonly">{adults}</p>

                    <label>Number of Juniors</label>
                    <p className="readonly">{juniors}</p>

                </div>    
                
                <div className="section"><span>3</span>Members</div>
                {showMembers &&
                    <ViewMembers 
                        members={viewMembers} 
                    />
                }

                <div className="button-section">
                    <button type="submit"> Return Home </button>
                </div>     
            </form>  
        </div>  
    )   
}  

export default ViewRegistration
