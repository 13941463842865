import React from 'react'

import './CreditsRep.css'

const CreditsEntry = ({ creditsList }) =>  {   
    
    if(creditsList[0]){
        if(Object.keys(creditsList[0]).length === 0){
            //console.log('return null')
            return null
        }
    } else {
        return null
    }
     
    return(
        creditsList.map((entry, idx)=> { 
            let key = entry.id

            let fnIdx = `fn-${key}`
            let lnIdx = `ln-${key}`
            let pcIdx = `pc-${key}`
            let acIdx = `ac-${key}`
  
            return (
                <>
                {key && 
                    <tr key={key.toString()}>
                        <td key={fnIdx}>{entry.firstName}</td>  
                        <td key={lnIdx}>{entry.lastName}</td>   
                        <td key={pcIdx}>{entry.purchasedCredits}</td>   
                        <td key={acIdx}>{entry.availableCredits}</td>   
                    </tr>   
                }
                </>
            )
        })
    )
}  

export default CreditsEntry
