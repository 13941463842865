import React from 'react'
import {Link} from 'react-router-dom'

import './Members.css'

const MembersEntry = ({ membersList, selectedEventMode, eventId, authProfile }) =>  {   
    // authProfile indicator means user has access to the member profile. Available for staff and exec only
    //console.log('rendering member entry', membersList)
    
    if(membersList[0]){
        if(Object.keys(membersList[0]).length === 0){
            //console.log('return null')
            return null
        }
    } else {
        return null
    }
     
    return(
        membersList.map((entry, idx)=> { 
            let key = entry.id

            let idIdx = `id-${key}`
            let fnIdx = `fn-${key}`
            let lnIdx = `ln-${key}`
            let pnIdx = `pn-${key}`
 
            let linkedUrl = ''
            if(selectedEventMode){
                linkedUrl = `/events/currentevents/${entry.id}/${entry.firstName}/${entry.lastName}/${eventId}`
            } else {
                linkedUrl = `/staff/singlemember/${entry.id}`
            }
            
            return (
                <>
                {key && authProfile &&
                    <tr key={key.toString()}>
                        <td key={idIdx}><Link to={linkedUrl} className="link">{entry.id}</Link></td>    
                        <td key={fnIdx}><Link to={linkedUrl} className="link">{entry.firstName}</Link></td>  
                        <td key={lnIdx}><Link to={linkedUrl} className="link">{entry.lastName}</Link></td>   
                        <td key={pnIdx}><Link to={linkedUrl} className="link">{entry.phoneNumber}</Link></td>
                    </tr>   
                }
                {key && !authProfile &&
                    <tr key={key.toString()}>
                        <td key={idIdx}>{entry.id}</td>    
                        <td key={fnIdx}>{entry.firstName}</td>  
                        <td key={lnIdx}>{entry.lastName}</td>   
                        <td key={pnIdx}>{entry.phoneNumber}</td>
                    </tr>   
                }
                </>
            )
        })
    )
}  

export default MembersEntry
