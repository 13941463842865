import React, { useState, useEffect } from 'react';

import './Camera.css';

const Camera = ({user, history, updateChildMessage }) => {
    const [authorizedUser, setAuthorizedUser] = useState(false)

    useEffect(()=> {
        let nextPage = ''
        //console.log('useEffect Camera')
        if(!user){
            updateChildMessage("", "Please Login to continue...", "primary", 3000)
            nextPage = '/login'
            
        } else if(user.isMember || user.isStaff || user.isExec || user.isInstructor || user.isAdmin) {
            setAuthorizedUser(true)
        } else {
            setAuthorizedUser(false)
            nextPage = '/'
        }
        //console.log('nextpage1', nextPage)

        if(nextPage){
            history.push(nextPage)
            return null
        }

        /*
        return () => {
            // Anything in here is fired on component unmount.
            console.log('nextpage', nextPage)
            
        } 
        */   
    },[])

    return (
        <>
        {authorizedUser &&

            <div className="Camera">
                
                <h2> View Live Action Below</h2>

                <div className="cameraName"> Left Camera </div>
                
                <div className="spacerTop"></div>
                <div className="Camera-Container">
                    <iframe 
                        title="camera"
                        src="//video.nest.com/embedded/live/Lvhp6YBxmp?autoplay=1"
                    >      
                    </iframe>
                </div>
        
                <div className="spacer"></div>

                <div className="cameraName"> Right Camera </div>

                <div className="spacerTop"></div>
                <div className="Camera-Container">
                    <iframe 
                        title="camera"
                        src="//video.nest.com/embedded/live/C6afW38Khg?autoplay=1"
                    >      
                    </iframe>
                </div>
                <div className="spacer"></div>
                            
                <h3> No audio available</h3>
                
            </div>
        }
        </>
    )
}

export default Camera