import React, { useState, useEffect } from 'react';
import axios from 'axios'
import ReactMarkdown from 'react-markdown';
import CountdownTimer from '../../components/CountdownTimer'

import { Link } from 'react-router-dom';

import './RegisterInfo.css'

import { INSTRUCTIONS_URL, MEMBERSHIP_RENEWAL_URL } from '../../utils/urls';
import { logAxiosError } from '../../utils/functions';
import { AXIOS_TIMEOUT } from '../../utils/constants';

const NOTIFICATION_MESSAGE = "Sorry, Registration is closed for the season"

const RegisterInfo = ({history, user, updateChildMessage, configValues}) => {
    const[instructions, setInstructions] = useState({})
    const [authorizedUser, setAuthorizedUser] = useState(false)
    const [isAllowedRegister, setIsAllowedRegister] = useState(true)
    const [juniorOnlyRegistrationAllowed, setJuniorOnlyRegistrationAllowed] = useState(true)

    const [registrationOpen, setRegistrationOpen] = useState(false)
    const [registrationClosed, setRegistrationClosed] = useState(false)
    const [timeoutId, setTimeoutId] = useState()
    const [timeoutId2, setTimeoutId2] = useState()
    const [timeToReg, setTimeToReg] = useState({
        days: 0,
        hours: 0, 
        minutes: 0,
        seconds: 0
    })

    const [timeToClosing, setTimeToClosing] = useState({
        days: 0,
        hours: 0, 
        minutes: 0,
        seconds: 0
    })

    const TIMER_NAME_OPEN = "Time to Registration:"
    const TIMER_NAME_CLOSE = "Registration closes in:"

    const [openingTimerStarted, setOpeningTimerStarted] = useState(false)
    const [closingTimerStarted, setClosingTimerStarted] = useState(false)
    const [regStartDateTimeLocal, setRegStartDateTimeLocal] = useState()
    const [regEndDateTimeLocal, setRegEndDateTimeLocal] = useState()
    const [regStartDateTime, setRegStartDateTime] = useState()
    const [regEndDateTime, setRegEndDateTime] = useState()
    const [instructionsFetched, setInstructionsFetched] = useState(false)

    const _displayDateTime = (dateTime) => {
        let displayDateTime = new Date(dateTime).toLocaleDateString(undefined, {
            day:    'numeric',
            month:  'numeric',
            year:   'numeric',
            hour:   '2-digit',
            minute: '2-digit',
        });

        return displayDateTime.toString()
    }

    useEffect(()=> {
        if(!user){
            // child message not showing in this case. Investigate Later
            // updateChildMessage("", "Please Login to continue...", "primary", 0)
            history.push('/login')
        } else if(user.isAuth) {
            setAuthorizedUser(true)
            setInstructionsFetched(false)
        } else {
            setAuthorizedUser(false)
            history.push('/')
        }
    },[])

    useEffect(() => {
        if(authorizedUser){
            const getInstructions = async () => {
                let res

                try{
                    res = await axios({
                        method: 'GET',
                        timeout: AXIOS_TIMEOUT,
                        url: INSTRUCTIONS_URL,
                    })
                } catch(error){
                    logAxiosError(error)
                }
                
                const {data} = res
                //console.log('instructions', data)
                
                let registerAllowed= false

                if(configValues.allowRegister){
                    registerAllowed = true
                } else {
                    registerAllowed = false
                    setRegistrationClosed(true)
                }

                setIsAllowedRegister(registerAllowed)
                
                setInstructions(data)
                setInstructionsFetched(true)
            }

            getInstructions() 
        }
    }, [authorizedUser])  

    useEffect(()=>{
        if(isAllowedRegister){
            //check if priority registration is in effect
            let juniorOnly = false

            //default renewal id is "1" for every year
            let renewalId = 1

            if(configValues.prioritizeRegistration){
                if(user.registrationAllowed){
                    //for prioritized renewal, use the user's renewal id (ideally 2 to 9)
                    renewalId = user.renewal
                } else {
                    //only junior registrations are allowed
                    juniorOnly = true
                }
            } else if(configValues.juniorOnly) {
                //prioritized registration is off
                //if Junior only is ON, then set the juniorOnly flag on
                juniorOnly = true
            }

            setJuniorOnlyRegistrationAllowed(juniorOnly)

            const getRenewalInfo = async () => {
                let res
                let processRequest = true

                try {
                    res = await axios({
                        method: 'GET',
                        url: MEMBERSHIP_RENEWAL_URL,
                        timeout: AXIOS_TIMEOUT,
                        params: {
                            renewalId: renewalId,
                            membershipYear: configValues.currentSeason
                        }
                    })
                } catch (error) {
                    //console.log('Error fetching users membership')
                    logAxiosError(error)
                    processRequest = false
                }

                if(processRequest){
                    let renewalData = res.data[0]
                    
                    //check the dates to show registration link
                    
                    setRegistrationOpen(false)
                    setRegistrationClosed(false)
                    
                    setRegStartDateTime(_displayDateTime(renewalData.startDate))
                    setRegEndDateTime(_displayDateTime(renewalData.endDate))

                    let tempDate = new Date(renewalData.startDate)
                    setRegStartDateTimeLocal(tempDate)

                    let tempDate2 = new Date(renewalData.endDate)
                    setRegEndDateTimeLocal(tempDate2)
                } else {
                    //don't allow registration if there is a failure checking for renewal
                    setIsAllowedRegister(false)
                }
            }

            getRenewalInfo()
        }
    },[authorizedUser, isAllowedRegister])
    
    useEffect(() => {   
        //console.log('event-reg-regStartDateTime', regStartDateTimeLocal)

        if(regStartDateTimeLocal && !registrationOpen){
            const initTimeoutId = setTimeout(() => {
                //update timer every 1 second
                let now = new Date().getTime();
                let distance = regStartDateTimeLocal - now
                
                if(distance <= 0){
                    //user can register
                    setRegistrationOpen(true)
                } else {
                    //calculate the time for opening of registration
    
                    let currentTimeToReg ={}
                    // Time calculations for days, hours, minutes and seconds
                    currentTimeToReg.days = Math.floor(distance / (1000 * 60 * 60 * 24));
                    currentTimeToReg.hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                    currentTimeToReg.minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
                    currentTimeToReg.seconds = Math.floor((distance % (1000 * 60)) / 1000);
    
                    setTimeToReg(currentTimeToReg)
                }
            }, 1000);
    
            setTimeoutId(initTimeoutId)
    
            if(registrationOpen){
                clearTimeout(timeoutId)
            }
        }
    },[regStartDateTimeLocal, timeToReg]) 

    useEffect(() => {   
        //console.log('event-reg-regEndDateTime', regEndDateTimeLocal)

        if(regEndDateTimeLocal && registrationOpen && !registrationClosed){
            const initTimeoutId2 = setTimeout(() => {
                //update timer every 1 second
                let now = new Date().getTime();
                let distance = regEndDateTimeLocal - now
                
                if(distance <= 0){
                    //registration has closed. User cannot register
                    setRegistrationClosed(true)
                } else {
                    //calculate the time for end of registration
    
                    let currentTimeToClosing ={}
                    // Time calculations for days, hours, minutes and seconds
                    currentTimeToClosing.days = Math.floor(distance / (1000 * 60 * 60 * 24));
                    currentTimeToClosing.hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                    currentTimeToClosing.minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
                    currentTimeToClosing.seconds = Math.floor((distance % (1000 * 60)) / 1000);
                    
                    setTimeToClosing(currentTimeToClosing)
                }
            }, 1000);
    
            setTimeoutId2(initTimeoutId2)
    
            if(registrationClosed){
                clearTimeout(timeoutId2)
            }
        }
    },[regEndDateTimeLocal, timeToClosing, registrationOpen]) 

    //temp-001-end - */

    //setup the display flags
    
    let showCountdownTimer = false
    let countDownToRegistration = false

    //*temp-003
    if(timeToReg.seconds > 0 || openingTimerStarted){
        if(!openingTimerStarted){
            setOpeningTimerStarted(true)
        }
    }

    if(!registrationOpen && openingTimerStarted){
        //timer has started
        countDownToRegistration = true
    }

    if(timeToClosing.seconds > 0 || closingTimerStarted){
        if(!closingTimerStarted){
            setClosingTimerStarted(true)
        }
    }

    if(registrationOpen && !registrationClosed && closingTimerStarted){
        showCountdownTimer = true
    }

    const imageUrl = '/images/wptc-pic1.JPG'
    const { welcomeTitle, welcomeText, leftBox, rightBox, formLink, message, messageType } = instructions

    useEffect(()=>{
        if(instructionsFetched){
            //determine what message to display only after the instructions are fetched
            if(isAllowedRegister){
                let childMessage = 'Only Junior Registrations can be submitted at this time. '
    
                if(instructions.message > ''){
                    if(juniorOnlyRegistrationAllowed){
                        updateChildMessage("Notice", childMessage + instructions.message, 'warning', 0)
                    } else {
                        updateChildMessage("Notice", instructions.message, instructions.messageType, 0)
                    }
                } else if(juniorOnlyRegistrationAllowed){
                    updateChildMessage('Warning', childMessage, 'warning', 0)
                } else {
                    updateChildMessage('')
                }
            } else {
                updateChildMessage("Notice", NOTIFICATION_MESSAGE, "danger", 0)
            }
        }
    },[instructionsFetched])
    
    return (
        <div className="Register">
            <img className="Image" src={imageUrl} alt="Register Page"/>
                
            <div className="Welcome"> {welcomeTitle} </div>  
            <div className="Welcomemsg"> {welcomeText} </div>

            <div className="Textcontainer"> 
                <div className="Para">
                    <ReactMarkdown>{leftBox}</ReactMarkdown>
                </div>
                <div className="Para"> 
                    <ReactMarkdown>{rightBox}</ReactMarkdown>
                </div>
            </div>
            
            {showCountdownTimer &&
                <div className="Link-box">
                    <h3>
                        Registration closes on {regEndDateTime}
                    </h3>
                    <p></p>
                    <CountdownTimer 
                        timerName={TIMER_NAME_CLOSE}
                        days={timeToClosing.days}
                        hours={timeToClosing.hours}
                        minutes={timeToClosing.minutes}
                        seconds={timeToClosing.seconds}
                        colorScheme="blue"
                    />
                    <p></p>
                    {formLink && authorizedUser && isAllowedRegister &&  
                        <Link to="/register/self">{formLink} </Link>
                    }
                </div>
            }

            {countDownToRegistration && 
                <div className="Link-box">
                    <h3>
                        Registration opens on {regStartDateTime}
                    </h3>
                    <p></p>
                    <CountdownTimer 
                        timerName={TIMER_NAME_OPEN}
                        days={timeToReg.days}
                        hours={timeToReg.hours}
                        minutes={timeToReg.minutes}
                        seconds={timeToReg.seconds}
                        colorScheme="blue"
                    />
                </div>    
            }

            {registrationClosed &&
                <div className="Link-box">
                    <h3>
                        Sorry, Registration has closed for the Season. 
                    </h3>
                </div>    
            }

            {!isAllowedRegister && authorizedUser &&
                <div className="Link-box">
                    <p> {NOTIFICATION_MESSAGE} </p>
                </div>
            }
        </div>
    )    
}

export default RegisterInfo