import React from 'react'

import './Members.css'

const TransactionEntry = ({ transactionList }) =>  {   
    if(transactionList[0]){
        if(Object.keys(transactionList[0]).length === 0){
            //console.log('return null')
            return null
        }
    } else {
        return null
    }
     
    return(
        transactionList.map((entry, idx)=> { 
            let key = entry.id

            let dtIdx = `dt-${key}`
            let dcIdx = `dc-${key}`
            let amIdx = `am-${key}`
            let ttIdx = `tt-${key}`

            return (
                <>
                {key &&
                    <tr key={key.toString()}>
                        <td key={dtIdx}>{entry.tranDateFmt}</td>    
                        <td key={dcIdx}>{entry.description}</td>  
                        <td key={amIdx}>{entry.credits}</td>   
                        <td key={ttIdx}>{entry.transactionType}</td>
                    </tr>   
                }
                </>
            )
        })
    )
}  

export default TransactionEntry
