export const WEBSITE_TITLE = "Wallace Park Tennis Club"

export const RESET_PASSWORD_MSG = 'A Reset Password Link has been sent to your email address'
export const RESET_PASSWORD_MSG2 = 'Please check your email for the Reset Password Link'

export const CONSENT_MESSAGE = `As a member of the Wallace Park Tennis Club I firmly commit to treat all fellow members, 
                                players and club staff with integrity, fairness and respect at all times`

export const SUCCESS_PASSWORD_MSG = 'Your password has been reset successfully'

export const MSG_TYPE_SUCCESS = 'success'

export const MSG_TYPE_INFO = 'primary'

export const MSG_TYPE_ERROR = 'danger'

export const MSG_TYPE_DANGER = 'danger'

export const RETURN_CODE_OK = 200

export const RETURN_CODE_NOT_AUTH = 400

export const NTRP_INFO_URL = 'https://www.tenniscanada.com/wp-content/uploads/2015/12/Self-Rating-Guide-English.pdf'
