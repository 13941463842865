import React, { useState } from "react"

import './Calendar.css'

// Calendar Coloring
// https://cnfdesigns.com/embed-shared-google-calendar-different-colored-events/

const embedURL = 'https://calendar.google.com/calendar/embed'
const competitive = 'src=neis2d48mngsaqiajqftkrssbs%40group.calendar.google.com&ctz=America%2FToronto'
const clubEvents = 'src=3u0siiagldj592fnsfg9d6u8uo%40group.calendar.google.com&ctz=America%2FToronto'
const instructional = 'src=hvl4tnj5hh9ll5ff9jdeah5gi4%40group.calendar.google.com&ctz=America%2FToronto'
const camps = 'src=qnajdadeopf384p41uhekc3o3k%40group.calendar.google.com&ctz=America%2FToronto'
const other = 'src=dnj2rarfs12hhgnlm3039gbufg%40group.calendar.google.com&ctz=America%2FToronto'
const combined = 'height=600&wkst=1&bgcolor=%23ffffff&ctz=America%2FToronto&title=Wallace%20Park%20Combined%20Calendar&mode=WEEK&src=M3Uwc2lpYWdsZGo1OTJmbnNmZzlkNnU4dW9AZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&src=bmVpczJkNDhtbmdzYXFpYWpxZnRrcnNzYnNAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&src=aHZsNHRuajVoaDlsbDVmZjlqZGVhaDVnaTRAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&src=ZG5qMnJhcmZzMTJoaGdubG0zMDM5Z2J1ZmdAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&src=cW5hamRhZGVvcGYzODRwNDF1aGVrYzNvM2tAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&src=aDZsZXRzanZ1bmI5YzYybnU1aXNnM3BiZTRAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&color=%233540ad&color=%23EF6C00&color=%237CB342&color=%23914d14&color=%234585a3&color=%23151514'

//const combined2 = 'src=h6letsjvunb9c62nu5isg3pbe4%40group.calendar.google.com&ctz=America%2FToronto'
//const other2 = 'src=bmVpczJkNDhtbmdzYXFpYWpxZnRrcnNzYnNAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&color=%23EF6C00'

//<iframe src="https://calendar.google.com/calendar/embed?height=600&wkst=1&bgcolor=%23ffffff&ctz=America%2FToronto&title=Wallace%20Park%20Combined%20Calendar&mode=WEEK&src=M3Uwc2lpYWdsZGo1OTJmbnNmZzlkNnU4dW9AZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&src=bmVpczJkNDhtbmdzYXFpYWpxZnRrcnNzYnNAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&src=aHZsNHRuajVoaDlsbDVmZjlqZGVhaDVnaTRAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&src=ZG5qMnJhcmZzMTJoaGdubG0zMDM5Z2J1ZmdAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&src=cW5hamRhZGVvcGYzODRwNDF1aGVrYzNvM2tAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&src=aDZsZXRzanZ1bmI5YzYybnU1aXNnM3BiZTRAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&color=%233540ad&color=%23EF6C00&color=%237CB342&color=%23914d14&color=%234585a3&color=%23151514" style="border:solid 1px #777" width="800" height="600" frameborder="0" scrolling="no"></iframe>

//Orange – %23BE6D00
const competitiveColor = 'color=%23BE6D00'

//Sea Blue – %23113F47
const clubEventsColor = 'color=%23113F47'

//Olive Green – %23528800
const instructionalColor = 'color=%23528800'

//Dark Blue - %23060D5E
const campsColor = 'color=%23060D5E'

//Nice Brown - %236B3304
const otherColor = 'color=%236B3304'

export default () => {
    //let combinedCalendar2 = `${embedURL}?${combined}&${competitiveColor}&${competitive}&${competitiveColor}&${clubEvents}&${clubEventsColor}
    //                        &${instructional}&${instructionalColor}&${camps}&${campsColor}&${other}&${otherColor}`
    
    let combinedCalendar = `${embedURL}?${combined}`
                            
    let selectedCalendar = combinedCalendar

    const[currentCalendar, setCurrentCalendar] = useState(combinedCalendar)

    const onClickHandler = (e) => {
        e.preventDefault()
        
        switch(e.target.id){
            case "CombinedCourts": 
                selectedCalendar = combinedCalendar
                break
            case "competitive":    
                selectedCalendar = `${embedURL}?${competitive}&${competitiveColor}`
                break
            case "clubEvents":    
                selectedCalendar = `${embedURL}?${clubEvents}&${clubEventsColor}`
                break
            case "instructional":    
                selectedCalendar = `${embedURL}?${instructional}&${instructionalColor}`
                break   
            case "camps":    
                selectedCalendar = `${embedURL}?${camps}&${campsColor}`
                break   
            case "other":    
                selectedCalendar = `${embedURL}?${other}&${otherColor}`
                break   
            default:              
                selectedCalendar = combinedCalendar
        }

        setCurrentCalendar(selectedCalendar)
    }    

    return(
        <div className="Calendar">
            <div className="Title">
                <h4> Browse Calendars for Events and Programs  </h4>
            </div>

            <div className="Selection">
                <button id="combined"onClick={onClickHandler}> WPTC Calendar </button>
                <button id="competitive"onClick={onClickHandler}> Competitive </button>
                <button id="clubEvents"onClick={onClickHandler}> Club Events </button>
                <button id="instructional"onClick={onClickHandler}> Instructional Programs </button>
                <button id="camps"onClick={onClickHandler}> Camps </button>
                <button id="other"onClick={onClickHandler}> Other </button>
            </div>
            
            <div className="Cal-container"> 
                <div className="Frame"></div>
                <iframe 
                    src={currentCalendar}
                    width="800"
                    height="600"
                    frameBorder="0" 
                    scrolling="no">
                </iframe>
            </div>
        </div>
    )
}
