import React, { useState, useEffect } from 'react'
import ReactMarkdown from 'react-markdown';
import { Link } from 'react-router-dom';
import {fromImageToUrl} from '../utils/urls'

import './SingleEventDetail.css'

const SingleEventDetail = (props) => {
    let {
        currentEvent, 
        idx, 
        mode,
        handleOnSelect,
        handleReturn, 
        submitDelete,
    } = props

    let {
        id,
        eventType,
        maxParticipants, 
        maxOnlineParticipants, 
        eventStartDateTime,
        eventEndDateTime,
        showFromDateTime,
        bookingStartDateTime,
        bookingEndDateTime,
        title, 
        description, 
        requiresToken,
        tokensPerSpot,
        enableWaitlist,
        waitlistLimit,
        autoManageWaitList,
        currentParticipants,
        currentOnlineParticipants,
        currentWaitingList,
        status,
        versionNumber,
        url
    } = currentEvent

    const [confirmDelete, setConfirmDelete] = useState(false)
    const [error, setError] = useState()
    const [updateEnabled, setUpdateEnabled] = useState(false)
    const [eventStartDateTimeD, setEventStartDateTime] = useState()
    const [eventEndDateTimeD, setEventEndDateTime] = useState()
    const [showFromDateTimeD, setShowFromDateTime] = useState()
    const [bookingStartDateTimeD, setBookingStartDateTime] = useState()
    const [bookingEndDateTimeD, setBookingEndDateTime] = useState()
    
    //console.log('event', currentEvent)

    let showDetail = false
    let image = {}

    const _displayDateTime = (dateTime) => {
        let displayDateTime = new Date(dateTime).toLocaleDateString(undefined, {
            day:    'numeric',
            month:  'numeric',
            year:   'numeric',
            hour:   '2-digit',
            minute: '2-digit',
        });

        return displayDateTime.toString()
    }

    useEffect(()=>{
        console.log("V1.0")
        setConfirmDelete(false)
        setUpdateEnabled(false)

        if(bookingStartDateTime){
            setEventStartDateTime(_displayDateTime(eventStartDateTime))
            setEventEndDateTime(_displayDateTime(eventEndDateTime))
            setShowFromDateTime(_displayDateTime(showFromDateTime))
            setBookingStartDateTime(_displayDateTime(bookingStartDateTime))
            setBookingEndDateTime(_displayDateTime(bookingEndDateTime))
        }
    },[])

    if(url){
        image.url = url
    }

    if(mode === "detail"){
        showDetail = true
    }

    const _displayDate = (date) => {
        if(date){
            return date.toString().slice(0,10)
        } else {
            return "0001-01-01"
        }
    }

    const _convertTime = (inTime) => {
        if(!inTime){
            //time not provided. Default to 00:00
            return '00:00'
        }
        
        let outTime = '';
        let hrs = inTime.toString().slice(0,2)
        let min = inTime.toString().slice(3,5)
        
        let suffix = "AM"
        let numHrs = parseInt(hrs)
        
        if(numHrs > 11){
            suffix = "PM"
            if(numHrs > 12){
                let tempHrs = numHrs - 12
                hrs = tempHrs.toString()
            }
        }

        outTime = hrs + ":" + min + " " + suffix
        return outTime
    }

    const verifyDelete = () => {
        //delete not allowed if participants are registered for the event
        if(currentParticipants > 0){
            setError('Event cannot be deleted with registered participants')
            window.scroll(0,0)
        } else {
            //delete allowed
            setConfirmDelete(true)
        }
    }

    return(
        <div className="card" key={idx}>
            <div className="container">
                <div>
                    {!showDetail &&
                        <h5 className="EventTitle" onClick={()=>{handleOnSelect(idx)}}>{title}</h5> 
                    }

                    {showDetail &&
                        <h5 className="EventTitle" onClick={()=>{handleReturn()}}>{title}</h5>    
                    }
                </div>   

                {error &&
                    <div className="error">{error}</div>
                }    

                <form> 
                    <ul className="form-style-1">
                        <div className="inline">
                            <li>
                                <label>Event Type</label>
                                {eventType}
                            </li>
                        </div>

                        {eventStartDateTime &&
                            <div className="inline">
                                <li>
                                    <label>Event Starts </label>
                                    {eventStartDateTimeD}
                                </li>
                                
                                <li>
                                    <label>Event Ends</label>
                                    {eventEndDateTimeD}
                                </li>
                            </div>
                        }
                        
                        {url &&
                            <img className="Image" src={fromImageToUrl(image, "event")} alt="Club Event"/>
                        }
                        
                        {!url &&
                            <img src={fromImageToUrl('', 'event')} alt="Club Event"/>
                        }

                        <li>
                            {showDetail &&
                                <>
                                    <label>Description</label>
                                    
                                    <ReactMarkdown>
                                        {description}
                                    </ReactMarkdown>
                                </>
                            }
                        </li>

                        <div className="inline">
                            <li>
                                <label>Show Event From</label>
                                {showFromDateTimeD}
                            </li>
                        </div>

                        <div className="inline">
                            <li>
                                <label>Booking Starts From</label>
                                {bookingStartDateTimeD}
                            </li>
                        </div>

                        <div className="inline">
                            <li>
                                <label>Booking Ends At</label>
                                {bookingEndDateTimeD}
                            </li>
                        </div>

                        {showDetail &&
                        <>    
                            <div className="inline">
                                <li>
                                    <label>Maximum Participants</label>
                                    {maxParticipants}
                                </li>

                                <li>
                                    <label>Maximum Online Participants</label>
                                    {maxOnlineParticipants}
                                </li>
                            </div>

                            <div className="inline">
                                <li>
                                    <label>Requires Credits</label>
                                    <input type="checkbox" readonly checked={requiresToken}/>
                                </li>
                                
                                <li>
                                    <label>Credits per Spot</label>
                                    {tokensPerSpot}
                                </li>
                            </div>

                            <div className="inline">
                                <li>
                                    <label>Enable Waitlist</label>
                                    <input type="checkbox" readonly checked={enableWaitlist}/>
                                </li>

                                <li>
                                    <label>Auto Manage Waitlist</label>
                                    <input type="checkbox" readonly checked={autoManageWaitList}/>
                                </li>
                                
                                <li>
                                    <label>Waitlist Limit</label>
                                    {waitlistLimit}
                                </li>
                            </div>
                        </>
                        }    
                        <li>
                            <label>Status</label>
                            {status}
                        </li>
                        {showDetail &&
                            <>
                                <div className="divider"></div>
                                <div className="inline">
                                    <li>
                                        <label>Participants</label>
                                        {currentParticipants}
                                    </li>

                                    <li>
                                        <label>Online Participants</label>
                                        {currentOnlineParticipants}
                                    </li>
                                    
                                    <li>
                                        <label>Current Waitlist</label>
                                        {currentWaitingList}
                                    </li>
                                </div>
                                <div className="divider"></div>
                            </>
                        }
                        
                    </ul>
                </form>

                <div>
                    {!showDetail &&
                        <div className="EventFooter" onClick={()=>{handleOnSelect(idx)}}> View/Edit Event </div>
                    }

                    {showDetail &&
                        <div className="buttons">
                            <div onClick={()=>{handleReturn()}}> Return </div>    
                            
                            {!confirmDelete && !error && updateEnabled &&
                            <>
                                <div>
                                    <Link to={`/events/update/${id}`}>Update</Link>
                                </div>
                                <div onClick={verifyDelete}> Delete </div>    
                            </>
                            }

                            {confirmDelete && !error &&
                                <div className="del" onClick={()=>{submitDelete(id, versionNumber)}}> Confirm Delete </div>    
                            }
                        </div>    
                    }
                </div>
            </div>        
        </div>
    ) 
}

export default SingleEventDetail