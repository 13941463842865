//This form will be used for registring users that call in via phone

import React, {useState, useEffect} from 'react'
import axios from 'axios'

import ContactDetailsPhone from './ContactDetailsPhone'
import MembersInfo from './MembersInfo'
import BillingDetails from './BillingDetails'

import SquarePayment from '../../components/SquarePayment'

import {requestFees} from '../../utils/fetchFees'

import { validateMembershipInputs, validateBillingInputs } from '../../utils/validations'
import { generateIdempotencyKey, logAxiosError } from '../../utils/functions'

import Rulebook from '../../components/Rulebook'

import './RegistrationForm.css'

import * as Constants from '../../Constants'
import { PHONE_REGISTER_URL } from '../../utils/urls'
import { AXIOS_LONG_TIMEOUT } from '../../utils/constants'

import {
    MAX_NTRP_RATING,
    MIN_NTRP_RATING
} from '../../utils/constants'

const DEPLOYED_ENV = process.env.REACT_APP_DEPLOYED_ENV;
const UPDATE_INFO_MESSAGE = 'Click here to update Membership and Billing information if needed'

function RegistrationFormPhone (props) {
    const { history, user, configValues, updateChildMessage, updatePhonebook } = props
    const [authorizedUser, setAuthorizedUser] = useState(false)
    
    //define fees here. In the next version, get fees from Strapi

    //default max values
    let maxAdults = 2
    let maxJuniors = 6

    if(configValues.maxJuniors > 0){
        maxJuniors = configValues.maxJuniors
    }

    if(configValues.maxAdults > 0){
        maxAdults = configValues.maxAdults
    }

    if(configValues.juniorOnly){
        //only juniors are allowed to register
        maxAdults = 0
    }

    useEffect(()=> {
        if(!user){
            updateChildMessage("", "Please Login to continue...", "primary", 3000)
            history.push('/login')
        } else if(user.isStaff || user.isAdmin) {
            setAuthorizedUser(true)
        } else {
            setAuthorizedUser(false)
            history.push('/')
        }
    },[])
     
    //define all State Variables to be used. Initialize values wherever needed
    const [firstTimeMemberInput, setFirstTimeMemberInput] = useState(true)
    const [membershipFormCompleted, setMembershipFormCompleted] = useState(false)
    const [billingInfoCompleted, setBillingInfoCompleted] = useState(false)
    const [adults, setAdults] = useState(0)
    const [juniors, setJuniors] = useState(0)
    const [membership, setMembership] = useState()
    const [membershipLabel, setMembershipLabel] = useState()
    const [membershipFee, setMembershipFee] = useState()
    const [hst, setHst] = useState()
    const [totalFee, setTotalFee] = useState()
    const [idempotencyKey, setIdempotencyKey] = useState()  
    const [terms, setTerms] = useState(false)
    const [confirmEntries, setConfirmEntries] = useState(false)
    const [refreshFees, setRefreshFees] = useState(false)
    const [showRulebook, setShowRulebook] = useState(false)
    const [consentMessage, setConsentMessage] = useState()
    const [isProduction, setIsProduction] = useState()
    const [formSubmitted, setFormSubmitted] = useState(false)

    const [contactFields, setContactFields] = useState(
        {
        primaryFirstName: '',
        primaryLastName: '',
        primaryEmail: '',    
        address: '',
        city: '',
        postal: '',
        primaryPhone: '',
        emergencyContact: '',
        emergencyPhone: ''
        }
    )

    const [members, setMembers] = useState([])          

    const [billingFields, setBillingFields] = useState(
        {
        firstName: '',
        lastName: '',
        address: '',
        city: '',
        postal: '',
        phone: '',
        email: '',
        membershipLabel: '',
        membershipFee: 0,
        hst: 0,
        totalAmount: 0
        }
    )
    const [paymentFields, setPaymentFields] = useState({})

    const [regFormSubmitted, setRegFormSubmitted] = useState(false)

    //define default fees
    const [membershipFees, setMembershipFees] = useState({
        single: 120,
        couple: 180,
        double: 140,
        junior: 60,
        family: 220
    })
    
    const [applyHST, setApplyHST] = useState(false)
    const [hstIncluded, setHstIncluded] = useState(false)
    const [hstLabel, setHstLabel] = useState('HST:')

    let idempotency_key

    //Redirect to Home Page once the form is submitted
    useEffect(() => {
        if(formSubmitted){
          history.push('/')
        }
    },[formSubmitted])

    useEffect(()=>{
        if(authorizedUser){
            setRefreshFees(false)
            requestFees(refreshFees).then(data => {
                setMembershipFees({
                    single: data.currentFees.single,
                    couple: data.currentFees.couple,
                    double: data.currentFees.double,
                    junior: data.currentFees.junior,
                    family: data.currentFees.family
                })
            })

            if(configValues.addHST){
                setApplyHST(true)

                //if HST is applicable, check if it is included in the price
                if(configValues.hstIncluded){
                    setHstIncluded(true)
                    setHstLabel('HST (included):')
                }
            }
        }
    },[refreshFees, authorizedUser])

    //generate an idempotency key once when the form is loaded
    useEffect (() => {
        if(DEPLOYED_ENV === 'production'){
            setIsProduction(true)
        } else {
            setIsProduction(false)
        }

        idempotency_key = generateIdempotencyKey()

        setShowRulebook(false)
        setIdempotencyKey(idempotency_key)
        setRegFormSubmitted(false)
        window.scrollTo(0, 0)
        setConsentMessage('Click here to view and accept the Club Rules and Code of Conduct')
    },[])

    //whenever the # of adults or juniors change, update the Membership Type
    useEffect(() => {
        const updateMembership = () => {
          //determine membership based on adults and juniors. Defualt to Single Adult

          let currentFee = 0
          let calculatedHST = 0
          let totalAmount = 0

          if(adults <= 0 && juniors === 0){
            setMembership('')
            setMembershipLabel('')
          }  else if(adults === 1 && juniors === 0){
            setMembership('single')
            setMembershipLabel('Single Adult')
            currentFee = membershipFees.single
          } else if(adults === 2 && juniors === 0){
            setMembership('couple')
            setMembershipLabel('Couple')
            currentFee = membershipFees.couple
          } else if(adults === 1 && juniors === 1){
            setMembership('double')
            setMembershipLabel('Single Adult and Junior')
            currentFee = membershipFees.double
          } else if(adults === 0 && juniors > 0 && juniors <4 ){
            setMembership('junior')
            setMembershipLabel('Junior')
            currentFee = membershipFees.junior * juniors
          } else {
            setMembership('family')
            setMembershipLabel('Family')
            currentFee = membershipFees.family
          }  

          if(applyHST){
            if(!hstIncluded){
                // HST is not included. Calculate the HST on the base and add to the total  
                calculatedHST = currentFee * configValues.percentHST/100
                totalAmount = currentFee + calculatedHST
              } else {
                // HST is not included. Calculate the HST on the base and add to the total  
                calculatedHST = currentFee * configValues.percentHST/(100 + configValues.percentHST)
                totalAmount = currentFee
              }
              calculatedHST = Math.round(calculatedHST * 100)/100
          } else {
            totalAmount = currentFee
          }

          //console.log('configValues', configValues)
          //console.log('applyHST ', applyHST)
          //console.log('calculatedHST ', calculatedHST)
          //console.log('totalAmunt ', totalAmount)

          setMembershipFee(currentFee)
          setHst(calculatedHST)
          setTotalFee(totalAmount)
        }

        updateMembership()
    }, [adults, juniors])

    const updateMembership = () => {
        //update membership info
        setMembershipFormCompleted(false)
        setBillingInfoCompleted(false)
        setFormSubmitted(false)
    }

    const trimInputFields = () => {
        let updatedContactFields = {...contactFields}

        updatedContactFields.postal = updatedContactFields.postal.trim()
        updatedContactFields.primaryPhone = updatedContactFields.primaryPhone.trim()
        updatedContactFields.emergencyPhone = updatedContactFields.emergencyPhone.trim()
        
        setContactFields(updatedContactFields)
    
        let updatedMembers = [...members]

        for(let idx=0; idx < members.length; idx++){
            updatedMembers[idx].firstName = updatedMembers[idx].firstName.trim()
            updatedMembers[idx].lastName = updatedMembers[idx].lastName.trim()
            updatedMembers[idx].email = updatedMembers[idx].email.trim()
            updatedMembers[idx].phoneNumber = updatedMembers[idx].phoneNumber.trim()

            if(updatedMembers[idx].yearOfBirth > ''){
                updatedMembers[idx].yearOfBirth = updatedMembers[idx].yearOfBirth.trim()
            }
        }

        setMembers(updatedMembers)
    }

    const handleRegFormSubmit = (e) => {
        e.preventDefault()

        //trim input fields
        trimInputFields()

        //first validate all input fields
        
        const validateFields = {
            adults: adults,
            juniors: juniors, 
            terms: terms, 
            address: contactFields.address, 
            city: contactFields.city, 
            postal: contactFields.postal, 
            primaryPhone: contactFields.primaryPhone, 
            emergencyContact: contactFields.emergencyContact, 
            emergencyPhone: contactFields.emergencyPhone, 
            members: members,
            currentYear: configValues.currentSeason,
            maxAgeJunior: configValues.maxAgeJunior
        }

        let validations = validateMembershipInputs(validateFields)
        
        if(validations.validatedResult){
            //passed all validations
            //setup billing fields
            let updatedBillingFields = {
                firstName: contactFields.primaryFirstName,
                lastName: contactFields.primaryLastName,
                address: contactFields.address,
                city: contactFields.city,
                postal: contactFields.postal,
                phone: contactFields.primaryPhone,
                email: contactFields.primaryEmail,
                membershipLabel: membershipLabel,
                membershipFee: membershipFee,
                hst: hst,
                totalAmount: totalFee
            }

            setBillingFields(updatedBillingFields)

            //scroll to top of screen
            window.scrollTo(0, 0)
            setMembershipFormCompleted(true)
        } else {
            //display the validation error message
            updateChildMessage('Error', validations.validatedMessage, 'danger', 0)
            window.scrollTo(0, 0)
        }
    }

    const trimBillingInputFields = () => {
        let updatedBillingFields = {...billingFields}
        updatedBillingFields.postal = updatedBillingFields.postal.trim()
        updatedBillingFields.phone = updatedBillingFields.phone.trim()
        setBillingFields(updatedBillingFields)
    }

    const handleBillingSubmit = (e) => {
        e.preventDefault()

        //trim input fields
        trimBillingInputFields()

        //first validate all input fields
        let validateBillingFields = {
            firstName: billingFields.firstName, 
            lastName: billingFields.lastName, 
            address: billingFields.address, 
            city: billingFields.city, 
            postal: billingFields.postal, 
            phone: billingFields.phone, 
            email: billingFields.email, 
            confirmEntries: confirmEntries
        }

        let validations = validateBillingInputs(validateBillingFields)
        
        if(validations.validatedResult){
            setBillingInfoCompleted(true)

            //setup the payment fields
            let updatedPaymentFields = {
                irstName: billingFields.firstName, 
                lastName: billingFields.lastName, 
                address: billingFields.address, 
                city: billingFields.city, 
                postal: billingFields.postal, 
                phone: billingFields.phone, 
                email: billingFields.email, 
                totalAmount: billingFields.totalAmount, 
                lineItemLabel: `${billingFields.membershipLabel} - membership`, 
                updateInfoMessage: UPDATE_INFO_MESSAGE
            }

            setPaymentFields(updatedPaymentFields)

        } else{
            updateChildMessage('Error', validations.validatedMessage, 'danger', 0)
            window.scrollTo(0, 0)
        }
    }

    //handle billing change
    const handleBillingChange = (e) => {
        // clear errors, if any
        updateChildMessage('', '', '', 0)
        
        let updatedBillingFields = {...billingFields}

        if(e.target.name === "confirmEntries"){
            setConfirmEntries(e.target.checked)
        }else{
            if(e.target.name==="postal"){
                updatedBillingFields[e.target.name] = e.target.value.toUpperCase()
            } else {
                updatedBillingFields[e.target.name] = e.target.value
            }
            
            setBillingFields(updatedBillingFields)
        }    
    }

    const deleteMember = (memberId) => {
        let updatedMembers = [...members]
        let memberType = updatedMembers[memberId].memberType

        let message = 'Removed Member: '
                    + updatedMembers[memberId].firstName
                    + ' ' 
                    + updatedMembers[memberId].lastName

        updateChildMessage('Alert', message, 'danger', 5000)            

        if(memberType==="adult"){
            let updatedAdults = adults - 1
            setAdults(updatedAdults)
        } else {
            let updatedJuniors = juniors - 1
            setJuniors(updatedJuniors)
        }

        updatedMembers.splice(memberId,1)
        setMembers(updatedMembers)

        //scroll to top of the form
        window.scrollTo(0, 0)
    }

    const addMember = (memberType) =>{
        if(memberType==="adult"){
            let updatedAdults = adults + 1
            setAdults(updatedAdults)
        } else {
            let updatedJuniors = juniors + 1
            setJuniors(updatedJuniors)
        }

        let updatedMembers = [...members, {
            memberType: memberType,
            firstName:"", 
            lastName:"",
            email:"",
            yearOfBirth: "",
            ntrpSelf: "",
            consentEmailContact: false,
            showNewsLetterConsent: true,
            primaryMember: false,
            consentNewsLetter: false,
            phoneNumber:"",
            consentPhoneBook: false
        }]

        if(memberType==="adult" && firstTimeMemberInput){
            //default the member details to the applicant user
            let index = updatedMembers.length - 1
            
            if(contactFields.primaryFirstName > ' '){
                updatedMembers[index].firstName = contactFields.primaryFirstName
            }
            
            if(contactFields.primaryLastName > ' '){
                updatedMembers[index].lastName = contactFields.primaryLastName
            }

            if(contactFields.primaryEmail > ' '){
                updatedMembers[index].email = contactFields.primaryEmail
            }
            
            if(contactFields.primaryPhone){
                updatedMembers[index].phoneNumber = contactFields.primaryPhone
            }
            setFirstTimeMemberInput(false)
        }
        
        setMembers(updatedMembers)
    }

    const handleAddAdult = () => {
        addMember("adult")
    }

    const handleAddJunior = () => {
        addMember("junior")
    }

    const handleChange = (e) => {
        // clear errors, if any
        updateChildMessage('', '', '', 0)

        if (["firstName", "lastName", "email", "phoneNumber", "yearOfBirth", "ntrpSelf"].includes(e.target.className) ) {
            let updatedMembers = [...members]
            if(e.target.className === "yearOfBirth"){
                if(e.target.value.length <= 4){
                    //do not allow more than 4 digits to be entered for year of Birth
                    updatedMembers[e.target.dataset.id][e.target.className] = e.target.value
                }
            } else if(e.target.className === "ntrpSelf"){
                if(e.target.value >= MIN_NTRP_RATING 
                    && e.target.value <= MAX_NTRP_RATING){
                    //do not allow a value more than 7 for NTRP rating
                    updatedMembers[e.target.dataset.id][e.target.className] = e.target.value
                }
            } else {
                updatedMembers[e.target.dataset.id][e.target.className] = e.target.value
            }
            
            if(updatedMembers[e.target.dataset.id].primaryMember 
                && e.target.className === "email" 
                && !updatedMembers[e.target.dataset.id].showNewsLetterConsent ){
                //when primary members email id is being updated, show the consent field
                //primary member email is being updated.
                console.log('primary member email is being updated')
                //show the newsletter consent
                updatedMembers[e.target.dataset.id].showNewsLetterConsent = true
            }

            setMembers(updatedMembers)
        } else if (["consentEmailContact", "consentPhoneBook", "consentNewsLetter"].includes(e.target.className)) {
            let updatedMembers = [...members]
            updatedMembers[e.target.dataset.id][e.target.className] = e.target.checked
            setMembers(updatedMembers)
        } else {
            let updatedContactFields = {...contactFields}

            if(e.target.name === "terms"){
                setTerms(e.target.checked)
            }else{
                if(e.target.name === "postal"){
                    updatedContactFields[e.target.name] = e.target.value.toUpperCase()
                } else {
                    updatedContactFields[e.target.name] = e.target.value
                }

                setContactFields(updatedContactFields)
            }    
        }
    }

    const submitMembership = (cnon, verficationToken) => {
        // submit the registration form and Payment and transfer to home page
        // submit form logic to be added here
        
        let messageTitle = 'Default'
        let message = 'Default'
        let messageType = Constants.MSG_TYPE_SUCCESS  

        if(!regFormSubmitted){
            //do not allow the user to submit the form multiple times
            setRegFormSubmitted(true)
            let allowResubmit = false
            let processFlag = true
   
            const postRegistration = async () => {
                let res 
                //console.log('idempotency key', idempotencyKey)

                try{
                    res = await axios({
                        method: 'POST',
                        timeout: AXIOS_LONG_TIMEOUT,
                        url: PHONE_REGISTER_URL,
                        data: {
                            primaryFirstName: contactFields.primaryFirstName,
                            primaryLastName: contactFields.primaryLastName,
                            primaryEmail: contactFields.primaryEmail,
                            terms: terms,
                            address: contactFields.address,
                            city: contactFields.city,
                            postal: contactFields.postal,
                            primaryPhone: contactFields.primaryPhone,
                            emergencyContact: contactFields.emergencyContact,
                            emergencyPhone: contactFields.emergencyPhone,
                            adults: adults, 
                            juniors: juniors,
                            membership: membership,
                            members: members,
                            cnon: cnon,
                            verficationToken: verficationToken,
                            idempotencyKey: idempotencyKey,
                            billing: billingFields
                        }  
                    })
                } catch (error){
                    console.log('phone registration error')
                    messageTitle = 'Error' 
                    message = 'Error submitting Registeration form'
                    messageType = Constants.MSG_TYPE_ERROR
                    logAxiosError(error)
                    processFlag = false
                    allowResubmit = true
                }

                //console.log('res:', res)
                const {data} = res
                //console.log('reg-res-data', data)
        
                
                if(processFlag){
                    
                    if (data.status === Constants.RETURN_CODE_OK) {
                        let memberName = `${contactFields.primaryFirstName} ${contactFields.primaryLastName} `
                        
                        messageTitle = 'Registration   Successful' 
                        message = `${memberName} has been registered for the ${configValues.currentSeason} Season`
                        messageType = Constants.MSG_TYPE_SUCCESS    

                        updatePhonebook(true)
                    } else if (data.status === Constants.RETURN_CODE_NOT_AUTH) {
                        messageTitle = 'Error' 
                        message = 'User not authorized'
                        messageType = Constants.MSG_TYPE_ERROR
                    } else {
                        messageTitle = 'Error' 
                        message = 'Error submitting form. Please try again'
                        messageType = Constants.MSG_TYPE_ERROR
                    }
                      
                    updateChildMessage(messageTitle, message, messageType, 10000)

                    setFormSubmitted(true)
            
                    //send user back to homepage after form submission
                    

                    if(allowResubmit){
                        //generate a new key. Navigate user to Billing Page to verify billing info
                        idempotency_key = generateIdempotencyKey()
                        setIdempotencyKey(idempotency_key)
                        setRegFormSubmitted(false)
                        setBillingInfoCompleted(false)
                        setConfirmEntries(false)
                    }
                } else {
                    updateChildMessage("Submit Error", "Error submitting registration", "danger", 10000)
                }
            }  
            postRegistration() 
        }
    }

    const onChangeHandler = () =>{
        return null
    }

    const handleAcceptOnClick = (e) => {
        e.preventDefault()

        setShowRulebook(false)
        setTerms(true)  
        setConsentMessage('I agree to the Club Rules and Code of Conduct')
    }

    if(!user){
        return(null)
    }
    
    return (
        <>
            {
                !membershipFormCompleted && !showRulebook &&
                <div className="form-style-10">
                    <h1>Registration Form<span>Sign up for the {configValues.currentSeason} Tennis Season</span></h1>
                    <form onSubmit={handleRegFormSubmit} onChange={handleChange}>
                        <ContactDetailsPhone
                            primaryFirstName={contactFields.primaryFirstName}
                            primaryLastName={contactFields.primaryLastName}
                            primaryEmail={contactFields.primaryEmail}
                            address={contactFields.address}
                            city={contactFields.city}
                            postal={contactFields.postal}
                            primaryPhone={contactFields.primaryPhone}
                            emergencyContact={contactFields.emergencyContact}
                            emergencyPhone={contactFields.emergencyPhone}
                        />

                        <div className="section"><span>4</span>Members</div>
                        <MembersInfo
                            members={members}
                            deleteMember={deleteMember}
                            waitlist={false}
                        />

                        {adults < maxAdults ?
                            <div className="section-addadult">
                                <span>
                                    <i className="fas fa-plus-circle"
                                        onClick={handleAddAdult}
                                    ></i>
                                </span>Add Adult
                            </div> : <></>
                        }    
                        
                        {juniors < maxJuniors ?
                            <div className="section-addjunior">
                                <span>
                                <i className="fas fa-plus-circle"
                                    onClick={handleAddJunior}
                                >    
                                </i>
                                </span>Add Junior
                            </div> : <></>
                        }
                        
                        <div className="section"><span>S</span>Summary</div>
                        <div className="inner-summarywrap">
                            {membershipLabel &&
                                <p>Membership Type: {membershipLabel} </p>
                            }

                            <table>
                                <tbody>
                                    <tr>
                                        <td>Total Adults:</td>
                                        <td>{adults}</td>
                                    </tr>                                        
                                    <tr>
                                        <td>Total Juniors:</td>
                                        <td>{juniors}</td>
                                    </tr>                                        
                                </tbody>
                            </table>
                            
                            {membershipLabel &&
                                <table>
                                    <tbody>
                                        <tr>
                                            <td>Membership Fee:</td>
                                            <td>${membershipFee.toFixed(2)}</td>
                                        </tr>
                                        <tr>
                                            <td>{hstLabel}</td>
                                            <td>${hst.toFixed(2)}</td>
                                        </tr>                                        
                                        <tr>
                                            <td>Total Amount:</td>
                                            <td>${totalFee.toFixed(2)}</td>
                                        </tr>                                        
                                    </tbody>
                                </table>
                            }
                        </div>

                        <div className="button-section">
                            <span className="privacy-policy">
                                <label className="checkbox" htmlFor="terms">
                                    <input 
                                        type="checkbox" 
                                        name="terms"
                                        checked={terms} 
                                        onChange={onChangeHandler}
                                    /> 
                                    <a href="/rulebook" target="_blank">Applicant agrees to the Club Rules and Code of Conduct</a>
                                </label>
                            </span>
                            <button type="submit"> Pay Membership Fee </button>
                        </div>
                    </form>
                </div>
            }    

            {showRulebook &&
                <Rulebook 
                    mode="acceptTerms"
                    handleAcceptOnClick={handleAcceptOnClick}
                />    
            }

            {
                membershipFormCompleted && !billingInfoCompleted &&

                <div className="form-style-10">
                    <h1>Billing Details<span>Confirm and/or update billing details</span></h1>
                    <form onSubmit={handleBillingSubmit} onChange={handleBillingChange}>
                        <BillingDetails
                            billingFields={billingFields}
                            confirmEntries={confirmEntries}
                            updateMembership={ updateMembership }
                        />
                    </form>
                </div>
            }    

            {
                membershipFormCompleted && billingInfoCompleted &&
                <SquarePayment
                    paymentFields={paymentFields}
                    isProduction={isProduction}
                    submitPayment={ submitMembership }
                    updateBilling={ updateMembership }
                />
             } 
        </>
    )
}

export default RegistrationFormPhone