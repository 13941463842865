import React, {useState, useContext, useEffect} from 'react'
import axios from 'axios'
import Alert from 'react-bootstrap/Alert'

import {UserContext} from '../../context/UserContext'
import { capitalize, setUserRole } from '../../utils/functions'

import './Auth.css'
import * as Constants from '../../Constants'

import { AUTH_LOCAL_URL, MEMBER_MEMBERSHIP_URL, MEMBERSHIP_RENEWAL_URL } from '../../utils/urls'
import { logAxiosError } from '../../utils/functions';
import { AXIOS_TIMEOUT } from '../../utils/constants'

const Login = ({history, updateChildMsg, configValues}) => {
    const { loginAllowed, 
            currentSeason,
            prioritizeRegistration 
    } = configValues
    //console.log('history', history)

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [error, setError] = useState('')
    
    const [user, setUser] = useContext(UserContext)

    let processRequest = true

    useEffect(()=>{
        if(!loginAllowed){
            //login is not allowed. Navigate user to the Home Page
            history.push('/')
        }
    },[])

    useEffect(() => {
        //console.log('history path', history.location)
        if(user){
            //navigate the user to the home page if the previous page was an "Auth" page
            history.goBack()
        }
        //console.log('rendering use effect - Login')
    }, [history, user])

    const handleEmail = (event) => {
        setEmail(event.target.value)
        setError('')
    }

    const handlePassword = (event) => {
        setPassword(event.target.value)
        setError('')
    }

    const validateInput = () => {
        const validInput = true
        return validInput
    }
   
    const handleSubmit = async (event) => {
        event.preventDefault()

        var validInput = validateInput()
        //var errMessage = ''
        
        if (validInput){    
            const postLogin = async () => {
                let res

                try{
                    res = await axios({
                        method: 'POST',
                        timeout: AXIOS_TIMEOUT,
                        url: AUTH_LOCAL_URL,
                        data: {
                            identifier: email,
                            password
                        }
                    })
                } catch(error){
                    setError(error)
                    logAxiosError(error)
                }
                  
                const {data} = res
                //console.log('login data', data)
                
                if (data.status === Constants.RETURN_CODE_OK) {
                    //console.log('login user: ', data.user)
                    //setUser(user => ({ ...user, user: data.user }))
                    let userRole = setUserRole(data.user)

                    //find membership details if the user is not the primary member
                    if(userRole.isMember || userRole.isExec){
                        if(!data.user.membershipId){
                            //membership id does not exist - this is the secondary user
                            //find the membership corresponding to this member
                            //a member or Exec must have a membership id

                            let res
                            processRequest = true
                            let membership
    
                            try {
                                res = await axios({
                                    method: 'GET',
                                    url: MEMBER_MEMBERSHIP_URL,
                                    timeout: AXIOS_TIMEOUT,
                                    params: {
                                        memberId: data.user.memberId
                                    }
                                })
                                //console.log('membership-response', res)
                            } catch (error) {
                                //console.log('Error fetching users membership')
                                setError("Error fetching users membership. ")
                                logAxiosError(error)
                                processRequest = false
                            }
                            
                            if(processRequest){
                                membership = res.data.data[0]
                            }
    
                            if(processRequest && membership.membershipId > 0){
                                let membershipFields = {}

                                membershipFields.membershipId = membership.membershipId
                                membershipFields.credits = membership.credits

                                //console.log('membership fields', membershipFields)
    
                                setUser({...data.user, ...userRole, ...membershipFields})    
                            }  else {
                                setError("Error fetching users membership. ")
                            }                          
                        } else {
                            //user is primary member. Membership ID is already available
                            setUser({...data.user, ...userRole})    
                        }
                    } else if(userRole.isAuth){
                        //check if the user can view the Registration Page
                        let renewal = {
                            registrationAllowed: false
                        }

                        if(!prioritizeRegistration){
                            //regitration is open for all users
                            renewal.registrationAllowed = true
                        } else {
                            //console.log('user-data ', data.user)
                            if(data.user.renewal){
                                //The user has been prioritized for renewal. Check the renewal dates      
                                try {
                                    res = await axios({
                                        method: 'GET',
                                        url: MEMBERSHIP_RENEWAL_URL,
                                        timeout: AXIOS_TIMEOUT,
                                        params: {
                                            renewalId: data.user.renewal,
                                            membershipYear: currentSeason
                                        }
                                    })
                                    //console.log('renewal-response', res)
                                } catch (error) {
                                    //console.log('Error fetching users membership')
                                    setError("Error fetching renewal. ")
                                    logAxiosError(error)
                                    processRequest = false
                                }

                                if(processRequest){
                                    let renewalData = res.data[0]
                                    //console.log('renewalData: ', renewalData)

                                    //check the dates to show registration link
                                    let registerShowFromDate = new Date(renewalData.showFromDate)
                                    let registerEndDate = new Date(renewalData.endDate)

                                    let now = new Date().getTime();

                                    if( now >= registerShowFromDate &&
                                        now <= registerEndDate ){
                                            //user can access the registration link
                                            renewal.registrationAllowed = true
                                    } else {
                                        //user cannot access registration link as yet
                                        renewal.registrationAllowed = false
                                    }
                                } else {
                                    //don't allow registration if there is a failure checking for renewal
                                    renewal.registrationAllowed = false
                                }
                            }
                        }

                        setUser({...data.user, ...userRole, ...renewal})    
                    } else {
                        setUser({...data.user, ...userRole})    
                    }

                    if(processRequest){
                        //let testUser = {...data.user, ...userRole}
                        //console.log('testuser', testUser)

                        let message = 'Welcome back ' + capitalize(data.user.firstName) + ' ' + capitalize(data.user.lastName)
                        
                        updateChildMsg('', message , Constants.MSG_TYPE_SUCCESS, 3000)
                    }
                } else if (data.status === Constants.RETURN_CODE_NOT_AUTH) {
                    setError("Invalid username or password")
                } else {
                    setError('Request failed. Please try again later')
                }
            }
            postLogin()
        }
    }          

    return (
        <div className="Auth">
            <h2> Login </h2>
            
            {error &&
                <Alert variant="danger" onClose={() => setError('')} dismissible>
                    <Alert.Heading>Error!</Alert.Heading>
                    <p>
                        {error}
                    </p>
                </Alert>                
            }

            <form onSubmit={handleSubmit}>
                <a className="reset-link" href='/forgot'>  Forgot Password? Click here to reset your password </a> 
                <br></br>
                <br></br>
                Email
                <input 
                    name="email"
                    type="email"
                    value={email}
                    placeholder="Email Address"
                    onChange={handleEmail}
                />
                <br></br>
                Password
                <input 
                    name="password"
                    type="password"
                    value={password}
                    placeholder="password"
                    onChange={handlePassword}
                />
                <button type="submit"> Login </button>
            </form>
            <div className="Spacer"></div>
        </div>
    )    
}

export default Login
