import React, { useState, useEffect } from 'react';
import axios from 'axios'
import ReactMarkdown from 'react-markdown';

import { Link } from 'react-router-dom';

import './MembershipInfo.css'

import { MEMBERSHIP_INFO_URL, fromImageToUrl } from '../../utils/urls'

import { logAxiosError } from '../../utils/functions';
import { AXIOS_TIMEOUT } from '../../utils/constants'

const MembershipInfo = () => {
    const[membershipInfo, setMembershipInfo] = useState({})
    
    useEffect(() => {
        const getMembershipInfo = async () => {
            let res 

            try{
                res = await axios({
                    method: 'GET',
                    timeout: AXIOS_TIMEOUT,
                    url: MEMBERSHIP_INFO_URL
                })
            } catch(error){
                console.log('Error retrieving membership information')
                logAxiosError(error)
            }

            const {data} = res
            setMembershipInfo(data)
        }  
        getMembershipInfo()
    }, [])  

    let image = ''

    const { mainTitle, subTitle, leftBox, rightBox, linkText } = membershipInfo

    return (
        <div className="MembershipInfo">
            <img className="Image" src={fromImageToUrl(image)} alt="Info Page"/>
                
            <div className="PgTitle"> {mainTitle} </div>  
            <div className="PgSubTitle"> {subTitle} </div>

            <div className="Textcontainer"> 
                <div className="Para">
                    <ReactMarkdown>{leftBox}</ReactMarkdown>
                </div>
                <div className="Para"> 
                    <ReactMarkdown>{rightBox}</ReactMarkdown>
                </div>
            </div>
            
            {linkText &&
                <div className="Link-box">
                    <Link to="/membership/fees">{linkText} </Link>
                </div>
            }  
        </div>
    )    
}

export default MembershipInfo