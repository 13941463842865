import React from 'react'

import './CountdownTimer.css'

const CountdownTimer = (props) => {
    let {
        timerName,
        days,
        hours,
        minutes,
        seconds,
        colorScheme
    } = props

    let daysTitle = "Days"
    let hoursTitle = "Hours"
    let minutesTitle = "Minutes"
    let secondsTitle = "Seconds"
    let cssClassTitle = "title"
    let cssClassStrings = "strings"

    if(colorScheme === "blue"){
        cssClassTitle = "title1"
        cssClassStrings = "strings2"
    }

    return(
        <div className="Timer">
            <header></header>
            <section>
                <div className="wrapper">
                    <hgroup>
                        <p className={cssClassTitle}>{timerName}</p>
                    </hgroup>

                    <div id="countdown">
                        
                        <div className="cd-box">
                            <p className="numbers">{days}</p>
                            <p className={cssClassStrings}>{daysTitle}</p>
                        </div>
                    
                        <div className="cd-box">
                            <p className="numbers">{hours}</p>
                            <p className={cssClassStrings}>{hoursTitle}</p>
                        </div>
                        
                        <div className="cd-box">
                            <p className="numbers">{minutes}</p>
                            <p className={cssClassStrings}>{minutesTitle}</p>
                        </div>
                        <div className="cd-box">
                            <p className="numbers">{seconds}</p>
                            <p className={cssClassStrings}>{secondsTitle}</p>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default CountdownTimer
