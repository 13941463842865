import { useContext, useEffect } from 'react';
import {UserContext} from '../context/UserContext';
import axios from 'axios';

import { SIGNOUT_URL } from '../utils/urls'
import { AXIOS_TIMEOUT } from '../utils/constants'
import { logAxiosError } from '../utils/functions';

export default ({history}) => {

  const [user, setUser] = useContext(UserContext)

  //logout of Express Session
  useEffect(() => {
    const signoutUser = async () => {
      let res 

      try{
        res = await axios({
          method: 'GET',
          timeout: AXIOS_TIMEOUT,
          url: SIGNOUT_URL
        })
      } catch(error){
        console.log('Error sigining out user')
        logAxiosError(error)
      }
      
      //console.log('signout res: ', res)
      setUser(null)  
      window.location.reload();
    }  
    signoutUser()   
  }, [user, setUser])    

  useEffect(() => {
    if(!user){
        history.push('/')
    }
  }, [history, user])

  return null
}