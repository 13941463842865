import React, { useEffect, useState } from 'react'

import WaitlistEntry from './WaitlistEntry'
import { requestWaitlist  } from '../../utils/fetchWaitlist'
import { compareValues } from '../../utils/functions'

import ExportXLS from '../../components/ExportXLS'    

import './Waitlist.css'

/* 
    Documentation
    State Variables:
    membershipEntries    - the total set of all members 
    searchText  - user entered text to search. It will search both first and last names for contained characters
                - it can search only 1 name (no spaces)
    searchResult    - the result of the search. This array is the total list of entries that will be shown to the user (over multiple pages)
                    - it is a subset of membershipEntries. It equals the membershipEntries when no search is specified
    
    currentPage     - The page# of the current page the user is on. This is updated everytime the user navigates to a new page

    totalCurrentEntries     - the total# of entries based on the current search criteria
    pageStartEntry          - the serial# of the first item being displayed on the screen
    pageEndEntry            - the serial# of the last item being displayed on the screen
    currentPageList         - the list of entries that are shown to the user on the active page
    entriesPerPage          - user selected variable. Determines how many entries will be shown per page

    Pagination Bar:                
        This Bar is shown on the bottom right only if there is more than 1 page to diplay
        firstPage   - The Page Number associated with the first page shown on the pagenation bar 
        secondPage  - The Page Number associated with the second page shown on the pagenation bar 
        thirdPage   - The Page Number associated with the third page shown on the pagenation bar 
        showChevron - Set if there is more than 1 page to display
    
    Sort Fields
    First Name Ascending    -> firstNameSortAscending -> Default Sort Order when entering the page
    First Name Descending   -> firstNameSortDescending
    Last Name Ascending     -> lastNameSortAscending
    Last Name Descending    -> lastNameSortDescending
    id Ascending            -> idSortAscending
    id Descending           -> idSortDescending
    Note: Only 1 sort will be active at any give time

    Modes:
    There are several modes that this screen supports
    selectedEventMode ->    A staff user is using this screen to search for member to signup for a 
                            particular event

*/
const Waitlist = ({ history, user, updateWaitlist, refreshWaitlist }) =>  {
    const [membershipEntries, setMembershipEntries] = useState([{}])
    const [searchText, setSearchText] = useState('')
    const [searchResult, setSearchResult] = useState([{}])
    const [idSortAscending, setIdSortAscending] = useState(true)
    const [idSortDescending, setIdSortDescending] = useState(false)
    const [firstNameSortAscending, setFirstNameSortAscending] = useState(true)
    const [firstNameSortDescending, setFirstNameSortDescending] = useState(false)
    const [lastNameSortAscending, setLastNameSortAscending] = useState(false)
    const [lastNameSortDescending, setLastNameSortDescending] = useState(false)
    const [firstPage, setFirstPage] = useState()
    const [secondPage, setSecondPage] = useState()
    const [thirdPage, setThirdPage] = useState()
    const [firstPageStyle, setFirstPageStyle] = useState('')
    const [secondPageStyle, setSecondPageStyle] = useState('')
    const [thirdPageStyle, setThirdPageStyle] = useState('')
    const [showChevron, setShowChevron] = useState(false)
    const [currentPage, setCurrentPage] = useState()
    const [totalCurrentEntries, setTotalCurrentEntries] = useState()
    const [pageStartEntry, setPageStartEntry] = useState()
    const [pageEndEntry, setPageEndEntry] = useState()
    const [currentPageList, setCurrentPageList] = useState([{}])
    const [entriesPerPage, setEntriesPerPage] = useState(10)
    const [entriesFound, setEntriesFound] = useState(true)

    const [authorizedUser, setAuthorizedUser] = useState(false)
    const [membershipDetails, setMembershipDetails] = useState(false)
    const [xlsData, setXlsData] = useState()

    let newEntriesPerPage = 10

    useEffect(()=> {
        if(!user){
            // child message not showing in this case. Investigate Later
            // updateChildMessage("", "Please Login to continue...", "primary", 0)
            history.push('/login')
        } else if(user.isStaff || user.isAdmin  || user.isExec) {
            setAuthorizedUser(true)
        }else {
            setAuthorizedUser(false)
            history.push('/')
        }

        setMembershipDetails(false)
    },[])

    useEffect(() => {
        if(authorizedUser){
            setFirstNameSortAscending(false)
            setFirstNameSortDescending(false)
            setLastNameSortAscending(false)
            setLastNameSortDescending(false)
            setIdSortAscending(true)
            setIdSortDescending(false)
            //defualt to 10 entries per page
            setEntriesPerPage(10)

            requestWaitlist(refreshWaitlist).then(membersList => {
                //console.log('membersList: ',membersList)
                if(membersList && membersList.length>0){
                    //default sort by ID
                    membersList.sort(compareValues('id'))
                    setMembershipEntries(membersList)
                    setSearchResult(membersList)
                    updateWaitlist(false)
                    setEntriesFound(true)
                } else {
                    setEntriesFound(false)
                }
            })
        }
    }, [authorizedUser, refreshWaitlist])   

    useEffect(()=>{
        if(authorizedUser){
            setPagination(searchResult, 1, 1, true)
        }
    },[entriesPerPage, searchResult, authorizedUser])

    const handleRefreshWaitlist = () => {
        //setReloadMembers(true)
        updateWaitlist(true)
    }

    const setPagination = (list, startPage=1, thisPage=1, rebuildPage=false) => {
        let totalEntries = list.length
        
        let totalPages = Math.ceil(totalEntries / entriesPerPage)
        //console.log('params: ', totalPages, startPage, thisPage)

        if((thisPage !== currentPage) || rebuildPage){
            setCurrentPage(thisPage)
            //show the entries for this page
            setTotalCurrentEntries(totalEntries)
            let startingEntry = 0
            let endingEntry = 0
            let newCurrentPageList = [...list]

            if(totalEntries > 0){
                startingEntry = ((thisPage - 1) * entriesPerPage) + 1
                endingEntry = startingEntry + entriesPerPage - 1
                if (endingEntry > totalEntries){
                    endingEntry = totalEntries
                } 
                newCurrentPageList = [...list].slice((startingEntry-1), (endingEntry))
            }
            
            setPageStartEntry(startingEntry)
            setPageEndEntry(endingEntry)
            setCurrentPageList(newCurrentPageList)
            //console.log('current list: ', newCurrentPageList)
        }

        switch(totalPages){
            case 0:
            case 1:    
                setFirstPage(null)
                setSecondPage(null)
                setThirdPage(null)                
                setShowChevron(false)
                break
            case 2:
                setFirstPage(1)
                setSecondPage(2)
                setThirdPage(null)                
                setShowChevron(true)

                if (thisPage === 1){
                    setFirstPageStyle('active')
                    setSecondPageStyle('')
                } else {
                    setFirstPageStyle('')
                    setSecondPageStyle('active')
                }

                break
            default:
                if((totalPages > 3) && (startPage + 2) > totalPages){
                    //there are more than 3 pages. Align the last set to show the last 3 pages
                    startPage = totalPages - 2
                }

                if((totalPages > 0) && (startPage <= 0)){
                    //show the first page if there are pages to display if an invalid start page is requested
                    startPage = 1
                }

                if(startPage <= totalPages){
                    setFirstPage(startPage)
                } else {
                    setFirstPage(null)
                } 
                
                if((startPage + 1) <= totalPages){
                    setSecondPage(startPage + 1)
                } else {
                    setSecondPage(null)
                } 

                if((startPage + 2) <= totalPages){
                    setThirdPage(startPage + 2)     
                } else {
                    setThirdPage(null)
                }   
                
                if (thisPage === startPage) {
                    //first page is active
                    setFirstPageStyle('active')
                    setSecondPageStyle('')
                    setThirdPageStyle('')
                } else if (thisPage === (startPage + 1)) {
                    //second page is active
                    setFirstPageStyle('')
                    setSecondPageStyle('active')
                    setThirdPageStyle('')
                } else {
                    // third page is active
                    setFirstPageStyle('')
                    setSecondPageStyle('')
                    setThirdPageStyle('active')
                }
                setShowChevron(true)
        }
    }

    const handleIdSort = () => {
        let currentMemberList = searchResult

        if(idSortAscending){
            setIdSortDescending(true)
            setFirstNameSortDescending(false)
            setFirstNameSortAscending(false)
            setLastNameSortAscending(false)
            setLastNameSortDescending(false)
            setIdSortAscending(false)

            currentMemberList.sort(compareValues('id', 'desc'))
            setSearchResult(currentMemberList)
            setPagination(currentMemberList, 1, 1, true)
        } else {
            setIdSortAscending(true)
            setFirstNameSortAscending(false)
            setFirstNameSortDescending(false)
            setLastNameSortAscending(false)
            setLastNameSortDescending(false)
            setIdSortDescending(false)

            currentMemberList.sort(compareValues('id'))
            setSearchResult(currentMemberList)
            setPagination(currentMemberList, 1, 1, true)
        }
    }

    const handleFirstNameSort = () => {
        let currentMemberList = searchResult

        if(firstNameSortAscending){
            setFirstNameSortDescending(true)
            setFirstNameSortAscending(false)
            setLastNameSortAscending(false)
            setLastNameSortDescending(false)
            setIdSortAscending(false)
            setIdSortDescending(false)

            currentMemberList.sort(compareValues('firstName', 'desc'))
            setSearchResult(currentMemberList)
            setPagination(currentMemberList, 1, 1, true)
        } else {
            setFirstNameSortAscending(true)
            setFirstNameSortDescending(false)
            setLastNameSortAscending(false)
            setLastNameSortDescending(false)
            setIdSortAscending(false)
            setIdSortDescending(false)

            currentMemberList.sort(compareValues('firstName'))
            setSearchResult(currentMemberList)
            setPagination(currentMemberList, 1, 1, true)
        }
    }

    const handleLastNameSort = () => {
        let currentMemberList = searchResult

        if(lastNameSortAscending){
            setFirstNameSortDescending(false)
            setFirstNameSortAscending(false)
            setLastNameSortAscending(false)
            setLastNameSortDescending(true)
            setIdSortAscending(false)
            setIdSortDescending(false)

            currentMemberList.sort(compareValues('lastName', 'desc'))
            setSearchResult(currentMemberList)
            setPagination(currentMemberList, 1, 1, true)
        } else {
            setFirstNameSortAscending(false)
            setFirstNameSortDescending(false)
            setLastNameSortAscending(true)
            setLastNameSortDescending(false)
            setIdSortAscending(false)
            setIdSortDescending(false)

            currentMemberList.sort(compareValues('lastName'))
            setSearchResult(currentMemberList)
            setPagination(currentMemberList, 1, 1, true)
        }
    }

    const handleChange = (e) => {
        let searchValue = e.target.value
        setSearchText(searchValue)
        buildCurrentPage(searchValue)        
    }

    const buildCurrentPage = (searchName) => {
        //console.log('search name: ', searchName)
        let currentSearchResult = [{}]

        if(!searchName){
            currentSearchResult = membershipEntries 
        } else {
            //find all entries where first name part or last name part match the search criteria
            currentSearchResult = membershipEntries.filter(item => {
                if((item.firstName.toLowerCase().includes(searchName.toLowerCase())) || 
                   (item.lastName.toLowerCase().includes(searchName.toLowerCase()))) {
                    return item
                }
            })                                            
        }
        setSearchResult(currentSearchResult)
    }

    const handlePrevPage = () => {
        if(currentPage <= 1){
            //no action to be performed
            return null
        }

        if(currentPage === firstPage){
            //we are already on the first page of this list. The page numbers need to be updated 
            setFirstPage(firstPage -1)
            setSecondPage(secondPage - 1)
            setThirdPage(thirdPage - 1)
            //decrement pages in sets of 3
            setPagination(searchResult, (currentPage - 3), (currentPage - 1))
        } else {
            setPagination(searchResult, firstPage, (currentPage - 1))
        }
    }

    const handleNextPage = () => {
        let maxPage = Math.ceil( searchResult.length / entriesPerPage)

        if (currentPage >= maxPage){
            //we are already at the last page. No action needed
            return null
        }

        if(currentPage === thirdPage){
            //we are already on the first page of this list. The page numbers need to be updated 
            setFirstPage(firstPage + 1)
            setSecondPage(secondPage + 1)
            setThirdPage(thirdPage + 1)
            setPagination(searchResult, (currentPage + 1), (currentPage + 1))
        } else {
            setPagination(searchResult, firstPage, (currentPage + 1))
        }
    }

    const handlePageClick = (pageNum) => {
        if(pageNum === "1"){
            //first page clicked
            setPagination(searchResult, firstPage, firstPage)
        } else if(pageNum === "2"){
            //second page requested
            setPagination(searchResult, firstPage, secondPage)
        } else {
            // third page requested
            setPagination(searchResult, firstPage, thirdPage)
        }
    }

    const handleEntriesPerPage = (e) => {
        newEntriesPerPage = parseInt(e.target.value, 10)

        setEntriesPerPage(newEntriesPerPage)
        //setPagination(searchResult, 1, 1, true)
    }

    const formatDownload = () => {
        //format the event data for download
        //console.log('data-format', data)
        
        let outputData = [{}]
        let sequence = 0
        
        if(membershipEntries && membershipEntries.length > 0 ){
            for(let idx=0; idx < membershipEntries.length; idx++){
                let entry = {}
                sequence +=1

                entry.Sequence = sequence
                entry.FirstName = membershipEntries[idx].firstName
                entry.LastName = membershipEntries[idx].lastName
                entry.Email = membershipEntries[idx].primaryEmail
                entry.Phone = membershipEntries[idx].primaryPhone
                entry.Adults = membershipEntries[idx].totalAdults
                entry.Juniors = membershipEntries[idx].totalJuniors
                entry.City = membershipEntries[idx].city
                
                if(membershipEntries[idx].renewal && membershipEntries[idx].renewal > 1){
                    entry.Renewal = "Approved"    
                } else {
                    entry.Renewal = "Pending"    
                }

                if(idx === 0){
                    outputData[0] = entry
                } else {
                    //for idx > 0, use the push function
                    outputData.push(entry)
                }
            }
        }

        setXlsData(outputData)
    }

    const handleDownloadFile = (e) => {
        e.preventDefault()
        setMembershipDetails(true)

        //format data
        formatDownload()
    }

    const handleFileDownloaded = () => {
        //restore the original buttons after the file is downloaded
        setMembershipDetails(false)
    }

    const handleGoback = () => {
        history.goBack()
    }

    return(
        <>
        {!user &&
            <></>
        }
        {user && !entriesFound &&
            <>
                <div className="Waitlist">
                    <h3> No waitlist applicants found </h3>
                </div>    
            </>    
        }

        <div className="Waitlist">
            <div className="Header2">
                {xlsData && membershipDetails && 
                    <>
                        <ExportXLS
                            csvData={xlsData}
                            fileName={`waitlist.csv`}
                            fileDownloaded={handleFileDownloaded}
                        />
                    </>
                }
            </div>
        </div>
        
        {user && entriesFound && 
        <div className="Waitlist">
            <div className="Searchbar">
                <h3> Waitlist Applicants </h3>
                <span className="SearchCard">
                    <form className="Searchbox" >
                        <div className="InputContainer">
                            <span>
                                <input 
                                    className="InputBox"
                                    placeholder= "search waitlist..."
                                    type="search" 
                                    value={searchText}
                                    onChange={handleChange}
                                />
                            </span>
                        </div>
                    </form>    
                </span> 
            </div>
            
            <div className="Memberlist">
                <div className="table-scroll">
                    <table>
                        <thead>
                            <tr>
                                <th>
                                    <span className="text" onClick={handleIdSort}>Id
                                    {idSortAscending &&
                                        <span><i className="fas fa-sort-up"></i></span>
                                    }
                                    {idSortDescending && 
                                        <span><i className="fas fa-sort-down"></i></span>
                                    }
                                    </span>
                                </th>
                                <th>
                                    <span className="text" onClick={handleFirstNameSort}>First Name
                                    {firstNameSortAscending &&
                                        <span><i className="fas fa-sort-up"></i></span>
                                    }
                                    {firstNameSortDescending && 
                                        <span><i className="fas fa-sort-down"></i></span>
                                    }
                                    </span>
                                </th>
                                <th>
                                    <span className="text" onClick={handleLastNameSort}>Last Name
                                    {lastNameSortAscending &&
                                        <span><i className="fas fa-sort-up"></i></span>
                                    }
                                    {lastNameSortDescending && 
                                        <span><i className="fas fa-sort-down"></i></span>
                                    }
                                    </span>
                                </th>

                                <th><span className="text"># Adults</span></th>

                                <th><span className="text"># Juniors</span></th>
                            </tr>
                        </thead>

                        <tbody>
                            <WaitlistEntry
                                membersList={currentPageList}
                            />
                        </tbody>               
                    </table>                    
                </div>    
            </div>
            <div className="pagefooter">
                <div className="PageSelection"> 
                    <form>   
                        <select 
                            name="perPage"  
                            size="1"
                            value={entriesPerPage} 
                            onChange={(e)=>{handleEntriesPerPage(e)}}
                        >
                            <option value="10">10 </option>
                            <option value="20">20 </option>
                            <option value="50">50 </option>
                        </select> 
                        <label htmlFor="perPage"> ...entries per page</label>
                    </form>                
                </div>

                <div> 
                    Showing {pageStartEntry} to {pageEndEntry} of {totalCurrentEntries}  
                </div>

                <div className="pagination">
                    {showChevron && 
                        <span onClick={handlePrevPage}>&laquo;</span>
                    }    
                    {firstPage &&
                        <span 
                            className={firstPageStyle}
                            onClick={()=>{handlePageClick("1")}}
                        >
                            {firstPage} 
                        </span>
                    }
                    {secondPage &&
                        <span 
                            className={secondPageStyle}
                            onClick={()=>{handlePageClick("2")}}
                        >
                            {secondPage} 
                        </span>
                    }
                    {thirdPage &&
                        <span 
                            className={thirdPageStyle}
                            onClick={()=>{handlePageClick("3")}}
                        >
                            {thirdPage} 
                        </span>
                    }
                
                    {showChevron &&
                        <span onClick={handleNextPage}>&raquo;</span>
                    }    
                </div>
            </div>

            {!membershipDetails && 
                <div className="bottomButtons">
                    <button className="memberDetails" onClick={handleDownloadFile}> Download Waitlist </button>
                    <button className="memberDetails" onClick={handleRefreshWaitlist}> Refresh Waitlist </button>
                </div>
            }
            
            {membershipDetails && 
                <>
                    Click on the Excel Icon above to download the waitlist file
                </>
            }
        </div>
        }
        </>
    )
}  

export default Waitlist
