import React, { useState, useEffect } from 'react'
import ReactMarkdown from 'react-markdown';
import CountdownTimer from './CountdownTimer'
import { Link } from 'react-router-dom';
import {fromImageToUrl} from '../utils/urls'

const SingleEventStaff = (props) => {
    let {
        currentEvent, 
        idx, 
        mode,
        handleOnSelect,
        handleReturn, 
        submitBooking,
        submitWaitList,
        submitCancel,
        submitCancelEvent,
        manageEventMode
    } = props

    let { 
        id,
        status,
        tokensPerSpot,
        currentParticipants,
        maxParticipants,
        currentWaitingList,
        enableWaitlist,
        waitlistLimit,
        eventStartDateTime,
        eventEndDateTime,
        bookingStartDateTime,
        bookingEndDateTime,
        requiresToken,
        title,
        url,
        description,
        isMemberRegistered,
    } = currentEvent

    const [cancelRequested, setCancelRequested] = useState(false)
    const [cancelEventRequested, setCancelEventRequested] = useState(false)
    const [registrationOpen, setRegistrationOpen] = useState(false)
    const [registrationClosed, setRegistrationClosed] = useState(false)
    const [timeoutId, setTimeoutId] = useState()
    const [timeoutId2, setTimeoutId2] = useState()
    const [timeToBooking, setTimeToBooking] = useState({
        days: 0,
        hours: 0, 
        minutes: 0,
        seconds: 0
    })

    const [timeToClosing, setTimeToClosing] = useState({
        days: 0,
        hours: 0, 
        minutes: 0,
        seconds: 0
    })

    const [eventStartDateTimeD, setEventStartDateTime] = useState()
    const [bookingStartDateTimeD, setBookingStartDateTime] = useState()
    const [bookingEndDateTimeD, setBookingEndDateTime] = useState()
    const [openingTimerStarted, setOpeningTimerStarted] = useState(false)
    const [closingTimerStarted, setClosingTimerStarted] = useState(false)
    const [bookingStartDateTimeLocal, setBookingStartDateTimeLocal] = useState()
    const [eventEndDateTimeLocal, setEventEndDateTimeLocal] = useState()


    const TIMER_NAME_OPEN = "Registration Opens in:"
    const TIMER_NAME_CLOSE = "Registration closes in:"

    const _displayDateTime = (dateTime) => {
        let displayDateTime = new Date(dateTime).toLocaleDateString(undefined, {
            day:    'numeric',
            month:  'numeric',
            year:   'numeric',
            hour:   '2-digit',
            minute: '2-digit',
        });

        return displayDateTime.toString()
    }

    useEffect(()=>{
        setRegistrationOpen(false)
        
        setEventStartDateTime(_displayDateTime(eventStartDateTime))
        setBookingStartDateTime(_displayDateTime(bookingStartDateTime))
        setBookingEndDateTime(_displayDateTime(bookingEndDateTime))

        let tempDate = new Date(bookingStartDateTime)
        setBookingStartDateTimeLocal(tempDate)

        let tempDate2 = new Date(eventEndDateTime)
        setEventEndDateTimeLocal(tempDate2)
    },[])

    useEffect(() => {   
        //console.log('event-booking-bookingStartDateTime', bookingStartDateTime)

        if(bookingStartDateTimeLocal && !registrationOpen){
            const initTimeoutId = setTimeout(() => {
                //update timer every 1 second
                let now = new Date().getTime();
                let distance = bookingStartDateTimeLocal - now
                
                if(distance <= 0){
                    //user can register
                    setRegistrationOpen(true)
                } else {
                    //calculate the time for opening of registration
    
                    let currentTimeToBooking ={}
                    // Time calculations for days, hours, minutes and seconds
                    currentTimeToBooking.days = Math.floor(distance / (1000 * 60 * 60 * 24));
                    currentTimeToBooking.hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                    currentTimeToBooking.minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
                    currentTimeToBooking.seconds = Math.floor((distance % (1000 * 60)) / 1000);
    
                    setTimeToBooking(currentTimeToBooking)
                }
            }, 1000);
    
            setTimeoutId(initTimeoutId)
    
            if(registrationOpen){
                clearTimeout(timeoutId)
            }
        }
    },[bookingStartDateTimeLocal, timeToBooking]) 

    useEffect(() => {   
        //console.log('event-booking-bookingEndDateTime', bookingEndDateTime)

        if(eventEndDateTimeLocal && registrationOpen && !registrationClosed){
            const initTimeoutId2 = setTimeout(() => {
                //update timer every 1 second
                let now = new Date().getTime();
                let distance = eventEndDateTimeLocal - now
                
                if(distance <= 0){
                    //registration has closed. User cannot register
                    setRegistrationClosed(true)
                } else {
                    //calculate the time for end of registration
    
                    let currentTimeToClosing ={}
                    // Time calculations for days, hours, minutes and seconds
                    currentTimeToClosing.days = Math.floor(distance / (1000 * 60 * 60 * 24));
                    currentTimeToClosing.hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                    currentTimeToClosing.minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
                    currentTimeToClosing.seconds = Math.floor((distance % (1000 * 60)) / 1000);
                    
                    setTimeToClosing(currentTimeToClosing)
                }
            }, 1000);
    
            setTimeoutId2(initTimeoutId2)
    
            if(registrationClosed){
                clearTimeout(timeoutId2)
            }
        }
    },[eventEndDateTimeLocal, timeToClosing, registrationOpen]) 

    let showDetail = false

    let isConfirmed = false
    let isWaitlist = false

    let showAvailable = false
    let showWaitList = false
    let showFull = false

    let regularSpotsAvailable = 0
    let waitListSpotsAvailable = 0

    let credit ="credit"
    
    if(mode === "detail"){
        showDetail = true
    }

    if(status){
        if(status === "confirmed"){
            isConfirmed = true
        } else if(status === "waitlist"){
            isWaitlist = true
        }
    }

    if(tokensPerSpot > 1){
        credit = "credits"
    } 
    
    if(!isConfirmed){
        //do not show any availability information if Member is confirmed
        //show if regular spots are available
        if(currentParticipants < maxParticipants){
            if(isWaitlist){
                showAvailable = true
            } else if(currentWaitingList === 0){
                showAvailable = true
            }

            if(showAvailable){
                regularSpotsAvailable =  maxParticipants - currentParticipants
            }
        }

        //show if waitlist spots are available
        if(!showAvailable && enableWaitlist && !isWaitlist){
            //only show waitlist when 
            //  1. regular spots are not available
            //  2. Waitlist is enabled
            //  3. Current Status is Not Confirmed or WaitList

            //calculate available waitlist spots
            waitListSpotsAvailable = (maxParticipants + waitlistLimit)
                                    - (currentParticipants + currentWaitingList)  

            if(waitListSpotsAvailable > 0){
                showWaitList = true
            }
        } 

        if(!isWaitlist && !showAvailable && !showWaitList){
            showFull = true
        }
    }

    let showCredits = false

    if(requiresToken){
        showCredits = true
    }

    //setup the display flags
    let showRegisterButton = false
    let countDownToRegistration = false
    let showBookingDates = false
    
    if(timeToBooking.seconds > 0 || openingTimerStarted){
        if(!openingTimerStarted){
            setOpeningTimerStarted(true)
        }
    }

    if(!registrationOpen && openingTimerStarted){
        //timer has started
        countDownToRegistration = true
    }

    if(timeToClosing.seconds > 0 || closingTimerStarted){
        if(!closingTimerStarted){
            setClosingTimerStarted(true)
        }
    }

    if(countDownToRegistration || showRegisterButton){
        showBookingDates = true
    }

    return(
        <div className="card" key={idx}>
            <div className="container">
                <div>
                    {!showDetail &&
                        <h5 className="EventTitle" onClick={()=>{handleOnSelect(idx)}}>{title}</h5> 
                    }

                    {showDetail &&
                        <h5 className="EventTitle" onClick={()=>{handleReturn()}}>{title}</h5>    
                    }
                </div>   

               {eventStartDateTime &&
                    <>
                        <h5> Starts On: </h5>
                        <h5> {eventStartDateTimeD} </h5>
                    </>
               }     
               
                
                <p> </p>

                {showDetail && bookingStartDateTime && showBookingDates &&
                    <>
                        <p></p>
                        <strong>Booking Starts On: </strong> {bookingStartDateTimeD}
                        <p></p>
                    </>
                }
                
                {showDetail && bookingEndDateTime && showBookingDates &&
                    <>
                        <p></p>
                        <strong>Booking Ends On: </strong> {bookingEndDateTimeD}
                        <p></p>
                    </>
                }

                {showCredits &&
                    <p> This event requires {tokensPerSpot} {credit} to signup online </p>
                }

                {url &&
                    <img className="Image" src={fromImageToUrl(currentEvent, "event")} alt="Club Event"/>
                }

                {!url &&
                    <img src={fromImageToUrl('', 'event')} alt="Club Event"/>
                }

                {showDetail &&
                    <div className="EventDetails">
                        <div className="EventDescription">
                            <ReactMarkdown>{description}</ReactMarkdown>
                        </div>
                    </div>
                }

                {!isWaitlist  && showAvailable  && registrationOpen &&
                    <>
                        <div>Spots Available: {regularSpotsAvailable} </div>
                        {!manageEventMode &&
                            <button 
                                className="EventReg" 
                                onClick={()=>{submitBooking(id)}}
                            > 
                                Click to Register 
                            </button>
                        }

                        <CountdownTimer 
                            timerName={TIMER_NAME_CLOSE}
                            days={timeToClosing.days}
                            hours={timeToClosing.hours}
                            minutes={timeToClosing.minutes}
                            seconds={timeToClosing.seconds}
                        />
                    </>
                }

                {countDownToRegistration && 
                    <CountdownTimer 
                        timerName={TIMER_NAME_OPEN}
                        days={timeToBooking.days}
                        hours={timeToBooking.hours}
                        minutes={timeToBooking.minutes}
                        seconds={timeToBooking.seconds}
                    />
                }

                {isWaitlist && showAvailable && !manageEventMode &&     
                    <>
                        <p>Confirm "Waitlisted" Member</p>
                        <button className="EventReg" onClick={()=>{submitWaitList(currentEvent.id)}}> Click to Confirm Waitlist </button>
                    </> 
                }        

                {showWaitList && registrationOpen &&
                    <>
                        <div> Waitlist spots available: {waitListSpotsAvailable}</div>
                        {!manageEventMode &&
                            <button className="EventReg" onClick={()=>{submitBooking(currentEvent.id)}}> Click to Register </button>
                        }

                        <CountdownTimer 
                            timerName={TIMER_NAME_CLOSE}
                            days={timeToClosing.days}
                            hours={timeToClosing.hours}
                            minutes={timeToClosing.minutes}
                            seconds={timeToClosing.seconds}
                        />
                    </>
                }
                                
                {showFull &&
                    <p> Sorry, Event is Full. No bookings are available  </p>
                }

                <div>
                    {isMemberRegistered && !manageEventMode &&
                        <>
                            {isConfirmed  &&
                                <div>
                                    <p>Member is Registered. </p>
                                    Status is "Confirmed"
                                </div>
                            }   
                            {isWaitlist  &&
                                <div>
                                    <p>Member is Registered. </p>
                                    Status is "WaitListed"
                                </div>
                            }

                            <div>
                                {!cancelRequested &&
                                    <button className="CancelEventReg" onClick={()=>{setCancelRequested(true)}}> Cancel Booking </button>
                                }

                                {cancelRequested &&
                                    <button className="CancelEventReg" onClick={()=>{submitCancel(currentEvent.id)}}> Confirm Cancellation  </button>
                                }
                            </div>
                        </>
                    }   
                </div>

                <div>
                    {!showDetail &&
                        <div className="EventFooter" onClick={()=>{handleOnSelect(idx)}}> View Event Details </div>
                    }

                    {showDetail &&
                        <div className="EventFooter" onClick={()=>{handleReturn()}}> Return to Event List </div>    
                    }
                </div>

                {manageEventMode && registrationOpen &&
                    <>
                        <Link to={`/events/event/${id}/${title}`}>Manage Participants</Link>

                        {!cancelEventRequested &&
                            <button className="CancelEvent" onClick={()=>{setCancelEventRequested(true)}}> Cancel Event </button>
                        }

                        {cancelEventRequested &&
                            <button className="CancelEvent" onClick={()=>{submitCancelEvent(id)}}> Confirm Event Cancellation  </button>
                        }
                    </>
                }
            </div>        
        </div>
    ) 
}

export default SingleEventStaff