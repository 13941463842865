import React from 'react'

import { getNtrpLabel } from '../../utils/functions';

const ViewMembers = (props) => {
    const { members } = props

    return(
        members.map((member, idx)=> {
            let adult = false
            let label = "Adult"

            if(member.memberType === "adult"){
                adult = true
                label = "Adult"
            } else {
                label = "Junior"
            }
            
            let consentNewsLetterId = `consentNewsLetter-${idx}`
            let consentPhoneBookId = `consentPhoneBook-${idx}`
            
            var memberHeading = 'Member Details'
            let ntrpSelfLabel = ''
      
            if(idx === 0){
              memberHeading = `1st Member - ${label}`
            } else if (idx === 1) {
              memberHeading = `2nd Member - ${label}` 
            } else if (idx === 2) {
              memberHeading = `3rd Member - ${label}`
            } else if (idx > 2) {
              memberHeading = `${idx+1}th Member - ${label}`        
            }

            ntrpSelfLabel = getNtrpLabel(members[idx].ntrpSelf)
      
            return (
                <div key={idx}>
                    <div className="inner-wrap">  
                        <p>{memberHeading} </p>    
                        
                        <label>First Name </label>
                        <p className="readonly">{members[idx].firstName}</p>

                        <label>Last Name </label>
                        <p className="readonly">{members[idx].lastName}</p>

                        {
                            members[idx].ntrpSelf > 0 ?
                            <>
                                <label>NTRP rating (Self Assessed) </label>
                                <p className="readonly">{ntrpSelfLabel}</p>
                            </>:<></>
                        }

                        {
                            members[idx].yearOfBirth > 0 ?
                            <>
                                <label>Year Of Birth </label>
                                <p className="readonly">{members[idx].yearOfBirth}</p>
                            </>:<></>
                        }
                        
                        {adult && members[idx].email &&
                        <> 
                            <label>Email</label>
                            <p className="readonly">{members[idx].email}</p>
                            
                            {members[idx].email && 
                            <>
                                <label htmlFor={consentNewsLetterId} className="checkbox">  
                                    Member receives club news
                                    <input 
                                        type="checkbox"
                                        name={consentNewsLetterId}
                                        data-id={idx}
                                        id={consentNewsLetterId}
                                        checked={members[idx].consentNewsLetter} 
                                        className="consentNewsLetter"
                                        readOnly
                                    />
                                </label>     
                            </>
                            }
                         </>   
                        }

                        {adult && 
                        members[idx].phoneNumber > 0 ? 
                        <>
                            <label>Phone Number</label>
                            <p className="readonly">{members[idx].phoneNumber}</p>
                            
                            {members[idx].phoneNumber &&
                            <>
                                <label htmlFor={consentPhoneBookId}  className="checkbox">
                                    Phone Number is stored in club directory
                                    <input
                                        type="checkbox"
                                        name={consentPhoneBookId}
                                        data-id={idx}
                                        id={consentPhoneBookId}
                                        checked={members[idx].consentPhoneBook} 
                                        className="consentPhoneBook"
                                        readOnly
                                    />
                                </label>
                            </> 
                            } 
                        </>
                        : <></>
                        }
                    </div>
                </div>
            )
        })
    )
}

export default ViewMembers
