import React, { useEffect, useState } from 'react'
import {requestFees} from '../../utils/fetchFees'

import './MembershipFees.css'

const MembershipFees = ({configValues}) => {
    const [fees, setFees] = useState([{}])
    const [discounts, setDiscounts] = useState([{}])
    
    let {
        addHST,
        hstIncluded,
        currentSeason,
        maxAgeJunior
    } = configValues

    const yearOfBirth = currentSeason - maxAgeJunior
    const juniorLabel = `Juniors are those born on or after Jan 1st ${yearOfBirth}`
    const adultLabel = `Those born before Jan 1st ${yearOfBirth} will be considered Adults`

    let refreshFees = false

    useEffect(()=>{
        requestFees(refreshFees).then(data => {
            initializeFees(data.outputFees)
            setDiscounts(data.outputDiscounts)
            //console.log('fees', data.outputFees)
        })
    },[])

    //get current date - future for discounts only
    //let currentDate = new Date()
    //let utcDate= currentDate.getUTCDate();

    //console.log('current date:', currentDate)
    //console.log('utc date:', utcDate)
    
    const initializeFees = (inputFees) => {
        //add showDetails flag to each fee before setting it
        inputFees.map((inputFee) => {
            inputFee.showDetails = false
            
            //console.log('membershipType: ', inputFee.membershipType)

            switch(inputFee.membershipType){
                case "family":
                case "double":    
                    inputFee.showJuniorLabel = true
                    inputFee.showAdultLabel = true
                    break;
                case "junior":
                    inputFee.showAdultLabel = false
                    inputFee.showJuniorLabel = true
                    break;
                case "single":
                case "couple":
                    inputFee.showAdultLabel = true
                    inputFee.showJuniorLabel = false
                    break;
                default:
                    inputFee.showJuniorLabel = false
                    inputFee.showAdultLabel = false
                    break;
            }
        })

        setFees(inputFees)
    }

    const toggleMembershipDetails = (idx) => {
        let updatedFees = [{}]
        
        for(let i=0; i < fees.length; i++){
            updatedFees[i] = {...fees[i]}        
        }

        if(updatedFees[idx].showDetails){
            updatedFees[idx].showDetails = false
        } else {
            updatedFees[idx].showDetails = true
        }
        
        setFees(updatedFees)
    }

    return(
        <div className="MembershipFees">
            <h3> Membership Fees </h3>

            {fees && fees.length > 0 && 
                <>
                    <div className="CurrentFees">
                        {fees.map((fee, idx) =>{
                            return(
                                <div className="card" key={idx} onClick={() => toggleMembershipDetails(idx)}>
                                    <div className="container">
                                        <h5><span className="FeeTitle">{fee.membershipLabel}</span></h5>

                                        {(fee.discountAmount > 0) &&
                                            <>
                                                <div>Seasonal Fee: ${fee.amount}</div>
                                                <div>Total discounts: ${fee.discountAmount}</div>
                                            </>
                                        }
                                        
                                        {addHST && !hstIncluded &&
                                            <>
                                                <div className="feeTotal">Membership Fee: ${fee.totalAmount}</div> 
                                                <div className="feeTotal">HST: ${(fee.totalAmount * configValues.percentHST/100).toFixed(2)} </div>
                                                <div className="feeTotal">Total Amount: ${(fee.totalAmount * (1 + configValues.percentHST/100)).toFixed(2)}</div> 
                                            </>
                                        }
                                    
                                        {addHST && hstIncluded &&
                                            <>
                                                <div className="feeTotal">Membership Fee: ${fee.totalAmount}</div> 
                                                <div className="feeTotal">HST (included): ${(fee.totalAmount * configValues.percentHST/(100 + configValues.percentHST)).toFixed(2)} </div>
                                                <div className="feeTotal">Total Amount: ${(fee.totalAmount)}</div> 
                                            </>
                                        }
                                    
                                        {!addHST &&
                                            <>
                                                <span className="feeTotal">Membership Fee: ${fee.totalAmount}</span> 
                                            </>
                                        }
                                    
                                        <p></p>
                                        {!fee.showDetails &&
                                            <div className="showHide">
                                                Show details ....     
                                            </div>   
                                        } 
                                        
                                        {fee.showDetails &&
                                            <div>
                                                <div className="details">
                                                    {fee.description}
                                                    {fee.showJuniorLabel &&
                                                        <div className="label"> {juniorLabel} </div>
                                                    }
                                                    {fee.showAdultLabel &&
                                                        <div className="label"> {adultLabel} </div>
                                                    }
                                                </div>
                                                
                                                <div className="showHide"> 
                                                    Hide details ... 
                                                </div>
                                            </div>   
                                        } 
                                        
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </>
            }
         </div>
    )
}

export default MembershipFees